import {createApp} from 'vue'
import App from './App.vue'
import i18n from "@/util/i18n"
import {createRouter, createWebHistory} from 'vue-router'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import Tooltip from 'primevue/tooltip'
import dayjs from "@/util/dayjs"
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import "leaflet/dist/leaflet.css"
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

//import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import 'vue-advanced-cropper/dist/style.css'

//Route imports:
import OrganizationRegistrationForm from "@/components/common/OrganizationRegistrationForm.vue"
import PasswordReset from "@/components/PasswordReset.vue"
import UserList from "@/components/admin/UserList.vue"
import ProjectList from "@/components/common/ProjectList.vue"
import OrganizationList from "@/components/admin/OrganizationList.vue"
import ProjectView from "@/components/common/ProjectView.vue"
import UserDetails from "@/components/common/UserDetails.vue"
import UserRegistrationForm from "@/components/common/UserRegistrationForm.vue"
import OrganizationDetails from "@/components/common/OrganizationDetails.vue"
import Organization from "@/model/Organization"
import Contact from "@/model/Contact"
import AuditLog from "@/components/admin/AuditLog.vue"
import SystemSettings from "@/components/admin/SystemSettings.vue"
import Greeting from "@/components/Greeting.vue"
import User from "@/model/User"
import {reactive} from "@vue/reactivity"
import TopMenu from "@/components/TopMenu.vue"
import AdminSidebar from "@/components/controls/AdminSidebar.vue"
import ProjectForm from "@/components/admin/ProjectForm.vue"
import ProjectTemplateList from "@/components/admin/ProjectTemplateList.vue"
import ProjectTemplateForm from "@/components/admin/ProjectTemplateForm.vue"
import {rpcClient} from "@/api/WebsocketClient"
import LoginForm from "@/components/LoginForm.vue"
import Sidebar from "@/components/controls/Sidebar.vue"
import PropertyList from "@/components/properties/PropertyList.vue"
import PropertyDetails from "@/components/properties/PropertyDetails.vue"
import globalState from "@/util/GlobalState"
import LocationList from "@/components/locations/LocationList.vue"
import PropertySettings from "@/components/admin/PropertySettings.vue"
import Changelog from '@/components/admin/Changelog.vue'
//Print Versions:

declare const __GIT_HASH__: string
declare const __GIT_VERSION__: string

console.log(__GIT_VERSION__) // eslint-disable-line no-console
console.log(__GIT_HASH__) // eslint-disable-line no-console

const routes = [
    {
        path: "/",
        components: {
            default: Greeting
        }
    },
    {
        path: "/wizard/:step1?/:step2?/:step3?/",
        components: {
            default: Greeting,
            topbar: TopMenu
        },
        meta: { requiresAuth: ($route: any) => { return $route.path !== '/wizard/2' } }
    },
    {
        path: "/standorte",
        components: {
            default: LocationList,
            topbar: TopMenu,
            sidebar: AdminSidebar
        }
    },
    {
        path: "/grundstuecke",
        components: {
            default: PropertyList,
            topbar: TopMenu,
            sidebar: AdminSidebar
        }
    },
    {
        path: "/grundstuecke/:id",
        components: {
            default: PropertyDetails,
            topbar: TopMenu,
            sidebar: AdminSidebar
        }
    },
    {
        path: "/projekte",
        components: {
            default: ProjectList,
            topbar: TopMenu,
            sidebar: AdminSidebar
        }
    },
    {
        //projekte/1/datenraum/1/angebot/1
        path: "/projekte/:id/:childRoute1?/:childId1?/:childRoute2?/:childId2?",
        components: {
            default: ProjectView,
            topbar: TopMenu,
            sidebar: Sidebar
        }
    },
    {
        path: "/registrierung",
        component: OrganizationRegistrationForm,
        props: {
            organization: reactive(Object.assign(new Organization(), {
                contact: new Contact(), type: 'CONTRACTOR'
            }))
        }
    },
    {
        path: "/benutzer/anlage",
        components: {
            default: UserRegistrationForm,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        props: {
            default: {
                user: reactive(Object.assign(new User(), {
                    roles: [ 'ORGANIZATION_USER' ],
                    contact: new Contact()
                }))
            }
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/benutzer/intern/anlage",
        components: {
            default: UserRegistrationForm,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        props: {
            default: {
                user: reactive(Object.assign(new User(), {
                    roles: [ 'SYSTEM_USER' ],
                    contact: new Contact()
                }))
            }
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/benutzer",
        components: {
            default: UserList,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        props: {
            default: {
                role: 'ORGANIZATION_USER'
            }
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/benutzer/intern",
        components: {
            default: UserList,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        props: {
            default: {
                role: 'SYSTEM_USER'
            }
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/benutzer/:email",
        components: {
            default: UserDetails,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/organisation",
        components: {
            default: OrganizationDetails,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/organisation/:id",
        alias: [ "/auftraggeber/:id", "/bieter/:id" ],
        components: {
            default: OrganizationDetails,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/auftraggeber",
        components: {
            default: OrganizationList,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        props: {
            default: {
                type: 'CLIENT'
            }
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/bieter",
        components: {
            default: OrganizationList,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        props: {
            default: {
                type: 'CONTRACTOR'
            }
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/bieter/anlage",
        components: {
            default: OrganizationRegistrationForm,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        props: {
            default: {
                organization: reactive(Object.assign(new Organization(), {
                    contact: new Contact(), type: 'CONTRACTOR'
                }))
            }
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/auftraggeber/anlage",
        components: {
            default: OrganizationRegistrationForm,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        props: {
            default: {
                organization: reactive(Object.assign(new Organization(), {
                    contact: new Contact(), type: 'CLIENT'
                }))
            }
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/projekte/bearbeiten/:id?",
        components: {
            default: ProjectForm,
            topbar: TopMenu,
            sidebar: Sidebar
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/projekte/anlage/:id?",
        components: {
            default: ProjectForm,
            topbar: TopMenu,
            sidebar: Sidebar
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/formulare",
        components: {
            default: ProjectTemplateList,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/formulare/anlage/:id?",
        components: {
            default: ProjectTemplateForm,
            topbar: TopMenu,
            sidebar: Sidebar
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/audit-log/:id?",
        components: {
            default: AuditLog,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/changelog",
        components: {
            default: Changelog,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/einstellungen",
        components: {
            default: SystemSettings,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/grundstueckseinstellungen",
        components: {
            default: PropertySettings,
            topbar: TopMenu,
            sidebar: AdminSidebar
        },
        meta: { requiresAuth: true }
    },
    {
        path: "/passwort-reset",
        component: PasswordReset
    },
    {
        path: "/login",
        component: LoginForm
    }
]

//@ts-ignore
const router = createRouter({
    history: createWebHistory(),
    //@ts-ignore
    routes,
    //@ts-ignore
    scrollBehavior: function(to, from, savedPosition) {
        if (to.hash) {
            const element = document.getElementById(to.hash.replace('#', ''))
            const parent = getScrollParent(element)
            if (element && parent) {
                setTimeout(() => {
                    const diff = element.getBoundingClientRect().top - parent.getBoundingClientRect().top + parent.scrollTop - 30
                    parent.scrollTo({ top: Math.max(diff, 0), left: 0, behavior: 'smooth' })
                }, to.path.includes('projekte/anlage') ? 500 : 0)
                return false
                /*const rect = element.getBoundingClientRect()
                if (rect) {
                    return { top: rect.y - 180 + window.scrollY, behavior: 'smooth' }
                }*/
            }
            return { x: 0, y: 0 }
        } else {
            return { x: 0, y: 0 }
        }
    }
})

function getScrollParent(node: any): HTMLElement | null {
    if (!node) {
        return null
    } else if (node.scrollHeight > node.clientHeight) {
        return node
    } else if (node.parentNode) {
        return getScrollParent(node.parentNode)
    } else {
        return null
    }
}

const localeForPrimeVue: any = {
    startsWith: i18n.$gettext('Beginnt mit'),
    contains: i18n.$gettext('Enthält'),
    notContains: i18n.$gettext('Enthält nicht'),
    endsWith: i18n.$gettext('Endet mit'),
    equals: i18n.$gettext('Ist gleich'),
    notEquals: i18n.$gettext('ist ungleich'),
    noFilter: i18n.$gettext('Kein Filter'),
    lt: i18n.$gettext('Kleiner als'),
    lte: i18n.$gettext('Kleiner oder gleich'),
    gt: i18n.$gettext('Größer als'),
    gte: i18n.$gettext('Größer oder gleich'),
    dateIs: i18n.$gettext('Datum ist'),
    dateIsNot: i18n.$gettext('Datum ist nicht'),
    dateBefore: i18n.$gettext('Datum ist vor dem'),
    dateAfter: i18n.$gettext('Datum ist nach dem'),
    clear: i18n.$gettext('Leeren'),
    apply: i18n.$gettext('Anwenden'),
    matchAll: i18n.$gettext('Alles stimmt überein'),
    matchAny: i18n.$gettext('Etwas stimmt überein'),
    addRule: i18n.$gettext('Regel hinzufügen'),
    removeRule: i18n.$gettext('Regel löschen'),
    accept: i18n.$gettext('Ja'),
    reject: i18n.$gettext('Nein'),
    choose: i18n.$gettext('Auswählen'),
    upload: i18n.$gettext('Hochladen'),
    cancel: i18n.$gettext('Abbrechen'),
    dayNames: [
        i18n.$gettext("Sonntag"),
        i18n.$gettext("Montag"),
        i18n.$gettext("Dienstag"),
        i18n.$gettext("Mittwoch"),
        i18n.$gettext("Donnerstag"),
        i18n.$gettext("Freitag"),
        i18n.$gettext("Samstag")
    ],
    dayNamesShort: [
        i18n.$gettext("So"),
        i18n.$gettext("Mo"),
        i18n.$gettext("Di"),
        i18n.$gettext("Mi"),
        i18n.$gettext("Do"),
        i18n.$gettext("Fr"),
        i18n.$gettext("Sa")
    ],
    dayNamesMin: [
        i18n.$gettext("So"),
        i18n.$gettext("Mo"),
        i18n.$gettext("Di"),
        i18n.$gettext("Mi"),
        i18n.$gettext("Do"),
        i18n.$gettext("Fr"),
        i18n.$gettext("Sa")
    ],
    monthNames: [
        i18n.$gettext("Januar"),
        i18n.$gettext("Februar"),
        i18n.$gettext("März"),
        i18n.$gettext("April"),
        i18n.$gettext("Mai"),
        i18n.$gettext("Juni"),
        i18n.$gettext("Juli"),
        i18n.$gettext("August"),
        i18n.$gettext("September"),
        i18n.$gettext("Oktober"),
        i18n.$gettext("November"),
        i18n.$gettext("Dezember")
    ],
    monthNamesShort: [
        i18n.$gettext("Jan"),
        i18n.$gettext("Feb"),
        i18n.$gettext("Mar"),
        i18n.$gettext("Apr"),
        i18n.$gettext("Mai"),
        i18n.$gettext("Jun"),
        i18n.$gettext("Jul"),
        i18n.$gettext("Aug"),
        i18n.$gettext("Sep"),
        i18n.$gettext("Okt"),
        i18n.$gettext("Nov"),
        i18n.$gettext("Dez")
    ],
    today: i18n.$gettext('Heute'),
    weekHeader: i18n.$gettext('Woche'),
    firstDayOfWeek: 1,
    dateFormat:'dd.mm.yy',
    weak: i18n.$gettext('Schwach'),
    medium: i18n.$gettext('Mittel'),
    strong: i18n.$gettext('Start'),
    passwordPrompt: i18n.$gettext('Geben Sie ein Passwort ein'),
    emptyFilterMessage: i18n.$gettext('Keine Ergebnisse gefunden'),
    emptyMessage: i18n.$gettext('Keine verfügbaren Optionen'),
    aria: {
        navigation: i18n.$gettext('Navigation'),
        slideNumber: "{slideNumber}",
        pageLabel: "{pageLabel}"
    }
}

dayjs.extend(LocalizedFormat)

// @ts-ignore
const app = createApp(App)
// @ts-ignore
//app.component('v-select', vSelect);
app.use(PrimeVue, { locale: localeForPrimeVue})
app.use(ToastService)
app.use(ConfirmationService)
app.use(i18n)

router.beforeEach((to, from) => {
    if (to.path === '/' || to.path === '') {
        if ((rpcClient.fullyLoggedIn || rpcClient.needsTwoFactorConfirm) && !rpcClient.isInternalUser) {
            return { path: '/projekte', meta: { animateButtons: true } }
        } else if (rpcClient.isInternalUser) {
            return { path: '/wizard/1', meta: { animateButtons: true } }
        }
    }
    if (from?.path !== to?.path &&
        (from?.path?.includes('projekte/anlage') ||
        from?.path?.includes('formulare/anlage')) &&
        !globalState.dataSaved) {
        const answer = window.confirm(i18n.$gettext('Sind Sie sicher, dass Sie die Seite verlassen wollen? Ungespeicherte Änderungen gehen verloren.'))
        if (!answer) return false
    }
    if (to?.meta?.requiresAuth === true || (typeof to?.meta?.requiresAuth === 'function' && to.meta.requiresAuth(to) === true)) {
        if (rpcClient.needsTwoFactorConfirm) {
            rpcClient.nextRoute.after2FA = to
            return false
        } else if (!rpcClient.fullyLoggedIn) {
            rpcClient.nextRoute.afterLogin = to
            return false
        }
    }
    rpcClient.nextRoute.after2FA = null
    rpcClient.nextRoute.afterLogin = null
    globalState.dataSaved = false
    return true
})

app.use(router)
app.directive('tooltip', Tooltip)
app.mount('#app')
export const toastServiceInstance = app.config.globalProperties.$toast
