
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import ProgressBar from "primevue/progressbar"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import UserForm from "@/components/common/UserForm.vue"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import RpcError from "@/api/RpcError"
import Multiselect from "@/components/controls/Multiselect.vue"
import SWR from "@/api/SWR"
import Organization from "@/model/Organization"
import {organizationServiceApi} from "@/api/OrganizationServiceApi"
import Dropdown from "@/components/controls/Dropdown.vue"
import Dialog from "@/components/common/Dialog.vue"
import useToast from "@/util/toasts"
import {MenuItem} from "primevue/menuitem"
import Breadcrumb from "primevue/breadcrumb"
import {userStore} from "@/store/UserStore"

@Options({
  name: "UserRegistrationForm",
  components: {
    AnimatedInput, Button, ProgressBar, Multiselect, Dropdown, Dialog, Breadcrumb
  },
  //@ts-ignore
  props: {
    user: [ User, Object ]
  }
})
export default class UserRegistrationForm extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient
  toast = useToast()

  user!: User
  oldPassword: string = ''
  newPassword: string = ''
  passwordRepeat: string = ''
  showChangePasswordDialog: boolean = false

  roleChoices: any = this.isIntern ? {
    SYSTEM_USER: 'Sachbearbeiter',
    SYSTEM_ADMIN: 'System-Administrator'
  } : {
    ORGANIZATION_USER: 'Organisations-Mitglied',
    ORGANIZATION_ADMIN: 'Organisations-Administrator'
  }

  get isIntern(): boolean {
    return !!this.user?.roles?.find((r: string) => r === 'SYSTEM_USER')
  }

  get organizations(): SWR<Organization[], number[]> {
    return organizationServiceApi.getOrganizations('CONTRACTOR')
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    if (this.isIntern && rpcClient.isInternalUser) {
      breadcrumbs.push({
        label: this.i18n.$gettext('Interne Benutzer'),
        to: '/benutzer/intern'
      })
    } else {
      breadcrumbs.push({
        label: rpcClient.isInternalUser ? this.i18n.$gettext('Externe Benutzer') : this.i18n.$gettext('Benutzer'),
        to: '/benutzer'
      })
    }
    breadcrumbs.push({
      label: this.i18n.$gettext('Benutzer anlegen'),
      to: this.$route.path
    })
    return breadcrumbs
  }

  openChangePasswordDialog() {
    this.showChangePasswordDialog = true
  }

  resetChangePasswordDialog() {
    this.showChangePasswordDialog = false
    this.oldPassword = ''
    this.newPassword = ''
    this.passwordRepeat = ''
  }

  changePassword() {
    userServiceApi._changePassword(this.oldPassword, null, this.newPassword).then(() => {
      rpcClient.logout()
      this.toast.error(this.i18n.$gettext('Das Passwort wurde geändert.'))
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Das Passwort konnte nicht geändert werden.'))
    })
  }

  save() {
    if (!!this.user.authenticationBackend) {
      userServiceApi._updateUser(this.user).then((userName: string) => {
        this.toast.success(this.i18n.$gettext('Der Benutzer wurde gespeichert.'))
        Object.assign(this.user, userStore.state.users.get(userName))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Der Benutzer konnte nicht gespeichert werden.'))
      })
    } else {
      userServiceApi._createUser(this.user).then((userName: string) => {
        this.toast.success(this.i18n.$gettext('Der Benutzer wurde gespeichert.'))
        Object.assign(this.user, userStore.state.users.get(userName))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Der Benutzer konnte nicht gespeichert werden.'))
      })
    }
  }
}
