import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ff71031"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "list-wrapper",
  class: "h-100"
}
const _hoisted_2 = {
  key: 0,
  class: "list-loading"
}
const _hoisted_3 = { class: "pl-3 pr-3 pt-2 pb-4 d-flex" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "cil-arrow-bottom"
}
const _hoisted_6 = {
  key: 1,
  class: "cil-arrow-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.initialLoadDone && _ctx.loadingOrRefreshing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "loading", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      id: "root",
      ref: "root",
      style: _normalizeStyle([{"overflow":"auto"}, _ctx.rootStyle])
    }, [
      _createElementVNode("div", {
        id: "viewport",
        ref: "viewport",
        style: _normalizeStyle(_ctx.viewportStyle)
      }, [
        _createElementVNode("div", {
          id: "spacer",
          ref: "spacer",
          style: _normalizeStyle([_ctx.spacerStyle, {"padding-top":"1px"}])
        }, [
          (_ctx.scrollDirection < 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                style: _normalizeStyle({ 'height': _ctx.bottomSpacerHeight + 'px' })
              }, null, 4))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleItems, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item[_ctx.idProperty],
              ref_for: true,
              ref: el => { _ctx.rows.set(item[_ctx.idProperty], el) },
              class: "list-item"
            }, [
              (item.isDummy)
                ? _renderSlot(_ctx.$slots, "skeleton", { key: 0 }, () => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_Skeleton, {
                        height: "36px",
                        class: "flex-grow-1"
                      })
                    ])
                  ], true)
                : _renderSlot(_ctx.$slots, "element", {
                    key: 1,
                    item: item
                  }, undefined, true)
            ]))
          }), 128)),
          (_ctx.initialLoadDone && _ctx.visibleItems.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "empty", {}, undefined, true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.scrollDirection > 0 && _ctx.bottomSpacerHeight > 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                style: _normalizeStyle({ 'height': _ctx.bottomSpacerHeight + 'px' })
              }, null, 4))
            : _createCommentVNode("", true)
        ], 4)
      ], 4)
    ], 4),
    (_ctx.showScrollToStartButton && ((!_ctx.isAtStart && _ctx.scrollDirection < 0) || (_ctx.isAtEnd && _ctx.scrollDirection >= 0)))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: "scrollToStartButton",
          class: "z-2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollToStart('smooth')))
        }, [
          (_ctx.scrollDirection < 0)
            ? (_openBlock(), _createElementBlock("i", _hoisted_5))
            : (_openBlock(), _createElementBlock("i", _hoisted_6))
        ]))
      : _createCommentVNode("", true)
  ]))
}