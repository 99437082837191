import {reactive} from "@vue/reactivity"
import axios, {AxiosResponse} from "axios"
export class NominatimResponse {

  place_id!: number
  licence!: string
  osm_type!: string
  osm_id!: number
  boundingbox!: string[]
  lat!: string
  lon!: string
  display_name!: string
  class!: string
  type!: string
  importance!: number
  address!: NominatimAddressStructure
  geojson!: any
}

export class NominatimAddressStructure {
  road!: string
  quarter!: string
  suburb!: string
  city_district!: string
  city!: string
  state!: string
  postcode!: string
  country!: string
  country_code!: string
  house_number!: string
  hamlet!: string
  village!: string
  locality!: string
  town!: string
}

export function getSafeDisplayNameOfAdress(address: NominatimAddressStructure): string {
  let streetlevel: string =
    (address.road ? address.road :
      (address.hamlet ? address.hamlet :
        (address.locality ? address.locality : "")))  +
    (address.house_number ? " " + address.house_number : "")
  streetlevel = streetlevel.trim()

  let municipalityLevel: string = (address.postcode ? address.postcode : "") + " " +  (address.city ? address.city : (address.village ? address.village : (address.town ? address.town : "")))
  municipalityLevel = municipalityLevel.trim()

  let stateLevel: string = (address.state ? address.state : "")
  stateLevel = stateLevel.trim()

  return  ((streetlevel ? streetlevel + ", " : "") + (municipalityLevel ? municipalityLevel + ", ": "") + stateLevel).trim()
}

export function getDetailsTypeOfOsmType(osmType: string) {
  switch (osmType) {
    case "way":
      return "w"
    case "node":
      return "n"
    case "relation":
      return "r"
  }
}

export default class Nominatim {

  private readonly serviceUrl: string = 'http://192.168.1.180/nominatim/search'
  private data: { items: NominatimResponse[] } = reactive({items: [] })

  constructor() {
  }

  async query(query: string): Promise<void> {
    const res: AxiosResponse<NominatimResponse[]> = await axios.get(this.serviceUrl, { params: {
        q: query,
        format: 'json',
        results: '10',
        addressdetails: '1',
        polygon_geojson: '1'
      } })
    let result: NominatimResponse[] = res.data
    console.log("Got response:", result)
    //Filter for same name:
    result = result.filter((item: NominatimResponse, index: number) => {
      return result.findIndex((needle: NominatimResponse) => {
          return getSafeDisplayNameOfAdress(needle.address) === getSafeDisplayNameOfAdress(item.address)
        }) === index
    })
    this.data.items = result
  }

  get result(){
    return this.data.items
  }
}
