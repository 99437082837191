import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container-wide" }
const _hoisted_2 = { class: "row align-items-center justify-content-around" }
const _hoisted_3 = { class: "col-12 col-lg-10" }
const _hoisted_4 = { class: "card p-sm-4 p-md-6 shadow-row-element" }
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = { class: "row d-flex align-items-center mb-4" }
const _hoisted_7 = { class: "col-auto pl-0" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "text-dark" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "d-flex flex-row flex-wrap justify-content-end mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_AnimatedInput = _resolveComponent("AnimatedInput")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("form", {
            class: "card-body",
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.enable && _ctx.enable(...args)), ["prevent"]))
          }, [
            _createElementVNode("h1", _hoisted_5, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Zwei-Faktor-Authentifizierung")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_ctx.qrCode)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: 'data:image/png;base64, ' + _ctx.qrCode
                    }, null, 8, _hoisted_8))
                  : (_openBlock(), _createBlock(_component_ProgressSpinner, { key: 1 }))
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Zum Aktivieren der Zwei-Faktor-Authentifizierung, scannen Sie den QR-Code mit einer RFC6238-kompatiblen App und geben Sie den Code aus der App und das Passwort des Benutzerkontos zur Überprüfung an.")
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_AnimatedInput, {
                  id: "login",
                  name: "newPassword",
                  type: "password",
                  label: _ctx.i18n.$gettext('Zwei-Faktor Code'),
                  modelValue: _ctx.code,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
                  modelModifiers: { lazy: true, trim: true },
                  required: ""
                }, null, 8, ["label", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_AnimatedInput, {
                  name: "newPasswordRepeat",
                  type: "password",
                  label: _ctx.i18n.$gettext('Passwort'),
                  modelValue: _ctx.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                  modelModifiers: { lazy: true, trim: true },
                  required: ""
                }, null, 8, ["label", "modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              (_ctx.canSkip)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    id: "disable",
                    class: "p-button-text flat mr-2",
                    variant: "primary",
                    onClick: _ctx.disable
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Überspringen")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_Button, {
                id: "signin",
                type: "submit",
                variant: "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Absenden")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ], 32)
        ])
      ])
    ])
  ]))
}