import Content from "@/model/common/Content"
import {reactive} from "@vue/reactivity"
import ContentUtil from "@/util/ContentUtil"
import dayjs from "@/util/dayjs"
import i18n from "@/util/i18n"

class Scheme {

    id: string
    count: number = 0
    values: string[] = reactive([])

    constructor(id: string) {
        this.id = id
    }

    createNewValue(): string {
        this.count ++
        const numPart = "000" + (this.count)
        const newValue = this.id + '-' + numPart.substr(numPart.length - 4)
        this.addValue(newValue)
        return newValue
    }

    addValue(newValue: string) {
        if (!this.values.includes(newValue))
            this.values.push(newValue)
    }

    removeValue(value: string) {
        this.values.filter(e => e !== value)
    }
}

export default class SdkServiceClient {

    private static loadedInitial: boolean = false
    private static loadedNoticeTypes: boolean = false
    private static loadedTranslations: boolean = false

    static selectedLanguage: string = ""
    static sdkVersion: string = "1.7"
    static ublVersion: string = ""

    static schemes: Scheme[] = reactive([])

    static fields: any = reactive({})
    static nodes: any = reactive({})
    static codeLists: any = reactive({})
    static codeListsPromises: any = reactive({})

    // Can be used to add missing translations
    static translationsAdditions: any = {
       // "de": {"group|name|ND-LocalLegalBasisNoID": "Sonstige Rechtsgrundlage ohne bekannte Kennung"}
       "de": {
           "group|name|meta-data-group": "Meta Informationen",
           "group|name|ND-ChangedSection": "Nummer des geänderten Absatzes"
       }
    }
    static translations: any = reactive({
        "notice|name|1": "Bekanntmachung der Veröffentlichung einer Vorinformation in einem Beschafferprofil – allgemeine Richtlinie",
        "notice|name|10": "Vorinformation als Aufruf zum Wettbewerb – allgemeine Richtlinie, Standardregelung",
        "notice|name|11": "Regelmäßige nicht verbindliche Bekanntmachung als Aufruf zum Wettbewerb – Sektorenrichtlinie, Standardregelung",
        "notice|name|12": "Vorinformation als Aufruf zum Wettbewerb – allgemeine Richtlinie, Sonderregelung",
        "notice|name|13": "Regelmäßige nicht verbindliche Bekanntmachung als Aufruf zum Wettbewerb – Sektorenrichtlinie, Sonderregelung",
        "notice|name|14": "Vorinformation als Aufruf zum Wettbewerb – Konzessionsrichtlinie, Sonderregelung",
        "notice|name|15": "Bekanntmachung über das Bestehen eines Qualifizierungssystems – Sektorenrichtlinie",
        "notice|name|16": "Auftragsbekanntmachung – allgemeine Richtlinie, Standardregelung",
        "notice|name|17": "Auftragsbekanntmachung – Sektorenrichtlinie, Standardregelung",
        "notice|name|18": "Auftragsbekanntmachung – Richtlinie für Beschaffung im Bereich Verteidigung, Standardregelung",
        "notice|name|19": "Konzessionsbekanntmachung – Richtlinie für Beschaffung im Bereich Verteidigung, Standardregelung",
        "notice|name|2": "Bekanntmachung der Veröffentlichung einer regelmäßigen nicht verbindlichen Bekanntmachung in einem Beschafferprofil – Sektorenrichtlinie",
        "notice|name|20": "Auftragsbekanntmachung – allgemeine Richtlinie, Sonderregelung",
        "notice|name|21": "Auftragsbekanntmachung – allgemeine Richtlinie, Sonderregelung",
        "notice|name|22": "Bekanntmachung über Unteraufträge – Richtlinie für Beschaffung im Bereich Verteidigung",
        "notice|name|23": "Wettbewerbsbekanntmachung – allgemeine Richtlinie, Wettbewerb",
        "notice|name|24": "Wettbewerbsbekanntmachung – Sektorenrichtlinie, Wettbewerb",
        "notice|name|25": "Bekanntmachung für die Zwecke der freiwilligen Ex-Ante-Transparenz – allgemeine Richtlinie",
        "notice|name|26": "Bekanntmachung für die Zwecke der freiwilligen Ex-Ante-Transparenz – Sektorenrichtlinie",
        "notice|name|27": "Bekanntmachung für die Zwecke der freiwilligen Ex-Ante-Transparenz – Richtlinie für Beschaffung im Bereich Verteidigung",
        "notice|name|28": "Bekanntmachung für die Zwecke der freiwilligen Ex-Ante-Transparenz – Konzessionsrichtlinie",
        "notice|name|29": "Vergabebekanntmachung – allgemeine Richtlinie, Standardregelung",
        "notice|name|3": "Bekanntmachung der Veröffentlichung einer Vorinformation in einem Beschafferprofil – Richtlinie für Beschaffung im Bereich Verteidigung",
        "notice|name|30": "Vergabebekanntmachung – Sektorenrichtlinie, Standardregelung",
        "notice|name|31": "Vergabebekanntmachung – Richtlinie für Beschaffung im Bereich Verteidigung, Standardregelung",
        "notice|name|32": "Zuschlagsbekanntmachung – Konzessionsrichtlinie, Standardregelung",
        "notice|name|33": "Vergabebekanntmachung – allgemeine Richtlinie, Sonderregelung",
        "notice|name|34": "Vergabebekanntmachung – Sektorenrichtlinie, Sonderregelung",
        "notice|name|35": "Zuschlagsbekanntmachung – Konzessionsrichtlinie, Sonderregelung",
        "notice|name|36": "Bekanntmachung über das Ergebnis des Wettbewerbs – allgemeine Richtlinie, Wettbewerb",
        "notice|name|37": "Bekanntmachung über das Ergebnis des Wettbewerbs – Sektorenrichtlinie, Wettbewerb",
        "notice|name|38": "Bekanntmachung über Auftragsänderung – allgemeine Richtlinie",
        "notice|name|39": "Bekanntmachung über Auftragsänderung – Sektorenrichtlinie",
        "notice|name|4": "Vorinformation nur zu Informationszwecken – allgemeine Richtlinie",
        "notice|name|40": "Bekanntmachung über Auftragsänderung – Konzessionsrichtlinie",
        "notice|name|5": "Regelmäßige nicht verbindliche Bekanntmachung nur zu Informationszwecken – Sektorenrichtlinie",
        "notice|name|6": "Vorinformation nur zu Informationszwecken – Richtlinie für Beschaffung im Bereich Verteidigung",
        "notice|name|7": "Vorinformation zum Zweck der Verkürzung der Frist für den Eingang der Angebote – allgemeine Richtlinie",
        "notice|name|8": "Regelmäßige nicht verbindliche Bekanntmachung zum Zweck der Verkürzung der Frist für den Eingang der Angebote – Sektorenrichtlinie",
        "notice|name|9": "Vorinformation zum Zweck der Verkürzung der Frist für den Eingang der Angebote – Richtlinie für die Beschaffung im Bereich Verteidigung",
        "notice|name|CEI": "Aufruf zur Interessenbekundung",
        "notice|name|E1": "Preliminary market consultation notice",
        "notice|name|E2": "Below Threshold Prior Information Notice (PIN) Only",
        "notice|name|E3": "Below Threshold Contract Notice (CN) general",
        "notice|name|E4": "Below Threshold Contract Award Notice (CAN) general",
        "notice|name|E5": "Contract Completion Notice",
        "notice|name|T01": "Vorinformation für öffentliche Dienstleistungsaufträge",
        "notice|name|T02": "Bekanntmachung über vergebene öffentliche Dienstleistungsaufträge",
        "notice|name|X01": "Europäische wirtschaftliche Interessenvereinigung",
        "notice|name|X02": "Europäische Gesellschaft / Europäische Genossenschaft"
    })

    static availableNoticeSubtypes: string[] = reactive([
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "CEI",
        "T01",
        "T02",
        "X01",
        "X02"
    ])

    static createNewValueForSchemeId(id: string): string {
        let scheme: Scheme|undefined = this.schemes.find((scheme: Scheme) => scheme.id == id)
        if (scheme == undefined) {
           scheme = new Scheme(id)
           this.schemes.push(scheme)
        }
        return scheme.createNewValue()
    }

    static removeValueForSchemeId(id: string, value: string) {
        const scheme: Scheme|undefined = this.schemes.find((scheme: Scheme) => scheme.id == id)
        if (scheme) scheme.removeValue(value)
    }

    static listValuesForSchemeId(id:string): string[] {
        const scheme: Scheme|undefined = this.schemes.find((scheme: Scheme) => scheme.id == id)
        return scheme?.values || []
    }

    static listValuesForSchemeIds(ids:string[]): string[] {
        const values: string[] = []
        ids?.forEach((id) => values.push(...this.listValuesForSchemeId(id)))
        return values
    }

    static getSubTypeForField(field: Content): string {
        return this.getEFormFieldForField(field)?.type || ""
    }

    static getLabelForField(label: string | null | undefined): string {
        if (label) {
            return this.translations[label] || this.findLabelInAdditionalTranslations(label) || label
        }
        return ""
    }

    static getHintForField(field: Content): string | null {
        if (field.contentId) {
            const id = "field|description|" + field.contentId
            return this.translations[id] || this.findLabelInAdditionalTranslations(id) || null

        }
        return null
    }

    private static findLabelInAdditionalTranslations(fieldName: string): string {
        const translations: any|null = this.translationsAdditions[this.selectedLanguage]
        if (translations) {
            return translations[fieldName] || ""
        }
        return ""
    }

    static getNoticeOptions(): { value: string, label: string }[] {
        const options: { value: string, label: string }[] = []
        this.availableNoticeSubtypes.forEach((option: string) => {
            options.push({value: 'EU-' + option, label: this.getLabelForNoticeType(option)})
        })
        return options
    }

    static getLabelForNoticeType(noticeType: string): string {
        return this.translations["notice|name|" + noticeType] || noticeType
    }

    static getEFormFieldForField(field: Content): any|null {
        if (this.fields && field.contentId) {
            return this.fields[field.contentId]
        }
        return null
    }

    static getListForField(field: Content): Promise<{ value: string; label: string }[]> {
        return this.fetchFieldsAndCodeLists().then(() => {
            const eForm: any = this.getEFormFieldForField(field)
            const listId: string = eForm?.codeList?.value?.id || ""
            return this.getCodeList(listId)
        })
    }

    static getCodeList(listId: string): Promise<{ value: string; label: string }[]> {
        const codeList: any = this.codeLists[listId]
        const fileName: string = codeList ? codeList['filename'] || "" : ""
        if (fileName) {
            let data: Promise<{ value: string; label: string }[]> | undefined = this.codeListsPromises[fileName]
            if (data) {
                return data
            } else {
                data = fetch("/sdk/" + this.sdkVersion + "/codelists/" + fileName + "/lang/" + this.selectedLanguage).then((response: Response) => {
                    return response.json().then((json: any) => {
                        const result: { value: string; label: string }[] = []
                        json.codes.forEach((code: any) => {
                            result.push({value: code.codeValue, label: code[this.selectedLanguage]})
                        })
                        this.codeListsPromises[fileName] = data
                        return result
                    })
                })
                return data
            }
        } else {
            return Promise.resolve([])
        }
    }

    private static async fetchFieldsAndCodeLists(): Promise<void> {
        if (!this.loadedInitial && this.sdkVersion) try {
            this.loadedInitial = true
            const response = await fetch("/sdk/" + this.sdkVersion + "/basic-meta-data")
            const json = await response.json()

            this.ublVersion = json.fieldsJson.ublVersion
            const fields = Object.fromEntries(json.fieldsJson.fields.map((field: any) => [field.id, field]))
            const nodes = Object.fromEntries(json.fieldsJson.xmlStructure.map((node: any) => [node.id, node]))
            const codeLists = Object.fromEntries(json.codelistsJson.codelists.map((codelist: any) => [codelist.id, codelist]))

            Object.assign(this.fields, fields)
            Object.assign(this.nodes, nodes)
            Object.assign(this.codeLists, codeLists)
        } catch (e) {
            this.loadedInitial = false
            setTimeout(() => {
                void this.fetchFieldsAndCodeLists()
            }, 3000)
        }
    }

    private static async fetchAvailableNoticeSubtypes() {
        if (!this.loadedNoticeTypes && this.sdkVersion) try {
            this.loadedNoticeTypes = true
            const response = await fetch("/sdk/" + this.sdkVersion + "/notice-types")
            const json = await response.json()
            this.availableNoticeSubtypes = json.noticeTypes
        } catch (e) {
            this.loadedNoticeTypes = false
            setTimeout(() => {
                void this.fetchAvailableNoticeSubtypes()
            }, 3000)
        }

    }

    private static async setLanguage(languageCode: string) {
        this.selectedLanguage = languageCode
        this.loadedTranslations = false
        await this.fetchTranslations()
    }

    private static async fetchTranslations() {
        if (!this.loadedTranslations && this.sdkVersion && this.selectedLanguage) try {
            this.loadedTranslations = true
            const response = await fetch("/sdk/" + this.sdkVersion + "/translations/" + this.selectedLanguage + ".json")
            Object.assign(this.translations, await response.json())
        } catch (e) {
            this.loadedTranslations = false
            setTimeout(() => {
                void this.fetchTranslations()
            }, 3000)
        }
    }

    static async fetchAll(locale: string) {
        if (!this.loadedInitial) {
            void this.fetchFieldsAndCodeLists()
            void this.fetchAvailableNoticeSubtypes()
        }
        const languageCode: string = this.extractLanguageCode(locale)
        if (!this.loadedTranslations || this.selectedLanguage !== languageCode) {
            void this.setLanguage(languageCode)
        }
    }

    private static extractLanguageCode(locale: string): string {
        if (locale.length == 2) return locale.toLowerCase()
        if (locale.includes('_')) return locale.substr(0, locale.indexOf('_')).toLowerCase()
        //@ts-ignore
        return navigator.language || navigator.userLanguage //default to current browser settings
    }

    static getStringValue(content: Content | null): { value: string } {
        if (content?.contentType?.toLowerCase() === 'field') {
            const type: string | null = ContentUtil.typeOfField(content)
            if (type === 'NUMBER' && content.longValue) {
                return this.getStringFromLongValue(content)
            } else if (type === 'DATE' && content.dateValue) {
                return { value: dayjs(content.dateValue).format('LL') || '' }
            } else if (type === 'TIME' && content.dateTimeValue) {
                return { value: dayjs(content.dateTimeValue).format('HH:mm') || '' }
            } else if (type === 'DATETIME' && content.dateTimeValue) {
                return { value: dayjs(content.dateTimeValue).format('LLL') || '' }
            } else if ((type === 'CHECKBOX' || (type === 'RADIO' && content.listValue === null))) {
                return { value: String(content.booleanValue || 'false') === 'true' ? i18n.$gettext('Ja') : i18n.$gettext('Nein') }
            } else if ((type === 'RADIO' || type === 'MULTICHECKBOX') && (content.listValue || []).length) {
                return { value: (content.listValue || []).join(',') }
            } else {
                return { value: content.stringValue || '' }
            }
        } else {
            return { value: '' }
        }
    }

    static getStringFromLongValue(content: Content): { value: string } {
        let result = reactive({ value: String(content.longValue || '') })
        if (content.unitValue) {
            this.getCodeList('currency').then((list: { value: string, label: string }[]) => {
                const currencyLabel = list.find(entry => entry.value === content.unitValue)
                if (currencyLabel) {
                    result.value += ' ' + currencyLabel.label
                } else {
                    this.getCodeList('duration-unit').then((list: { value: string, label: string }[]) => {
                        const durationLabel = list.find(entry => entry.value === content.unitValue)
                        if (durationLabel) {
                            result.value += ' ' + durationLabel.label
                        }
                    })
                }
            })
        }
        return result
    }
}
