
import {Vue, Options} from "vue-class-component"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import DatePicker from "@/components/controls/DatePicker.vue"
import Card from "primevue/card"
import Checkbox from "@/components/controls/Checkbox.vue"
import Dropdown from "@/components/controls/Dropdown.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import {rpcClient} from "@/api/WebsocketClient"
import Button from "primevue/button"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"
import Content from '@/model/common/Content'
import RadioButton from "primevue/radiobutton"
import ProgressBar from "primevue/progressbar"
import SWR from "@/api/SWR"
import Organization from "@/model/Organization"
import {organizationServiceApi} from "@/api/OrganizationServiceApi"
import Project from "@/model/Project"
import ContentUtil from "@/util/ContentUtil"
import Choice from "@/model/common/Choice"
import Dialog from "@/components/common/Dialog.vue"
import Contact from "@/model/Contact"
import {defineAsyncComponent} from "vue"
import AnimatedNumberInput from "@/components/controls/AnimatedNumberInput.vue"

@Options({
  name: "FieldTemplate",
  components: {
    AnimatedNumberInput, AnimatedInput, DatePicker, Card, Checkbox, Dropdown, Button, RadioButton, ProgressBar, Dialog,
    'OrganizationForm': defineAsyncComponent(() => import('@/components/common/OrganizationForm.vue'))
  },
  //@ts-ignore
  props: {
    field: [ Content, Object ],
    project: [ Project, Object ],
    hidden: Boolean,
    canRemove: Boolean
  },
  emits: [
    'value-changed', 'remove-content', 'create-organization'
  ]
})
export default class FieldTemplate extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient

  field!: Content
  project!: Project
  loadingList: boolean = true
  eFormListEntries: { value: string; label: string }[] | null = null
  unitList: { value: string; label: string }[] | null = null
  unitLabel: string | null = null
  organization: Organization | null = null
  hidden: boolean = false

  organizationSelectFields: string[] = [
    'OPT-300-Contract-Signatory', 'OPT-300-Procedure-Buyer', 'OPT-300-Procedure-SProvider', 'OPT-300-Tenderer', 'OPT-301-Lot-AddInfo',
    'OPT-301-Lot-DocProvider', 'OPT-301-Lot-EmployLegis', 'OPT-301-Lot-EnvironLegis', 'OPT-301-Lot-FiscalLegis', 'OPT-301-Lot-Mediator',
    'OPT-301-Lot-ReviewInfo', 'OPT-301-Lot-ReviewOrg', 'OPT-301-Lot-TenderEval', 'OPT-301-Lot-TenderReceipt', 'OPT-301-LotResult-Financing',
    'OPT-301-LotResult-Paying', 'OPT-301-Part-AddInfo', 'OPT-301-Part-DocProvider', 'OPT-301-Part-EmployLegis', 'OPT-301-Part-EnvironLegis',
    'OPT-301-Part-FiscalLegis', 'OPT-301-Part-Mediator', 'OPT-301-Part-ReviewInfo', 'OPT-301-Part-ReviewOrg', 'OPT-301-Part-TenderEval',
    'OPT-301-Part-TenderReceipt', 'OPT-301-ReviewBody', 'OPT-301-ReviewReq', 'OPT-301-Tenderer-MainCont', 'OPT-301-Tenderer-SubCont'
  ]

  get labelTranslated(): string {
    if (this.field?.contentId === 'BT-1311(d)-Lot' && this.project?.type === 5) {
      return this.i18n.$gettext('Finale Teilnahmefrist')
    } else if (this.field?.contentId === 'BT-131(d)-Lot' && this.project?.type === 5) {
      return this.i18n.$gettext('Finale Angebotsfrist')
    } else {
      return SdkServiceClient.getLabelForField(this.field?.label)
    }
  }

  get hasHint(): boolean {
    return this.hintTranslated !== null
  }

  get hintTranslated(): string | null {
    return SdkServiceClient.getHintForField(this.field)
  }

  get typeOfField(): string | null {
    return ContentUtil.typeOfField(this.field)
  }

  children(choice: any | null | undefined): Content[] {
    if (this.typeOfField === 'MULTICHECKBOX') {
      if (choice && this.field.hasOwnProperty('listValue') && Array.isArray(this.field['listValue']) && this.field['listValue'].includes(choice.name)) {
        return choice.fields || []
      }
    } else if ([ 'DROPDOWN', 'COMBOBOX' ].includes(this.typeOfField || '')) {
      if (choice && this.field.hasOwnProperty('stringValue') && this.field['stringValue'] === choice) {
        return this.field.choices?.find((c: any) => c.name === choice)?.fields || []
      }
    } else if ([ 'CHECKBOX', 'RADIO' ].includes(this.typeOfField || '')) {
      if (choice && String(choice) === 'true') {
        return this.field.fields || []
      }
    }
    return []
  }

  get listEntries(): { value: string; label: string }[] {
    if (this.isOrganizationSelect) {
      return this.organizations?.data?.map((o: Organization) => {
        return { value: String(o.id || 0), label: o.name || '' }
      }) || []
    } else if (this.eFormListEntries?.length) {
      return this.eFormListEntries.map(e => { return { label: e.value + ' - ' + e.label, value: e.value } })
    } else if (this.field?.idSchemes) {
      const result: { value: string; label: string }[] = []
      SdkServiceClient.listValuesForSchemeIds(this.field.idSchemes).forEach((entry: string) => {
        result.push({ value: entry, label:entry })
      })
      return result
    } else {
      return (this.field.choices || []).map((c: Choice) => { return { value: c.name || '', label: c.name || '' } })
    }
  }

  get organizations(): SWR<Organization[], number[]> {
    return organizationServiceApi.getOrganizations('CLIENT')
  }

  get isOrganizationSelect() {
    return this.organizationSelectFields.includes(this.field?.contentId || '')
  }

  get showOrganizationForm() {
    return !!this.organization
  }

  set showOrganizationForm(showOrganizationForm: boolean) {
    if (showOrganizationForm) {
      this.openOrganizationForm()
    } else {
      this.organization = null
    }
  }

  get unitChoices(): { value: string; label: string }[] | null {
    if (this.unitList) {
      return this.unitList
    } else {
      const subType: string | null = this.field ? SdkServiceClient.getSubTypeForField(this.field) : null
      if (subType?.toLowerCase() === 'amount') {
        void SdkServiceClient.getCodeList('currency').then((list: { value: string, label: string }[]) => {
          this.unitList = list
        })
        this.unitLabel = this.i18n.$gettext('Währung')
        return []
      } else if (subType?.toLowerCase() === 'measure') {
        void SdkServiceClient.getCodeList('duration-unit').then((list: { value: string, label: string }[]) => {
          this.unitList = list
        })
        this.unitLabel = this.i18n.$gettext('Zeiteinheit')
        return []
      } else {
        return null
      }
    }
  }

  get isHidden(): boolean {
    return Boolean(!this.field || this.field.hidden || this.hidden || (this.field.hidden !== false && this.field.readOnly))
  }

  openOrganizationForm() {
    this.organization = Object.assign(new Organization(), {
      type: 'CLIENT',
      contact: new Contact()
    })
  }

  mounted() {
    if (this.field?.schemeId && !this.field.stringValue) {
      this.field.stringValue = SdkServiceClient.createNewValueForSchemeId(this.field.schemeId)
    }
    void SdkServiceClient.fetchAll(this.i18n.current)
    if (!this.field?.idSchemes && this.typeOfField && ['COMBOBOX', 'RADIO'].includes(this.typeOfField)) {
      SdkServiceClient.getListForField(this.field).then((list: { value: string, label: string }[]) => {
        this.eFormListEntries = list
      }).catch(() => {
        this.eFormListEntries = null
      }).finally(() => {
        this.loadingList = false
      })
    } else {
      this.loadingList = false
    }
  }
}
