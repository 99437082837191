
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import {userServiceApi} from "@/api/UserServiceApi"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"

@Options({
  name: "PasswordReset",
  components: {
    Button, AnimatedInput
  }
})
export default class PasswordReset extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient

  newPassword: string = ''
  newPasswordRepeat: string = ''
  emailOrUserName: string = ''
  loading = false

  get token() {
    return this.$route.query?.token
  }

  send() {
    if (this.token && this.newPassword) {
      this.loading = true
      userServiceApi._changePassword(null, this.token, this.newPassword).then(() => {
        this.$router.push('/')
      }).finally(() => {
        this.loading = false
      })
    } else if (this.emailOrUserName) {
      this.loading = true
      userServiceApi._sendPasswordResetMail(this.emailOrUserName).finally(() => {
        this.loading = false
      })
    }
  }
}
