import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Message from '@/model/Message';
import Page from '@/model/Page';
import { messageStore } from '@/store/MessageStore';

export default class GeneratedMessageServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _sendMessage(message: Message): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('sendMessage', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Message(), data)
            messageStore.addOrReplaceMessage(model)
            return model.id
        })
    }

    _getMessages(projectId: number | null, offerId: number | null): Promise<Message[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getMessages', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const messages: Message[] = data.map(message => Object.assign(new Message(), message))
                messageStore.addOrReplaceMessages(messages)
                return messages
            } else return Promise.reject()
        })
    }


    _addTag(id: number, tag: string): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('addTag', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Message(), data)
            messageStore.removeMessage(id)
            messageStore.addOrReplaceMessage(model)
            return model.id
        })
    }

    getMessages(projectId: number | null, offerId: number | null, refresh?: boolean | number): SWR<Message[], number[]> {
        //@ts-ignore
        const result: SWR<Message[], number[]> = reactive(new SWR<Message[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_getMessages' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getMessages[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const messages: Message[] = []
                for (const id of data) {
                    const message: Message | undefined = messageStore.state.messages.get(id)
                    if (message) {
                        messages.push(message)
                    }
                }
                result.data = messages
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getMessages(projectId, offerId).then((data: Message[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(message => message.id || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let messages: Message[] = [...messageStore.state.messages.values()]
        if (projectId !== undefined && projectId !== null) messages = SortAndFilterUtil.filter(messages, { projectId: projectId })
if (offerId !== undefined && offerId !== null) messages = SortAndFilterUtil.filter(messages, { offerId: offerId })
        
        
        result.data = messages
        return result
    }

    getMessage(id: number): Message | undefined {
        return messageStore.state.messages.get(id)
    }
}
