import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-12 col-md-4" }
const _hoisted_3 = { class: "col-12 col-md-4" }
const _hoisted_4 = { for: "sizeRangeId" }
const _hoisted_5 = { class: "col-12 col-md-4" }
const _hoisted_6 = { class: "row mb-4" }
const _hoisted_7 = { class: "col-12 col-md-4" }
const _hoisted_8 = { class: "col-12 col-md-4" }
const _hoisted_9 = { class: "col-12 col-md-4" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-12 col-md-4" }
const _hoisted_12 = { class: "col-12 col-md-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnimatedInput = _resolveComponent("AnimatedInput")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_TriStateDropdown = _resolveComponent("TriStateDropdown")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_ctx.showFilters)
    ? (_openBlock(), _createBlock(_component_Accordion, {
        key: 0,
        class: "w-100 filter-accordion",
        style: {"border":"none"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AccordionTab, {
            header: _ctx.i18n.$gettext('Filter')
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_AnimatedInput, {
                    modelValue: _ctx.platFilter,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.platFilter) = $event)),
                      _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('filter-changed', _ctx.propertiesFiltered)))
                    ],
                    "auto-resize": "",
                    label: _ctx.i18n.$gettext('Flurstück suchen')
                  }, null, 8, ["modelValue", "label"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.sizeSliderLabel), 1),
                  _createVNode(_component_Slider, {
                    id: "sizeRangeId",
                    modelValue: _ctx.sizeFilter,
                    "onUpdate:modelValue": [
                      _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sizeFilter) = $event)),
                      _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('filter-changed', _ctx.propertiesFiltered)))
                    ],
                    range: "",
                    step: 1,
                    min: 0,
                    max: _ctx.maxSize
                  }, null, 8, ["modelValue", "max"])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_AnimatedInput, {
                    modelValue: _ctx.usageFilter,
                    "onUpdate:modelValue": [
                      _cache[4] || (_cache[4] = ($event: any) => ((_ctx.usageFilter) = $event)),
                      _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('filter-changed', _ctx.propertiesFiltered)))
                    ],
                    "auto-resize": "",
                    label: _ctx.i18n.$gettext('Nutzungsart suchen')
                  }, null, 8, ["modelValue", "label"])
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_TriStateDropdown, {
                    modelValue: _ctx.buildingFilter,
                    "onUpdate:modelValue": [
                      _cache[6] || (_cache[6] = ($event: any) => ((_ctx.buildingFilter) = $event)),
                      _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('filter-changed', _ctx.propertiesFiltered)))
                    ],
                    class: "mr-3 w-100",
                    "falsy-label": _ctx.i18n.$gettext('Keine Bebbauung'),
                    "truthy-label": _ctx.i18n.$gettext('Bebaut'),
                    "none-label": _ctx.i18n.$gettext('Egal'),
                    label: _ctx.i18n.$gettext('Bebauung')
                  }, null, 8, ["modelValue", "falsy-label", "truthy-label", "none-label", "label"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_TriStateDropdown, {
                    modelValue: _ctx.availabilityFilter,
                    "onUpdate:modelValue": [
                      _cache[8] || (_cache[8] = ($event: any) => ((_ctx.availabilityFilter) = $event)),
                      _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('filter-changed', _ctx.propertiesFiltered)))
                    ],
                    class: "mr-3 w-100",
                    "falsy-label": _ctx.i18n.$gettext('Nicht verfügbar'),
                    "truthy-label": _ctx.i18n.$gettext('Verfügbar'),
                    "none-label": _ctx.i18n.$gettext('Egal'),
                    label: _ctx.i18n.$gettext('Verfügbarkeit')
                  }, null, 8, ["modelValue", "falsy-label", "truthy-label", "none-label", "label"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_TriStateDropdown, {
                    modelValue: _ctx.developmentPlanFilter,
                    "onUpdate:modelValue": [
                      _cache[10] || (_cache[10] = ($event: any) => ((_ctx.developmentPlanFilter) = $event)),
                      _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('filter-changed', _ctx.propertiesFiltered)))
                    ],
                    class: "mr-3 w-100",
                    "falsy-label": _ctx.i18n.$gettext('Nein'),
                    "truthy-label": _ctx.i18n.$gettext('Ja'),
                    "none-label": _ctx.i18n.$gettext('Egal'),
                    label: _ctx.i18n.$gettext('In einem Bebauungsplan')
                  }, null, 8, ["modelValue", "falsy-label", "truthy-label", "none-label", "label"])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_TriStateDropdown, {
                    modelValue: _ctx.archivedFilter,
                    "onUpdate:modelValue": [
                      _cache[12] || (_cache[12] = ($event: any) => ((_ctx.archivedFilter) = $event)),
                      _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('filter-changed', _ctx.propertiesFiltered)))
                    ],
                    class: "mr-3 w-100",
                    "falsy-label": _ctx.i18n.$gettext('Nein'),
                    "truthy-label": _ctx.i18n.$gettext('Ja'),
                    label: _ctx.i18n.$gettext('Archiviert'),
                    binary: ""
                  }, null, 8, ["modelValue", "falsy-label", "truthy-label", "label"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_AnimatedInput, {
                    modelValue: _ctx.nameFilter,
                    "onUpdate:modelValue": [
                      _cache[14] || (_cache[14] = ($event: any) => ((_ctx.nameFilter) = $event)),
                      _cache[15] || (_cache[15] = ($event: any) => (_ctx.$emit('filter-changed', _ctx.propertiesFiltered)))
                    ],
                    "auto-resize": "",
                    label: _ctx.i18n.$gettext('Bezeichnung suchen')
                  }, null, 8, ["modelValue", "label"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}