const EmojiConvertor = require('emoji-js')
const toUnicodeWrapper = new EmojiConvertor()
toUnicodeWrapper.replace_mode = 'unified'
toUnicodeWrapper.allow_native = true
toUnicodeWrapper.wrap_native = true

const toUnicode = new EmojiConvertor()
toUnicode.replace_mode = 'unified'
toUnicode.allow_native = true

const toColons = new EmojiConvertor()
toColons.colons_mode = true

export function replaceColonsWithUTF8 (contentWithColonEmojis: string | null, wrapEmojis?: boolean): string {
    if (contentWithColonEmojis === null) return ""
    return wrapEmojis ? toUnicodeWrapper.replace_colons(contentWithColonEmojis) : toUnicode.replace_colons(contentWithColonEmojis)
}

export function replaceUTF8WithColons (contentWithUTF8Emojis: string | null): string {
    if (contentWithUTF8Emojis === null) return ""
    return toColons.replace_unified(contentWithUTF8Emojis)
}
