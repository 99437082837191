import Organization from '@/model/Organization';
import ProjectRole from '@/model/ProjectRole';
import Room from '@/model/Room';
import RoomTemplate from '@/model/RoomTemplate';

export default class Project {
  id!: number | null;
  parentId!: number | null;
  templateId!: string | null;
  name!: string | null;
  description!: string | null;
  type!: number | null;
  stage!: number | null;
  published!: string | null;
  updated!: string | null;
  created!: string | null;
  archived!: string | null;
  openDate!: string | null;
  openUser!: string | null;
  lastOpenedRound!: number | null;
  finalDeadline!: string | null;
  deadlinePeriodDays!: number | null;
  applicationDeadline!: string | null;
  offerDeadline!: string | null;
  skippedPhases!: string | null;
  organizationId!: number | null;
  panels!: any | null;
  hasChanges!: boolean | null;
  lots!: Project[] | null;
  propertyIds!: number[] | null;
  currentApplicationRound!: number | null;
  nextOpenableRound!: number | null;
  numberOfApplicationsByRound!: any | null;
  numberOfOffersByRound!: any | null;
  database!: any | null;
  credentialService!: any | null;
  organization!: Organization | null;
  team!: ProjectRole[] | null;
  informationRooms!: Room[] | null;
  roomTemplates!: RoomTemplate[] | null;
  tags!: string[] | null;

  constructor() {
    this.id = null;
    this.parentId = null;
    this.templateId = null;
    this.name = null;
    this.description = null;
    this.type = null;
    this.stage = null;
    this.published = null;
    this.updated = null;
    this.created = null;
    this.archived = null;
    this.openDate = null;
    this.openUser = null;
    this.lastOpenedRound = null;
    this.finalDeadline = null;
    this.deadlinePeriodDays = null;
    this.applicationDeadline = null;
    this.offerDeadline = null;
    this.skippedPhases = null;
    this.organizationId = null;
    this.panels = null;
    this.hasChanges = null;
    this.lots = null;
    this.propertyIds = null;
    this.currentApplicationRound = null;
    this.nextOpenableRound = null;
    this.numberOfApplicationsByRound = null;
    this.numberOfOffersByRound = null;
    this.database = null;
    this.credentialService = null;
    this.organization = null;
    this.team = null;
    this.informationRooms = null;
    this.roomTemplates = null;
    this.tags = null;
  }
}
