
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import Project from "@/model/Project"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import SWR from "@/api/SWR"
import InfiniteList from "@/components/controls/InfiniteList.vue"
import ProgressBar from "primevue/progressbar"
import dayjs from "@/util/dayjs"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import ProjectForm from "@/components/admin/ProjectForm.vue"
import Dropdown from "@/components/controls/Dropdown.vue"
import MarkdownUtil from "@/util/MarkdownUtil"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Multiselect from "@/components/controls/Multiselect.vue"
import {MenuItem} from "primevue/menuitem"
import Breadcrumb from "primevue/breadcrumb"
import {messageServiceApi} from "@/api/MessageServiceApi"
import Tag from "primevue/tag"
import breakpointUtil from "@/util/BreakpointUtil"

@Options({
  name: "ProjectList",
  components: {
    Multiselect, Button, InfiniteList, ProgressBar, ProjectForm, Dropdown, AnimatedInput, Breadcrumb, Tag
  },
  //@ts-ignore
  props: {
    tags: [ String, Array ]
  }
})
export default class ProjectList extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient
  breakpointUtil = breakpointUtil

  tags!: string | string[]

  searchTerm: string = ''

  projectTypes: string[] = []
  sortBy: string[] = this.archived ? [ 'archived:desc' ] : [ 'published:desc' ]
  sortOptions: { name: string, by: string[], icon: string }[] = [
    {
      name: this.i18n.$gettext('Frist'),
      by: ['deadline:desc'],
      icon: ''
    },
    {
      name: this.i18n.$gettext('Frist'),
      by: ['deadline:asc'],
      icon: ''
    },
    {
      name: this.i18n.$gettext('Stufe'),
      by: ['stage:desc'],
      icon: ''
    },
    {
      name: this.i18n.$gettext('Stufe'),
      by: ['stage:asc'],
      icon: ''
    },
    {
      name: this.i18n.$gettext('Auftraggeber'),
      by: ['organization.name:desc', 'published:desc'],
      icon: ''
    },
    {
      name: this.i18n.$gettext('Auftraggeber'),
      by: ['organization.name:asc', 'published:desc'],
      icon: ''
    },
    {
      name: this.i18n.$gettext('Veröffentlicht'),
      by: ['published:desc'],
      icon: ''
    },
    {
      name: this.i18n.$gettext('Veröffentlicht'),
      by: ['published:asc'],
      icon: ''
    },
    {
      name: this.i18n.$gettext('Archiviert'),
      by: ['archived:desc'],
      icon: ''
    },
    {
      name: this.i18n.$gettext('Archiviert'),
      by: ['archived:asc'],
      icon: ''
    }
  ]
  TYPE_OPTIONS: { type: string, label: string }[] = [
    {
      type: '0',
      label: this.i18n.$gettext('Ausschreibung')
      /*}, {
        type: '1',
        label: this.i18n.$gettext('Ohne Teilnahmewettbewerb')*/
    }, {
      type: '2',
      label: this.i18n.$gettext('Interessenbekundungsverfahren')
    }, {
      type: '3',
      label: this.i18n.$gettext('Bekanntmachung')
    }, {
      type: '4',
      label: this.i18n.$gettext('Vergebener Auftrag')
    }, {
      type: '5',
      label: this.i18n.$gettext('Dauerverhandlungsverfahren')
    }
  ]

  get projects(): SWR<Project[], number[]> {
    return projectServiceApi.getProjects(this.filter, 1000)
  }

  get filter(): string[] {
    return this.$route?.hash ? [ this.$route.hash.substring(1, this.$route.hash.length) ] : []
  }

  get archived(): boolean {
    return this.filter.includes('archiv')
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    breadcrumbs.push({
      label: this.i18n.$gettext('Ausschreibungen'),
      to: '/projekte'
    })
    if (this.$route.hash === '#entwurf') {
      breadcrumbs.push({
        label: this.i18n.$gettext('Entwürfe'),
        to: '/projekte#entwurf'
      })
    } else if (this.$route.hash === '#öffentlich') {
      breadcrumbs.push({
        label: this.i18n.$gettext('Öffentliche'),
        to: '/projekte#öffentlich'
      })
    } else if (this.$route.hash === '#archiv') {
      breadcrumbs.push({
        label: this.i18n.$gettext('Archiv'),
        to: '/projekte#archiv'
      })
    } else if (this.$route.hash === '#merkliste') {
      breadcrumbs.push({
        label: this.i18n.$gettext('Merkliste'),
        to: '/projekte#merkliste'
      })
    } else if (this.$route.hash === '#teilnahme') {
      breadcrumbs.push({
        label: this.i18n.$gettext('Teilnahmen'),
        to: '/projekte#teilnahme'
      })
    } else if (this.$route.hash === '#einladung') {
      breadcrumbs.push({
        label: this.i18n.$gettext('Einladungen'),
        to: '/projekte#einladung'
      })
    } else if (this.$route.hash === '#bewerbung') {
      breadcrumbs.push({
        label: this.i18n.$gettext('Teilnahmeanträge'),
        to: '/projekte#bewerbung'
      })
    }
    return breadcrumbs
  }

  getDeadline(project: Project) {
    if (project?.type === 5) {
      return project.finalDeadline ? this.dateTimeString(project.finalDeadline) : ''
    } else if (project?.stage || 0 < 4) {
      return project.applicationDeadline ? this.dateTimeString(project.applicationDeadline) : ''
    } else if (project?.stage || 0 < 4) {
      return project.offerDeadline ? this.dateTimeString(project.offerDeadline) : ''
    }
    return ''
  }

  matchesSearchTerm(project: Project): boolean {
    const term = this.searchTerm?.toLowerCase()
    if (term && term.trim() !== '') {
      return Boolean(project && project.name?.toLowerCase()?.includes(term) || project.description?.toLowerCase()?.includes(term))
    } else {
      return true
    }
  }

  matchesType(project: Project): boolean {
    if (this.projectTypes?.length) {
      return Boolean(project && this.projectTypes.includes(String(project.type || 0)))
    } else {
      return true
    }
  }

  //TODO separate lots
  stageName(project: Project): string {
    if (project.type === 3) {
      return this.TYPE_OPTIONS.find(o => o.type === '3')?.label || ''
    } else if (project.type === 4) {
      return this.TYPE_OPTIONS.find(o => o.type === '4')?.label || ''
    } else if (!project.stage || !project.published) {
      return this.i18n.$gettext('Entwurf')
    } else if (project.stage < 3) {
      if (dayjs(project.published).isAfter(dayjs())) {
        return this.i18n.$gettext('Veröffentlichung am' + ' ' + dayjs(project.published).format('LLL'))
      } else {
        return this.i18n.$gettext('Veröffentlicht')
      }
    } else if (project.stage < 5) {
      return this.i18n.$gettext('Teilnahmewettbewerb')
    } else if (project.stage < 6) {
      return this.i18n.$gettext('Verhandlung')
    } else if (project.stage < 7) {
      return this.i18n.$gettext('Angebotsphase')
    } else {
      return this.i18n.$gettext('Abgeschlossen')
    }
  }

  unreadMessages(project: Project): number {
    return messageServiceApi.getMessages(project?.id || null, null).data?.filter(m => !m.read)?.length || 0
  }

  goToProject(id: number) {
    this.$router.push('/projekte/' + encodeURIComponent(id) + '#top')
  }

  dateString(date: string | null | undefined) {
    return date ? dayjs(date).format('LL') : ''
  }

  dateTimeString(date: string | null | undefined) {
    return date ? dayjs(date).format('LLL') : ''
  }

  safeDescription(project: Project) {
    return MarkdownUtil.safeRenderMarkdown(project.description || '')
  }

  setSort(by: string) {
    let direction: string
    if (this.sortBy.length > 0 && this.sortBy[0].startsWith(by + ':')) {
      direction = this.sortBy[0].endsWith('desc') ? 'asc' : 'desc'
    } else {
      direction = by === 'lastModified' ? 'desc' : 'asc'
    }
    this.sortBy[0] = by + ':' + direction
  }

  sort(projects: Project[]) {
    SortAndFilterUtil.sort(projects, this.sortBy)
    return projects
  }

  mounted() {
    projectServiceApi.getProjects(this.filter, 1000)
  }
}
