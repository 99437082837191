export default class Message {
  id!: number | null;
  created!: string | null;
  userName!: string | null;
  senderOrganizationId!: number | null;
  recipientOrganizationId!: number | null;
  recipientOrganizationIds!: any | null;
  projectId!: number | null;
  offerId!: number | null;
  replyToMessageId!: number | null;
  messageSubject!: string | null;
  messageText!: string | null;
  messageExtra!: any | null;
  roomId!: number | null;
  senderName!: string | null;
  recipientNames!: string[] | null;
  attachmentCount!: number | null;
  tags!: string[] | null;
  read!: boolean | null;
  readByUser!: boolean | null;
  readByOrganization!: boolean | null;

  constructor() {
    this.id = null;
    this.created = null;
    this.userName = null;
    this.senderOrganizationId = null;
    this.recipientOrganizationId = null;
    this.recipientOrganizationIds = null;
    this.projectId = null;
    this.offerId = null;
    this.replyToMessageId = null;
    this.messageSubject = null;
    this.messageText = null;
    this.messageExtra = null;
    this.roomId = null;
    this.senderName = null;
    this.recipientNames = null;
    this.attachmentCount = null;
    this.tags = null;
    this.read = null;
    this.readByUser = null;
    this.readByOrganization = null;
  }
}
