import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "d-flex flex-column vh-100" }
const _hoisted_2 = { class: "container text-center p-2" }
const _hoisted_3 = { class: "font-weight-bold text-white mb-0" }
const _hoisted_4 = {
  key: 1,
  class: "container"
}
const _hoisted_5 = { class: "p-4" }
const _hoisted_6 = {
  key: 2,
  class: "h-100 container-wide d-flex flex-column pt-lg-4"
}
const _hoisted_7 = {
  class: "flex-shrink-1 flex-grow-1 d-flex flex-row",
  style: {"min-height":"0"}
}
const _hoisted_8 = {
  class: "flex-shrink-1 flex-grow-1 d-flex flex-column",
  style: {"min-width":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_EnableTwoFactorForm = _resolveComponent("EnableTwoFactorForm")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Message = _resolveComponent("Message")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_TwoFactorForm = _resolveComponent("TwoFactorForm")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "background",
      style: {"position":"absolute","top":"0","left":"0","width":"100%","height":"100%","overflow":"hidden"},
      class: _normalizeClass({ 'd-none': !(_ctx.$route.path === '/' || _ctx.$route.path === '' || _ctx.$route.path?.includes('wizard')) })
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(["bg-danger z-1 w-100 collapse flex-shrink-0 flex-grow-0", { show: _ctx.rpcClient.state.failed }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => [
              _createTextVNode("Keine Verbindung zum Server.")
            ]),
            _: 1
          })
        ])
      ])
    ], 2),
    (_ctx.needsTwoFactorSetting)
      ? (_openBlock(), _createBlock(_component_EnableTwoFactorForm, { key: 0 }))
      : (_ctx.rpcClient.fullyLoggedIn && _ctx.needsEmailConfirmation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_Message, {
              closable: false,
              class: "mt-0"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("p", null, [
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Zur Aktivierung Ihres Benutzer-Kontos ist die Bestätigung Ihrer E-Mail Adresse notwendig. Bitte klicken Sie dazu auf den Link, den Sie per E-Mail erhalten haben. Falls Sie eine erneute Zusendung der Aktivierungs-E-Mail wünschen, klicken Sie auf den Button \"E-Mail erneut zusenden\"")
                      ]),
                      _: 1
                    })
                  ]),
                  _createVNode(_component_Button, {
                    class: "flex-shrink-0 p-button-success",
                    onClick: _ctx.resendActivationMail
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("E-Mail erneut zusenden")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_router_view, {
              name: "topbar",
              class: "mb-4"
            }, {
              default: _withCtx(({ Component }) => [
                _createVNode(_Transition, { name: "topmenu-slide" }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_router_view, {
                name: "sidebar",
                class: "h-100"
              }, {
                default: _withCtx(({ Component }) => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_router_view, null, {
                  default: _withCtx(({ Component }) => [
                    _createVNode(_Transition, {
                      name: "scale",
                      mode: "out-in"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                })
              ])
            ])
          ])),
    _createVNode(_component_LoginForm),
    _createVNode(_component_TwoFactorForm),
    _createVNode(_component_Toast),
    _createVNode(_component_ConfirmDialog)
  ]))
}