
import {Options, Vue} from 'vue-class-component'
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import Card from "primevue/card"

@Options({
  name: "ProjectStageCard",
  components: {
    Card
  },
  //@ts-ignore
  props: {
    active: Boolean,
    inactive: Boolean,
    index: {
      type: Number,
      default: 0
    },
    arrow: Boolean
  }
})
export default class ProjectStageCard extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient

}
