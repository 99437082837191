import Contact from '@/model/Contact';

export default class User {
  email!: string | null;
  surName!: string | null;
  givenName!: string | null;
  organizationId!: number | null;
  roles!: any | null;
  password!: string | null;
  twoFactorEnabled!: boolean | null;
  twoFactorSecret!: string | null;
  state!: string | null;
  inviteToken!: string | null;
  authenticationBackend!: string | null;
  settings!: any | null;
  created!: string | null;
  updated!: string | null;
  version!: number | null;
  contact!: Contact | null;

  constructor() {
    this.email = null;
    this.surName = null;
    this.givenName = null;
    this.organizationId = null;
    this.roles = null;
    this.password = null;
    this.twoFactorEnabled = null;
    this.twoFactorSecret = null;
    this.state = null;
    this.inviteToken = null;
    this.authenticationBackend = null;
    this.settings = null;
    this.created = null;
    this.updated = null;
    this.version = null;
    this.contact = null;
  }
}
