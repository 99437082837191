import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Property from '@/model/gis/Property';
import Page from '@/model/Page';
import { propertyStore } from '@/store/PropertyStore';

export default class GeneratedPropertyServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getProperties(): Promise<Property[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getProperties', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const propertys: Property[] = data.map(property => Object.assign(new Property(), property))
                propertyStore.addOrReplacePropertys(propertys)
                return propertys
            } else return Promise.reject()
        })
    }


    _createProperty(name: string, location: number): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createProperty', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Property(), data)
            propertyStore.addOrReplaceProperty(model)
            return model.id
        })
    }

    _updateProperty(property: Property): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateProperty', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Property(), data)
            propertyStore.removeProperty(property.id)
            propertyStore.addOrReplaceProperty(model)
            return model.id
        })
    }

    _getPropertyById(id: number): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getPropertyById', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Property(), data)
            propertyStore.addOrReplaceProperty(model)
            return model.id
        })
    }

    _deleteProperty(id: number): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteProperty', rpcParams, false).then(() => {
            propertyStore.removeProperty(id)
        })
    }

    getProperties(refresh?: boolean | number): SWR<Property[], number[]> {
        //@ts-ignore
        const result: SWR<Property[], number[]> = reactive(new SWR<Property[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_getProperties' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getProperties[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const propertys: Property[] = []
                for (const id of data) {
                    const property: Property | undefined = propertyStore.state.propertys.get(id)
                    if (property) {
                        propertys.push(property)
                    }
                }
                result.data = propertys
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getProperties().then((data: Property[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(property => property.id || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let propertys: Property[] = [...propertyStore.state.propertys.values()]
        
        
        
        result.data = propertys
        return result
    }

    getPropertys(pageIndex?: number, pageSize?: number): Property[] {
        let propertys: Property[] = [...propertyStore.state.propertys.values()]
        
        
        if (pageSize !== null && pageSize !== undefined) {
            propertys = propertys.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return propertys
    }

    getPropertyById(id: number, refresh?: boolean | number): SWR<Property | null, number> {
        //@ts-ignore
        const result: SWR<Property | null, number> = reactive(new SWR<Property | null, number>())
        const callId: string = '_getPropertyById' + JSON.stringify(id)
        const cached: Call<number> | undefined = this.cache.get(callId)
        if (refresh === undefined) {
            refresh = 3000
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: number) => {
                result.data = propertyStore.state.propertys.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number>()) as Call<number>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._getPropertyById(id).then((id: number) => {
                call.data = id
                result.data = propertyStore.state.propertys.get(id) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        result.data = propertyStore.state.propertys.get(id) || null
        return result
    }

}
