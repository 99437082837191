import Contact from '@/model/Contact';

export default class Organization {
  id!: number | null;
  name!: string | null;
  type!: string | null;
  registrationNumber!: string | null;
  registrationCourt!: string | null;
  vat!: string | null;
  nutsCode!: string | null;
  identificationNumber!: string | null;
  additionalInformation!: string | null;
  additionalFields!: any | null;
  forms!: any | null;
  contactId!: number | null;
  updated!: string | null;
  created!: string | null;
  database!: any | null;
  contact!: Contact | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.type = null;
    this.registrationNumber = null;
    this.registrationCourt = null;
    this.vat = null;
    this.nutsCode = null;
    this.identificationNumber = null;
    this.additionalInformation = null;
    this.additionalFields = null;
    this.forms = null;
    this.contactId = null;
    this.updated = null;
    this.created = null;
    this.database = null;
    this.contact = null;
  }
}
