
import {Options, Vue} from "vue-class-component"
import Card from "primevue/card"
import Button from "primevue/button"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Dropdown from "@/components/controls/Dropdown.vue"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import {Container, Draggable} from 'vue3-smooth-dnd'
import PanelEditorField from "@/components/admin/PanelEditorField.vue"
import PropertyFileFolder from "@/model/gis/PropertyFileFolder"
import PropertyFile from "@/model/gis/PropertyFile"
import fileSizeString from "@/util/fileSize"
import FileUpload, {FileUploadBeforeUploadEvent} from "primevue/fileupload"
import AccordionTab from "primevue/accordiontab"
import Accordion from "primevue/accordion"
import PropertyFileDisplay from "@/components/properties/PropertyFileDisplay.vue"
import Dialog from "@/components/common/Dialog.vue"
import {getRandomUUID} from "@/util/uuid"
import {rpcClient} from "@/api/WebsocketClient"
import {propertyServiceApi} from "@/api/PropertyServiceApi"
import dayjs from "@/util/dayjs"
import useToast from "@/util/toasts"
import axios from "axios"
import ProgressBar from "primevue/progressbar"

@Options({
  name: "PropertyFolderDisplay",
  components: {
    PropertyFileDisplay, ProgressBar,
    PanelEditorField, Card, Button, Dialog, AnimatedInput, Dropdown, Checkbox, Container, Draggable, FileUpload, Accordion, AccordionTab
  },
  //@ts-ignore
  props: {
    folder: [PropertyFileFolder, Object],
    interactive: Boolean,
    propertyId: [Number, null]
  },
  emits: ['updateNeeded', 'revertNeeded']
})
export default class PanelEditor extends Vue {

  basePath: string = "/api/v1/files/gis/document/"

  i18n: Language = useGettext()
  toast = useToast()
  rpcClient = rpcClient

  folder: PropertyFileFolder | null = null

  showNewFileDialog: boolean = false
  newFileName: string = ""

  showArchiveDialog: boolean = false

  interactive: boolean = true
  propertyId: number | null = null

  uploadInProgress: boolean = false
  eventcounter: number = 0
  showDropZone: boolean = false

  visibilitySwitchLabel(entry: any) {
    return entry.visibility ? this.i18n.$gettext("Freigegeben") : this.i18n.$gettext("Geheim")
  }

  get files(): PropertyFile[] {
    return (this.folder?.contents || []).sort((a: PropertyFile, b: PropertyFile) => {
      if (!a.name || !b.name) {
        return 0
      }
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
  }

  get children(): PropertyFileFolder[] {
    return this.folder?.children || []
  }

  archiveFile(file: PropertyFile) : void {
    if(!this.folder || !this.folder.contents) return

    //Remove file on archive from contents.
    const idx = this.folder.contents.indexOf(file)
    this.folder.contents.splice(idx, 1)

    //Add file to archive:
    if (!this.folder.archive) {
      this.folder.archive = []
    }
    this.folder.archive.push(file)

    this.signalUpdateIsNeeded()
  }

  get hasNoFileContents(){
    if(this.folder) {
      return !this.childrenHaveFileContents(this.folder)
    } else {
      return false
    }
  }

  childrenHaveFileContents(folder: PropertyFileFolder): boolean {

    let result: boolean = false

    if(!folder.children && !folder.contents) {
      return false
    }

    if(folder.children?.length === 0 && folder.contents?.length === 0) {
      return false
    }

    if(typeof folder.contents?.length !== "undefined" && folder.contents.length > 0) {
      return true //We have contents, so we don't need to look further
    }

    //No contents, but children, so visit them:
    folder.children?.forEach((child: PropertyFileFolder) => {
      result = result || this.childrenHaveFileContents(child)
    })

    return result
  }


  get hasArchivedFiles(): boolean {
    return !!this.folder && this.folder.archive !== null && this.folder.archive.length > 0
  }

  get archivedFiles(): PropertyFile[]{
    if(!this.folder?.archive) return []
    return this.folder.archive.sort((a: PropertyFile, b: PropertyFile) => {
      const dateA = new Date(a.created || 0)
      const dateB = new Date(b.created || 0)
      return dateA.getMilliseconds() - dateB.getMilliseconds()
    })
  }

  resetModal() {
    this.showNewFileDialog = false
    this.newFileName = ""
  }

  createNewFile() {
    const file: PropertyFile = new PropertyFile()
    file.created = new Date().toISOString()
    file.name = this.newFileName
    file.serverId = null
    file.fileSizeInBytes = 0
    this.folder?.contents?.push(file)
    this.signalUpdateIsNeeded()
    this.resetModal()
  }

  signalUpdateIsNeeded() {
    this.$emit('updateNeeded')
  }

  interceptUploadAndChangeFilename(event : FileUploadBeforeUploadEvent){
    if(!this.propertyId === null) return

    //Now create random id:
    const fileId: string = getRandomUUID()

    let fileToUpload: File = event.formData.get('files') as File
    //Copy Current Version to version history:

    //create new file:
    let newFile: PropertyFile = new PropertyFile()
    newFile.fileSizeInBytes = fileToUpload.size
    newFile.name = fileToUpload.name
    newFile.created = (new Date(fileToUpload.lastModified)).toISOString()
    newFile.serverId = fileId
    //Create file instance that will be uploaded
    fileToUpload = new File([fileToUpload], fileId)
    event.formData.set("files", fileToUpload)

    //Now authorize XHR:
    if (rpcClient.session.token) {
      event.xhr.setRequestHeader('X-Auth-Token', rpcClient.session.token)
    }

    this.uploadInProgress = true
    this.folder?.contents?.push(newFile)
  }

  handleFileUploadInternally(files: File[]) {

    if (files.length == 0) {
      return
    }

    let formData = new FormData()

    let filesToAppend:  PropertyFile[] = []

    files.forEach((file: File) => {
      const backendFileId: string = getRandomUUID()
      let propFileDescriptor: PropertyFile = new PropertyFile()
      propFileDescriptor.fileSizeInBytes = file.size
      propFileDescriptor.name = file.name
      propFileDescriptor.created = (new Date(file.lastModified)).toISOString()
      propFileDescriptor.serverId = backendFileId
      let fileToUpload = new File([file], backendFileId)
      formData.append('files', fileToUpload)
      filesToAppend.push(propFileDescriptor)
    })

    this.uploadInProgress = true
    axios.post(this.basePath + this.propertyId , formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Auth-Token': rpcClient.session.token
      }
    }).then(() => {
      this.folder?.contents?.push(...filesToAppend)
      this.onUploadDone()
    }).catch(() => {
      this.onUploadFailed()
    })

  }

  onUploadDone(){
    this.toast.success(this.i18n.$gettext("Datei erfolgreich hochgeladen"))
    this.uploadInProgress = false
  }

  onUploadFailed(){
    this.toast.error(this.i18n.$gettext("Hochladen fehlgeschlagen"))
    //Reload the property so the changes do not persist.
    if(this.propertyId != null) {
      propertyServiceApi.getPropertyById(this.propertyId)
    }
    this.uploadInProgress = false
  }

  formatIsoDate(isoDateString: string): string {
    return dayjs(isoDateString).format('LLL') || ""
  }

  assembleDownloadUrlOfFile(file: PropertyFile) : string {
    if(!file.serverId) return "#"
    return this.basePath + this.propertyId + "/" + file.serverId
  }

  fileSizeFormatted(file: PropertyFile) : string {
    return file.fileSizeInBytes !==null ? fileSizeString(file.fileSizeInBytes || 0) : ""
  }

  requestFileDeletion(file: PropertyFile): void {
    if(!file) return
    file.deletionRequested = (new Date()).toISOString()
    file.deletionRequestUsername = rpcClient.session.user?.email || null
    this.signalUpdateIsNeeded()
  }

  canRequestFileDeletion(file: PropertyFile) : boolean {
    if(!file) return false
    if(file.deletionRequested == null) return true
    const requestDate = dayjs(file.deletionRequested)
    const deadline = dayjs().add(60, "minutes")
    return requestDate.isAfter(deadline)
  }

  canApproveFileDeletion(file: PropertyFile) : boolean {
    if(!file || !file.deletionRequested) return false
    const requestDate = dayjs(file.deletionRequested)
    const deadline = dayjs().subtract(60, "minutes")

    return requestDate.isAfter(deadline) && file.deletionRequestUsername !== rpcClient.session.user?.email
  }

  reallyDeleteFile(file: PropertyFile): void {
    if(!this.folder || !this.folder.archive || !file) return

    //Remove file from archive.
    const idx = this.folder.archive.indexOf(file)
    this.folder.archive.splice(idx, 1)

    this.toast.success(this.i18n.$gettext("Datei wurde gelöscht"))
  }

  get isInternalUser(): boolean{
    return this.rpcClient.isInternalUser
  }
  handleDragEnter(e: Event): void {
    //We wn
    if(this.eventcounter == 0) {
      this.showDropZone = true
    }
    this.eventcounter = this.eventcounter + 1

  }

  handleDragLeave(e: Event): void {
    this.eventcounter = this.eventcounter - 1
    if(this.eventcounter == 0) {
      this.showDropZone = false
    }
  }

  handleFileDrop(e: DragEvent): void {
    const files: File[] = []
    if (e.dataTransfer?.items) {
      // Use DataTransferItemList interface to access the file(s)
      [...e.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const file = item.getAsFile()
          if(file) {
            files.push(file)
          }
        }
      })
    } else {
      // Use DataTransfer interface to access the file(s)
      [...e.dataTransfer?.files || []].forEach((file, i) => {
        files.push(file)
      })
    }

    this.handleFileUploadInternally(files)
  }


}
