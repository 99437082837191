import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "p-inputgroup" }
const _hoisted_2 = { class: "p-float-label" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_translate = _resolveComponent("translate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_AutoComplete, {
        modelValue: _ctx.newUser,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newUser) = $event)),
        disabled: _ctx.disabled,
        suggestions: _ctx.selectableUsers,
        placeholder: _ctx.placeholder,
        optionLabel: "email",
        onComplete: _ctx.filterUsers,
        onItemSelect: _ctx.updateSelection
      }, null, 8, ["modelValue", "disabled", "suggestions", "placeholder", "onComplete", "onItemSelect"]),
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}