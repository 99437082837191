
import {Options, Vue} from 'vue-class-component'
import {rpcClient} from '@/api/WebsocketClient'
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import ConfirmDialog from "primevue/confirmdialog"
import Button from "primevue/button"
import Toast from "primevue/toast"
import Dialog from "@/components/common/Dialog.vue"
import {Router, useRouter} from "vue-router"
import TwoFactorForm from "@/components/TwoFactorForm.vue"
import EnableTwoFactorForm from "@/components/EnableTwoFactorForm.vue"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"
import LoginForm from "@/components/LoginForm.vue"
import {registrationServiceApi} from "@/api/RegistrationServiceApi"
import RpcError from "@/api/RpcError"
import User from "@/model/User"
import Message from "primevue/message"
import {Watch} from "vue-property-decorator"
import globalState from "@/util/GlobalState"
import 'flatpickr/dist/flatpickr.css'
import useToast from "@/util/toasts"

@Options({
  components: {
    LoginForm, Message, EnableTwoFactorForm, TwoFactorForm, ConfirmDialog, Button, Toast, Dialog
  }
})
export default class App extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient
  globalState = globalState

  get user(): User | null {
    return rpcClient.session.user || null
  }

  get needsEmailConfirmation() {
    return this.user && !this.user.state
  }

  get needsTwoFactorSetting() {
    const setting = this.user?.twoFactorEnabled
    return this.user && setting !== true && setting !== false
  }

  @Watch('$route.query.token')
  watchToken(ot: string | undefined, nt: string | undefined) {
    if (ot !== nt) {
      registrationServiceApi._confirmEmail(this.$route.query?.token).then((user: User) => {
        if (user?.email) {
          this.rpcClient.session.user = user
          //@ts-ignore
          this.rpcClient.session.user.activated = true
        } else {
          this.rpcClient.session.user = null
        }
      }).catch((e: RpcError) => {
        //TODO
      })
    }
  }

  resendActivationMail() {
    registrationServiceApi._resendEmail().then(() => {
      //TODO
    }).catch((e: RpcError) => {
      //TODO
    })
  }

  mounted() {
    void SdkServiceClient.fetchAll(this.i18n.current)
    setTimeout(() => {
      this.watchToken(undefined, this.$route.query?.token)
      this.rpcClient.registerHandlerMethod("notifyOfNewMessage", (title: string, message: string) => {
        console.log("Notifying", title)
        console.log("Notifying", message)
        if(title && message) {
          this.toast.info(message, title)
        } else if(title) {
          this.toast.info(title)
        } else if (message) {
          this.toast.info(message)
        }
      })
    }, 100)
  }

  unmounted() {

  }
}
