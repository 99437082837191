
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import SWR from "@/api/SWR"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Room from "@/model/Room"
import FileUpload from "primevue/fileupload"
import {rpcClient} from "@/api/WebsocketClient"
import {documentServiceApi} from "@/api/DocumentServiceApi"
import Document from "@/model/Document"
import ProgressBar from "primevue/progressbar"
import MarkdownUtil from "@/util/MarkdownUtil"
import dayjs from "@/util/dayjs"
import Property from "@/model/gis/Property"
import {propertyServiceApi} from "@/api/PropertyServiceApi"
import Dialog from "@/components/common/Dialog.vue"
import {useGettext} from "@jshmrtn/vue3-gettext"
import Tree, {TreeNode} from "primevue/tree"
import PropertyFile from "@/model/gis/PropertyFile"
import PropertyFileFolder from "@/model/gis/PropertyFileFolder"
import useToast from "@/util/toasts"
import Dropdown from "@/components/controls/Dropdown.vue"

@Options({
  name: "RoomDetails",
  components: {
    AnimatedInput, Button, FileUpload, ProgressBar, Dialog, Tree, Dropdown
  },
  //@ts-ignore
  props: {
    room: [ Room, Object ],
    roomId: Number,
    projectId: Number,
    showDelete: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    hint: String,
    header: String,
    linkedProperties: {
      type: Array,
      default: []
    },
  },
  emits: [
    'delete', 'roomCreated'
  ]
})
export default class RoomDetails extends Vue {

  rpcClient = rpcClient
  dayjs = dayjs
  i18n = useGettext()
  toast = useToast()

  room!: Room
  roomId!: number
  projectId!: number
  showDelete!: boolean
  disabled!: boolean
  isTemplate!: boolean
  hint!: string

  roomIdInternal: number | null = null

  linkedProperyId: number | null = null
  linkedProperties: Property[] = []
  showPropertyFileChooser: boolean = false
  selectedKey: any = null

  progress: number = 0

  get documents(): SWR<Document[], string[]> | null {
    if (this.isTemplate) {
      return null
    }
    const id = this.room?.id || this.roomId || this.roomIdInternal
    if (id) {
      return documentServiceApi.listDocuments(id)
    } else {
      return null
    }
  }

  get safeDescription() {
    let description = '<p class="mb-0">' + MarkdownUtil.safeRenderMarkdown(this.room?.description, true) + '</p>'
    if (this.internLink) {
      description += '<a href="' + this.internLink + '">Link: ' + this.internLink + '</a>'
    }
    return description
  }

  get internLink() {
    if (this.projectId) {
      let link = window.location.protocol + '//' + window.location.host + '/projekte/' + this.projectId + '/datenraum/' + this.room.id
      if (this.room.offerId) {
        link += '/angebot/' + this.room.offerId
      }
      return link
    } else {
      return null
    }
  }

  get hasLinkedProperty(): boolean {
    return this.linkedProperties?.length > 0
  }

  get linkedProperty(): Property | null {
    if (!this.linkedProperyId) return null
    return propertyServiceApi.getPropertyById(this.linkedProperyId).data
  }

  get linkedPropertyFileTree(): TreeNode[] {
    if(!this.linkedProperty || !this.linkedProperty.files) return []

    let result: TreeNode[] = [];

    (this.linkedProperty.files as PropertyFileFolder[]).forEach((folder: PropertyFileFolder) => {
      result.push(this.convertFileFolderToTreeEntry(folder))
    })

    return result
  }

  convertFileFolderToTreeEntry(folder: PropertyFileFolder): TreeNode {
    const subFolders: TreeNode[] = []
    if (folder.children) {
      folder.children.forEach((childFolder: PropertyFileFolder) => {
        subFolders.push(this.convertFileFolderToTreeEntry(childFolder))
      })
    }

    if (folder.contents) {
      folder.contents.forEach((file: PropertyFile) => {
        let fileNode: TreeNode = {
          key: file.serverId ? file.serverId : undefined,
          label: file.name ? file.name : undefined,
          data: file.serverId ? file.serverId : undefined,
          icon: 'pi pi-file'
        }
        subFolders.push(fileNode)
      })
    }
    return {
      key: "directory-" + folder.name?.trim(), //Folders should not be selectable
      label: folder.name ? folder.name : undefined,
      icon: 'pi pi-folder',
      children: subFolders
    }
  }

  get selectedFilesForUpload(): string[] {
    if(!this.selectedKey) return []
    let keys: string[] = Object.keys(this.selectedKey)
    return keys.filter((s: string) => {
      return s !== "undefined" && !s.startsWith("directory") //This is converted by primevue
    })
  }

  attachDocumentsFromProperty(): Promise<void> {
    if(this.room.id && this.linkedProperty?.id && this.selectedFilesForUpload.length > 0){
      return documentServiceApi._importDocumentsFromProperty(this.room.id, this.linkedProperty.id, this.selectedFilesForUpload).then(() => {
        this.toast.success(this.i18n.$gettext("Dokumente wurden erfolgreich angehängt."))
        if(this.room.id) {
          documentServiceApi.listDocuments(this.room.id, true)
        }
      }).catch(() => {
        this.toast.error(this.i18n.$gettext("Dokumente konnten nicht angehängt werden."))
      }).finally(() => {
        this.showPropertyFileChooser = false
      })
    } else {
      return Promise.reject()
    }

  }

  downloadFile(document: Document): Promise<void> {
    if (document.roomId && document.name) {
      return documentServiceApi._getDownloadLink(document.roomId, document.name).then((link: string) => {
        window.open(link, '_blank')
      })
    } else {
      return Promise.reject()
    }
  }

  hideUploadFromPropertyModal(){
    this.showPropertyFileChooser = false
    this.linkedProperyId = null
    this.selectedKey = null
  }

  deleteFile(document: Document): Promise<void> {
    if (document.id) {
      return documentServiceApi._deleteDocument(document.id).finally(() => {

      })
    } else {
      return Promise.reject()
    }
  }

  iconClass(document: Document) {
    const name = document.name?.toLowerCase() || ''
    if (name.endsWith('pdf')) {
      return 'cil-file-pdf'
    } else if (name.endsWith('docx') || name.endsWith('doc')) {
      return 'cil-file-word'
    } else if (name.endsWith('xlsx') || name.endsWith('xls')) {
      return 'cil-file-excel'
    } else if (name.endsWith('pptx') || name.endsWith('ppt')) {
      return 'cil-file-powerpoint'
    } else {
      return 'cil-file'
    }
  }

  updateProgress(event: { progress: number }) {
    if (event.progress < 100) {
      this.progress = event.progress
    } else {
      this.progress = 0
    }
  }

  refresh(request: { xhr: XMLHttpRequest, files: any[] }) {
    if (!this.room && request.xhr.responseText) {
      this.roomIdInternal = Number(request.xhr.responseText)
      this.$emit('roomCreated', this.roomIdInternal)
    }
    const id = this.room?.id || this.roomId || this.roomIdInternal
    if (id) {
      documentServiceApi.listDocuments(id, true)
    }
  }

  addHeader(request: { xhr: XMLHttpRequest, formData: FormData }) {
    if (rpcClient.session.token) {
      request.xhr.setRequestHeader('X-Auth-Token', rpcClient.session.token)
    }
    return request
  }
}
