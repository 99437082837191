import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  class: "text-primary",
  href: "https://simap.ted.europa.eu/de/nuts",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelTemplate = _resolveComponent("PanelTemplate")!
  const _component_AnimatedInput = _resolveComponent("AnimatedInput")!
  const _component_translate = _resolveComponent("translate")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_ctx.organizationForms)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationForms, (panel) => {
          return (_openBlock(), _createBlock(_component_PanelTemplate, {
            panel: panel,
            class: "mb-4",
            active: "",
            onValueChanged: _cache[0] || (_cache[0] = f => _ctx.updateValues(f))
          }, null, 8, ["panel"]))
        }), 256))
      ]))
    : (_ctx.organization)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_AnimatedInput, {
            type: "text",
            class: "mb-3",
            label: _ctx.i18n.$gettext('Offizielle Bezeichnung'),
            modelValue: _ctx.organization.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.organization.name) = $event)),
            modelModifiers: { lazy: true, trim: true },
            "auto-resize": "",
            required: ""
          }, null, 8, ["label", "modelValue"]),
          _createVNode(_component_AnimatedInput, {
            type: "text",
            class: "mb-3",
            label: _ctx.i18n.$gettext('Nationale Identifikationsnummer'),
            modelValue: _ctx.organization.identificationNumber,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.organization.identificationNumber) = $event)),
            modelModifiers: { lazy: true, trim: true }
          }, null, 8, ["label", "modelValue"]),
          (_ctx.organization.contact)
            ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                key: 0,
                type: "text",
                class: "mb-3",
                label: _ctx.i18n.$gettext('Postanschrift'),
                modelValue: _ctx.organization.contact.street,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.organization.contact.street) = $event)),
                modelModifiers: { lazy: true, trim: true },
                "auto-resize": "",
                required: ""
              }, null, 8, ["label", "modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.organization.contact)
            ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                key: 1,
                type: "text",
                class: "mb-3",
                label: _ctx.i18n.$gettext('Postleitzahl'),
                modelValue: _ctx.organization.contact.zip,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.organization.contact.zip) = $event)),
                modelModifiers: { lazy: true, trim: true },
                required: ""
              }, null, 8, ["label", "modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.organization.contact)
            ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                key: 2,
                type: "text",
                class: "mb-3",
                label: _ctx.i18n.$gettext('Ort'),
                modelValue: _ctx.organization.contact.city,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.organization.contact.city) = $event)),
                modelModifiers: { lazy: true, trim: true },
                required: ""
              }, null, 8, ["label", "modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.organization.contact)
            ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                key: 3,
                type: "text",
                class: "mb-3",
                label: _ctx.i18n.$gettext('Land'),
                modelValue: _ctx.organization.contact.country,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.organization.contact.country) = $event)),
                modelModifiers: { lazy: true, trim: true },
                required: ""
              }, null, 8, ["label", "modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.organization.contact)
            ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                key: 4,
                type: "text",
                class: "mb-3",
                label: _ctx.i18n.$gettext('Kontaktstelle'),
                modelValue: _ctx.organization.contact.department,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.organization.contact.department) = $event)),
                modelModifiers: { lazy: true, trim: true },
                "auto-resize": "",
                required: ""
              }, null, 8, ["label", "modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.organization.contact)
            ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                key: 5,
                type: "text",
                class: "mb-3",
                label: _ctx.i18n.$gettext('Telefon'),
                modelValue: _ctx.organization.contact.phone,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.organization.contact.phone) = $event)),
                modelModifiers: { lazy: true, trim: true },
                required: ""
              }, null, 8, ["label", "modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.organization.contact)
            ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                key: 6,
                type: "text",
                class: "mb-3",
                label: _ctx.i18n.$gettext('Fax'),
                modelValue: _ctx.organization.contact.facsimile,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.organization.contact.facsimile) = $event)),
                modelModifiers: { lazy: true, trim: true }
              }, null, 8, ["label", "modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.organization.contact)
            ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                key: 7,
                type: "email",
                class: "mb-3",
                label: _ctx.i18n.$gettext('E-Mail'),
                modelValue: _ctx.organization.contact.email,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.organization.contact.email) = $event)),
                modelModifiers: { lazy: true, trim: true },
                required: ""
              }, null, 8, ["label", "modelValue"]))
            : _createCommentVNode("", true),
          (_ctx.organization.contact)
            ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                key: 8,
                type: "text",
                class: "mb-3",
                label: _ctx.i18n.$gettext('Hauptadresse (URL)'),
                modelValue: _ctx.organization.contact.url,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.organization.contact.url) = $event)),
                modelModifiers: { lazy: true, trim: true },
                required: ""
              }, null, 8, ["label", "modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_AnimatedInput, {
            type: "text",
            class: "mb-3",
            label: _ctx.i18n.$gettext('Adresse des Beschafferprofils (URL)'),
            modelValue: _ctx.organization.contact.projectUrl,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.organization.contact.projectUrl) = $event)),
            modelModifiers: { lazy: true, trim: true }
          }, null, 8, ["label", "modelValue"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AnimatedInput, {
              type: "text",
              label: _ctx.i18n.$gettext('NUTS Code'),
              modelValue: _ctx.organization.nutsCode,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.organization.nutsCode) = $event)),
              modelModifiers: { lazy: true, trim: true },
              required: ""
            }, null, 8, ["label", "modelValue"]),
            _createElementVNode("a", _hoisted_4, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Link zur NUTS-Code-Suche")
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : (_openBlock(), _createBlock(_component_ProgressBar, {
          key: 2,
          mode: "indeterminate"
        }))
}