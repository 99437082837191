import { v4 as uuidv4 } from 'uuid'

function generateRandom(byteCount: number): Uint8Array {
  const buffer: Uint8Array = new Uint8Array(byteCount)
  crypto.getRandomValues(buffer)
  return buffer
}

export function getRandomUUID(): string {
  const random = Array.from(generateRandom(16))
  return uuidv4({random})
}