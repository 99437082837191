import PropertyFile from '@/model/gis/PropertyFile';

export default class PropertyFileFolder {
  name!: string | null;
  created!: string | null;
  children!: PropertyFileFolder[] | null;
  contents!: PropertyFile[] | null;
  archive!: PropertyFile[] | null;

  constructor() {
    this.name = null;
    this.created = null;
    this.children = null;
    this.contents = null;
    this.archive = null;
  }
}
