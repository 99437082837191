import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueTagsInput = _resolveComponent("VueTagsInput")!

  return (_openBlock(), _createBlock(_component_VueTagsInput, {
    class: "p-component p-inputwrapper-filled",
    modelValue: _ctx.currentTag,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTag) = $event)),
    tags: _ctx.allTags,
    "autocomplete-items": _ctx.autocompleteItems,
    placeholder: _ctx.i18n.$gettext('Click and type to add'),
    onTagsChanged: _ctx.onTagsChanged
  }, null, 8, ["modelValue", "tags", "autocomplete-items", "placeholder", "onTagsChanged"]))
}