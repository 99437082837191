import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b5b54f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex-shrink-1 flex-grow-1 w-100 d-flex flex-column container-wide card bg-white p-5",
  style: {"min-height":"0","overflow":"auto"}
}
const _hoisted_2 = { class: "row mb-4" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "w-100 d-flex align-items-center mb-4" }
const _hoisted_5 = { class: "mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_AnimatedInput = _resolveComponent("AnimatedInput")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_translate = _resolveComponent("translate")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_TipTapTextArea = _resolveComponent("TipTapTextArea")!
  const _component_PanelEditor = _resolveComponent("PanelEditor")!
  const _component_Draggable = _resolveComponent("Draggable")!
  const _component_Container = _resolveComponent("Container")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!

  return (_openBlock(), _createElementBlock("form", {
    class: "container-wide w-100 flex-shrink-1 flex-grow-1 d-flex flex-column",
    style: {"min-height":"0"},
    onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
  }, [
    (_openBlock(), _createBlock(_Teleport, { to: "#topmenu-buttons" }, [
      (!_ctx.disabled)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            type: "submit",
            class: "p-button-success mr-2",
            icon: "pi pi-save",
            label: _ctx.i18n.$gettext('Speichern'),
            disabled: !_ctx.name,
            onClick: _withModifiers(_ctx.save, ["stop","prevent"])
          }, null, 8, ["label", "disabled", "onClick"]))
        : _createCommentVNode("", true)
    ])),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AnimatedInput, {
            class: "mb-2",
            type: "text",
            label: _ctx.i18n.$gettext('Name der Vorlage'),
            modelValue: _ctx.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
            modelModifiers: { lazy: true, trim: true },
            "auto-resize": "",
            required: ""
          }, null, 8, ["label", "modelValue"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.active,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.active) = $event)),
              class: "mr-2"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_translate, null, {
              default: _withCtx(() => [
                _createTextVNode("Formular is aktiv")
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_Multiselect, {
            class: "w-100 mb-2",
            label: _ctx.i18n.$gettext('Typ der Ausschreibung'),
            modelValue: _ctx.projectTypes,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.projectTypes) = $event)),
            options: _ctx.availableProjectTypes,
            "option-label": "translation",
            "option-value": "code"
          }, null, 8, ["label", "modelValue", "options"]),
          _createVNode(_component_TipTapTextArea, {
            type: "text",
            placeholder: _ctx.i18n.$gettext('Beschreibung der Vorlage'),
            modelValue: _ctx.description,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.description) = $event)),
            modelModifiers: { lazy: true, trim: true },
            required: ""
          }, null, 8, ["placeholder", "modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.disabled)
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              class: "d-flex flex-row",
              onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => {_ctx.panels.push({ label: _ctx.panelName, ordinal: _ctx.panelOrdinal, content: [], contentType: 'group' }); _ctx.panelName = ''; _ctx.panelOrdinal = ''}, ["prevent"]))
            }, [
              _createVNode(_component_AnimatedInput, {
                modelValue: _ctx.panelOrdinal,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.panelOrdinal) = $event)),
                label: _ctx.i18n.$gettext('Gliederungspunkt'),
                class: "ordinal-input"
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_AnimatedInput, {
                modelValue: _ctx.panelName,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.panelName) = $event)),
                "auto-resize": "",
                label: _ctx.i18n.$gettext('Abschnitt-Name'),
                required: ""
              }, null, 8, ["modelValue", "label"]),
              _createVNode(_component_Button, {
                type: "submit",
                icon: "pi pi-plus",
                class: "ml-3",
                disabled: !_ctx.panelName
              }, null, 8, ["disabled"])
            ], 32))
          : _createCommentVNode("", true)
      ]),
      ((_ctx.panels || []).length)
        ? (_openBlock(), _createBlock(_component_Container, {
            key: 0,
            orientation: "vertical",
            "group-name": "Panels",
            "drag-handle-selector": ".panel-drag-handle",
            "lock-axis": "y",
            "drop-placeholder": { className:
                                 `drop-area`,
                               animationDuration: '200',
                               showOnTop: true },
            "drag-class": "shadow bg-color-lightgray\n            transition duration-100 ease-in\n            transform rotate-3 scale-110",
            "drop-class": "transition duration-100\n            ease-in z-50 transform\n            -rotate-2 scale-90",
            "get-child-payload": index => _ctx.panels[index],
            onDrop: _cache[7] || (_cache[7] = (e) => _ctx.onPanelDrop(_ctx.panels, e))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.panels, (panel, i) => {
                return (_openBlock(), _createBlock(_component_Draggable, {
                  key: 'panels-' + i
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PanelEditor, {
                      panel: panel,
                      id: panel.contentId + '-' + i,
                      class: "bg-white mb-4",
                      "can-move-up": i > 0,
                      "can-move-down": i < _ctx.panels.length - 1,
                      disabled: _ctx.disabled,
                      "is-eu-copy": _ctx.id?.includes('EU-'),
                      onCopyContent: ($event: any) => (_ctx.panels.splice(i, 0, _ctx.copyPanel(panel))),
                      onRemove: ($event: any) => (_ctx.panels.splice(i, 1)),
                      onMoveUp: ($event: any) => (_ctx.panels.splice(Math.max(i - 1, 0), 0, _ctx.panels.splice(i, 1)[0])),
                      onMoveDown: ($event: any) => (_ctx.panels.splice(Math.min(i + 1, _ctx.panels.length - 1), 0, _ctx.panels.splice(i, 1)[0]))
                    }, null, 8, ["panel", "id", "can-move-up", "can-move-down", "disabled", "is-eu-copy", "onCopyContent", "onRemove", "onMoveUp", "onMoveDown"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["get-child-payload"]))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "#breadcrumbs" }, [
      _createVNode(_component_Breadcrumb, {
        home: { icon: 'pi pi-home', to: '/' },
        model: _ctx.breadcrumbs
      }, null, 8, ["model"])
    ]))
  ], 32))
}