import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: _ctx.internalValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
    options: _ctx.choices,
    class: "w-100",
    "option-label": "label",
    "option-value": "key",
    label: _ctx.label,
    disabled: _ctx.disabled
  }, null, 8, ["modelValue", "options", "label", "disabled"]))
}