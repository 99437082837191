
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import Divider from 'primevue/divider'
import GreetingCircle from "@/components/GreetingCircle.vue"
import {MenuItem} from "primevue/menuitem"
import Breadcrumb from "primevue/breadcrumb"
import LoginForm from "@/components/LoginForm.vue"
import {settingsServiceApi} from "@/api/SettingsServiceApi"
import Settings from "@/model/settings/Settings"
import {projectTemplateServiceApi} from "@/api/ProjectTemplateServiceApi"
import breakpointUtil from "@/util/BreakpointUtil"

@Options({
  name: "Greeting",
  components: {
    LoginForm, GreetingCircle, Button, Divider, Breadcrumb
  }
})
export default class Greeting extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient
  breakpointUtil = breakpointUtil

  initialized: boolean = false
  settings: Settings | null = null

  get step1(): number {
    const step: string = this.$route.params?.step1
    if ((this.rpcClient.fullyLoggedIn || this.rpcClient.needsTwoFactorConfirm) && !this.rpcClient.isInternalUser) {
      this.$router.push('/projekte')
      return -1
    } else if (this.rpcClient.isInternalUser && !step) {
      this.$router.push('/wizard/1')
      return -1
    }
    return step?.match(/\d+/) ? Number(step) : 0
  }

  get step2(): number {
    const step: string = this.$route.params?.step2 || '0'
    return step?.match(/\d+/) ? Number(step) : 0
  }

  get step3(): number {
    const step3: string = this.$route.params?.step3 || '0'
    if (this.step1 === 3 && this.step2 === 1 && step3 === '1' && this.rpcClient.isInternalUser) {
      projectTemplateServiceApi.getProjectTemplates(10000)
    }
    return step3?.match(/\d+/) ? Number(step3) : 0
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    if (this.step1 >= 3) {
      breadcrumbs.push({
        label: this.i18n.$gettext('Ausschreibung erstellen'),
        to: '/wizard/3'
      })
    }
    if (this.step2 === 1) {
      breadcrumbs.push({
        label: this.i18n.$gettext('Vergaberecht'),
        to: '/wizard/3/1'
      })
    } else if (this.step2 === 2) {
      breadcrumbs.push({
        label: this.i18n.$gettext('Kartellrecht'),
        to: '/wizard/3/2'
      })
    } else if (this.step2 === 3) {
      breadcrumbs.push({
        label: this.i18n.$gettext('Beihilferecht'),
        to: '/wizard/3/3'
      })
    } else if (this.step2 === 4) {
      breadcrumbs.push({
        label: this.i18n.$gettext('Eisenbahnrecht'),
        to: '/wizard/3/4'
      })
    } else if (this.step2 === 5) {
      breadcrumbs.push({
        label: this.i18n.$gettext('Andere Verfahren'),
        to: '/wizard/3/5'
      })
    }
    if (this.step2 === 1 && this.step3 === 1) {
      breadcrumbs.push({
        label: this.i18n.$gettext('Konzessionsrecht'),
        to: '/wizard/3/1/1'
      })
    } else if (this.step2 === 1 && this.step3 === 2) {
      breadcrumbs.push({
        label: this.i18n.$gettext('Anderes Vergaberecht'),
        to: '/wizard/3/1/2'
      })
    }
    return breadcrumbs
  }

  openProjectForm(regulation: string, type: number) {
    if (this.step2 === 1) {
      if (this.step3 === 1) {
        let noticeId
        switch (type) {
          case 5:
            noticeId = this.settings?.defaultTemplateSettings?.konzVD
            break
          default:
            noticeId = this.settings?.defaultTemplateSettings?.konzVV
            break
        }
        this.$router.push({
          path: '/projekte/anlage/' + (noticeId || 'EU-19'),
          hash: '#top',
          query: {
            'BT-01-notice': regulation,
            type: type
          }
        })
      } else if (this.step3 === 2) {

      }
    } else {

    }
  }

  mounted() {
    settingsServiceApi._getSettings().then((settings: Settings) => {
      this.settings = settings
    })
  }

}
