
import {Options, Vue} from "vue-class-component"
import PanelTemplate from "@/model/common/Content"
import Card from "primevue/card"
import Button from "primevue/button"
import Dialog from "@/components/common/Dialog.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Dropdown from "@/components/controls/Dropdown.vue"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import {Container, Draggable} from 'vue3-smooth-dnd'
import Field from "@/model/common/Content"
import Panel from "@/model/common/Content"
import Choice from "@/model/common/Choice"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"
import Content from "@/model/common/Content"
import PanelEditorField from "@/components/admin/PanelEditorField.vue"
import ContentUtil from "@/util/ContentUtil"

@Options({
  name: "PanelEditor",
  components: {
    PanelEditorField, Card, Button, Dialog, AnimatedInput, Dropdown, Checkbox, Container, Draggable
  },
  //@ts-ignore
  props: {
    panel: [ PanelTemplate, Object ],
    canMoveUp: Boolean,
    canMoveDown: Boolean,
    id: String,
    disabled: Boolean,
    isEuCopy: Boolean
  },
  emits: [
      'copy', 'remove', 'move-up', 'move-down'
  ]
})
export default class PanelEditor extends Vue {

  i18n: Language = useGettext()

  panel!: PanelTemplate

  fieldTypeOptions: { name: string, type: string }[] = [
    {
      name: this.i18n.$gettext('Text-Feld (lang)'),
      type: 'TEXTAREA'
    },
    {
      name: this.i18n.$gettext('Text-Feld (kurz)'),
      type: 'TEXTBOX'
    },
    {
      name: this.i18n.$gettext('Einfach-Auswahl'),
      type: 'COMBOBOX'
    },
    {
      name: this.i18n.$gettext('Mehrfach-Auswahl'),
      type: 'MULTICHECKBOX'
    },
    {
      name: this.i18n.$gettext('Ganzzahl-Feld'),
      type: 'NUMBER'
    },
    {
      name: this.i18n.$gettext('Datum+Zeit-Feld'),
      type: 'DATETIME'
    },
    {
      name: this.i18n.$gettext('Datum-Feld'),
      type: 'DATE'
    },
    {
      name: this.i18n.$gettext('Zeit-Feld'),
      type: 'TIME'
    },
    {
      name: this.i18n.$gettext('Kontrollkästchen (Ja/Nein)'),
      type: 'RADIO'
    }
  ]

  showNewFieldDialog: boolean = false
  newFieldPanel: any | null = null
  newFieldOrdinal: string = ''
  newFieldName: string = ''
  newFieldContentId: string | null = null
  newFieldType: string = this.fieldTypeOptions[0].type
  newFieldRequired: boolean = false
  newFieldAlwaysShow: boolean = false
  newFieldChoiceName: string = ''
  newFieldChoices: Choice[] = []
  newFieldChoice: Choice | null = null
  newFieldField: Field | null = null
  newFieldDefaultStringValue: string = ''

  showNewPanelDialog: boolean = false
  newPanelPanel: any | null = null
  newPanelOrdinal: string = ''
  newPanelName: string = ''
  newPanelContentId: string | null = null
  editedPanel: PanelTemplate | null = null
  editedField: Field | null = null
  editedChoice: Choice | null = null

  showEditPanelDialog: boolean = false
  showEditChoiceDialog: boolean = false
  editedChoiceOrPanelName: string = ''
  editedChoiceContentId: string = ''
  editedPanelOrdinal: string = ''
  editedPanelContentId: string | null = null

  get hasRequiredField(): boolean {
    return ContentUtil.hasRequiredField(this.panel)
  }

  get labelTranslated(): string {
    return SdkServiceClient.getLabelForField(this.panel?.label)
  }

  openPanelEditDialog() {
    this.resetDialogues()
    this.editedPanelOrdinal = this.panel.ordinal || ''
    this.editedPanelContentId = this.panel.contentId
    this.editedChoiceOrPanelName = this.panel.label || ''
    this.editedPanel = this.panel
    this.showEditPanelDialog = true
    //TODO
  }

  openFieldEditDialog(field: Field) {
    this.resetDialogues()
    this.newFieldPanel = null
    this.newFieldChoice = null
    this.newFieldField = null
    this.newFieldOrdinal = field.ordinal || ''
    this.newFieldName = field.label || ''
    this.newFieldType = (this.fieldTypeOptions.find(o => o.type === field.displayType) || this.fieldTypeOptions[0]).type
    this.newFieldChoices = field.choices || []
    this.newFieldRequired = !!field.required
    this.newFieldAlwaysShow = !!field.alwaysShow
    this.newFieldContentId = field.contentId
    this.editedField = field
    this.showNewFieldDialog = true
    //TODO
  }

  openChoiceEditDialog(choice: Choice) {
    this.resetDialogues()
    this.editedChoiceOrPanelName = choice.name || ''
    this.editedChoiceContentId = choice.contentId || ''
    this.editedChoice = choice
    this.showEditChoiceDialog = true
    //TODO
  }

  acceptChoiceChanges(){
    if(!this.editedChoice) return
    this.editedChoice.name = this.editedChoiceOrPanelName
    this.editedChoice.contentId = this.editedChoiceContentId
    this.resetDialogues()
  }

  openNewFieldDialog(panel: any) {
    this.resetDialogues()
    this.newFieldPanel = panel
    this.showNewFieldDialog = true
    //TODO
  }

  openNewPanelDialog(panel: any) {
    this.resetDialogues()
    this.newPanelPanel = panel
    this.showNewPanelDialog = true
    //TODO
  }

  openNewFieldForChioceDialog(choice: Choice) {
    this.resetDialogues()
    this.newFieldChoice = choice
    this.showNewFieldDialog = true
    //TODO
  }

  openNewFieldForCheckboxDialog(field: Field) {
    this.resetDialogues()
    this.newFieldField = field
    this.showNewFieldDialog = true
    //TODO
  }

  resetDialogues() {
    this.editedField = null
    this.editedChoice = null
    this.editedPanel = null
    this.newFieldPanel = null
    this.newFieldOrdinal = ''
    this.newFieldName = ''
    this.newFieldType = this.fieldTypeOptions[0].type
    this.newFieldRequired = false
    this.newFieldAlwaysShow = false
    this.newFieldChoiceName = ''
    this.newFieldChoices = []
    this.showNewFieldDialog = false
    this.showEditPanelDialog = false
    this.showEditChoiceDialog = false
    this.showNewPanelDialog = false
    this.newPanelPanel = null
    this.newPanelName = ''
    this.newPanelOrdinal = ''
    //TODO
  }

  addOrSavePanel() {
    if (this.newPanelPanel && this.newPanelName) {
      if (!this.newPanelPanel.children) {
        this.newPanelPanel.children = []
      }
      const panel = Object.assign(new Panel(), {
        label: this.newPanelName,
        contentId: this.newPanelContentId,
        ordinal: this.newPanelOrdinal,
        content: [],
        contentType: 'group',
      })
      this.newPanelPanel.children.push(panel)
      this.resetDialogues()
    }
  }

  addOrSaveField() {
    if (this.newFieldPanel && this.newFieldName && this.newFieldType) {
      if (!this.newFieldPanel.children) {
        this.newFieldPanel.children = []
      }
      const field = Object.assign(new Field(), {
        contentType: 'field',
        label: this.newFieldName,
        contentId: this.newFieldContentId,
        ordinal: this.newFieldOrdinal || '',
        displayType: this.newFieldType,
        required: this.newFieldRequired,
        stringValue: this.newFieldDefaultStringValue,
        choices: [],
        fields: []
      })
      if (this.newFieldChoices) {
        field.choices.push(...this.newFieldChoices)
      }
      this.newFieldPanel.children.push(field)
      this.resetDialogues()
    } else if (this.newFieldChoice && this.newFieldName && this.newFieldType) {
      if (!this.newFieldChoice.fields) {
        this.newFieldChoice.fields = []
      }
      const field = Object.assign(new Field(), {
        contentType: 'field',
        label: this.newFieldName,
        contentId: this.newFieldContentId,
        ordinal: this.newFieldOrdinal || '',
        displayType: this.newFieldType,
        required: this.newFieldRequired,
        choices: [],
        fields: []
      })
      if (this.newFieldChoices) {
        field.choices.push(...this.newFieldChoices)
      }
      this.newFieldChoice.fields.push(field)
      this.resetDialogues()
    } else if (this.newFieldField && this.newFieldName && this.newFieldType) {
      if (!this.newFieldField.fields) {
        this.newFieldField.fields = []
      }
      const field = Object.assign(new Field(), {
        contentType: 'field',
        label: this.newFieldName,
        contentId: this.newFieldContentId,
        ordinal: this.newFieldOrdinal || '',
        displayType: this.newFieldType,
        required: this.newFieldRequired,
        choices: [],
        fields: []
      })
      if (this.newFieldChoices) {
        field.choices.push(...this.newFieldChoices)
      }
      this.newFieldField.fields.push(field)
      this.resetDialogues()
    } else if (this.editedField && this.newFieldName && this.newFieldType) {
      this.editedField.ordinal = this.newFieldOrdinal
      this.editedField.label = this.newFieldName
      this.editedField.contentId = this.newFieldContentId
      this.editedField.displayType = this.newFieldType
      this.editedField.required = this.newFieldRequired
      this.editedField.choices = this.newFieldChoices
      this.resetDialogues()
    } else {
      //TODO
    }
  }

  addOption() {
    //TODO
    if (this.newFieldChoiceName) {
      if (!this.newFieldChoices) {
        this.newFieldChoices = []
      }
      this.newFieldChoices.push({
        name: this.newFieldChoiceName,
        contentId: '', //No content Id here, this is only settable after creation
        fields: []
      })
      this.newFieldChoiceName = ''
    }
  }

  copyPanel(panel: Panel): Panel {
    return Object.assign(new Panel(), JSON.parse(JSON.stringify(panel)))
  }

  onFieldDrop(panel: PanelTemplate | null | undefined, dropResult: any) {
    if(!panel) return
    if(!panel.children) {
      panel.children = []
    }
    if ((dropResult.removedIndex !== null || dropResult.addedIndex !== null) && dropResult.removedIndex != dropResult.addedIndex) {
      if (dropResult.removedIndex != null) {
        panel.children.splice(dropResult.removedIndex, 1)
      }
      if (dropResult.addedIndex != null && dropResult.payload) {
        panel.children.splice(dropResult.addedIndex, 0, dropResult.payload)
      }
    }
  }
}
