
import {Vue, Options} from "vue-class-component"
import PrimeVueCheckbox from "primevue/checkbox"
import {Watch} from "vue-property-decorator"

@Options({
  name: "Checkbox",
  components: {
    PrimeVueCheckbox
  },
  //@ts-ignore
  props: {
    modelValue: [ Array, Object, Boolean ],
    label: String,
    value: String,
    checked: [ String, Boolean, Object ],
    binary: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class Checkbox extends Vue {
  binary!: boolean
  disabled!: boolean
  label!: string
  value!: string
  checked!: any
  modelValue!: any
  internalValue: any = Array.isArray(this.modelValue) ? this.modelValue : (this.modelValue || false)

  initialize(): void {
    this.internalValue = this.modelValue
    if (this.checked !== undefined && this.checked !== null && this.checked !== false) {
      if (Array.isArray(this.modelValue) && !this.modelValue.includes(this.value)) {
        this.internalValue.push(this.value)
      } else if (!Array.isArray(this.modelValue)) {
        this.internalValue = true
      }
    }
  }

  @Watch('internalValue')
  handleInput (val: any, oldVal: any) {
    this.$emit('update:modelValue', this.internalValue)
  }

  @Watch('modelValue')
  onValueChanged (val: any, oldVal: any) {
    this.initialize()
  }

  @Watch('checked')
  onCheckedChanged (val: any, oldVal: any) {
    this.initialize()
  }

  mounted() {
    this.initialize()
  }
}
