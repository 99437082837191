
import {Vue, Options} from "vue-class-component"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Organization from "@/model/Organization"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Content from "@/model/common/Content"
import PanelTemplate from "@/components/admin/PanelTemplate.vue"
import ProgressBar from "primevue/progressbar"
import Project from "@/model/Project"
import ContentUtil from "@/util/ContentUtil"
import Contact from "@/model/Contact"
import Panel from "@/model/common/Content"

@Options({
  name: "OrganizationForm",
  components: {
    PanelTemplate, AnimatedInput, ProgressBar
  },
  //@ts-ignore
  props: {
    organization: [ Organization, Object ],
    project: [ Project, Object ]
  }
})
export default class OrganizationForm extends Vue {

  i18n: Language = useGettext()

  organization!: Organization
  project!: Project

  get organizationForms(): Content[] | null {
    if (!this.organization.forms && this.project?.panels) {
      let template: Content | null = ContentUtil.findContent(this.project.panels, 'GR-Organisations', false) ||
                                     ContentUtil.findContent(this.project.panels, 'GR-Organisations')
      if (template) {
        template = this.copyPanel(template)
        const forms = []
        const company = template.children?.find(c => c.contentId === 'GR-Company')
        if (company) forms.push(company)
        const address = template.children?.find(c => c.contentId === 'GR-Company-Address')
        if (address) forms.push(address)
        const contact = template.children?.find(c => c.contentId === 'GR-Company-Contact')
        if (contact) forms.push(contact)
        this.organization.forms = forms
      }
    }
    return this.organization.forms
  }

  copyPanel(panel: Panel): Panel {
    const newPanel = Object.assign(new Panel(), JSON.parse(JSON.stringify(panel)))
    newPanel.added = true
    return newPanel
  }

  updateValues(field: Content) {
    if (!this.organization) {
      return
    }
    if (!this.organization.contact) {
      this.organization.contact = new Contact()
    }
    switch (field?.contentId) {
      case 'BT-500-Organization-Company':
        if (field.stringValue) this.organization.name = field.stringValue
        break
      case 'BT-501-Organization-Company':
        if (field.stringValue) this.organization.registrationNumber = field.stringValue
        break
      case 'BT-16-Organization-Company':
        if (field.stringValue) this.organization.contact.department = field.stringValue
        break
      case 'BT-505-Organization-Company':
        if (field.stringValue) this.organization.contact.url = field.stringValue
        break
      case 'BT-509-Organization-Company':
        if (field.stringValue) this.organization.contact.projectUrl = field.stringValue
        break
      case 'BT-510(a)-Organization-Company':
        if (field.stringValue) this.organization.contact.street = field.stringValue
        break
      case 'BT-510(b)-Organization-Company':
        if (field.stringValue) this.organization.contact.street2 = field.stringValue
        break
      case 'BT-510(c)-Organization-Company':
        this.organization.contact.street2 = this.organization.contact.street2 || ''
        if (field.stringValue) this.organization.contact.street2 += field.stringValue
        break
      case 'BT-513-Organization-Company':
        if (field.stringValue) this.organization.contact.city = field.stringValue
        break
      case 'BT-512-Organization-Company':
        if (field.stringValue) this.organization.contact.zip = field.stringValue
        break
      case 'BT-502-Organization-Company':
        if (field?.stringValue) {
          const split = field.stringValue.split(/\s+/)
          this.organization.contact.givenName = split[0]
          this.organization.contact.surName = split.length > 1 ? field.stringValue.replace(split[0], '').trim() : ''
        }
        break
      case 'BT-506-Organization-Company':
        if (field.stringValue) this.organization.contact.email = field.stringValue
        break
      case 'BT-503-Organization-Company':
        if (field.stringValue) this.organization.contact.phone = field.stringValue
        break
      case 'BT-739-Organization-Company':
        if (field.stringValue) this.organization.contact.facsimile = field.stringValue
        break
      default:
          break
    }
  }
}
