import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import LogEntry from '@/model/LogEntry';


export default class GeneratedLogEntryStore {

    state = reactive({
        logEntrys: new Map<number, LogEntry>(),
        calls: new Map<string, number | number[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.logEntrys) this.state.logEntrys.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceLogEntry(newLogEntry: LogEntry) {
        if (newLogEntry.id) {
            this.state.logEntrys.set(newLogEntry.id, newLogEntry)
            
        }
    }

    addOrReplaceLogEntrys(newLogEntrys: LogEntry[]) {
        for (const newLogEntry of newLogEntrys) {
            this.addOrReplaceLogEntry(newLogEntry)
        }
    }

    replaceLogEntrys(logEntrys: LogEntry[]) {
        const newLogEntrys = new Map<number, LogEntry>()
        for (const newLogEntry of logEntrys) {
            if (newLogEntry.id) {
                newLogEntrys.set(newLogEntry.id, newLogEntry)
                
            }
        }
        this.state.logEntrys = newLogEntrys
    }

    removeLogEntry(id: number | undefined | null) {
        if (id !== undefined && id !== null) {
            this.state.logEntrys.delete(id)
        }
    }

    removeLogEntrys(ids: (number | null)[] | undefined | null) {
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                this.removeLogEntry(id)
            }
        }
    }
}