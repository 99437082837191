
import {Options, Vue} from "vue-class-component"
import Card from "primevue/card"
import Button from "primevue/button"
import Dialog from "@/components/common/Dialog.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Dropdown from "@/components/controls/Dropdown.vue"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import {Container, Draggable} from 'vue3-smooth-dnd'
import PanelEditorField from "@/components/admin/PanelEditorField.vue"
import PropertyFile from "@/model/gis/PropertyFile"
import fileSizeString from "@/util/fileSize"
import FileUpload, {FileUploadBeforeUploadEvent} from "primevue/fileupload"
import AccordionTab from "primevue/accordiontab"
import Accordion from "primevue/accordion"
import {rpcClient} from "@/api/WebsocketClient"
import useToast from "@/util/toasts"
import TriStateDropdown from "@/components/controls/TriStateDropdown.vue"
import ToggleButton from "primevue/togglebutton"
import {Watch} from "vue-property-decorator"
import Tag from "primevue/tag"
import InputSwitch from "primevue/inputswitch"

@Options({
  name: "PropertyFileDisplay",
  components: {
    TriStateDropdown, ToggleButton, Tag, InputSwitch,
    PanelEditorField, Card, Button, Dialog, AnimatedInput, Dropdown, Checkbox, Container, Draggable, FileUpload, Accordion, AccordionTab
  },
  //@ts-ignore
  props: {
    file: [PropertyFile, Object],
    interactive: Boolean,
    propertyId: [Number, null]
  },
  emits: ['updateNeeded', 'revertNeeded']
})
export default class PanelEditor extends Vue {

  basePath: string = "/api/v1/files/gis/document/" //Should start and end with a slash
  toast = useToast()
  i18n: Language = useGettext()
  rpcClient = rpcClient

  file: PropertyFile | null = null
  interactive: boolean = true
  propertyId: number | null = null

  @Watch('file')
  correctFile(){
    if(this.file && typeof this.file.isPublic === "undefined") {
      this.file.isPublic = false
    }
  }


  get downloadUrl(): string {
    if(this.file) {
      return this.assembleDownloadUrlOfFile(this.file)
    } else {
      return "#"
    }
  }

  assembleDownloadUrlOfFile(file: PropertyFile) : string {
    if(!file.serverId) return "#"
    return this.basePath + this.propertyId + "/" + file.serverId
  }

  archiveCurrentVersionIfPossible(){
    if(!this.file) return
    this.$emit('archive')
    /*
    if(this.fileHasCurrentVersion) {
      const historyVersion: PropertyFile = Object.assign({}, this.file)
      historyVersion.previousVersions = null
      if(this.file.previousVersions == null) {
        this.file.previousVersions = []
      }
      this.file.previousVersions.push(historyVersion)
    }
    this.file.fileSizeInBytes = 0 //Indicates no data
    this.file.originalFileName = ""
    this.file.serverId = "" */
  }

  handleArchiveButton(){
    this.archiveCurrentVersionIfPossible()
  }

  fileSizeFormatted(file: PropertyFile) : string {
    return file.fileSizeInBytes !==null ? fileSizeString(file.fileSizeInBytes || 0) : ""
  }

  get isInternalUser(): boolean{
    return this.rpcClient.isInternalUser
  }

}
