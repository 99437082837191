
import {Options, Vue} from 'vue-class-component'

@Options({
  name: "Changelog",
  components: {},
  //@ts-ignore
  props: {}
})
export default class Changelog extends Vue {
}
