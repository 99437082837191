
import {Vue, Options} from "vue-class-component"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import DatePicker from "@/components/controls/DatePicker.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import Button from "primevue/button"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import {rpcClient} from "@/api/WebsocketClient"
import FieldDefinition from "@/model/common/Content"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"
import dayjs from "@/util/dayjs"
import ContentUtil from "@/util/ContentUtil"

@Options({
  name: "Field",
  components: {
    AnimatedInput, DatePicker, Checkbox, Button
  },
  //@ts-ignore
  props: {
    field: [ FieldDefinition, Object ],
  }
})
export default class Field extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient
  dayjs = dayjs

  field!: FieldDefinition
  loadingList: boolean = true
  listEntries: { value: string; label: string }[] = []
  computedLongValue: { value: string } | null = null

  get labelTranslated(): string {
    return SdkServiceClient.getLabelForField(this.field?.label)
  }

  get fieldChildren(): FieldDefinition[] {
    if ([ 'DROPDOWN', 'COMBOBOX' ].includes(ContentUtil.typeOfField(this.field) || '') && this.field.stringValue) {
      return this.field.choices?.find((c: any) => c.name === this.field.stringValue)?.fields || []
    } else if ([ 'CHECKBOX', 'RADIO' ].includes(ContentUtil.typeOfField(this.field) || '') && String(this.field.booleanValue || false) === 'true') {
      return this.field.fields || []
    }
    return []
  }

  get typeOfField(): string | null {
    return ContentUtil.typeOfField(this.field)
  }

  choiceChildren(choice: string): FieldDefinition[] {
    if (ContentUtil.typeOfField(this.field) === 'MULTICHECKBOX') {
      if (this.field.listValue && Array.isArray(this.field.listValue) && this.field.listValue.includes(choice)) {
        return  this.field.choices?.find((c: any) => c.name === choice)?.fields || []
      }
    }
    return []
  }

  get stringValue(): string {
    if (this.loadingList) return ""
    if (this.listEntries && this.field.stringValue) {
      const entry: { value: string; label: string }|undefined = this.listEntries.find((entry: { value: string; label: string }) => {
        return entry.value === this.field.stringValue
      })
      return entry ? entry.label : this.field.stringValue
    }
    return this.field.stringValue || ""
  }

  get longValueWithUnit(): { value: string } {
    if (!this.computedLongValue) {
      this.computedLongValue = SdkServiceClient.getStringFromLongValue(this.field)
    }
    return this.computedLongValue
  }

  mounted() {
    void SdkServiceClient.fetchAll(this.i18n.current)
    if (ContentUtil.typeOfField(this.field) === 'COMBOBOX') {
      SdkServiceClient.getListForField(this.field).then((list: { value: string, label: string }[]) => {
        this.listEntries = list
      }).catch(() => {
        this.listEntries = []
      }).finally(() => {
        this.loadingList = false
      })
    } else {
      this.loadingList = false
    }
  }
}
