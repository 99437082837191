
import {Options, Vue} from "vue-class-component"
import Card from "primevue/card"
import Button from "primevue/button"
import Dialog from "@/components/common/Dialog.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Dropdown from "@/components/controls/Dropdown.vue"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import {Container, Draggable} from 'vue3-smooth-dnd'
import PanelEditorField from "@/components/admin/PanelEditorField.vue"
import PropertyFileFolder from "@/model/gis/PropertyFileFolder"
import Field from "@/components/admin/Field.vue"
import {useConfirm} from "primevue/useconfirm"
import AdditionalField from "@/model/gis/AdditionalField"

@Options({
  name: "CustomFieldEditorElement",
  components: {
    PanelEditorField, Card, Button, Dialog, AnimatedInput, Dropdown, Checkbox, Container, Draggable
  },
  //@ts-ignore
  props: {
    field: [ AdditionalField , Object ],
    canMoveUp: Boolean,
    canMoveDown: Boolean,
    disabled: Boolean,
  },
  emits: [
    'copy', 'remove', 'move-up', 'move-down'
  ]
})
export default class PanelEditor extends Vue {

  i18n: Language = useGettext()
  confirm = useConfirm()

  field!: AdditionalField

  editedName: string = ""
  editedPrefill: string = ""

  showEditFolderDialog: boolean = false

  openEditDialog() {
    this.resetDialogues()
    this.editedName = this.field.name || ""
    this.editedPrefill = this.field.value || ""
    this.showEditFolderDialog = true
  }

  acceptChanges(){
    this.field.name = this.editedName;
    this.field.value = this.editedName
    this.resetDialogues()
  }

  resetDialogues() {
    this.showEditFolderDialog = false
    this.editedName = ""
    this.editedPrefill = ""
    //TODO
  }
}
