
import {Options, Vue} from 'vue-class-component'
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import SWR from "@/api/SWR"
import LogEntry from "@/model/LogEntry"
import {auditLogServiceApi} from "@/api/AuditLogServiceApi"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import InfiniteList from "@/components/controls/InfiniteList.vue"
import ProgressBar from "primevue/progressbar"
import dayjs from '@/util/dayjs'
import Project from "@/model/Project"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import Dropdown from "@/components/controls/Dropdown.vue"
import Multiselect from "@/components/controls/Multiselect.vue"
import Button from "primevue/button"
import Sidebar from 'primevue/sidebar'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import {MenuItem} from "primevue/menuitem"
import Breadcrumb from "primevue/breadcrumb"
import breakpointUtil from "@/util/BreakpointUtil"
import FormChange from "@/model/FormChange"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"

@Options({
  name: "AuditLog",
  components: {
    ProgressBar, InfiniteList, Dropdown, Multiselect, Button, Sidebar, Accordion, AccordionTab, Breadcrumb
  },
  //@ts-ignore
  props: {
    projectId: Number
  }
})
export default class AuditLog extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient
  dayjs = dayjs
  breakpointUtil = breakpointUtil

  projectId!: number

  selectedProjectId: number | null = null
  type: string[] | null = null
  sortBy: string[] = [ 'created:desc' ]
  typeDescriptions: any = {
    LOGIN: this.i18n.$gettext('Login'),
    FILE_DOWNLOADED: this.i18n.$gettext('Datei heruntergeladen'),
    FILE_UPLOADED: this.i18n.$gettext('Datei hochgeladen'),
    FILE_DELETED: this.i18n.$gettext('Datei gelöscht'),
    PASSWORD_CHANGED: this.i18n.$gettext('Passwort geändert'),
    PASSWORD_RESET: this.i18n.$gettext('Passwort zurückgesetzt'),
    INVITE_ACCEPTED: this.i18n.$gettext('Einladung angenommen'),
    TWO_FACTOR_AUTHENTICATED: this.i18n.$gettext('2FA-Login erfolgreich'),
    TWO_FACTOR_ENABLED: this.i18n.$gettext('2FA-Login aktiviert'),
    TWO_FACTOR_DISABLED: this.i18n.$gettext('2FA-Login deaktiviert'),
    ROOM_CREATED: this.i18n.$gettext('Datenraum erstellt'),
    ORGANIZATION_INVITED: this.i18n.$gettext('Organisation eingeladen'),
    EMAIL_CONFIRMED: this.i18n.$gettext('E-Mail Adresse bestätigt'),
    PROJECT_CREATED: this.i18n.$gettext('Ausschreibung erstellt'),
    PROJECT_ARCHIVED: this.i18n.$gettext('Ausschreibung archiviert'),
    PROJECT_TEAM_CHANGED: this.i18n.$gettext('Sachbearbeiter geändert'),
    PROJECT_STAGE_CHANGED: this.i18n.$gettext('Stufe der Ausschreibung geändert'),
    PROJECT_CHANGED: this.i18n.$gettext('Ausschreibung geändert'),
    MESSAGE_CREATED: this.i18n.$gettext('Nachricht gesendet'),
    PROJECT_INVITE_SENT: this.i18n.$gettext('Einladung zur Ausschreibung gesendet'),
    USER_DISABLED: this.i18n.$gettext('Benutzer wurde deaktiviert'),
    USER_ENABLED: this.i18n.$gettext('Benutzer wurde aktiviert'),
    LOCATION_CREATED: this.i18n.$gettext('Standort wurde erstellt'),
    LOCATION_MODIFIED: this.i18n.$gettext('Standort wurde bearbeitet'),
    LOCATION_ARCHIVED: this.i18n.$gettext('Standort wurde archiviert'),
    LOCATION_UNARCHIVED: this.i18n.$gettext('Standort wurde aus Archiv zurückgeholt'),
    PROPERTY_CREATED: this.i18n.$gettext('Grundstück wurde erstellt'),
    PROPERTY_MODIFIED: this.i18n.$gettext('Grundstück wurde bearbeitet'),
    PROPERTY_ARCHIVED: this.i18n.$gettext('Grundstück wurde archiviert'),
    PROPERTY_UNARCHIVED: this.i18n.$gettext('Grundstück wurde aus Archiv zurückgeholt'),
    PROPERTY_PUBLISHED: this.i18n.$gettext('Grundstück wurde öffentlich gemacht'),
    PROPERTY_DOCUMENT_ADDED: this.i18n.$gettext('Einem Grundstück wurde ein Dokument hinzugefügt'),
    PROPERTY_LINKED_TO_PROJECT: this.i18n.$gettext('Ein Grundstück wurde zu einer Ausschreibung hinzugefügt'),
  }

  detailEvent: LogEntry | null = null

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    breadcrumbs.push({
      label: this.i18n.$gettext('Audit-Log'),
      to: '/audit-log'
    })
    return breadcrumbs
  }

  get showSidebar(): boolean {
    return !!this.detailEvent
  }

  get entries(): SWR<LogEntry[], number[]> {
    return this.getEntriesSWR(this.projectId || this.selectedProjectId, this.type)
  }

  getEntriesSWR(selectedProjectId: number | null, type: string[] | null): SWR<LogEntry[], number[]> {
    return auditLogServiceApi.getLogEntries(selectedProjectId, type, 3000)
  }

  get projects(): SWR<Project[], number[]> {
    return this.getProjectSWR()
  }

  getProjectSWR(): SWR<Project[], number[]> {
    return projectServiceApi.getAllProjects()
  }

  sort(entries: LogEntry[]) {
    SortAndFilterUtil.sort(entries, this.sortBy)
    return entries
  }

  getDescriptionForEntry(entry : LogEntry): string {
    try {
      switch (entry.type) {
        case "LOGIN": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat sich angemeldet.')
          return this.$gettextInterpolate(translated, {
            fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName || entry.data.user?.email
          })
        }
        case "FILE_DOWNLOADED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat die Datei "%{ file }" heruntergeladen.')
          return this.$gettextInterpolate(translated, {
            fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, file: entry.data.file
          })
        }
        case "FILE_UPLOADED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat die Datei "%{ file }" hochgeladen.')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, file: entry.data.file})
        }
        case "FILE_DELETED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat die Datei "%{ file }" gelöscht.')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, file: entry.data.file})
        }
        case "PASSWORD_CHANGED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat sein Passwort geändert.')
          return this.$gettextInterpolate(translated, {mail: entry.data.email})
        }
        case "PASSWORD_RESET": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat sein Passwort via E-Mail zurückgesetzt.')
          return this.$gettextInterpolate(translated, {mail: entry.data.email})
        }
        case "INVITE_ACCEPTED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat seine Einladung via E-Mail angenommen.')
          return this.$gettextInterpolate(translated, {mail: entry.data.email})
        }
        case "TWO_FACTOR_AUTHENTICATED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat seine Anmeldung via zweitem Faktor bestätigt')
          return this.$gettextInterpolate(translated, {mail: entry.data.email})
        }
        case "TWO_FACTOR_ENABLED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat einen zweitem Faktor für die Anmeldung hinzugefügt')
          return this.$gettextInterpolate(translated, {mail: entry.data.email})
        }
        case "TWO_FACTOR_DISABLED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat einen zweitem Faktor für die Anmeldung entfernt')
          return this.$gettextInterpolate(translated, {mail: entry.data.email})
        }
        case "ROOM_CREATED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat Datenraum "%{ name }" angelegt')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, name: entry.data.room.name})
        }
        case "ORGANIZATION_INVITED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat eine neue Organisation "%{ org }" auf eine Einladung hin erstellt')
          return this.$gettextInterpolate(translated, {mail: entry.data.user?.email, org: entry.data.organization.name})
        }
        case "EMAIL_CONFIRMED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat seine E-Mail Adresse bestätigt')
          return this.$gettextInterpolate(translated, {mail: entry.data.email})
        }
        case "PROJECT_CREATED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat "%{ title }" angelegt')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, title: entry.data.project?.name || ''})
        }
        case "PROJECT_ARCHIVED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat "%{ title }" ins Archiv verschoben')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, title: entry.data.project?.name || ''})
        }
        case "PROJECT_TEAM_CHANGED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat die Bearbeiter von "%{ title }" geändert')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, title: entry.data.project?.name || ''})
        }
        case "PROJECT_STAGE_CHANGED": {
          let desc = this.getProjectStageTranslation(parseInt(entry.data.to))
          return this.$gettextInterpolate(desc, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, title: entry.data.project?.name})
        }
        case "PROJECT_CHANGED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat "%{ title }" geändert')
          return  this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, title: entry.data.project?.name || ''})
        }
        case "MESSAGE_CREATED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat die Nachricht "%{ subject }" verfasst')
          return this.$gettextInterpolate(translated, {
            fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName,
            subject: entry.data.message.messageSubject
          })
        }
        case "PROJECT_INVITE_SENT": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat eine Einladung für "%{ title }" an %{ target } gesendet')
          return this.$gettextInterpolate(translated, {
            fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName,
            title: entry.data.project?.name || '',
            target: entry.data.email
          })
        }
        case "USER_DISABLED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat "%{ user }" deaktiviert')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, user: entry.data.user?.email})
        }
        case "USER_ENABLED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat "%{ user }" aktiviert')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, user: entry.data.user?.email})
        }
        case "LOCATION_CREATED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat den Standort "%{ location }" erstellt')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, location: entry.data.location?.name})
        }
        case "LOCATION_MODIFIED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat den Standort "%{ location }" bearbeitet')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, location: entry.data.location?.name})
        }
        case "LOCATION_ARCHIVED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat den Standort "%{ location }" ins Archiv verschoben')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, location: entry.data.location?.name})
        }
        case "LOCATION_UNARCHIVED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat den Standort "%{ location }" aus dem Archiv zurückgeholt')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, location: entry.data.location?.name})
        }
        case "PROPERTY_CREATED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat das Grundstück "%{ property }" erstellt')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, property: entry.data.property?.name})
        }
        case "PROPERTY_MODIFIED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat das Grundstück "%{ property }" bearbeitet')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, property: entry.data.property?.name})
        }
        case "PROPERTY_ARCHIVED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat das Grundstück "%{ property }" ins Archiv verschoben')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, property: entry.data.property?.name})
        }
        case "PROPERTY_UNARCHIVED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat das Grundstück "%{ property }" aus dem Archiv zurückgeholt')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, property: entry.data.property?.name})
        }
        case "PROPERTY_PUBLISHED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat das Grundstück "%{ property }" öffentlich zugänglich gemacht')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, property: entry.data.property?.name})
        }
        case "PROPERTY_DOCUMENT_ADDED": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat dem Grundstück "%{ property }" ein Dokument hinzugefügt')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, property: entry.data.property?.name})
        }
        case "PROPERTY_LINKED_TO_PROJECT": {
          let translated = this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat das Grundstück "%{ property }" der Ausschreibung "%{ project }" hinzugefügt')
          return this.$gettextInterpolate(translated, {fn: entry.data.user?.givenName || '',
            sn: entry.data.user?.surName || '',
            mail: entry.userName, property: entry.data.property?.name, project: entry.data.project?.name})
        }
        case null:
          return ''
        default:
          return this.typeDescriptions[entry.type] || entry.type
      }
    } catch (e) {
      return entry.type ? this.typeDescriptions[entry.type] || entry.type : ''
    }
  }

  getChangeDescription(change: FormChange) {
    const translated = this.i18n.$gettext('Das Feld "%{ field }" wurde von "%{ oldValue }" zu "%{ newValue }" geändert.')
    const ordinal = change.oldValue?.ordinal
    const fieldName = (ordinal ? ordinal + ' ' : '') + SdkServiceClient.getLabelForField(change.label)
    const oldValue = SdkServiceClient.getStringValue(change.oldValue).value
    const newValue = SdkServiceClient.getStringValue(change.newValue).value
    return this.$gettextInterpolate(translated, { field: fieldName, oldValue: oldValue, newValue: newValue })
  }

  getProjectStageTranslation(stage: number): string {
    switch (stage) {
      case 0: return this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat "%{ title }" zu einem Entwurf gemacht.')
      case 1: return this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat "%{ title }" auf SIMAP veröffentlicht.')
      case 2: return this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat "%{ title }" veröffentlicht.')
      case 3: return this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat den Teilnahmewettbewerb für "%{ title }" gestartet.')
      case 4: return this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat die Teilnahmeanträge für "%{ title }" geöffnet.')
      case 5: return this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat die Verhandlungsphase für "%{ title }" gestartet.')
      case 6: return this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat die Angebotsphase für "%{ title }" gestartet.')
      case 7: return this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat die Angebote für "%{ title }" geöffnet.')
      case 8: return this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat "%{ title }" archiviert.')
      default: return this.i18n.$gettext('%{ fn } %{ sn } (%{ mail }) hat die Projektphase für "%{ title }" geändert.') //Generic Fallback
    }
  }

  getJsonForEntry(entry: LogEntry): string {
    return JSON.stringify(entry, null, 2)
  }

  get projectForDetailsView(): Project | null {
    if (this.detailEvent === null || this.detailEvent.projectId === null) return null

    return this.projects.data?.find((project: Project) => {
      return project.id === this.detailEvent?.projectId
    }) || null
  }

  closeSidebar(){
    this.detailEvent = null
  }

  get entryListForPDF() {
    return this.sort((this.entries.data || [])).map((entry: LogEntry) => {
      return {
        created: entry.created ? dayjs(entry.created).format('LLL') : '',
        userName: entry.userName,
        description: this.getDescriptionForEntry(entry)
            .replace('(' + entry.userName + ')', '')
            .replace(entry.userName + ' ', '')
      }
    })
  }

  downloadPDF(): Promise<void> {
    return auditLogServiceApi._createPDFAndGetDownloadLink(this.entryListForPDF).then((link: string) => {
      window.open(link, '_blank')
    })
  }

  mounted() {
    this.getEntriesSWR(this.projectId || this.selectedProjectId, this.type)
  }
}
