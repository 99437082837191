import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13de3be4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_translate = _resolveComponent("translate")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["p-float-label w-100", _ctx.animationClass])
  }, [
    _createVNode(_component_InputNumber, {
      ref: "myinput",
      class: "w-100",
      modelValue: _ctx.internalValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
      disabled: _ctx.disabled,
      name: _ctx.name || _ctx.label,
      placeholder: _ctx.placeholder,
      required: _ctx.required,
      onFocus: _ctx.handleFocus,
      onBlur: _ctx.handleBlur,
      onAnimationstart: _ctx.checkAutofill,
      onKeydown: _ctx.handleKeypress
    }, null, 8, ["modelValue", "disabled", "name", "placeholder", "required", "onFocus", "onBlur", "onAnimationstart", "onKeydown"]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label + (_ctx.required ? ' *' : '')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}