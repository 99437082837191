
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import FileUpload from "primevue/fileupload"
import RoomDetails from "@/components/common/RoomDetails.vue"
import {rpcClient} from "@/api/WebsocketClient"
import Panel from "@/components/admin/Panel.vue"
import Card from "primevue/card"
import TwoFactorForm from "@/components/TwoFactorForm.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import OrganizationCard from "@/components/common/OrganizationCard.vue"
import UserPicker from "@/components/controls/UserPicker.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import dayjs from "@/util/dayjs"
import Dialog from "@/components/common/Dialog.vue"
import ProgressBar from "primevue/progressbar"
import Divider from "primevue/divider"
import MessageList from "@/components/common/MessageList.vue"
import Message from "primevue/message"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import ProjectWithInfo from "@/util/ProjectWithInfo"

@Options({
  name: "ProjectInfo",
  components: {
    MessageList, TwoFactorForm, RoomDetails, AnimatedInput, Button, FileUpload, Message, Accordion, AccordionTab,
    Panel, Card, Checkbox, OrganizationCard, UserPicker, Dialog, TipTapTextArea, ProgressBar, Divider
  },
  //@ts-ignore
  props: {
    project: [ ProjectWithInfo, Object ]
  }
})
export default class ProjectInfo extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient
  dayjs = dayjs

  project!: ProjectWithInfo

  get isProjectEditor(): boolean {
    const userName = rpcClient.session.user?.email
    return Boolean(userName && rpcClient.session.user?.roles?.find((r: string) => r === 'SYSTEM_ADMIN') ||
        (rpcClient.isInternalUser && this.project?.team?.find(r => r.userName === userName)))
  }
}
