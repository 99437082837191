
import {Editor, EditorContent} from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Typography from "@tiptap/extension-typography"

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import {Placeholder} from "@tiptap/extension-placeholder"
import {Underline} from "@tiptap/extension-underline"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {TaskList} from '@tiptap/extension-task-list'
import {TaskItem} from "@/util/tip-tap-task-item"
import {createMarkdownEditor, MarkdownEditor as MDEditor} from "tiptap-markdown"
import {Watch} from "vue-property-decorator"
import MarkdownUtil from "@/util/MarkdownUtil"

const MarkdownEditor = createMarkdownEditor(Editor)

@Options({
  components: { EditorContent, Button },
  //@ts-ignore
  props: {
    showToolBar: { type: Boolean, default: true },
    editable: { type: Boolean, default: true },
    placeholder: String,
    modelValue: String,
    htmlMode: Boolean
  },
  emits: [
    'update:modelValue'
  ]
})
export default class TipTapTextArea extends Vue {

  editor: MDEditor | null = null

  i18n: Language = useGettext()

  editable!: boolean
  placeholder!: string
  modelValue!: string
  htmlMode!: boolean

  mounted() {
    const extensions: any[] = [
      StarterKit,
      Underline,
      Highlight,
      Typography,
      /*TaskList,
      TaskItem.configure({
        HTMLAttributes: {
          class: 'taskItem',
        },
      }),*/
      Placeholder.configure({
        placeholder: this.placeholder || '',
      }),
    ]

    this.editor = new MarkdownEditor({
      editable: this.editable,
      content: '',
      extensions: extensions,
      editorProps: {
        attributes: {
          class: 'custom-editor',
        }
      }
    })

    this.editor.on('update', ({ editor }: { editor: any }) => {
      // The content has changed.
      this.$emit('update:modelValue', this.htmlMode ? this.getHTML() : this.getMarkdown())
    })

    if (this.modelValue) {
      this.setContent(this.modelValue)
    }
  }

  insertContent(content: string | undefined) {
    if (content && this.editor) {
      this.editor.chain().focus().insertContent(content).run()
    }
  }

  @Watch('modelValue')
  setContent(content: string | undefined) {
    if (content && this.editor && content !== (this.htmlMode ? this.getHTML() : this.getMarkdown())) {
      const html = this.htmlMode ? content : MarkdownUtil.safeRenderMarkdown(content)
      this.editor.chain().focus().setContent(html).run()
    }
  }

  appendContent(content: string) {
    if (content && this.editor && content !== (this.htmlMode ? this.getHTML() : this.getMarkdown())) {
      const html = this.htmlMode ? content : MarkdownUtil.safeRenderMarkdown(content)
      this.editor.chain().focus('end').createParagraphNear().insertContent(html).run()
      this.editor.chain().focus('start').insertContent('<br><br>').run()
      this.$nextTick(() => {
        this.editor?.commands.setTextSelection(0)
      })
    }
  }

  getHTML(): string {
    return this.editor?.getHTML() || ""
  }

  getMarkdown(): string {
    return this.editor?.getMarkdown() || ""
  }

  addEmojiToEditor(emoji: string): void {
    if (this.editor) this.editor.chain().focus().insertContent(emoji).run()
  }

  clearContent(): void {
    if (this.editor) this.editor.commands.clearContent()
  }

  focus(): void {
    this.$nextTick(() => {
      //@ts-ignore
      if (this.editor) this.editor.view.dom.focus()
    })
  }

  beforeUnmount() {
    this.editor?.destroy()
  }
}
