
  import {Options, Vue} from 'vue-class-component'
  import {rpcClient} from "@/api/WebsocketClient"
  import Button from "primevue/button"
  import InputText from "primevue/inputtext"
  import {twoFactorServiceApi} from "@/api/TwoFactorServiceApi"
  import AnimatedInput from "@/components/controls/AnimatedInput.vue"
  import {Language, useGettext} from "@jshmrtn/vue3-gettext"
  import {Router, useRouter} from "vue-router"
  import useToast from "@/util/toasts"
  import Dialog from "@/components/common/Dialog.vue"

  @Options({
    name: "TwoFactorForm",
    components: { AnimatedInput, Button, Dialog }
  })
  export default class TwoFactorForm extends Vue {

    i18n: Language = useGettext()
    router: Router = useRouter()
    toast = useToast()
    rpcClient = rpcClient

    twoFactorCode: string = ""

    get show() {
      return Boolean(this.rpcClient.needsTwoFactorConfirm && rpcClient.nextRoute.after2FA)
    }

    confirm(): Promise<any> {
      return twoFactorServiceApi._authenticate(this.twoFactorCode).then(() => {
        this.rpcClient.session.twoFactorConfirmed = true
        if (rpcClient.nextRoute.after2FA) {
          this.$router.push(rpcClient.nextRoute.after2FA)
        }
      }).catch(() => {
        this.toast.error(this.i18n.$gettext('Falscher Code. Bitte versuchen Sie es erneut.'))
      })
    }

    beforeMount() {
      twoFactorServiceApi._isAuthenticated().then((isAuthenticated: boolean) => {
        this.rpcClient.session.twoFactorConfirmed = isAuthenticated
      })
    }
  }
