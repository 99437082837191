import Content from '@/model/common/Content';

export default class Choice {
  name!: string | null;
  contentId!: string | null;
  fields!: Content[] | null;

  constructor() {
    this.name = null;
    this.contentId = null;
    this.fields = null;
  }
}
