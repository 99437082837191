import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100 h-100 d-flex flex-column" }
const _hoisted_2 = {
  ref: "mapcontainer",
  class: "flex-grow-1"
}
const _hoisted_3 = { class: "lead mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createVNode(_component_Dialog, {
      visible: _ctx.showMarkerEditDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showMarkerEditDialog) = $event)),
      modal: true,
      "close-on-escape": false,
      onHide: _ctx.closeEditMarkerDialog
    }, {
      header: _withCtx(() => [
        _createElementVNode("p", _hoisted_3, [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => [
              _createTextVNode("Marker bearbeiten")
            ]),
            _: 1
          })
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.i18n.$gettext('Abbrechen'),
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _ctx.closeEditMarkerDialog
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.i18n.$gettext('Annehmen'),
          icon: "pi pi-check",
          autofocus: "",
          onClick: _ctx.applyChanges
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => [
            _createTextVNode("Panorama verknüpfen")
          ]),
          _: 1
        }),
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.selectedPanorama,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPanorama) = $event)),
          options: _ctx.panoramaTargetOptions,
          class: "w-100 mt-3",
          "option-label": "label",
          "option-value": "value",
          "show-clear": "",
          label: _ctx.i18n.$gettext('Panorama')
        }, null, 8, ["modelValue", "options", "label"])
      ]),
      _: 1
    }, 8, ["visible", "onHide"])
  ]))
}