import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "row mb-3"
}
const _hoisted_3 = { class: "col-12 col-md-5" }
const _hoisted_4 = {
  key: 0,
  class: "mb-0 font-weight-bold"
}
const _hoisted_5 = { class: "col-12 col-md-7" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = {
  key: 1,
  class: "row mb-3"
}
const _hoisted_8 = { class: "col-12 col-md-5" }
const _hoisted_9 = {
  key: 0,
  class: "mb-0 font-weight-bold"
}
const _hoisted_10 = { class: "col-12 col-md-7" }
const _hoisted_11 = { class: "mb-0" }
const _hoisted_12 = {
  key: 2,
  class: "row mb-3"
}
const _hoisted_13 = { class: "col-12 col-md-5" }
const _hoisted_14 = {
  key: 0,
  class: "mb-0 font-weight-bold"
}
const _hoisted_15 = { class: "col-12 col-md-7" }
const _hoisted_16 = { class: "mb-0" }
const _hoisted_17 = {
  key: 3,
  class: "row mb-3"
}
const _hoisted_18 = { class: "col-12 col-md-5" }
const _hoisted_19 = {
  key: 0,
  class: "mb-0 font-weight-bold"
}
const _hoisted_20 = { class: "col-12 col-md-7" }
const _hoisted_21 = { class: "mb-0" }
const _hoisted_22 = {
  key: 4,
  class: "row mb-3"
}
const _hoisted_23 = { class: "col-12 col-md-5" }
const _hoisted_24 = {
  key: 0,
  class: "mb-0 font-weight-bold"
}
const _hoisted_25 = { class: "col-12 col-md-7" }
const _hoisted_26 = { class: "mb-0" }
const _hoisted_27 = {
  key: 5,
  class: "row mb-3"
}
const _hoisted_28 = { class: "col-12 col-md-5" }
const _hoisted_29 = {
  key: 0,
  class: "mb-0 font-weight-bold"
}
const _hoisted_30 = { class: "col-12 col-md-7" }
const _hoisted_31 = { class: "mb-0" }
const _hoisted_32 = {
  key: 6,
  class: "row mb-3"
}
const _hoisted_33 = { class: "col-12 col-md-5" }
const _hoisted_34 = {
  key: 0,
  class: "mb-0 font-weight-bold"
}
const _hoisted_35 = { class: "col-12 col-md-7" }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Field = _resolveComponent("Field", true)!

  return (_ctx.field)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ((_ctx.typeOfField === 'COMBOBOX' || _ctx.typeOfField === 'TEXTAREA' || _ctx.typeOfField === 'TEXTBOX' || _ctx.typeOfField === 'TEXT') && (_ctx.stringValue || _ctx.field.alwaysShow))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.field.label || _ctx.field.ordinal)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.field.ordinal ? _ctx.field.ordinal + '  ' : '') + _toDisplayString(_ctx.labelTranslated) + ": ", 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.stringValue || ''), 1)
              ])
            ]))
          : (_ctx.typeOfField === 'NUMBER' && (_ctx.field.longValue || _ctx.field.longValue === 0 || _ctx.field.alwaysShow))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.field.label || _ctx.field.ordinal)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.field.ordinal ? _ctx.field.ordinal + ' ' : '') + _toDisplayString(_ctx.labelTranslated) + ": ", 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.longValueWithUnit?.value || ''), 1)
                ])
              ]))
            : (_ctx.typeOfField === 'DATE' && (_ctx.field.dateValue || _ctx.field.alwaysShow))
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    (_ctx.field.label || _ctx.field.ordinal)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.field.ordinal ? _ctx.field.ordinal + ' ' : '') + _toDisplayString(_ctx.labelTranslated) + ": ", 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.dayjs(_ctx.field.dateValue).format('LL') || ''), 1)
                  ])
                ]))
              : (_ctx.typeOfField === 'TIME' && (_ctx.field.dateTimeValue || _ctx.field.alwaysShow))
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      (_ctx.field.label || _ctx.field.ordinal)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.field.ordinal ? _ctx.field.ordinal + ' ' : '') + _toDisplayString(_ctx.labelTranslated) + ": ", 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.dayjs(_ctx.field.dateTimeValue).format('HH:mm') || ''), 1)
                    ])
                  ]))
                : (_ctx.typeOfField === 'DATETIME' && (_ctx.field.dateTimeValue || _ctx.field.alwaysShow))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        (_ctx.field.label || _ctx.field.ordinal)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_24, _toDisplayString(_ctx.field.ordinal ? _ctx.field.ordinal + ' ' : '') + _toDisplayString(_ctx.labelTranslated) + ": ", 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.dayjs(_ctx.field.dateTimeValue).format('LLL') || ''), 1)
                      ])
                    ]))
                  : ((_ctx.typeOfField === 'CHECKBOX' || (_ctx.typeOfField === 'RADIO' && _ctx.field.listValue === null)) && (_ctx.field.booleanValue !== null || _ctx.field.alwaysShow))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                        _createElementVNode("div", _hoisted_28, [
                          (_ctx.field.label || _ctx.field.ordinal)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_29, _toDisplayString(_ctx.field.ordinal ? _ctx.field.ordinal + ' ' : '') + _toDisplayString(_ctx.labelTranslated) + ": ", 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("p", _hoisted_31, _toDisplayString(String(_ctx.field.booleanValue || 'false') === 'true' ? _ctx.i18n.$gettext('Ja') : _ctx.i18n.$gettext('Nein')), 1)
                        ])
                      ]))
                    : ((_ctx.typeOfField === 'RADIO' || _ctx.typeOfField === 'MULTICHECKBOX') && ((_ctx.field.listValue || []).length || _ctx.field.alwaysShow))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                          _createElementVNode("div", _hoisted_33, [
                            (_ctx.field.label || _ctx.field.ordinal)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_34, _toDisplayString(_ctx.field.ordinal ? _ctx.field.ordinal + ' ' : '') + _toDisplayString(_ctx.labelTranslated) + ": ", 1))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_35, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.field.listValue || []), (item) => {
                              return (_openBlock(), _createElementBlock("div", null, [
                                (_openBlock(), _createBlock(_component_Checkbox, {
                                  checked: "",
                                  binary: "",
                                  disabled: "",
                                  label: item,
                                  key: item
                                }, null, 8, ["label"])),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.choiceChildren(item), (subfield, i) => {
                                  return (_openBlock(), _createBlock(_component_Field, {
                                    key: i + subfield.label + subfield.contentId,
                                    field: subfield,
                                    class: "row mb-3"
                                  }, null, 8, ["field"]))
                                }), 128))
                              ]))
                            }), 256)),
                            (_ctx.field.alwaysShow)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.field.choices || []).filter(c => c.name && !(_ctx.field.listValue || []).includes(c.name)), (item) => {
                                    return (_openBlock(), _createBlock(_component_Checkbox, {
                                      binary: "",
                                      disabled: "",
                                      label: item.name,
                                      key: item.name
                                    }, null, 8, ["label"]))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldChildren, (subfield, i) => {
          return (_openBlock(), _createBlock(_component_Field, {
            key: i + subfield.label + subfield.contentId,
            field: subfield
          }, null, 8, ["field"]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_37))
}