<template>
  <Portal :append-to="appendTo">
    <div
      v-if="containerVisible"
      :ref="maskRef"
      :class="maskClass"
      @click="onMaskClick"
    >
      <transition
        name="p-dialog"
        appear
        @before-enter="onBeforeEnter"
        @enter="onEnter"
        @before-leave="onBeforeLeave"
        @leave="onLeave"
        @after-leave="onAfterLeave"
      >
        <form
          v-if="visible"
          :ref="containerRef"
          v-focustrap="{ focusTrapDisabled: !modal }"
          :class="dialogClass"
          role="dialog"
          :aria-labelledby="ariaLabelledById"
          :aria-modal="modal"
          v-bind="$attrs"
          @submit.prevent.stop="$emit('submit', $event)"
        >
          <div
            v-if="showHeader"
            :ref="headerContainerRef"
            class="p-dialog-header"
            @mousedown="initDrag"
          >
            <slot name="header">
              <span v-if="header" :id="ariaLabelledById" class="p-dialog-title">{{ header }}</span>
            </slot>
            <div class="p-dialog-header-icons">
              <button
                v-if="maximizable"
                :ref="maximizableRef"
                v-ripple
                autofocus
                class="p-dialog-header-icon p-dialog-header-maximize p-link"
                type="button"
                :tabindex="maximizable ? '0' : '-1'"
                @click="maximize"
              >
                <span :class="maximizeIconClass" />
              </button>
              <button
                v-if="closable"
                :ref="closeButtonRef"
                v-ripple
                autofocus
                class="p-dialog-header-icon p-dialog-header-close p-link"
                :aria-label="closeAriaLabel"
                type="button"
                v-bind="closeButtonProps"
                @click="close"
              >
                <span :class="['p-dialog-header-close-icon', closeIcon]" />
              </button>
            </div>
          </div>
          <div
            :ref="contentRef"
            :class="contentStyleClass"
            :style="contentStyle"
            v-bind="contentProps"
          >
            <slot />
          </div>
          <div v-if="footer || $slots.footer" :ref="footerContainerRef" class="p-dialog-footer">
            <slot name="footer">
              {{ footer }}
            </slot>
          </div>
        </form>
      </transition>
    </div>
  </Portal>
</template>

<script>
import FocusTrap from 'primevue/focustrap'
import Portal from 'primevue/portal'
import Ripple from 'primevue/ripple'
import { DomHandler, UniqueComponentId, ZIndexUtils } from 'primevue/utils'
import { computed } from 'vue'

export default {
  name: 'Dialog',
  directives: {
    ripple: Ripple,
    focustrap: FocusTrap
  },
  components: {
    Portal: Portal
  },
  provide() {
    return {
      dialogRef: computed(() => this._instance)
    }
  },
  inheritAttrs: false,
  props: {
    header: {
      type: null,
      default: null
    },
    footer: {
      type: null,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: null
    },
    contentStyle: {
      type: null,
      default: null
    },
    contentClass: {
      type: String,
      default: null
    },
    contentProps: {
      type: null,
      default: null
    },
    rtl: {
      type: Boolean,
      default: null
    },
    maximizable: {
      type: Boolean,
      default: false
    },
    dismissableMask: {
      type: Boolean,
      default: false
    },
    closable: {
      type: Boolean,
      default: true
    },
    closeOnEscape: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    baseZIndex: {
      type: Number,
      default: 0
    },
    autoZIndex: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: 'center'
    },
    breakpoints: {
      type: Object,
      default: null
    },
    draggable: {
      type: Boolean,
      default: true
    },
    keepInViewport: {
      type: Boolean,
      default: true
    },
    minX: {
      type: Number,
      default: 0
    },
    minY: {
      type: Number,
      default: 0
    },
    appendTo: {
      type: String,
      default: 'body'
    },
    closeIcon: {
      type: String,
      default: 'pi pi-times'
    },
    maximizeIcon: {
      type: String,
      default: 'pi pi-window-maximize'
    },
    minimizeIcon: {
      type: String,
      default: 'pi pi-window-minimize'
    },
    closeButtonProps: {
      type: null,
      default: null
    },
    _instance: null
  },
  emits: ['update:visible', 'show', 'hide', 'after-hide', 'maximize', 'unmaximize', 'dragend', 'submit'],
  data() {
    return {
      containerVisible: this.visible,
      maximized: false
    }
  },
  documentKeydownListener: null,
  container: null,
  mask: null,
  content: null,
  headerContainer: null,
  footerContainer: null,
  maximizableButton: null,
  closeButton: null,
  styleElement: null,
  dragging: null,
  documentDragListener: null,
  documentDragEndListener: null,
  lastPageX: null,
  lastPageY: null,
  computed: {
    maskClass() {
      return ['p-dialog-mask', { 'p-component-overlay p-component-overlay-enter': this.modal }, this.getPositionClass()]
    },
    dialogClass() {
      return [
        'p-dialog p-component',
        {
          'p-dialog-rtl': this.rtl,
          'p-dialog-maximized': this.maximizable && this.maximized,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': this.$primevue.config.ripple === false
        }
      ]
    },
    maximizeIconClass() {
      return [
        'p-dialog-header-maximize-icon',
        {
          [this.maximizeIcon]: !this.maximized,
          [this.minimizeIcon]: this.maximized
        }
      ]
    },
    ariaId() {
      return UniqueComponentId()
    },
    ariaLabelledById() {
      return this.header != null || this.$attrs['aria-labelledby'] !== null ? this.ariaId + '_header' : null
    },
    closeAriaLabel() {
      return this.$primevue.config.locale.aria ? this.$primevue.config.locale.aria.close : undefined
    },
    attributeSelector() {
      return UniqueComponentId()
    },
    contentStyleClass() {
      return ['p-dialog-content', this.contentClass]
    }
  },
  updated() {
    if (this.visible) {
      this.containerVisible = this.visible
    }
  },
  beforeUnmount() {
    this.unbindDocumentState()
    this.unbindGlobalListeners()
    this.destroyStyle()

    if (this.mask && this.autoZIndex) {
      ZIndexUtils.clear(this.mask)
    }

    this.container = null
    this.mask = null
  },
  mounted() {
    if (this.breakpoints) {
      this.createStyle()
    }
  },
  methods: {
    close() {
      this.$emit('update:visible', false)
    },
    onBeforeEnter(el) {
      el.setAttribute(this.attributeSelector, '')
    },
    onEnter() {
      this.$emit('show')
      this.focus()
      this.enableDocumentSettings()
      this.bindGlobalListeners()

      if (this.autoZIndex) {
        ZIndexUtils.set('modal', this.mask, this.baseZIndex + this.$primevue.config.zIndex.modal)
      }
    },
    onBeforeLeave() {
      if (this.modal) {
        DomHandler.addClass(this.mask, 'p-component-overlay-leave')
      }
    },
    onLeave() {
      this.$emit('hide')
    },
    onAfterLeave() {
      if (this.autoZIndex) {
        ZIndexUtils.clear(this.mask)
      }

      this.containerVisible = false
      this.unbindDocumentState()
      this.unbindGlobalListeners()
      this.$emit('after-hide')
    },
    onMaskClick(event) {
      if (this.dismissableMask && this.closable && this.modal && this.mask === event.target) {
        this.close()
      }
    },
    focus() {
      const findFocusableElement = (container) => {
        return container.querySelector('[autofocus]')
      }

      let focusTarget = this.$slots.default && findFocusableElement(this.content)

      if (!focusTarget) {
        focusTarget = this.$slots.header && findFocusableElement(this.headerContainer)

        if (!focusTarget) {
          focusTarget = this.$slots.footer && findFocusableElement(this.footerContainer)

          if (!focusTarget) {
            focusTarget = findFocusableElement(this.container)
          }
        }
      }

      focusTarget && focusTarget.focus()
    },
    maximize(event) {
      if (this.maximized) {
        this.maximized = false
        this.$emit('unmaximize', event)
      } else {
        this.maximized = true
        this.$emit('maximize', event)
      }

      if (!this.modal) {
        if (this.maximized) DomHandler.addClass(document.body, 'p-overflow-hidden')
        else DomHandler.removeClass(document.body, 'p-overflow-hidden')
      }
    },
    enableDocumentSettings() {
      if (this.modal || (this.maximizable && this.maximized)) {
        DomHandler.addClass(document.body, 'p-overflow-hidden')
      }
    },
    unbindDocumentState() {
      if (this.modal || (this.maximizable && this.maximized)) {
        DomHandler.removeClass(document.body, 'p-overflow-hidden')
      }
    },
    onKeyDown(event) {
      if (event.code === 'Escape' && this.closeOnEscape) {
        this.close()
      }
    },
    bindDocumentKeyDownListener() {
      if (!this.documentKeydownListener) {
        this.documentKeydownListener = this.onKeyDown.bind(this)
        window.document.addEventListener('keydown', this.documentKeydownListener)
      }
    },
    unbindDocumentKeyDownListener() {
      if (this.documentKeydownListener) {
        window.document.removeEventListener('keydown', this.documentKeydownListener)
        this.documentKeydownListener = null
      }
    },
    getPositionClass() {
      const positions = ['left', 'right', 'top', 'topleft', 'topright', 'bottom', 'bottomleft', 'bottomright']
      const pos = positions.find((item) => item === this.position)

      return pos ? `p-dialog-${pos}` : ''
    },
    containerRef(el) {
      this.container = el
    },
    maskRef(el) {
      this.mask = el
    },
    contentRef(el) {
      this.content = el
    },
    headerContainerRef(el) {
      this.headerContainer = el
    },
    footerContainerRef(el) {
      this.footerContainer = el
    },
    maximizableRef(el) {
      this.maximizableButton = el
    },
    closeButtonRef(el) {
      this.closeButton = el
    },
    createStyle() {
      if (!this.styleElement) {
        this.styleElement = document.createElement('style')
        this.styleElement.type = 'text/css'
        document.head.appendChild(this.styleElement)

        let innerHTML = ''

        for (let breakpoint in this.breakpoints) {
          innerHTML += `
                        @media screen and (max-width: ${breakpoint}) {
                            .p-dialog[${this.attributeSelector}] {
                                width: ${this.breakpoints[breakpoint]} !important;
                            }
                        }
                    `
        }

        this.styleElement.innerHTML = innerHTML
      }
    },
    destroyStyle() {
      if (this.styleElement) {
        document.head.removeChild(this.styleElement)
        this.styleElement = null
      }
    },
    initDrag(event) {
      if (DomHandler.hasClass(event.target, 'p-dialog-header-icon') || DomHandler.hasClass(event.target.parentElement, 'p-dialog-header-icon')) {
        return
      }

      if (this.draggable) {
        this.dragging = true
        this.lastPageX = event.pageX
        this.lastPageY = event.pageY

        this.container.style.margin = '0'
        DomHandler.addClass(document.body, 'p-unselectable-text')
      }
    },
    bindGlobalListeners() {
      if (this.draggable) {
        this.bindDocumentDragListener()
        this.bindDocumentDragEndListener()
      }

      if (this.closeOnEscape && this.closable) {
        this.bindDocumentKeyDownListener()
      }
    },
    unbindGlobalListeners() {
      this.unbindDocumentDragListener()
      this.unbindDocumentDragEndListener()
      this.unbindDocumentKeyDownListener()
    },
    bindDocumentDragListener() {
      this.documentDragListener = (event) => {
        if (this.dragging) {
          let width = DomHandler.getOuterWidth(this.container)
          let height = DomHandler.getOuterHeight(this.container)
          let deltaX = event.pageX - this.lastPageX
          let deltaY = event.pageY - this.lastPageY
          let offset = this.container.getBoundingClientRect()
          let leftPos = offset.left + deltaX
          let topPos = offset.top + deltaY
          let viewport = DomHandler.getViewport()

          this.container.style.position = 'fixed'

          if (this.keepInViewport) {
            if (leftPos >= this.minX && leftPos + width < viewport.width) {
              this.lastPageX = event.pageX
              this.container.style.left = leftPos + 'px'
            }

            if (topPos >= this.minY && topPos + height < viewport.height) {
              this.lastPageY = event.pageY
              this.container.style.top = topPos + 'px'
            }
          } else {
            this.lastPageX = event.pageX
            this.container.style.left = leftPos + 'px'
            this.lastPageY = event.pageY
            this.container.style.top = topPos + 'px'
          }
        }
      }

      window.document.addEventListener('mousemove', this.documentDragListener)
    },
    unbindDocumentDragListener() {
      if (this.documentDragListener) {
        window.document.removeEventListener('mousemove', this.documentDragListener)
        this.documentDragListener = null
      }
    },
    bindDocumentDragEndListener() {
      this.documentDragEndListener = (event) => {
        if (this.dragging) {
          this.dragging = false
          DomHandler.removeClass(document.body, 'p-unselectable-text')

          this.$emit('dragend', event)
        }
      }

      window.document.addEventListener('mouseup', this.documentDragEndListener)
    },
    unbindDocumentDragEndListener() {
      if (this.documentDragEndListener) {
        window.document.removeEventListener('mouseup', this.documentDragEndListener)
        this.documentDragEndListener = null
      }
    }
  }
}
</script>
<style>
.p-dialog-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.p-dialog-mask.p-component-overlay {
    pointer-events: auto;
}

.p-dialog {
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    max-height: 90%;
    transform: scale(1);
}

.p-dialog-content {
    overflow-y: auto;
}

.p-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
}

.p-dialog-footer {
    flex-shrink: 0;
}

.p-dialog .p-dialog-header-icons {
    display: flex;
    align-items: center;
}

.p-dialog .p-dialog-header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

/* Fluid */
.p-fluid .p-dialog-footer .p-button {
    width: auto;
}

/* Animation */
/* Center */
.p-dialog-enter-active {
    transition: all 150ms cubic-bezier(0, 0, 0.2, 1);
}
.p-dialog-leave-active {
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.p-dialog-enter-from,
.p-dialog-leave-to {
    opacity: 0;
    transform: scale(0.7);
}

/* Top, Bottom, Left, Right, Top* and Bottom* */
.p-dialog-top .p-dialog,
.p-dialog-bottom .p-dialog,
.p-dialog-left .p-dialog,
.p-dialog-right .p-dialog,
.p-dialog-topleft .p-dialog,
.p-dialog-topright .p-dialog,
.p-dialog-bottomleft .p-dialog,
.p-dialog-bottomright .p-dialog {
    margin: 0.75rem;
    transform: translate3d(0px, 0px, 0px);
}
.p-dialog-top .p-dialog-enter-active,
.p-dialog-top .p-dialog-leave-active,
.p-dialog-bottom .p-dialog-enter-active,
.p-dialog-bottom .p-dialog-leave-active,
.p-dialog-left .p-dialog-enter-active,
.p-dialog-left .p-dialog-leave-active,
.p-dialog-right .p-dialog-enter-active,
.p-dialog-right .p-dialog-leave-active,
.p-dialog-topleft .p-dialog-enter-active,
.p-dialog-topleft .p-dialog-leave-active,
.p-dialog-topright .p-dialog-enter-active,
.p-dialog-topright .p-dialog-leave-active,
.p-dialog-bottomleft .p-dialog-enter-active,
.p-dialog-bottomleft .p-dialog-leave-active,
.p-dialog-bottomright .p-dialog-enter-active,
.p-dialog-bottomright .p-dialog-leave-active {
    transition: all 0.3s ease-out;
}
.p-dialog-top .p-dialog-enter-from,
.p-dialog-top .p-dialog-leave-to {
    transform: translate3d(0px, -100%, 0px);
}
.p-dialog-bottom .p-dialog-enter-from,
.p-dialog-bottom .p-dialog-leave-to {
    transform: translate3d(0px, 100%, 0px);
}
.p-dialog-left .p-dialog-enter-from,
.p-dialog-left .p-dialog-leave-to,
.p-dialog-topleft .p-dialog-enter-from,
.p-dialog-topleft .p-dialog-leave-to,
.p-dialog-bottomleft .p-dialog-enter-from,
.p-dialog-bottomleft .p-dialog-leave-to {
    transform: translate3d(-100%, 0px, 0px);
}
.p-dialog-right .p-dialog-enter-from,
.p-dialog-right .p-dialog-leave-to,
.p-dialog-topright .p-dialog-enter-from,
.p-dialog-topright .p-dialog-leave-to,
.p-dialog-bottomright .p-dialog-enter-from,
.p-dialog-bottomright .p-dialog-leave-to {
    transform: translate3d(100%, 0px, 0px);
}

/* Maximize */
.p-dialog-maximized {
    -webkit-transition: none;
    transition: none;
    transform: none;
    width: 100vw !important;
    height: 100vh !important;
    top: 0px !important;
    left: 0px !important;
    max-height: 100%;
    height: 100%;
}
.p-dialog-maximized .p-dialog-content {
    flex-grow: 1;
}

/* Position */
.p-dialog-left {
    justify-content: flex-start;
}
.p-dialog-right {
    justify-content: flex-end;
}
.p-dialog-top {
    align-items: flex-start;
}
.p-dialog-topleft {
    justify-content: flex-start;
    align-items: flex-start;
}
.p-dialog-topright {
    justify-content: flex-end;
    align-items: flex-start;
}
.p-dialog-bottom {
    align-items: flex-end;
}
.p-dialog-bottomleft {
    justify-content: flex-start;
    align-items: flex-end;
}
.p-dialog-bottomright {
    justify-content: flex-end;
    align-items: flex-end;
}

.p-confirm-dialog .p-dialog-content {
    display: flex;
    align-items: center;
}
</style>
