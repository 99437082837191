import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Room from '@/model/Room';
import Page from '@/model/Page';
import { roomStore } from '@/store/RoomStore';

export default class GeneratedRoomServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _createPublicLink(projectId: number, roomId: number): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createPublicLink', rpcParams, false) as Promise<string>
    }

    _getRoomsByProjectId(projectId: number): Promise<Room[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getRoomsByProjectId', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const rooms: Room[] = data.map(room => Object.assign(new Room(), room))
                roomStore.addOrReplaceRooms(rooms)
                return rooms
            } else return Promise.reject()
        })
    }


    _getRoom(token: string): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getRoom', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Room(), data)
            roomStore.addOrReplaceRoom(model)
            return model.id
        })
    }

    _createRoom(room: Room): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createRoom', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Room(), data)
            roomStore.addOrReplaceRoom(model)
            return model.id
        })
    }

    getRoomsByProjectId(projectId: number, refresh?: boolean | number): SWR<Room[], number[]> {
        //@ts-ignore
        const result: SWR<Room[], number[]> = reactive(new SWR<Room[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_getRoomsByProjectId' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getRoomsByProjectId[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const rooms: Room[] = []
                for (const id of data) {
                    const room: Room | undefined = roomStore.state.rooms.get(id)
                    if (room) {
                        rooms.push(room)
                    }
                }
                result.data = rooms
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getRoomsByProjectId(projectId).then((data: Room[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(room => room.id || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let rooms: Room[] = [...roomStore.state.rooms.values()]
        if (projectId !== undefined && projectId !== null) rooms = SortAndFilterUtil.filter(rooms, { projectId: projectId })
        
        
        result.data = rooms
        return result
    }

    getRoom(token: string, refresh?: boolean | number): SWR<Room | null, number> {
        //@ts-ignore
        const result: SWR<Room | null, number> = reactive(new SWR<Room | null, number>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_getRoom' + JSON.stringify(args)
        const cached: Call<number> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getRoom[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: number) => {
                result.data = roomStore.state.rooms.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number>()) as Call<number>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._getRoom(token).then((id: number) => {
                call.data = id
                result.data = roomStore.state.rooms.get(id) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = roomStore.state.rooms.get(cached.data) || null
        }
        return result
    }


    createRoom(room: Room, refresh?: boolean | number): SWR<Room | null, number> {
        //@ts-ignore
        const result: SWR<Room | null, number> = reactive(new SWR<Room | null, number>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_createRoom' + JSON.stringify(args)
        const cached: Call<number> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_createRoom[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: number) => {
                result.data = roomStore.state.rooms.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number>()) as Call<number>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._createRoom(room).then((id: number) => {
                call.data = id
                result.data = roomStore.state.rooms.get(id) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = roomStore.state.rooms.get(cached.data) || null
        }
        return result
    }


    getRooms(pageIndex?: number, pageSize?: number): Room[] {
        let rooms: Room[] = [...roomStore.state.rooms.values()]
        
        
        if (pageSize !== null && pageSize !== undefined) {
            rooms = rooms.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return rooms
    }
}
