
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import Divider from 'primevue/divider'

@Options({
  name: "GreetingCircle",
  components: {
    Button, Divider
  }
})
export default class GreetingCircle extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient

}
