export default class PropertyFile {
  name!: string | null;
  serverId!: string | null;
  fileSizeInBytes!: number | null;
  created!: string | null;
  isPublic!: boolean | null;
  deletionRequestUsername!: string | null;
  deletionRequested!: string | null;

  constructor() {
    this.name = null;
    this.serverId = null;
    this.fileSizeInBytes = null;
    this.created = null;
    this.isPublic = null;
    this.deletionRequestUsername = null;
    this.deletionRequested = null;
  }
}
