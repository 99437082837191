import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import ProjectTemplate from '@/model/ProjectTemplate';


export default class GeneratedProjectTemplateStore {

    state = reactive({
        projectTemplates: new Map<string, ProjectTemplate>(),
        calls: new Map<string, string | string[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.projectTemplates) this.state.projectTemplates.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceProjectTemplate(newProjectTemplate: ProjectTemplate) {
        if (newProjectTemplate.id) {
            this.state.projectTemplates.set(newProjectTemplate.id, newProjectTemplate)
            
        }
    }

    addOrReplaceProjectTemplates(newProjectTemplates: ProjectTemplate[]) {
        for (const newProjectTemplate of newProjectTemplates) {
            this.addOrReplaceProjectTemplate(newProjectTemplate)
        }
    }

    replaceProjectTemplates(projectTemplates: ProjectTemplate[]) {
        const newProjectTemplates = new Map<string, ProjectTemplate>()
        for (const newProjectTemplate of projectTemplates) {
            if (newProjectTemplate.id) {
                newProjectTemplates.set(newProjectTemplate.id, newProjectTemplate)
                
            }
        }
        this.state.projectTemplates = newProjectTemplates
    }

    removeProjectTemplate(id: string | undefined | null) {
        if (id !== undefined && id !== null) {
            this.state.projectTemplates.delete(id)
        }
    }

    removeProjectTemplates(ids: (string | null)[] | undefined | null) {
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                this.removeProjectTemplate(id)
            }
        }
    }
}