
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import Sidebar from "@/components/controls/Sidebar.vue"
import Divider from 'primevue/divider'
import {useConfirm} from "primevue/useconfirm"
import Menubar from "primevue/menubar"
import LoginForm from "@/components/LoginForm.vue"
import Dialog from "primevue/dialog"
import globalState from "@/util/GlobalState"
import breakpointUtil from "@/util/BreakpointUtil"
import Dropdown from "primevue/dropdown"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"

@Options({
  name: "TopMenu",
  components: {
    LoginForm, Button, Sidebar, Accordion, AccordionTab, Divider, Menubar, Dialog, Dropdown
  },
  emits: [
    'toggle-sidebar'
  ]
})
export default class TopMenu extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  confirm = useConfirm()
  rpcClient = rpcClient
  globalState = globalState
  breakpointUtil = breakpointUtil

  countries = [
    { name: 'Deutsch', code: 'de_DE', icon: '/img/de.svg' },
    { name: 'English', code: 'en_US', icon: '/img/gb.svg' }
  ]
  locale = this.findInitialLanguage()

  updateTranslations() {
    this.i18n.current = this.locale?.code || 'de_DE'
    void SdkServiceClient.fetchAll(this.i18n.current)
  }

  findInitialLanguage(): {name: string, code: string, icon: string} {
    let initialLang = this.countries.find((country) => country.code == this.i18n.current)
    if (!initialLang) {
      initialLang = { name: 'Deutsch', code: 'de_DE', icon: '/img/de.svg' }
      //language is not english and not german =>defaulting to german
      this.i18n.current = initialLang.code
      void SdkServiceClient.fetchAll(this.i18n.current)
    }
    return initialLang
  }

  /*initialTopBarY: number = 0
  initialTopBarHeight: number = 0
  initialTopBarWidth: number = 0
  initialWindowWidth: number = 0*/

  get menuItems() {
    const menuItems: any[] = []
    if (!this.rpcClient.fullyLoggedIn) {
      if (this.$route.path !== '/projekte') {
        /*menuItems.push({
          label: this.i18n.$gettext('Ausschreibungen'),
          to: '/projekte'
          //icon: 'pi pi-fw pi-file'
        })*/
      }
    } else if (this.rpcClient.isInternalUser) {
      /*menuItems.push({
        label: this.i18n.$gettext('Ausschreibungen'),
        //icon: 'pi pi-fw pi-file',
        items: [
          {
            label: this.i18n.$gettext('Aktive Ausschreibungen'),
            to: '/admin/projekte'
            //icon: 'pi pi-fw pi-file'
          },
          {
            label: this.i18n.$gettext('Archiv'),
            to: '/admin/archiv'
            //icon: 'pi pi-fw pi-file'
          }
        ]
      }, {
        label: this.i18n.$gettext('Organisationen'),
        //icon: 'pi pi-fw pi-file',
        items: [
          {
            label: this.i18n.$gettext('Auftraggeber'),
            to: '/admin/auftraggeber'
            //icon: 'pi pi-fw pi-file'
          },
          {
            label: this.i18n.$gettext('Bieter'),
            //icon: 'pi pi-fw pi-file'
            to: '/admin/bieter'
          }
        ]
      }, {
        label: this.i18n.$gettext('Benutzer'),
        //icon: 'pi pi-fw pi-file',
        items: [
          {
            label: this.i18n.$gettext('Interne Benutzer'),
            to: '/admin/benutzer/intern'
            //icon: 'pi pi-fw pi-file'
          },
          {
            label: this.i18n.$gettext('Externe Benutzer'),
            //icon: 'pi pi-fw pi-file'
            to: '/admin/benutzer/extern'
          }
        ]
      })
      if (this.rpcClient.isSystemAdmin) {
        menuItems[0].items.push({
          label: this.i18n.$gettext('Formulare'),
          //icon: 'pi pi-fw pi-file'
          to: '/admin/formulare'
        })
        menuItems.push({
          label: this.i18n.$gettext('System'),
          //icon: 'pi pi-fw pi-file',
          items: [
            {
              label: this.i18n.$gettext('Audit-Log'),
              //icon: 'pi pi-fw pi-file'
              to: '/admin/audit-log'
            },
            {
              label: this.i18n.$gettext('Einstellungen'),
              //icon: 'pi pi-fw pi-file'
              to: '/admin/einstellungen'
            }
          ]
        })
      }*/
    } else {
      /*menuItems.push({
        label: this.i18n.$gettext('Ausschreibungen'),
        //icon: 'pi pi-fw pi-file',
        items: [
          {
            label: this.i18n.$gettext('Öffentliche Ausschreibungen'),
            to: '/bieter/projekte'
            //icon: 'pi pi-fw pi-file'
          },
          {
            label: this.i18n.$gettext('Meine Bewerbungen'),
            to: '/bieter/bewerbungen'
            //icon: 'pi pi-fw pi-file'
          },
          {
            label: this.i18n.$gettext('Meine Merkliste'),
            to: '/bieter/merkliste'
            //icon: 'pi pi-fw pi-file'
          }
        ]
      })*/
      /*if (this.rpcClient.isOrganizationAdmin) {
        menuItems.push({
          label: this.i18n.$gettext('Unternehmenskonto'),
          //icon: 'pi pi-fw pi-file'
          items: [
            {
              label: this.i18n.$gettext('Unternehmensdaten'),
              to: '/organisation'
              //icon: 'pi pi-fw pi-file'
            },
            {
              label: this.i18n.$gettext('Benutzer'),
              to: '/benutzer'
              //icon: 'pi pi-fw pi-file'
            }
          ]
        })
      } else if (rpcClient.session.user) {
        menuItems.push({
          label: this.i18n.$gettext('Benutzerkonto'),
          to: '/benutzer/' + rpcClient.session.user.email
          //icon: 'pi pi-fw pi-file'
        })
      }*/
    }
    return menuItems
  }

  confirmLogout() {
    this.confirm.require({
      message: this.i18n.$gettext('Möchten Sie sich wirklich ausloggen?'),
      header: this.i18n.$gettext('Ausloggen'),
      icon: 'cil-warning',
      accept: () => {
        this.rpcClient.logout()
        this.$router.push('/')
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  goToLogin() {
    rpcClient.nextRoute.afterLogin = this.$route
  }

  created() {
    /*this.$nextTick(() => {
      const topbar = document.getElementById('topbar')
      const rect2 = topbar?.getBoundingClientRect()
      if (topbar && rect2 && !this.initialTopBarY) {
        this.initialTopBarY = rect2.y
        this.initialTopBarHeight = rect2.height
        this.initialTopBarWidth = rect2.width
        this.initialWindowWidth = window.innerWidth
      }
    })
    window.addEventListener('resize', () => {
      /*const topbar = document.getElementById('topbar')
      if (topbar?.parentElement) {
        //topbar.parentElement.style.maxWidth = ''
        //topbar.style.paddingTop = ''
      }
    })
    /*window.addEventListener('scroll', () => {
      const divider = document.getElementById('navbar-wrapper')
      const rect = divider?.getBoundingClientRect()
      if (divider && rect && rect.y <= 0) {
        divider.classList.add('z-1')
      } else if (divider) {
        divider.classList.remove('z-1')
      }
      const topbar = document.getElementById('topbar')
      const rect2 = topbar?.getBoundingClientRect()
      if (topbar && rect2 && !this.initialTopBarY) {
        this.initialTopBarY = rect2.y
        this.initialTopBarHeight = rect2.height
        this.initialTopBarWidth = rect2.width
        this.initialWindowWidth = window.innerWidth
      } else if (topbar?.parentElement && rect2) {
        topbar.parentElement.style.maxWidth = (this.initialWindowWidth - (this.initialWindowWidth - this.initialTopBarWidth) * rect2.y / this.initialTopBarY) + 'px'
        if (rect2.y < 0) {
          topbar.style.paddingTop = 'calc(2rem + ' + Math.max(-rect2.y, 0) + 'px - ' + (Math.max(-rect2.y, 0) / 1.333 / this.initialTopBarY) + 'rem)'
        } else {
          topbar.style.paddingTop = '2rem'
        }
        //topbar.style.paddingBottom = 1 + rect2.y / this.initialTopBarY + 'rem'
        //topbar.style.height = 'calc(' + this.initialTopBarHeight + 'px - ' + (2 - 2 * (rect2.y / this.initialTopBarY)) + 'rem)'
      }
    })*/
  }

}
