import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb61eaee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex property-filetree-element filetree-file" }
const _hoisted_2 = { class: "flex-grow-1 d-flex align-items-center" }
const _hoisted_3 = ["href", "download"]
const _hoisted_4 = {
  key: 0,
  class: "flex-shrink-0 d-flex align-items-center"
}
const _hoisted_5 = {
  key: 1,
  class: "flex-shrink-0 d-flex align-items-center"
}
const _hoisted_6 = {
  key: 2,
  class: "flex-shrink-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: _ctx.downloadUrl,
        download: _ctx.file.originalFileName || _ctx.file.name
      }, _toDisplayString(_ctx.file.name) + ", " + _toDisplayString(_ctx.fileSizeFormatted(_ctx.file)), 9, _hoisted_3)
    ]),
    (!_ctx.interactive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_InputSwitch, {
            modelValue: _ctx.file.isPublic,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.file.isPublic) = $event)),
            class: "mr-2"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.isInternalUser)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.file.isPublic)
            ? (_openBlock(), _createBlock(_component_Tag, {
                key: 0,
                value: _ctx.i18n.$gettext('Öffentlich')
              }, null, 8, ["value"]))
            : (_openBlock(), _createBlock(_component_Tag, {
                key: 1,
                severity: "success",
                value: _ctx.i18n.$gettext('Intern')
              }, null, 8, ["value"]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.isInternalUser)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_Button, {
            icon: "pi pi-briefcase",
            size: "small",
            class: "flat",
            disabled: _ctx.interactive,
            label: _ctx.i18n.$gettext('Archivieren'),
            text: "",
            onClick: _ctx.handleArchiveButton
          }, null, 8, ["disabled", "label", "onClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}