
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import SWR from "@/api/SWR"
import ProgressBar from "primevue/progressbar"
import UserRegistrationForm from "@/components/common/UserRegistrationForm.vue"

@Options({
  name: "UserDetails",
  components: {
    UserRegistrationForm, Button, ProgressBar
  },
  //@ts-ignore
  props: {
    user: [ User, Object ]
  }
})
export default class UserDetails extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient

  user!: User

  get userSWR(): SWR<User | null, string> | null {
    const email: string | null = this.$route.params.email || null
    if (email) {
      return userServiceApi.getUser(email)
    } else {
      return null
    }
  }

  get u(): User | null {
    return this.user || this.userSWR?.data
  }

  get displayName(): string {
    let displayName = ''
    if (this.u) {
      if (this.u.givenName) {
        displayName += this.u.givenName
      }
      if (this.u.surName) {
        displayName += displayName ? (' ' + this.u.surName) : this.u.surName
      }
    }
    return displayName
  }
}
