import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "h-100 w-100 d-flex flex-column overflow-y-auto",
  style: {"min-height":"0"}
}
const _hoisted_2 = { class: "p-4" }
const _hoisted_3 = { class: "m-0" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  class: "w-100 flex-grow-1 flex-shrink-0 d-flex flex-row align-items-stretch",
  style: {"min-width":"72rem"}
}
const _hoisted_6 = {
  class: "d-flex flex-column border-right border-top",
  style: {"width":"25%"}
}
const _hoisted_7 = {
  class: "flex-grow-1 d-flex flex-column border-right border-top",
  style: {"width":"75%"}
}
const _hoisted_8 = { class: "flex-shrink-0 d-flex flex-row" }
const _hoisted_9 = {
  key: 0,
  class: "h-100 d-flex flex-column border-right",
  style: {"flex":"1"}
}
const _hoisted_10 = { class: "py-3 text-center border-bottom bg-white" }
const _hoisted_11 = { class: "mb-0" }
const _hoisted_12 = {
  key: 1,
  class: "h-100 d-flex flex-column border-right",
  style: {"flex":"1"}
}
const _hoisted_13 = { class: "py-3 text-center border-bottom bg-white" }
const _hoisted_14 = {
  key: 0,
  class: "pt-3 mb-3"
}
const _hoisted_15 = {
  key: 1,
  class: "mb-0"
}
const _hoisted_16 = {
  key: 2,
  class: "h-100 d-flex flex-column",
  style: {"flex":"1"}
}
const _hoisted_17 = { class: "py-3 text-center border-bottom bg-white" }
const _hoisted_18 = { class: "mb-0" }
const _hoisted_19 = { class: "flex-grow-1 d-flex flex-column border-bottom" }
const _hoisted_20 = {
  key: 0,
  class: "px-4 py-2 bg-light"
}
const _hoisted_21 = { class: "m-0" }
const _hoisted_22 = { class: "flex-grow-1 d-flex flex-row" }
const _hoisted_23 = {
  key: 0,
  class: "h-100 d-flex flex-column border-right",
  style: {"flex":"1"}
}
const _hoisted_24 = { class: "card clickable-border p-2 mx-2 my-1" }
const _hoisted_25 = {
  key: 0,
  style: {"position":"absolute","top":"0","left":"0","width":"99%"}
}
const _hoisted_26 = { class: "m-0 font-weight-bold" }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = { class: "m-0" }
const _hoisted_29 = { class: "m-0" }
const _hoisted_30 = {
  key: 1,
  class: "h-100 d-flex flex-column border-right",
  style: {"flex":"1"}
}
const _hoisted_31 = { class: "card clickable-border p-2 mx-2 my-1" }
const _hoisted_32 = {
  key: 0,
  style: {"position":"absolute","top":"0","left":"0","width":"99%"}
}
const _hoisted_33 = { class: "m-0 font-weight-bold" }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = { class: "m-0" }
const _hoisted_36 = { class: "m-0" }
const _hoisted_37 = {
  key: 2,
  class: "h-100 d-flex flex-column",
  style: {"flex":"1"}
}
const _hoisted_38 = { class: "card clickable-border p-2 mx-2 my-1" }
const _hoisted_39 = {
  key: 0,
  style: {"position":"absolute","top":"0","left":"0","width":"99%"}
}
const _hoisted_40 = { class: "m-0 font-weight-bold" }
const _hoisted_41 = { key: 1 }
const _hoisted_42 = {
  key: 0,
  class: "m-0"
}
const _hoisted_43 = {
  key: 1,
  class: "m-0"
}
const _hoisted_44 = {
  key: 2,
  class: "m-0"
}
const _hoisted_45 = {
  key: 2,
  class: "mb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_ProjectInfo = _resolveComponent("ProjectInfo")!
  const _component_translate = _resolveComponent("translate")!
  const _component_Draggable = _resolveComponent("Draggable")!
  const _component_Container = _resolveComponent("Container")!

  return (!_ctx.project)
    ? (_openBlock(), _createBlock(_component_ProgressBar, {
        key: 0,
        mode: "indeterminate"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lots, (lot, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["card w-100 flex-shrink-0 flex-grow-1 d-flex flex-column overflow-x-auto", { 'mb-4': _ctx.lots.length > 1 && i < _ctx.lots.length - 1 }]),
            style: {"min-height":"0"}
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h6", _hoisted_3, [
                (_ctx.project.lotsWithInfo.length > 1)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Los " + _toDisplayString(i + 1) + ": ", 1))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(lot.name), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_ProjectInfo, {
                  project: lot,
                  class: "p-4"
                }, null, 8, ["project"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  (!lot.skippedPhases?.includes('3'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("h6", _hoisted_11, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Teilnahmewettbewerb")
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (!lot.skippedPhases?.includes('5'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          (lot.type === 2)
                            ? (_openBlock(), _createElementBlock("h6", _hoisted_14, [
                                _createVNode(_component_translate, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Interessenten")
                                  ]),
                                  _: 1
                                })
                              ]))
                            : (_openBlock(), _createElementBlock("h6", _hoisted_15, [
                                _createVNode(_component_translate, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Verhandlung")
                                  ]),
                                  _: 1
                                })
                              ]))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (!lot.skippedPhases?.includes('6'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("h6", _hoisted_18, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Angebot")
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.openedRounds(lot), (round) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    (lot.type === 5)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createElementVNode("h6", _hoisted_21, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Runde")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(" " + _toDisplayString(round), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_22, [
                      (!lot.skippedPhases?.includes('3'))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            (_ctx.openedApplications(lot, round).length)
                              ? (_openBlock(), _createBlock(_component_Container, {
                                  key: 0,
                                  class: "flex-grow-1 overflow-x-hidden",
                                  orientation: "vertical",
                                  "group-name": "col-items",
                                  "non-drag-area-selector": ".readOnly",
                                  "should-accept-drop": (e, payload) => _ctx.shouldAcceptDrop(payload, lot, round, 3),
                                  "get-child-payload": index => _ctx.openedApplications(lot, round)[index] || {},
                                  "drop-placeholder": { className: `drop-area`, animationDuration: '200', showOnTop: true },
                                  "drag-class": "shadow bg-color-lightgray transition duration-100 ease-in transform rotate-3 scale-110",
                                  "drop-class": "transition duration-100 ease-in z-50 transform -rotate-2 scale-90",
                                  onDrop: (e) => _ctx.onCardDrop(e, lot, round, 3),
                                  onDropReady: _cache[0] || (_cache[0] = (e) => _ctx.onDropReady(3, e))
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.openedApplications(lot, round), (item) => {
                                      return (_openBlock(), _createBlock(_component_Draggable, {
                                        key: item.lotOffer.id,
                                        class: _normalizeClass({ 'readOnly': item.readOnly || _ctx.isLoading(item), 'h-0': !item.visible }),
                                        onClick: ($event: any) => (_ctx.$router.push('/projekte/' + (lot.parentId || lot.id) + '/bewerber/' + item.offer.organizationId))
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_24, [
                                            (_ctx.isLoading(item))
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                                  _createVNode(_component_ProgressBar, { mode: "indeterminate" })
                                                ]))
                                              : _createCommentVNode("", true),
                                            _createElementVNode("p", _hoisted_26, _toDisplayString(item.offer.organization.name), 1),
                                            ((lot.stage || 0) >= 4)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                                  _createElementVNode("p", _hoisted_28, [
                                                    _createVNode(_component_translate, null, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode("Teilnahmeantrag eingereicht:")
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _createElementVNode("p", _hoisted_29, _toDisplayString(item.lotOffer.applicationSent ? _ctx.dayjs(item.lotOffer.applicationSent).format('LLL') : ''), 1)
                                                ]))
                                              : _createCommentVNode("", true)
                                          ])
                                        ]),
                                        _: 2
                                      }, 1032, ["class", "onClick"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["should-accept-drop", "get-child-payload", "onDrop"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (!lot.skippedPhases?.includes('5'))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                            (_ctx.acceptedApplications(lot, round).length)
                              ? (_openBlock(), _createBlock(_component_Container, {
                                  key: 0,
                                  class: "flex-grow-1 overflow-x-hidden",
                                  orientation: "vertical",
                                  "group-name": "col-items",
                                  "non-drag-area-selector": ".readOnly",
                                  "should-accept-drop": (e, payload) => _ctx.shouldAcceptDrop(payload, lot, round, 5),
                                  "get-child-payload": index => _ctx.acceptedApplications(lot, round)[index] || {},
                                  "drop-placeholder": { className: `drop-area`, animationDuration: '200', showOnTop: true },
                                  "drag-class": "shadow bg-color-lightgray transition duration-100 ease-in transform rotate-3 scale-110",
                                  "drop-class": "transition duration-100 ease-in z-50 transform -rotate-2 scale-90",
                                  onDrop: (e) => _ctx.onCardDrop(e, lot, round, 5),
                                  onDropReady: _cache[1] || (_cache[1] = (e) => _ctx.onDropReady(3, e))
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.acceptedApplications(lot, round), (item) => {
                                      return (_openBlock(), _createBlock(_component_Draggable, {
                                        key: item.lotOffer.id,
                                        class: _normalizeClass({ 'readOnly': item.readOnly || _ctx.isLoading(item), 'h-0': !item.visible }),
                                        onClick: ($event: any) => (_ctx.$router.push('/projekte/' + (lot.parentId || lot.id) + '/bewerber/' + item.offer.organizationId))
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_31, [
                                            (_ctx.isLoading(item))
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                                  _createVNode(_component_ProgressBar, { mode: "indeterminate" })
                                                ]))
                                              : _createCommentVNode("", true),
                                            _createElementVNode("p", _hoisted_33, _toDisplayString(item.offer.organization.name), 1),
                                            ((lot.stage || 0) >= 4)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                                  _createElementVNode("p", _hoisted_35, [
                                                    _createVNode(_component_translate, null, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode("Teilnahmeantrag eingereicht:")
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _createElementVNode("p", _hoisted_36, _toDisplayString(item.lotOffer.applicationSent ? _ctx.dayjs(item.lotOffer.applicationSent).format('LLL') : ''), 1)
                                                ]))
                                              : _createCommentVNode("", true)
                                          ])
                                        ]),
                                        _: 2
                                      }, 1032, ["class", "onClick"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["should-accept-drop", "get-child-payload", "onDrop"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (!lot.skippedPhases?.includes('6'))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                            (_ctx.requestedOffers(lot, round).length)
                              ? (_openBlock(), _createBlock(_component_Container, {
                                  key: 0,
                                  class: "flex-grow-1 overflow-x-hidden",
                                  orientation: "vertical",
                                  "group-name": "col-items",
                                  "non-drag-area-selector": ".readOnly",
                                  "should-accept-drop": (e, payload) => _ctx.shouldAcceptDrop(payload, lot, round, 6),
                                  "get-child-payload": index => _ctx.requestedOffers(lot, round)[index] || {},
                                  "drop-placeholder": { className: `drop-area`, animationDuration: '200', showOnTop: true },
                                  "drag-class": "shadow bg-color-lightgray transition duration-100 ease-in transform rotate-3 scale-110",
                                  "drop-class": "transition duration-100 ease-in z-50 transform -rotate-2 scale-90",
                                  onDrop: (e) => _ctx.onCardDrop(e, lot, round, 6),
                                  onDropReady: _cache[2] || (_cache[2] = (e) => _ctx.onDropReady(3, e))
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.requestedOffers(lot, round), (item) => {
                                      return (_openBlock(), _createBlock(_component_Draggable, {
                                        key: item.lotOffer.id,
                                        class: _normalizeClass({ 'readOnly': item.readOnly || _ctx.isLoading(item), 'h-0': !item.visible }),
                                        onClick: ($event: any) => (_ctx.$router.push('/projekte/' + (lot.parentId || lot.id) + '/bewerber/' + item.offer.organizationId))
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_38, [
                                            (_ctx.isLoading(item))
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                                                  _createVNode(_component_ProgressBar, { mode: "indeterminate" })
                                                ]))
                                              : _createCommentVNode("", true),
                                            _createElementVNode("p", _hoisted_40, _toDisplayString(item.offer.organization.name), 1),
                                            (item.lotOffer.stage && (lot.stage || 0) >= 7)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                                                  (item.lotOffer.stage >= 6)
                                                    ? (_openBlock(), _createElementBlock("p", _hoisted_42, [
                                                        _createVNode(_component_translate, { class: "text-success" }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode("Angebot eingereicht:")
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]))
                                                    : _createCommentVNode("", true),
                                                  (item.lotOffer.stage >= 6 && item.lotOffer.offerSent)
                                                    ? (_openBlock(), _createElementBlock("p", _hoisted_43, _toDisplayString(_ctx.dayjs(item.lotOffer.offerSent).format('LLL')), 1))
                                                    : (item.lotOffer.stage < 6)
                                                      ? (_openBlock(), _createElementBlock("p", _hoisted_44, [
                                                          _createVNode(_component_translate, null, {
                                                            default: _withCtx(() => [
                                                              _createTextVNode("Kein Angebot eingereicht")
                                                            ]),
                                                            _: 1
                                                          })
                                                        ]))
                                                      : _createCommentVNode("", true)
                                                ]))
                                              : ([ 4, 5 ].includes(item.lotOffer.stage || 0))
                                                ? (_openBlock(), _createElementBlock("p", _hoisted_45, [
                                                    _createVNode(_component_translate, null, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode("Angebot angefordert")
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]))
                                                : _createCommentVNode("", true)
                                          ])
                                        ]),
                                        _: 2
                                      }, 1032, ["class", "onClick"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["should-accept-drop", "get-child-payload", "onDrop"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 256))
              ])
            ])
          ], 2))
        }), 256))
      ]))
}