import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-777aa166"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_translate = _resolveComponent("translate")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["p-float-label w-100", _ctx.animationClass + _ctx.autoExpandClass])
  }, [
    (!_ctx.autoResize)
      ? (_openBlock(), _createBlock(_component_InputText, {
          key: 0,
          ref: "myinput",
          class: "w-100",
          modelValue: _ctx.internalValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
          disabled: _ctx.disabled,
          type: _ctx.type,
          name: _ctx.name || _ctx.label,
          autocomplete: _ctx.autocomplete === 'true' ? '' : 'new-password',
          placeholder: _ctx.placeholder,
          required: _ctx.required,
          onFocus: _ctx.handleFocus,
          onBlur: _ctx.handleBlur,
          onAnimationstart: _ctx.checkAutofill,
          onKeydown: _ctx.handleKeypress
        }, null, 8, ["modelValue", "disabled", "type", "name", "autocomplete", "placeholder", "required", "onFocus", "onBlur", "onAnimationstart", "onKeydown"]))
      : (_openBlock(), _createBlock(_component_Textarea, {
          key: 1,
          ref: "myinput",
          class: "w-100",
          modelValue: _ctx.internalValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.internalValue) = $event)),
          disabled: _ctx.disabled,
          type: _ctx.type,
          name: _ctx.name || _ctx.label,
          autocomplete: _ctx.autocomplete === 'true' ? '' : 'new-password',
          placeholder: _ctx.placeholder,
          required: _ctx.required,
          "auto-resize": _ctx.autoResize,
          rows: "1",
          onFocus: _ctx.handleFocus,
          onBlur: _ctx.handleBlur,
          onAnimationstart: _ctx.checkAutofill,
          onKeydown: _ctx.handleKeypress,
          style: {"resize":"none"}
        }, null, 8, ["modelValue", "disabled", "type", "name", "autocomplete", "placeholder", "required", "auto-resize", "onFocus", "onBlur", "onAnimationstart", "onKeydown"])),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label + (_ctx.required ? ' *' : '')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}