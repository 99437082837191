

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Watch} from "vue-property-decorator"
import AutoComplete from "primevue/autocomplete"
import Button from "primevue/button"
import User from "@/model/User"

@Options({
  name: 'UserPicker',
  //@ts-ignore
  props: {
    users: Array,
    modelValue: Array,
    placeholder: String,
    disabled: Boolean,
    label: String
  },
  components: {
    AutoComplete, Button
  },
  emits : [
    'update:modelValue'
  ]
})
export default class UserPicker extends Vue {

  i18n: Language = useGettext()

  users!: User[] | null
  modelValue!: User[]
  placeholder!: string
  disabled!: boolean
  label!: string

  newUser: string | User = ''
  filter: string = ''
  selectableUsers: any[] = []
  selectedUsers: User[] = []

  updateSelection() {
    let newUser
    if (this.newUser.hasOwnProperty('email')) {
      newUser = this.selectableUsers.find(u => u.email === (this.newUser as User).email)
    } else {
      newUser = this.selectableUsers.find(u => u.email === this.newUser)
    }
    if (newUser && !this.selectedUsers.map(u => u.email).includes(newUser.email)) {
      this.filterUsers({ query: ''})
      this.selectedUsers.push(newUser)
      this.newUser = ''
    }
    this.$emit('update:modelValue', this.selectedUsers)
  }

  filterUsers(input: { query: string }) {
    this.filter = (input.query || '').toLowerCase()
    this.watchUsers((this.users || []))
  }

  @Watch('modelValue')
  watchSelection(modelValue: User[], /* oldSelection: string[] */) {
    this.selectedUsers = modelValue
  }

  @Watch('users')
  watchUsers(newUsers: any[], /* oldUsers: any[] */) {
    this.selectableUsers = newUsers.filter((user: any) => {
      return !this.filter ||
          (user.surName && user.surName.toLowerCase().includes(this.filter)) ||
          (user.givenName && user.givenName.toLowerCase().includes(this.filter)) ||
          (user.email && user.email.toLowerCase().includes(this.filter))
    })
  }

  mounted() {
    this.watchSelection(this.modelValue || [])
    this.watchUsers((this.users || []))
  }

}
