

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {ref} from "@vue/reactivity"
import {ClickData, Viewer} from '@photo-sphere-viewer/core'
import {Watch} from "vue-property-decorator"
import {Marker, MarkerConfig, MarkersPlugin} from "@photo-sphere-viewer/markers-plugin"
import PanoramaImage from "@/model/gis/PanoramaImage"
import Button from "primevue/button"
import Dialog from "@/components/common/Dialog.vue"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Dropdown from "@/components/controls/Dropdown.vue"
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import ColorPicker from "primevue/colorpicker"
import Slider from "primevue/slider"

@Options({
  name: "PhotoSphereViewer",
  components: {
    Dialog, Button, AnimatedInput, Dropdown, TipTapTextArea, ColorPicker, Slider
  },
  //@ts-ignore
  props: {
    panorama: [ PanoramaImage, Object ],
    panoramaList: {
      type: [PanoramaImage, Array ],
      default: []
    },
    edit: Boolean
  }
})
export default class PhotoSphereViewer extends Vue {

  i18n: Language = useGettext()

  //@ts-ignore
  viewercontainer: HTMLDivElement = ref<HTMLDivElement>(null)
  viewer: Viewer|null = null
  markersPlugin: MarkersPlugin|null = null

  panorama!: PanoramaImage
  panoramaList: PanoramaImage[] = []
  edit: boolean = false

  pinIcon: string = '/img/map-marker.png'
  waypointIcon: string = '/img/map-marker-photo.png'
  strokeColor: string = ""

  addPinMode: boolean = false
  addPolygonMode: boolean = false
  addWaypointMode: boolean = false
  selectedMarker: MarkerConfig|null = null
  editedMarker: MarkerConfig|null = null

  showEditMarkerDialog: boolean = false

  markerTypePin: string = "pin"
  markerTypePolygon: string = "polygon"
  markerTypeWaypoint: string = "waypoint"
  buttonPinName: string = "button-add-pin"
  buttonPolygonName: string = "button-add-polygon"
  buttonWaypointName: string = "button-add-waypoint"

  get editButtonIds(): string[] {
    return [
      this.buttonPinName,
      this.buttonPolygonName,
      this.buttonWaypointName
    ]
  }

  mounted(){
    this.$nextTick(() => {
      this.createViewer()
    })
  }

  getMarkers(): string {
    const markers: any[] = []
    this.markersPlugin?.getMarkers().forEach((m: Marker) => {
      if (m.config) markers.push(m.config)
    })
    return JSON.stringify(markers)
  }

  updateMarker() {
    if (this.editedMarker) this.markersPlugin?.updateMarker(this.editedMarker)
    this.closeDialog()
  }

  get tooltipPositionOptions(): any[] {
    return [
      {label: this.i18n.$gettext("Oben"), value: 'top center'},
      {label: this.i18n.$gettext("Links"), value: 'center left'},
      {label: this.i18n.$gettext("Rechts"), value: 'center right'},
      {label: this.i18n.$gettext("Unten"), value: 'bottom center'},

      {label: this.i18n.$gettext("Oben Links"), value: 'top left'},
      {label: this.i18n.$gettext("Oben Recht"), value: 'top right'},
      {label: this.i18n.$gettext("Unten Links"), value: 'bottom left'},
      {label: this.i18n.$gettext("Unten Rechts"), value: 'bottom right'}
    ]
  }

  get waypointTargetOptions(): any[] {
    const waypoints: any[] = []
    this.panoramaList.forEach((p: PanoramaImage) => {
      if (p.url != this.panorama.url) {
        waypoints.push({label: p.name || p.originalFileName, value: p.url})
      }
    })
    return waypoints
  }

  openDialog() {
    if (!this.selectedMarker) return
    this.editedMarker = JSON.parse(JSON.stringify(this.selectedMarker))
    this.strokeColor = this.editedMarker?.svgStyle?.stroke || 'ff0000'
    this.showEditMarkerDialog = true
  }

  closeDialog() {
    this.showEditMarkerDialog = false
    this.editedMarker = null
    this.selectedMarker = null
  }

  createViewer() {
    this.viewer = new Viewer({
      plugins: [
        MarkersPlugin
      ],
      container: this.viewercontainer,
      caption: this.panorama.name || this.panorama.originalFileName || '',
      navbar: [
        'zoom',
        {
          id: this.buttonPinName,
          content: '<i class="cil-location-pin-plus"></i>',
          title: this.i18n.$gettext("Markierung setzen"),
          onClick: () => {
            this.enableAddPinMode()
          }
        },
        {
          id: this.buttonPolygonName,
          content: '<i class="cil-object-group"></i>',
          title: this.i18n.$gettext("Gebiet zeichnen"),
          onClick: () => {
            this.enableAddPolygonMode()
          }
        },
        {
          id: this.buttonWaypointName,
          content: '<i class="cil-target"></i>',
          title: this.i18n.$gettext("Wegpunkt setzen"),
          onClick: () => {
            this.enableAddWaypointMode()
          }
        },
        'caption',
        'fullscreen'
      ]
    })
    this.markersPlugin = this.viewer.getPlugin(MarkersPlugin) as MarkersPlugin
    this.watchEditAndRedrawPanorama()

    this.createCallbacks(this.viewer, this.markersPlugin)
  }

  async showPanorama(panorama: PanoramaImage, viewer: Viewer, markersPlugin: MarkersPlugin) {
    markersPlugin.clearMarkers()
    await viewer.setPanorama(panorama.url)
    const markers: MarkerConfig[] = JSON.parse(panorama.markers || '[]')
    markersPlugin.setMarkers(markers)
  }

  createCallbacks(viewer: Viewer, markers: MarkersPlugin) {
    viewer.addEventListener('click', ({ data }) => {
      if (data.rightclick) {
        this.handleGeneralRightClick(markers)
      } else {
        if (this.addPinMode) { //We are adding a pin
          this.addPin(data, markers) //Add pin
          this.resetEditState() //Reset edit state
        } else if (this.addPolygonMode) { //We are adding a polygon
          if (!this.selectedMarker) { //This must be the first point
            this.selectedMarker = this.addPolygon(data, markers) //Create marker
          } else {
            const points: [number, number][] = this.selectedMarker.polygon as [number, number][] || [] //Get the polygon array
            points.push([data.yaw, data.pitch]) //Add new point
            this.selectedMarker.polygon = points //Set polygon array to marker
            markers.updateMarker(this.selectedMarker) //Update marker
          }
        } else if (this.addWaypointMode) {
          this.addWaypoint(data, markers)
          this.resetEditState()
        }
      }
    })

    markers.addEventListener('select-marker', ({ marker, doubleClick, rightClick }) => {
      if (this.edit) {
        if (this.addPinMode || this.addPolygonMode || this.addWaypointMode) {
          if (rightClick) {
            this.handleGeneralRightClick(markers)
          }
        } else {
          if (!doubleClick && !rightClick) {
            this.selectedMarker = marker.config
            this.openDialog()
            //Open edit overlay
          }
        }
      } else {
        if (!doubleClick && !rightClick) {
          if (marker.data.waypoint) {
            this.goTo(marker.data.waypoint)
          }
        }
      }
    })
  }

  goTo(waypoint: string):void {
    const panorama: PanoramaImage|undefined = this.panoramaList.find((p: PanoramaImage) => p.url == waypoint)
    if (panorama && this.viewer && this.markersPlugin) {
      void this.showPanorama(panorama, this.viewer, this.markersPlugin)
    }
  }

  handleGeneralRightClick(markers: MarkersPlugin): void {
    if (this.addPolygonMode) { //We are adding a polygon
      if (this.selectedMarker) {
        if (!this.selectedMarker.polygon?.length || this.selectedMarker.polygon.length < 3) { //Not enough points for a polygon
          markers.removeMarker(this.selectedMarker) //Remove the marker
        }
      }
    }
    this.resetEditState() //Reset edit state
  }

  addPin(data: ClickData, markers: MarkersPlugin): MarkerConfig {
    const marker: MarkerConfig = this.newBaseMarker(this.markerTypePin)
    marker.size = { width: 60, height: 60}
    marker.imageLayer = this.pinIcon
    marker.position = {
      yaw: data.yaw,
      pitch: data.pitch,
    }
    markers.addMarker(marker)
    return marker
  }

  addPolygon(data: ClickData, markers: MarkersPlugin): MarkerConfig {
    const marker: MarkerConfig = this.newBaseMarker(this.markerTypePolygon)
    marker.polygon = [data.yaw, data.pitch]
    marker.svgStyle = {
      fill       : 'rgba(0, 0, 0, 0.5)',
      stroke     : '#ff0000',
      strokeWidth: '8px'
    }
    markers.addMarker(marker)
    return marker
  }

  addWaypoint(data: ClickData, markers: MarkersPlugin): MarkerConfig {
    const marker: MarkerConfig = this.newBaseMarker(this.markerTypeWaypoint)
    marker.imageLayer = this.waypointIcon
    marker.size = { width: 60, height: 60}
    marker.position = {
      yaw: data.yaw,
      pitch: data.pitch,
    }
    marker.data.waypoint = ""
    markers.addMarker(marker)
    return marker
  }

  get strokeWidthOptions(): any[] {
    return [
      {label: "1",  value: '1px'},
      {label: "2",  value: '2px'},
      {label: "3",  value: '3px'},
      {label: "4",  value: '4px'},
      {label: "5",  value: '5px'},
      {label: "6",  value: '6px'},
      {label: "7",  value: '7px'},
      {label: "8",  value: '8px'},
      {label: "9",  value: '9px'},
      {label: "10", value: '10px'}
    ]
  }

  get currentMarkerType(): string {
    return this.editedMarker?.data?.type || ''
  }

  newBaseMarker(markerType: string): MarkerConfig {
    return {
      id: '#' + markerType + (markerType?'-':'') + Math.random().toString(36).slice(2),
      tooltip: '',
      size: { width: 120, height: 120 },
      anchor: 'bottom center',
      data: {
        type: markerType,
        deletable: true,
        waypoint: ""
      },
    }
  }

  enableAddPinMode() {
    this.resetEditState()
    this.addPinMode = true
  }
  enableAddPolygonMode() {
    this.resetEditState()
    this.addPolygonMode = true
  }
  enableAddWaypointMode() {
    this.resetEditState()
    this.addWaypointMode = true
  }

  resetEditState() {
    this.addPinMode = false
    this.addPolygonMode = false
    this.addWaypointMode = false

    this.selectedMarker = null
  }

  @Watch('panorama')
  watchPanorama(): void {
    if (this.panorama && this.viewer && this.markersPlugin) {
      void this.showPanorama(this.panorama, this.viewer, this.markersPlugin)
    }
  }

  @Watch('edit')
  watchEditAndRedrawPanorama(): void {
    if (this.edit) {
      this.editButtonIds.forEach((id: string) => this.viewer?.navbar?.getButton(id)?.show())
    } else {
      this.editButtonIds.forEach((id: string) => this.viewer?.navbar?.getButton(id)?.hide())
    }
    this.watchPanorama() //Reset to selected panorama on edit change
  }
}

