import RoomTemplate from '@/model/RoomTemplate';

export default class ProjectTemplate {
  id!: string | null;
  name!: string | null;
  description!: string | null;
  published!: string | null;
  updated!: string | null;
  created!: string | null;
  panels!: any | null;
  version!: number | null;
  projectTypes!: any | null;
  active!: boolean | null;
  database!: any | null;
  roomTemplates!: RoomTemplate[] | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.description = null;
    this.published = null;
    this.updated = null;
    this.created = null;
    this.panels = null;
    this.version = null;
    this.projectTypes = null;
    this.active = null;
    this.database = null;
    this.roomTemplates = null;
  }
}
