
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import SWR from "@/api/SWR"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Room from "@/model/Room"
import {rpcClient} from "@/api/WebsocketClient"
import {roomServiceApi} from "@/api/RoomServiceApi"
import Offer from '@/model/Offer'
import RoomDetails from "@/components/common/RoomDetails.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import Card from "primevue/card"
import {offerServiceApi} from "@/api/OfferServiceApi"
import Dialog from "@/components/common/Dialog.vue"
import i18n from '@/util/i18n'
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import RpcError from "@/api/RpcError"
import ChatView from "@/components/common/ChatView.vue"
import OrganizationCard from "@/components/common/OrganizationCard.vue"
import MessageList from "@/components/common/MessageList.vue"
import useToast from "@/util/toasts"

@Options({
  name: "OfferDetails",
  components: {
    ChatView, AnimatedInput, Button, RoomDetails, Card, Checkbox, Dialog, TipTapTextArea, OrganizationCard, MessageList
  },
  //@ts-ignore
  props: {
    offer: [ Offer, Object ],
    projectStage: Number
  },
  emits: [
    'close'
  ]
})
export default class OfferDetails extends Vue {

  i18n = i18n
  rpcClient = rpcClient
  toast = useToast()

  offer!: Offer
  projectStage!: number

  showCreateRoomDialog: boolean = false
  roomName: string = ''
  roomDescription: string = ''

  get rooms(): SWR<Room[], number[]> | null {
    return this.offer.projectId ? roomServiceApi.getRoomsByProjectId(this.offer.projectId) : null
  }

  get organizationSpecificRooms(): Room[] {
    if (this.offer.projectId && this.offer.organizationId) {
      return this.rooms?.data?.filter((r: Room) => r.organizationId === this.offer.organizationId) || []
    } else {
      return []
    }
  }

  roomsByOfferId(offerId: number | null | undefined): Room[] {
    return offerId ? (this.rooms?.data?.filter((r: Room) => !r.organizationId && r.offerId === offerId) || []) : []
  }

  resetCreateRoomDialog() {
    this.roomName = ''
    this.roomDescription = ''
    this.showCreateRoomDialog = false
  }

  createRoom() {
    const room = Object.assign(new Room(), {
      name: this.roomName,
      description: this.roomDescription,
      projectId: this.offer.projectId,
      organizationId: this.offer.organizationId,
      stage: this.projectStage,
      type: 'CLIENT'
    })
    return roomServiceApi._createRoom(room).then((id: number) => {
      this.resetCreateRoomDialog()
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Der Datenraum konnte nicht angelegt werden.'))
    })
  }
}
