import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Document from '@/model/Document';


export default class GeneratedDocumentStore {

    state = reactive({
        documents: new Map<string, Document>(),
        calls: new Map<string, string | string[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.documents) this.state.documents.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceDocument(newDocument: Document) {
        if (newDocument.id) {
            this.state.documents.set(newDocument.id, newDocument)
            
        }
    }

    addOrReplaceDocuments(newDocuments: Document[]) {
        for (const newDocument of newDocuments) {
            this.addOrReplaceDocument(newDocument)
        }
    }

    replaceDocuments(documents: Document[]) {
        const newDocuments = new Map<string, Document>()
        for (const newDocument of documents) {
            if (newDocument.id) {
                newDocuments.set(newDocument.id, newDocument)
                
            }
        }
        this.state.documents = newDocuments
    }

    removeDocument(id: string | undefined | null) {
        if (id !== undefined && id !== null) {
            this.state.documents.delete(id)
        }
    }

    removeDocuments(ids: (string | null)[] | undefined | null) {
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                this.removeDocument(id)
            }
        }
    }
}