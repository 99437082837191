

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import Divider from "primevue/divider"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import InputText from "primevue/inputtext"
import RadioButton from 'primevue/radiobutton'
import Slider from "primevue/slider"
import Location from "@/model/gis/Location"
import Dropdown from "@/components/controls/Dropdown.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import InfiniteList from "@/components/controls/InfiniteList.vue"
import Breadcrumb from "primevue/breadcrumb"
import { MenuItem } from 'primevue/menuitem'
import SWR from "@/api/SWR"
import ProgressBar from "primevue/progressbar"
import Dialog from "@/components/common/Dialog.vue"
import {locationServiceApi} from "@/api/LocationServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import RpcError from "@/api/RpcError"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import LeafletMap from "@/components/common/LeafletMap.vue"
import useToast from "@/util/toasts"
import Checkbox from "@/components/controls/Checkbox.vue"
import {propertyServiceApi} from "@/api/PropertyServiceApi"
import {useConfirm} from "primevue/useconfirm"
import L, {LatLng} from "leaflet"
import {ref} from "@vue/reactivity"
import Nominatim, {getSafeDisplayNameOfAdress, NominatimResponse} from "@/util/nominatim"
import Autocomplete from "@/components/controls/AutoComplete.vue"

/* Leaflet map */

@Options({
  name: "PropertyList",
  components: { Autocomplete,
    Button, Divider, TabPanel, TabView, Dropdown, LeafletMap, InfiniteList, Checkbox,
    Breadcrumb, InputText, Slider, RadioButton, ProgressBar, Dialog, AnimatedInput
  }
})
export default class LocationList extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()
  rpcClient = rpcClient

  locationToEdit: Location | null = null

  showLocationEditorDialog: boolean = false
  archivedFilter: boolean = false

  autocompleteInput: { label: string, data: NominatimResponse} | string | null = null
  nominatim: Nominatim = new Nominatim()

  //@ts-ignore
  leafLetMapRef: LeafletMap = ref<LeafletMap | null>(null)

  locationName(location: Location): string {
    let name: string = location.name || ''
    if (location.isArchived) {
      name = '(' + this.i18n.$gettext("Archiviert") + ') ' + name
    }
    return name
  }

  get locations(): Location[] {
    return this.locationsSWR.data || []
  }

  get locationsToDisplayInMap(): Location[] {
    return this.locationToEdit ? [ this.locationToEdit ] : []
  }

  get locationsSWR(): SWR<Location[], number[]> {
    return locationServiceApi.getLocations(this.archivedFilter)
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    breadcrumbs.push({
      label: this.i18n.$gettext('Standorte'),
      to: '/standorte'
    })
    return breadcrumbs
  }

  handleMapSelect(e: L.LeafletMouseEvent): void{
    const newLoc: Location = new Location()
    if(this.locationToEdit){
      newLoc.name = this.locationToEdit.name
      newLoc.id = this.locationToEdit.id
    }
    newLoc.longitude = e.latlng.lng
    newLoc.latitude = e.latlng.lat
    this.locationToEdit = newLoc
  }

  get locationSuggestions(): { label: string, data: NominatimResponse}[]{
    return this.nominatim.result.map((elem: NominatimResponse) => {
      return {
        label: getSafeDisplayNameOfAdress(elem.address),
        data: elem
      }
    })
  }

  updateLocationSuggestions(input: { query: string }){
    if(this.autocompleteInput) this.nominatim.query(input.query)
  }

  onLocationComplete(event: any){
    //@ts-ignore
    const result: NominatimResponse = this.autocompleteInput.data
    const lat: number = Number.parseFloat(result.lat)
    const lon: number = Number.parseFloat(result.lon)
    this.leafLetMapRef?.flyToPositon(lat, lon, 15)
    if(this.locationToEdit) {
      this.locationToEdit.addressDisplayName = getSafeDisplayNameOfAdress(result.address)
      this.locationToEdit.addressOsmType = result.osm_type
      this.locationToEdit.addessOsmId = result.osm_id + ''
    }
  }

  createLocation(): void {
    this.locationToEdit = new Location()
    this.autocompleteInput = ""
    this.showLocationEditorDialog = true
  }

  editLocation(location: Location): void {
    this.locationToEdit =  JSON.parse(JSON.stringify(location))
    if(this.locationToEdit?.addressDisplayName) {
      this.autocompleteInput = this.locationToEdit?.addressDisplayName
    }
    this.showLocationEditorDialog = true
  }

  unArchiveLocation(location: Location) {
    this.confirm.require({
      message: this.i18n.$gettext('Wollen Sie den aus dem Archiv wiederherstellen?'),
      header: this.i18n.$gettext('Sind Sie sicher?'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        location.isArchived = false
        return locationServiceApi._updateLocation(location).then(() => {
          this.toast.success(this.i18n.$gettext('Der Standort wurde aus dem Archiv wiederhergestellt.'))
        }).catch((e: RpcError) => {
          this.toast.error(this.i18n.$gettext('Der Standort konnte nicht wiederhergestellt werden.'), e.message)
        })
      },
      reject: () => {
        //DO nothing
      }
    })
  }

  archiveLocation(location: Location) {
    this.confirm.require({
      message: this.i18n.$gettext('Wollen Sie den Standort archivieren?'),
      header: this.i18n.$gettext('Sind Sie sicher?'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        location.isArchived = true
        return locationServiceApi._updateLocation(location).then(() => {
          this.toast.success(this.i18n.$gettext('Der Standort wurde archiviert.'))
        }).catch((e: RpcError) => {
          this.toast.error(this.i18n.$gettext('Der Standort konnte nicht archiviert werden.'), e.message)
        })
      },
      reject: () => {
        //DO nothing
      }
    })
  }

  closeDialog(): void {
    this.locationToEdit = null
    this.showLocationEditorDialog = false
  }

  onShapeCreate(e: any): void {
    if(e.shape == "Polygon" && e.layer && this.locationToEdit) {
      console.log("Polygon drawn", e)
      const editedGroup: L.FeatureGroup = L.featureGroup()
      editedGroup.addLayer(e.layer)
      const center: LatLng = e.layer.getBounds().getCenter()
      this.locationToEdit.geoJson = JSON.stringify(editedGroup.toGeoJSON())
      this.locationToEdit.latitude = center.lat
      this.locationToEdit.longitude = center.lng
      this.$nextTick(() => {
        if(this.leafLetMapRef) {
          this.leafLetMapRef.refreshMap()
        }
      })
    }
  }

  save(): void {
    if(!this.locationToEdit) return
    const nameExists: boolean = this.locations.findIndex((l: Location) => {
      return l.name?.trim() == this.locationToEdit?.name?.trim() && l.id != this.locationToEdit?.id
    }) != -1
    if (nameExists) {
      this.toast.info(
        this.i18n.$gettext('Bitte wählen Sie einen anderen Standortnamen'),
        this.i18n.$gettext('Der Name ist bereits vergeben.')
      )
      return
    }
    if (this.locationToEdit.id) {
      locationServiceApi._updateLocation(this.locationToEdit).then(() => {
        this.toast.success(this.i18n.$gettext('Der Standort wurde aktualisiert.'))
        this.closeDialog()
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext('Der Standort konnte nicht aktualisiert werden.'))
      })
    } else {
      locationServiceApi._createLocation(this.locationToEdit).then(() => {
        this.toast.success(this.i18n.$gettext('Der Standort wurde erstellt.'))
        this.closeDialog()
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext('Der Standort konnte nicht erstellt werden.'))
      })
    }
  }
}

