import MailNotificationSettings from '@/model/settings/MailNotificationSettings';
import DefaultTemplateSettings from '@/model/settings/DefaultTemplateSettings';

export default class Settings {
  filesUrl!: string | null;
  filesRepo!: string | null;
  twoFactorAuthenticationRequired!: boolean | null;
  mailNotifications!: MailNotificationSettings | null;
  defaultTemplateSettings!: DefaultTemplateSettings | null;
  propertyFolderTemplateList!: any | null;
  additionalFieldsTemplateList!: any | null;
  showFilterViewForExternalUsers!: boolean | null;

  constructor() {
    this.filesUrl = null;
    this.filesRepo = null;
    this.twoFactorAuthenticationRequired = null;
    this.mailNotifications = null;
    this.defaultTemplateSettings = null;
    this.propertyFolderTemplateList = null;
    this.additionalFieldsTemplateList = null;
    this.showFilterViewForExternalUsers = null;
  }
}
