export default class ProjectRole {
  projectId!: number | null;
  userName!: string | null;
  role!: string | null;

  constructor() {
    this.projectId = null;
    this.userName = null;
    this.role = null;
  }
}
