import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["clickable-border d-flex align-items-center", { active: _ctx.active }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(_ctx.target)))
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(["m-0 link", { active: _ctx.active }])
    }, [
      _createTextVNode(_toDisplayString(_ctx.link.label), 1),
      (_ctx.link.icon && _ctx.showIcon)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass(["ml-2", _ctx.link.icon + _ctx.linkIconClass])
          }, null, 2))
        : _createCommentVNode("", true)
    ], 2),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.link.links, (subLink) => {
      return (_openBlock(), _createElementBlock("div"))
    }), 256))
  ], 2))
}