
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import SWR from "@/api/SWR"
import ProgressBar from "primevue/progressbar"
import {organizationServiceApi} from "@/api/OrganizationServiceApi"
import Organization from "@/model/Organization"
import OrganizationRegistrationForm from "@/components/common/OrganizationRegistrationForm.vue"

@Options({
  name: "OrganizationDetails",
  components: {
    OrganizationRegistrationForm, Button, ProgressBar
  },
  //@ts-ignore
  props: {
    organization: [ Organization, Object ]
  }
})
export default class OrganizationDetails extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient

  organization!: Organization

  get organizationSWR(): SWR<Organization | null, number> | null {
    const organizationId = this.$route.params.id || rpcClient.session.user?.organizationId
    if (organizationId) {
      return organizationServiceApi.getOrganization(organizationId)
    } else {
      return null
    }
  }

  get o(): Organization | null {
    return this.organization || this.organizationSWR?.data
  }
}
