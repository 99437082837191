import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-float-label w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatPickr = _resolveComponent("FlatPickr")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_FlatPickr, {
      "model-value": _ctx.internalValue,
      class: _normalizeClass(["p-inputtext p-component w-100", _ctx.animationClass]),
      config: _ctx.startPickerOptions,
      onOnChange: _ctx.convertValueToDate,
      onFocusin: _ctx.handleFocus,
      onFocusout: _ctx.handleBlur
    }, null, 8, ["model-value", "class", "config", "onOnChange", "onFocusin", "onFocusout"]),
    _createElementVNode("label", null, _toDisplayString(_ctx.label + (_ctx.required ? ' *' : '')), 1)
  ]))
}