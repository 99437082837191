
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import SWR from "@/api/SWR"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import FileUpload from "primevue/fileupload"
import RoomDetails from "@/components/common/RoomDetails.vue"
import {rpcClient} from "@/api/WebsocketClient"
import Panel from "@/components/admin/Panel.vue"
import Card from "primevue/card"
import TwoFactorForm from "@/components/TwoFactorForm.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import OrganizationCard from "@/components/common/OrganizationCard.vue"
import UserPicker from "@/components/controls/UserPicker.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import {Watch} from "vue-property-decorator"
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import dayjs from "@/util/dayjs"
import Dialog from "@/components/common/Dialog.vue"
import MarkdownUtil from "@/util/MarkdownUtil"
import ProgressBar from "primevue/progressbar"
import Divider from "primevue/divider"
import MessageList from "@/components/common/MessageList.vue"
import ContentUtil from "@/util/ContentUtil"
import Content from "@/model/common/Content"
import Message from "primevue/message"
import Offer from "@/model/Offer"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import ProjectWithInfo from "@/util/ProjectWithInfo"

@Options({
  name: "ProjectDetails",
  components: {
    MessageList, TwoFactorForm, RoomDetails, AnimatedInput, Button, FileUpload, Message, Accordion, AccordionTab,
    Panel, Card, Checkbox, OrganizationCard, UserPicker, Dialog, TipTapTextArea, ProgressBar, Divider
  },
  //@ts-ignore
  props: {
    project: [ ProjectWithInfo, Object ],
    offer: [ Offer, Object ]
  }
})
export default class ProjectDetails extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient
  dayjs = dayjs

  project!: ProjectWithInfo

  showCreateRoomDialog: boolean = false
  roomName: string = ''
  roomDescription: string = ''
  teamMembers: User[] | null = null

  get showChanges(): boolean {
    return !!this.project?.hasChanges
  }

  get panels() {
    const exclusionList = [ 'meta-data-group', 'GR-LotsGroup', 'GR-Organisations-Section' ]
    if(!this.showChanges) {
      exclusionList.push('GR-Change')
    }
    return (this.project?.panels || []).filter((p: Content) => {
      return !p.hidden && (!p.repeatable || p.added) && (p.required || ContentUtil.hasValuedField(p)) &&
          !exclusionList.includes(p.contentId || '')
    }).map((p: Content) => {
      if (p.contentId === 'GR-Buyer') {
        return this.findOrganizationForm(p.children || []) || p
      } else {
        return p
      }
    })
  }

  findOrganizationForm(contents: Content[]): Content | null {
    return ContentUtil.findContent(contents, 'GR-ContractingAuthority')
  }

  get users(): SWR<User[], string[]> | null {
    if (this.isProjectOrSystemAdmin) {
      const swr = userServiceApi.getUsers([ 'SYSTEM_USER' ])
      swr?.call?.promise?.then(() => {
        if (this.project) this.teamMembers = this.members
      })
      return swr
    } else {
      return null
    }
  }

  get projectId(): number | null {
    return this.project?.id || null
  }

  get route() {
    return this.$route.params['childRoute']
  }

  get safeDescription() {
    return MarkdownUtil.safeRenderMarkdown(this.project?.description)
  }

  @Watch('project')
  watchTeamMembers() {
    this.teamMembers = this.members
  }

  get members(): User[] | null {
    const users: User[] | null = this.users?.data || null
    if (users != null && this.project?.team) {
      return this.project.team.map(m => {
        return users.find(u => u.email === m.userName) || new User()
      }).filter(u => !!u.email)
    } else {
      return null
    }
  }

  get isProjectOrSystemAdmin() {
    return rpcClient.session.user?.roles?.find((r: string) => r === 'SYSTEM_ADMIN') ||
        (rpcClient.isInternalUser && this.project?.team?.find(r => r.role === 'ADMIN'))
  }

  displayName(user: User): string {
    let displayName = ''
    if (user.givenName || user.contact?.givenName) {
      displayName += user.givenName || user.contact?.givenName
    }
    if (user.surName || user.contact?.surName) {
      if (displayName) displayName += ' '
      displayName += user.surName || user.contact?.surName
    }
    return displayName
  }

  resetCreateRoomDialog() {
    this.roomName = ''
    this.roomDescription = ''
    this.showCreateRoomDialog = false
  }

  mounted() {
    if (this.project) this.teamMembers = this.members
  }
}
