import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, Teleport as _Teleport, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "container-wide flex-shrink-1 flex-grow-1 d-flex flex-column p-0",
  style: {"min-height":"0"}
}
const _hoisted_2 = {
  key: 0,
  class: "w-100"
}
const _hoisted_3 = {
  id: "top",
  class: "card bg-white p-5 mb-4"
}
const _hoisted_4 = { class: "d-flex flex-row justify-content-start" }
const _hoisted_5 = {
  class: "flex-shrink-1 flex-grow-1",
  style: {"overflow":"auto"}
}
const _hoisted_6 = {
  id: "top",
  class: "card bg-white p-5 mb-4"
}
const _hoisted_7 = { class: "row mb-4" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "row mb-4" }
const _hoisted_10 = {
  key: 0,
  class: "col-12 col-md-6"
}
const _hoisted_11 = {
  key: 1,
  class: "col-12 col-md-6"
}
const _hoisted_12 = {
  key: 2,
  class: "col-12 col-md-6"
}
const _hoisted_13 = {
  key: 0,
  class: "row mb-4"
}
const _hoisted_14 = { class: "col-12 col-md-6" }
const _hoisted_15 = { class: "col-12 col-md-6" }
const _hoisted_16 = {
  key: 0,
  id: "sachbearbeiter",
  class: "card bg-white p-5 mb-4"
}
const _hoisted_17 = { class: "row mb-4" }
const _hoisted_18 = { class: "col" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col" }
const _hoisted_21 = { class: "flex-grow-1" }
const _hoisted_22 = { class: "m-0" }
const _hoisted_23 = { class: "font-weight-bold" }
const _hoisted_24 = { class: "m-0" }
const _hoisted_25 = { class: "font-weight-bold" }
const _hoisted_26 = {
  key: 0,
  class: "mt-4 mb-0 text-primary"
}
const _hoisted_27 = {
  key: 1,
  class: "mt-4 mb-0 text-primary"
}
const _hoisted_28 = { id: "dokumente" }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col" }
const _hoisted_31 = { class: "mb-4" }
const _hoisted_32 = { class: "row" }
const _hoisted_33 = { class: "col" }
const _hoisted_34 = { class: "row" }
const _hoisted_35 = { class: "col" }
const _hoisted_36 = { class: "my-4" }
const _hoisted_37 = { class: "row mb-4" }
const _hoisted_38 = { class: "col" }
const _hoisted_39 = { class: "py-3" }
const _hoisted_40 = { class: "font-weight-bold pl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_translate = _resolveComponent("translate")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_AnimatedInput = _resolveComponent("AnimatedInput")!
  const _component_UserPicker = _resolveComponent("UserPicker")!
  const _component_PanelTemplate = _resolveComponent("PanelTemplate")!
  const _component_RoomDetails = _resolveComponent("RoomDetails")!
  const _component_SidebarLink = _resolveComponent("SidebarLink")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_ProgressBar, {
        key: 0,
        mode: "indeterminate"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.step === 0 && !_ctx.$route.params?.id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.projectTemplates.call?.loading)
                  ? (_openBlock(), _createBlock(_component_ProgressBar, {
                      key: 0,
                      mode: "indeterminate"
                    }))
                  : (_openBlock(), _createBlock(_component_Dropdown, {
                      key: 1,
                      class: "flex-grow-1",
                      modelValue: _ctx.templateId,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.templateId) = $event)),
                      loading: _ctx.projectTemplates.call?.loading,
                      options: _ctx.allTemplates,
                      "option-label": "label",
                      "option-value": "value",
                      label: _ctx.i18n.$gettext('Formular'),
                      "empty-message": _ctx.i18n.$gettext('Keine Formulare vorhanden. Bitte erstellen Sie zunächst ein Formular.'),
                      "scroll-height": "400px",
                      filter: "",
                      required: ""
                    }, null, 8, ["modelValue", "loading", "options", "label", "empty-message"])),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Button, {
                    class: "mt-4 px-6",
                    disabled: !_ctx.templateId,
                    onClick: _ctx.chooseTemplate
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Weiter")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ])
              ])
            ]))
          : (_ctx.project)
            ? (_openBlock(), _createElementBlock("form", {
                key: 1,
                class: "flex-shrink-1 flex-grow-1 w-100 d-flex flex-column",
                onSubmit: _cache[14] || (_cache[14] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"])),
                style: {"min-height":"0"}
              }, [
                (_openBlock(), _createBlock(_Teleport, { to: "#topmenu-buttons" }, [
                  _createVNode(_component_Button, {
                    type: "submit",
                    class: "p-button-success mr-2",
                    icon: "pi pi-save",
                    label: _ctx.i18n.$gettext('Speichern'),
                    disabled: !_ctx.name || (_ctx.teamMembers.length < 2 && ![ '3', '4' ].includes(_ctx.projectType)) || !_ctx.project.organizationId,
                    onClick: _withModifiers(_ctx.send, ["prevent","stop"])
                  }, null, 8, ["label", "disabled", "onClick"])
                ])),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_Dropdown, {
                          modelValue: _ctx.projectType,
                          "onUpdate:modelValue": [
                            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.projectType) = $event)),
                            _cache[2] || (_cache[2] = ($event: any) => (_ctx.project.type = Number(_ctx.projectType)))
                          ],
                          options: _ctx.TYPE_OPTIONS,
                          "option-label": "label",
                          "option-value": "type",
                          disabled: !!_ctx.project.id,
                          label: _ctx.i18n.$gettext('Art der Ausschreibung')
                        }, null, 8, ["modelValue", "options", "disabled", "label"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      ((!_ctx.projectType || [ '0', '2' ].includes(_ctx.projectType)) && !_ctx.findField('BT-1311(d)-Lot'))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createVNode(_component_DatePicker, {
                              modelValue: _ctx.project.applicationDeadline,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.project.applicationDeadline) = $event)),
                              "show-time": "",
                              label: _ctx.i18n.$gettext('Teilnahmefrist')
                            }, null, 8, ["modelValue", "label"])
                          ]))
                        : _createCommentVNode("", true),
                      ((!_ctx.projectType || [ '0', '2' ].includes(_ctx.projectType)) && !_ctx.findField('BT-131(d)-Lot'))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createVNode(_component_DatePicker, {
                              modelValue: _ctx.project.offerDeadline,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.project.offerDeadline) = $event)),
                              "show-time": "",
                              label: _ctx.i18n.$gettext('Angebotsfrist')
                            }, null, 8, ["modelValue", "label"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.projectType === '5' && !_ctx.findField('BT-1311(d)-Lot'))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode(_component_DatePicker, {
                              modelValue: _ctx.project.finalDeadline,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.project.finalDeadline) = $event)),
                              "show-time": "",
                              label: _ctx.i18n.$gettext('Finale Teilnahmefrist')
                            }, null, 8, ["modelValue", "label"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.projectType === '5')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_DatePicker, {
                              modelValue: _ctx.project.applicationDeadline,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.project.applicationDeadline) = $event)),
                              "show-time": "",
                              label: _ctx.i18n.$gettext('Teilnahmefrist Runde 1'),
                              required: ""
                            }, null, 8, ["modelValue", "label"])
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createVNode(_component_AnimatedInput, {
                              modelValue: _ctx.project.deadlinePeriodDays,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.project.deadlinePeriodDays) = $event)),
                              label: _ctx.i18n.$gettext('Dauer pro nachfolgender Runde in Tagen'),
                              type: "number",
                              required: ""
                            }, null, 8, ["modelValue", "label"])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (![ '3', '4' ].includes(_ctx.projectType))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("h5", null, [
                          _createVNode(_component_translate, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Sachbearbeiter")
                            ]),
                            _: 1
                          })
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("div", _hoisted_18, [
                            _createVNode(_component_UserPicker, {
                              modelValue: _ctx.teamMembers,
                              "onUpdate:modelValue": [
                                _cache[8] || (_cache[8] = ($event: any) => ((_ctx.teamMembers) = $event)),
                                _ctx.updateTeamMembers
                              ],
                              label: _ctx.i18n.$gettext('Sachbearbeiter'),
                              users: (_ctx.users.data || [])
                            }, null, 8, ["modelValue", "label", "users", "onUpdate:modelValue"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teamRoles, (role, i) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: role.user.email,
                                class: "card d-flex flex-row flex-wrap align-items-center p-2 mb-2"
                              }, [
                                _createElementVNode("div", _hoisted_21, [
                                  _createElementVNode("p", _hoisted_22, [
                                    _createElementVNode("span", _hoisted_23, [
                                      _createVNode(_component_translate, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Name")
                                        ]),
                                        _: 1
                                      }),
                                      _createTextVNode(": ")
                                    ]),
                                    _createTextVNode(_toDisplayString(_ctx.displayName(role.user)), 1)
                                  ]),
                                  _createElementVNode("p", _hoisted_24, [
                                    _createElementVNode("span", _hoisted_25, [
                                      _createVNode(_component_translate, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("E-Mail")
                                        ]),
                                        _: 1
                                      }),
                                      _createTextVNode(": ")
                                    ]),
                                    _createTextVNode(_toDisplayString(role.user.email), 1)
                                  ])
                                ]),
                                _createVNode(_component_Dropdown, {
                                  modelValue: role.role,
                                  "onUpdate:modelValue": ($event: any) => ((role.role) = $event),
                                  options: _ctx.ROLE_OPTIONS,
                                  "option-label": "label",
                                  "option-value": "role"
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]),
                                _createVNode(_component_Button, {
                                  class: "p-button-danger flat p-button-text",
                                  icon: "pi pi-trash",
                                  onClick: ($event: any) => (_ctx.teamRoles.splice(i, 1))
                                }, null, 8, ["onClick"])
                              ]))
                            }), 128)),
                            (_ctx.teamRoles.length < 2)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_26, [
                                  _createVNode(_component_translate, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Das Verfahren benötigt mindestens zwei Sachbearbeiter.")
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : (!_ctx.teamRoles.find(r => r.role === 'ADMIN'))
                                ? (_openBlock(), _createElementBlock("p", _hoisted_27, [
                                    _createVNode(_component_translate, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Das Verfahren benötigt mindestens einen Projekt-Admin.")
                                      ]),
                                      _: 1
                                    })
                                  ]))
                                : _createCommentVNode("", true)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterPanels, (panel, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: panel.contentId + '-' + i,
                      class: "mb-4"
                    }, [
                      (_openBlock(), _createBlock(_component_PanelTemplate, {
                        key: panel.contentId + '-' + i + '-panel',
                        ref_for: true,
                        ref: () => { if (panel.repeatable && (panel.required || _ctx.isLot(panel)) && !panel.added) { _ctx.addDefaultPanel(i, panel) } },
                        id: panel.contentId + '-' + i,
                        panel: panel,
                        project: _ctx.project,
                        index: i,
                        "repeatable-index": _ctx.repeatableIndex(panel),
                        "can-remove": _ctx.canRemove(panel),
                        label: (_ctx.isLot(panel) && !_ctx.hasLots) ? _ctx.i18n.$gettext('Auftragsgegenstand') : undefined,
                        class: _normalizeClass({ 'd-none': panel.repeatable && !panel.added }),
                        onRemoveContent: ($event: any) => (_ctx.project.panels.splice(i, 1)),
                        onCreateOrganization: _ctx.createOrganization,
                        onValueChanged: _ctx.fieldValueChanged,
                        onChooseProperty: _cache[9] || (_cache[9] = p => _ctx.lotToAddPropertyTo = p)
                      }, null, 8, ["id", "panel", "project", "index", "repeatable-index", "can-remove", "label", "class", "onRemoveContent", "onCreateOrganization", "onValueChanged"])),
                      (panel.repeatable && !panel.added)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            class: "p-button-text bg-white",
                            icon: "pi pi-plus",
                            label: _ctx.translateLabel(panel) + ' ' + _ctx.i18n.$gettext('hinzufügen'),
                            onClick: ($event: any) => (_ctx.project.panels.splice(i, 0, _ctx.copyPanel(panel)))
                          }, null, 8, ["label", "onClick"]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128)),
                  _createElementVNode("h4", _hoisted_28, [
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Datenräume")
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("h5", _hoisted_31, [
                        _createVNode(_component_translate, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Informationen für Bieter")
                          ]),
                          _: 1
                        })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("form", {
                        class: "d-flex align-items-center",
                        onSubmit: _cache[11] || (_cache[11] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addInformationRoom && _ctx.addInformationRoom(...args)), ["prevent"]))
                      }, [
                        _createVNode(_component_AnimatedInput, {
                          type: "text",
                          label: _ctx.i18n.$gettext('Raum-Name'),
                          modelValue: _ctx.informationRoomName,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.informationRoomName) = $event)),
                          modelModifiers: { lazy: true, trim: true },
                          "auto-resize": "",
                          required: ""
                        }, null, 8, ["label", "modelValue"]),
                        _createVNode(_component_Button, {
                          type: "submit",
                          class: "p-button-text bg-white flex-shrink-0 ml-3",
                          icon: "pi pi-plus",
                          label: _ctx.i18n.$gettext('Raum hinzufügen')
                        }, null, 8, ["label"])
                      ], 32)
                    ])
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.informationRooms, (room, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: 'informationRoom-' + index,
                      class: "mt-4"
                    }, [
                      _createVNode(_component_RoomDetails, {
                        room: room,
                        "show-delete": "",
                        disabled: "",
                        hint: _ctx.i18n.$gettext('Dokumente können nach dem Speichern des Projekts hochgeladen werden.'),
                        onDelete: ($event: any) => (_ctx.project.informationRooms.splice(index, 1))
                      }, null, 8, ["room", "hint", "onDelete"])
                    ]))
                  }), 128)),
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("div", _hoisted_35, [
                      _createElementVNode("h5", _hoisted_36, [
                        _createVNode(_component_translate, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Vom Bieter zu befüllende Datenräume")
                          ]),
                          _: 1
                        })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("form", {
                        class: "d-flex align-items-center",
                        onSubmit: _cache[13] || (_cache[13] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addRoomTemplate && _ctx.addRoomTemplate(...args)), ["prevent"]))
                      }, [
                        _createVNode(_component_AnimatedInput, {
                          type: "text",
                          label: _ctx.i18n.$gettext('Datenraum-Name'),
                          modelValue: _ctx.roomTemplateName,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.roomTemplateName) = $event)),
                          modelModifiers: { lazy: true, trim: true },
                          "auto-resize": "",
                          required: ""
                        }, null, 8, ["label", "modelValue"]),
                        _createVNode(_component_Button, {
                          type: "submit",
                          class: "p-button-text bg-white flex-shrink-0 ml-3",
                          icon: "pi pi-plus",
                          label: _ctx.i18n.$gettext('Raum hinzufügen')
                        }, null, 8, ["label"])
                      ], 32)
                    ])
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.roomTemplates, (room, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: 'roomTemplate-' + index,
                      class: "mt-4"
                    }, [
                      _createVNode(_component_RoomDetails, {
                        room: room,
                        "is-template": "",
                        "show-delete": "",
                        disabled: "",
                        onDelete: ($event: any) => (_ctx.project.roomTemplates.splice(index, 1))
                      }, null, 8, ["room", "onDelete"])
                    ]))
                  }), 128))
                ])
              ], 32))
            : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_Teleport, { to: "#sidebar" }, [
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("h5", _hoisted_40, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Ausschreibung erstellen")
                ]),
                _: 1
              })
            ]),
            (_ctx.template)
              ? (_openBlock(), _createBlock(_component_SidebarLink, {
                  key: 0,
                  class: "px-4 py-2 clickable-border d-flex align-items-center",
                  link: { hash: '#top', label: _ctx.i18n.$gettext('Beschreibung') }
                }, null, 8, ["link"]))
              : _createCommentVNode("", true),
            (_ctx.template && ![ '3', '4' ].includes(_ctx.projectType))
              ? (_openBlock(), _createBlock(_component_SidebarLink, {
                  key: 1,
                  class: "px-4 py-2 clickable-border d-flex align-items-center",
                  link: { hash: '#sachbearbeiter', label: _ctx.i18n.$gettext('Sachbearbeiter') }
                }, null, 8, ["link"]))
              : _createCommentVNode("", true),
            (!_ctx.template)
              ? (_openBlock(), _createBlock(_component_SidebarLink, {
                  key: 2,
                  class: "px-4 py-2 clickable-border d-flex align-items-center",
                  link: { hash: '#top', label: _ctx.i18n.$gettext('Formular') }
                }, null, 8, ["link"]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuStructure, (link) => {
              return (_openBlock(), _createElementBlock("div", null, [
                _createVNode(_component_SidebarLink, {
                  class: "px-4 py-2 clickable-border d-flex align-items-center",
                  link: link,
                  "show-icon": ""
                }, null, 8, ["link"]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((link.links || []), (sublink) => {
                  return (_openBlock(), _createBlock(_component_SidebarLink, {
                    class: "pl-5 pr-3 py-2 clickable-border d-flex align-items-center",
                    link: sublink,
                    "show-icon": ""
                  }, null, 8, ["link"]))
                }), 256))
              ]))
            }), 256))
          ])
        ])),
        (_openBlock(), _createBlock(_Teleport, { to: "#breadcrumbs" }, [
          _createVNode(_component_Breadcrumb, {
            home: { icon: 'pi pi-home', to: '/' },
            model: _ctx.breadcrumbs
          }, null, 8, ["model"])
        ]))
      ]))
}