import User from '@/model/User';

export default class LoginResult {
  token!: string | null;
  user!: User | null;

  constructor() {
    this.token = null;
    this.user = null;
  }
}
