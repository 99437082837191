import Content from "@/model/common/Content"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"

export default class ContentUtil {

    static getRequiredIncompleteField(content: Content): Content | false {
        if (content.contentType?.toLowerCase() === 'field' &&
            content.required && (!content.repeatable || content.added) &&
            !content.stringValue &&
            !content.dateValue &&
            !content.dateTimeValue &&
            !content.listValue &&
            content.booleanValue === null &&
            content.longValue === null) {
            return content
        } else if (content.children && (!content.repeatable || content.added)) {
            for (const child of content.children) {
                const requiredIncomplete = this.getRequiredIncompleteField(child)
                if (requiredIncomplete && this.fieldVisible(child, content.children || [])) {
                    return requiredIncomplete
                }
            }
        }
        return false
    }

    static hasRequiredField(content: Content): boolean {
        for (const child of (content.children || [])) {
            if (child.contentType?.toLowerCase() === 'field' && child.required && this.fieldVisible(child, content.children || [])) {
                return true
            } else if ((!child.repeatable || child.added) && ['group', 'section'].includes(child.contentType?.toLowerCase() || '') && this.hasRequiredField(child)) {
                return true
            }
        }
        return false
    }

    static hasValuedField(content: Content): boolean {
        if (content.contentType?.toLowerCase() === 'field'&&
            (content.required || content.added ||
                content.stringValue ||
                content.dateValue ||
                content.dateTimeValue ||
                content.listValue ||
                content.booleanValue !== null||
                content.longValue !== null)) {
            return true
        } else if (content.children) {
            for (const child of content.children) {
                if (this.hasValuedField(child)) {
                    return true
                }
            }
        }
        return false
    }

    static fieldVisible(content: Content, otherContents: Content[]) {
        if (content.contentId === 'BT-708-Lot') {
            return ContentUtil.findContent(otherContents, 'OPT-050-Lot')?.stringValue === 'official'
        } else if (content.contentId === 'BT-737-Lot') {
            return ContentUtil.findContent(otherContents, 'OPT-050-Lot')?.stringValue !== 'official'
        } else if (content.contentId === 'BT-615-Lot') {
            return ContentUtil.findContent(otherContents, 'BT-14-Lot')?.stringValue === 'restricted-document'
        } else if (content.contentId === 'BT-15-Lot') {
            return ContentUtil.findContent(otherContents, 'BT-14-Lot')?.stringValue !== 'restricted-document'
        } else {
            return true
        }
    }

    static findContent(contents: Content[], contentId: string, added?: boolean | undefined): Content | null {
        for (const child of contents) {
            if (child.contentId === contentId && (added === undefined || !child.added === !added)) {
                return child
            } else if (child.children) {
                const field: Content | null = this.findContent(child.children, contentId, added)
                if (field) {
                    return field
                }
            }
        }
        return null
    }

    static findField(contents: Content[], contentId: string): Content | null {
        for (const child of contents) {
            if (child.contentType?.toLowerCase() === 'field' && child.contentId === contentId) {
                return child
            } else if (child.children) {
                const field: Content | null = this.findField(child.children, contentId)
                if (field) {
                    return field
                }
            }
        }
        return null
    }

    static findFieldAndSetValue(key: string, value: any, contents: Content[]) {
        const field = ContentUtil.findField(contents, key)
        if (!field) {
            return
        } else if ([ 'COMBOBOX', 'TEXTAREA', 'TEXTBOX', 'TEXT' ].includes(ContentUtil.typeOfField(field) || '')) {
            field.stringValue = value
        } else if ([ 'NUMBER' ].includes(ContentUtil.typeOfField(field) || '')) {
            field.longValue = Number(value)
        } else if ([ 'DATE' ].includes(ContentUtil.typeOfField(field) || '')) {
            field.dateValue = value
        } else if ([ 'DATETIME', 'TIME' ].includes(ContentUtil.typeOfField(field) || '')) {
            field.dateTimeValue = value
        } else if ([ 'CHECKBOX', 'RADIO' ].includes(ContentUtil.typeOfField(field) || '')) {
            field.booleanValue = value === 'true'
        } else if ([ 'MULTICHECKBOX' ].includes(ContentUtil.typeOfField(field) || '')) {
            field.listValue = Array.isArray(value) ? value : [ value ]
        }
    }

    static typeOfField(field: Content): string | null {
        const subType: string = SdkServiceClient.getSubTypeForField(field)

        if (['amount', 'integer', 'number', 'measure'].includes(subType.toLowerCase())) return 'NUMBER'
        if (['date'].includes(subType.toLowerCase())) return 'DATE'
        if (['time'].includes(subType.toLowerCase())) return 'TIME'

        return field.displayType
    }
}
