import Project from "@/model/Project"
import Offer from "@/model/Offer"

export default class ProjectWithInfo extends Project {
    lotsWithInfo: ProjectWithInfo[]
    stageName: string
    canStartApplicationPhase: boolean
    laterRoundActive: boolean
    firstRoundActive: boolean
    offersByRound: { offer: Offer, lotOffer: Offer }[][]
    canOpenFirstRound: boolean
    canStartNegotiationPhase: boolean
    negotiationPhaseActive: boolean
    canStartOfferPhase: boolean
    offerPhaseActive: boolean
    canOpenOffers: boolean
    openDateText: string | null
    openedByText: string | null
    canOpenSecondStep: boolean
    openingInProgress: boolean
    isPublished: boolean
    canApply: boolean
    canOffer: boolean
    lotOffer: Offer | null

    constructor(project: Project, lots: ProjectWithInfo[], stageName: string, canStartApplicationPhase: boolean, laterRoundActive: boolean, firstRoundActive: boolean, offersByRound: { offer: Offer, lotOffer: Offer }[][], canOpenFirstRound: boolean, canStartNegotiationPhase: boolean, negotiationPhaseActive: boolean, canStartOfferPhase: boolean, offerPhaseActive: boolean, canOpenOffers: boolean, openDateText: string | null, openedByText: string | null, canOpenSecondStep: boolean, openingInProgress: boolean, isPublished: boolean, canApply: boolean, canOffer: boolean, lotOffer: Offer | null) {
        super()
        Object.assign(this, project)
        this.lotsWithInfo = lots
        this.stageName = stageName
        this.canStartApplicationPhase = canStartApplicationPhase
        this.laterRoundActive = laterRoundActive
        this.firstRoundActive = firstRoundActive
        this.offersByRound = offersByRound
        this.canOpenFirstRound = canOpenFirstRound
        this.canStartNegotiationPhase = canStartNegotiationPhase
        this.negotiationPhaseActive = negotiationPhaseActive
        this.canStartOfferPhase = canStartOfferPhase
        this.offerPhaseActive = offerPhaseActive
        this.canOpenOffers = canOpenOffers
        this.openDateText = openDateText
        this.openedByText = openedByText
        this.canOpenSecondStep = canOpenSecondStep
        this.openingInProgress = openingInProgress
        this.isPublished = isPublished
        this.canApply = canApply
        this.canOffer = canOffer
        this.lotOffer = lotOffer
    }
}
