import Location from '@/model/gis/Location';

export default class Property {
  id!: number | null;
  name!: string | null;
  description!: string | null;
  plat!: string | null;
  size!: number | null;
  hasBuilding!: boolean | null;
  regulatedInDevelopmentPlan!: boolean | null;
  buildingPlanIsInPreparation!: boolean | null;
  availability!: boolean | null;
  availabilityNotes!: string | null;
  siteDevelopment!: string | null;
  allowedUsageTypes!: string | null;
  additionalFields!: any | null;
  publicFields!: any | null;
  mapMarkers!: any | null;
  panoramas!: any | null;
  images!: any | null;
  geoJson!: string | null;
  isArchived!: boolean | null;
  locationId!: number | null;
  files!: any | null;
  isPublic!: boolean | null;
  internalNotes!: string | null;
  addressDisplayName!: string | null;
  addressOsmType!: string | null;
  addessOsmId!: string | null;
  projectId!: number | null;
  database!: any | null;
  credentialService!: any | null;
  location!: Location | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.description = null;
    this.plat = null;
    this.size = null;
    this.hasBuilding = null;
    this.regulatedInDevelopmentPlan = null;
    this.buildingPlanIsInPreparation = null;
    this.availability = null;
    this.availabilityNotes = null;
    this.siteDevelopment = null;
    this.allowedUsageTypes = null;
    this.additionalFields = null;
    this.publicFields = null;
    this.mapMarkers = null;
    this.panoramas = null;
    this.images = null;
    this.geoJson = null;
    this.isArchived = null;
    this.locationId = null;
    this.files = null;
    this.isPublic = null;
    this.internalNotes = null;
    this.addressDisplayName = null;
    this.addressOsmType = null;
    this.addessOsmId = null;
    this.projectId = null;
    this.database = null;
    this.credentialService = null;
    this.location = null;
  }
}
