

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import Divider from "primevue/divider"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import Property from "@/model/gis/Property"
import InputSwitch from "primevue/inputswitch"
import Dropdown from "@/components/controls/Dropdown.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import LeafletMap from "@/components/common/LeafletMap.vue"
import PhotoSphereViewer from "@/components/common/PhotoSphereViewer.vue"
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import {ref} from "@vue/reactivity"
import {Watch} from "vue-property-decorator"
import MarkdownUtil from "@/util/MarkdownUtil"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import AnimatedNumberInput from "@/components/controls/AnimatedNumberInput.vue"
import Tags from "@/components/common/Tags.vue"
import {rpcClient} from "@/api/WebsocketClient"
import {MenuItem} from "primevue/menuitem"
import Breadcrumb from "primevue/breadcrumb"
import {propertyServiceApi} from "@/api/PropertyServiceApi"
import SWR from '@/api/SWR'
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import ProgressBar from "primevue/progressbar"
import Location from "@/model/gis/Location"
import {locationServiceApi} from "@/api/LocationServiceApi"
import Carousel from "primevue/carousel"
import PanoramaImage from "@/model/gis/PanoramaImage"
import Dialog from "@/components/common/Dialog.vue"
import PropertyFileFolder from "@/model/gis/PropertyFileFolder"
import PropertyFileDisplay from "@/components/properties/PropertyFileDisplay.vue"
import Card from "primevue/card"
import PropertyFolderDisplay from "@/components/properties/PropertyFolderDisplay.vue"
import FileUpload, {FileUploadBeforeUploadEvent} from "primevue/fileupload"
import {getRandomUUID} from "@/util/uuid"
import Galleria from "primevue/galleria"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import Project from "@/model/Project"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import PropertyImage from "@/model/gis/PropertyImage"
import AdditionalField from "@/model/gis/AdditionalField"
import Checkbox from "@/components/controls/Checkbox.vue"
import TriStateCheckbox from "primevue/tristatecheckbox"
import {useConfirm} from "primevue/useconfirm"
import Image from "primevue/image"
import EyeIcon from 'primevue/icons/eye'
import TriStateDropdown from "@/components/controls/TriStateDropdown.vue"
import Tag from "primevue/tag"
import Nominatim, {getSafeDisplayNameOfAdress, NominatimResponse} from "@/util/nominatim"
import Autocomplete from "@/components/controls/AutoComplete.vue"

@Options({
  name: "PropertyDetails",
  components: {
    Autocomplete,
    TriStateDropdown, Tag,
    PropertyFolderDisplay, Image, EyeIcon,
    AnimatedInput, Tags, Button, Divider, TabPanel, TabView, Dropdown, LeafletMap,
    InputSwitch, TipTapTextArea, Breadcrumb, ProgressBar, AnimatedNumberInput, Card, PropertyFileDisplay,
    Carousel, PhotoSphereViewer, Dialog, FileUpload, Galleria, Accordion, AccordionTab, Checkbox, TriStateCheckbox
  }
})
export default class PropertyDetails extends Vue {

  basePath: string = "/api/v1/files/gis/media/"
  i18n: Language = useGettext()
  toast = useToast()
  rpcClient = rpcClient
  confirm = useConfirm()

  editedProperty: Property | null = null
  panorama: PanoramaImage|null = null
  editPanorama: boolean = false
  uploadedImage: PropertyImage|PanoramaImage|null = null
  showPanoramaDialog: boolean = false
  showUploadDialog: boolean = false
  showAddAdditionalFieldDialog: boolean = false
  newAdditionalFieldName: string = ''

  uploadInProgress: boolean = false

  //@ts-ignore
  propertyScroller: HTMLDivElement = ref<HTMLDivElement>(null)

  //@ts-ignore
  editor: TipTapTextArea = ref<TipTapTextArea>(null)
  //@ts-ignore
  leafLetMapRef: LeafletMap = ref<LeafletMap | null>(null)
  //@ts-ignore
  panoramaViewer: PhotoSphereViewer = ref<PhotoSphereViewer | null>(null)

  nominatim: Nominatim = new Nominatim()
  autocompleteInput: { label: string, data: NominatimResponse} | string | null = null

  get propertiesSWR(): SWR<Property[], number[]> {
    return propertyServiceApi.getProperties()
  }

  get properties(): Property[] {
    return this.propertiesSWR.data || []
  }

  get isInternalUser(): boolean{
    return this.rpcClient.isInternalUser
  }

  get locationSuggestions(): { label: string, data: NominatimResponse}[]{
    return this.nominatim.result.map((elem: NominatimResponse) => {
      return {
        label: getSafeDisplayNameOfAdress(elem.address),
        data: elem
      }
    })
  }

  updateLocationSuggestions(input: { query: string }){
    if(this.autocompleteInput) this.nominatim.query(input.query)
  }

  onLocationComplete(event: any){
    //@ts-ignore
    const result: NominatimResponse = this.autocompleteInput.data
    const lat: number = Number.parseFloat(result.lat)
    const lon: number = Number.parseFloat(result.lon)
    this.leafLetMapRef?.flyToPositon(lat, lon, 15)
    if(this.editedProperty) {
      this.editedProperty.addressDisplayName = getSafeDisplayNameOfAdress(result.address)
      this.editedProperty.addressOsmType = result.osm_type
      this.editedProperty.addessOsmId = result.osm_id + ''
    }
  }

  deleteAdditionalField(index: number) {
    if (!this.editedProperty?.additionalFields || index < 0) return
    this.editedProperty.additionalFields.splice(index, 1)
  }

  closeAdditionalFieldDialog() {
    this.showAddAdditionalFieldDialog = false
    this.newAdditionalFieldName = ''
  }

  addAdditionalField() {
    this.showAddAdditionalFieldDialog = false
    if (!this.editedProperty) return
    if (!this.editedProperty.additionalFields) {
      this.editedProperty.additionalFields = []
    }
    const newField: AdditionalField = new AdditionalField()
    newField.name = this.newAdditionalFieldName
    newField.value = ''
    this.editedProperty.additionalFields.push(newField)
    this.newAdditionalFieldName = ''
  }

  additionalFieldIsLink(field: AdditionalField) : boolean {
    if(!field.value) return false
    const urlRegex: RegExp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
    return urlRegex.test(field.value)
  }

  get galleriaItems(): any[] {
    const items: any[] = []
    items.push({type: "map"})
    this.panoramas?.forEach((p: PanoramaImage) => items.push({type: "panorama", panorama: p}))
    this.images?.forEach((p: PropertyImage) => items.push({type: "image", image: p}))
    return items
  }

  get propertyName(): string {
    let name: string = this.property?.name || ''
    if (this.property?.isArchived) {
      name = '(' + this.i18n.$gettext("Archiviert") + ') ' + name
    }
    return name
  }

  get additionalFields(): AdditionalField[] {
    if (this.editedProperty) return this.editedProperty.additionalFields || []
    else return this.property?.additionalFields || []
  }

  get panoramas(): PanoramaImage[] {
    const filterFunction = (image: PanoramaImage) => { return !!image.url }
    if (this.editedProperty) return this.editedProperty.panoramas?.filter(filterFunction) || []
    else return this.property?.panoramas?.filter(filterFunction) || []
  }

  get images(): PropertyImage[] {
    const filterFunction = (image: PropertyImage) => { return !!image.url }
    if (this.editedProperty) return this.editedProperty.images?.filter(filterFunction) || []
    else return this.property?.images?.filter(filterFunction) || []
  }

  showPanorama(panorama: PanoramaImage, edit: boolean = false): void {
    this.editPanorama = edit
    this.panorama = panorama
    this.showPanoramaDialog = true
  }

  savePanorama(): void {
    if (!this.panoramaViewer || !this.panorama || !this.editedProperty?.id) return
    this.panorama.markers = this.panoramaViewer.getMarkers()
    this.updateProperty(this.editedProperty)
  }

  onUploadDone(){
    this.toast.success(this.i18n.$gettext("Datei erfolgreich hochgeladen"))
    if (this.uploadedImage && this.editedProperty) {
      if (!this.editedProperty.panoramas) this.editedProperty.panoramas = []
      if (!this.editedProperty.images) this.editedProperty.images = []
      if (this.uploadedImage instanceof PanoramaImage) {
        this.editedProperty.panoramas.push(this.uploadedImage)
      } else {
        this.editedProperty.images.push(this.uploadedImage)
      }
      this.updateProperty(this.editedProperty, false)
      this.uploadedImage = null
    }
    this.uploadInProgress = false
    this.showUploadDialog = false
  }

  onUploadFailed(){
    this.toast.error(this.i18n.$gettext("Hochladen fehlgeschlagen"))
    //Reset edited property
    this.activateEditMode()
    this.uploadInProgress = false
  }

  interceptUploadAndChangePanoramaFilename(event : FileUploadBeforeUploadEvent) {
    this.interceptUploadAndChangeFilename(event, true)
  }

  interceptUploadAndChangeImageFilename(event : FileUploadBeforeUploadEvent) {
    this.interceptUploadAndChangeFilename(event, false)
  }

  interceptUploadAndChangeFilename(event : FileUploadBeforeUploadEvent, isPanorama: boolean){
    if(!this.editedProperty?.id) return

    //Now create random id:
    const fileId: string = getRandomUUID()

    let fileToUpload: File = event.formData.get('files') as File

    //Create image
    if (isPanorama) {
      this.uploadedImage = new PanoramaImage()
    } else {
      this.uploadedImage = new PropertyImage()
    }
    this.uploadedImage.originalFileName = fileToUpload.name
    this.uploadedImage.fileSizeInBytes = fileToUpload.size
    this.uploadedImage.url = this.basePath + this.editedProperty?.id + "/" + fileId
    //Create file instance that will be uploaded
    fileToUpload = new File([fileToUpload], fileId)
    event.formData.set("files", fileToUpload)

    //Now authorize XHR:
    if (rpcClient.session.token) {
      event.xhr.setRequestHeader('X-Auth-Token', rpcClient.session.token)
    }

    this.uploadInProgress = true
  }

  deletePanorama(panorama: PanoramaImage): void {
    if (!this.editedProperty?.id) return
    const index = this.editedProperty.panoramas.map((p: PanoramaImage) => {
      return p.url
    }).indexOf(panorama.url)
    if (index > -1) {
      this.editedProperty.panoramas.splice(index, 1)
      this.updateProperty(this.editedProperty)
      //TODO delete file
    }
  }

  deleteImage(image: PropertyImage): void {
    if (!this.editedProperty?.id) return
    const index = this.editedProperty.images.map((p: PropertyImage) => {
      return p.url
    }).indexOf(image.url)
    if (index > -1) {
      this.editedProperty.images.splice(index, 1)
      //TODO delete file
    }
  }

  closePanoramaDialog(): void {
    this.panorama = null
    this.showPanoramaDialog = false
  }

  get hasDescription(): boolean {
    if (!this.property || !this.property.description) return false
    return (this.property.description as string).length > 0
  }

  get hasInternalNotes(): boolean {
    if (!this.property || !this.property.internalNotes) return false
    return (this.property.internalNotes as string).length > 0
  }

  get hasLinkedProject(): boolean {
    if (!this.property) return false
    return this.property.projectId != null
  }

  get linkedProject(): Project | null {
    if(!this.property || this.property.projectId === null) return null
    return projectServiceApi.getProject(this.property.projectId).data
  }

  get safeRenderLinkedProjectDescription(): string {
    if(!this.linkedProject || !this.linkedProject.description) return ""
    return MarkdownUtil.safeRenderMarkdown(this.linkedProject.description)
  }

  goToLinkedProject(): void {
    if(this.linkedProject) this.$router.push("/projekte/" + this.linkedProject.id)
  }

  get projects(): Project[] {
    return projectServiceApi.getProjects([]).data || []
  }

  get propertyFiles(): PropertyFileFolder[] {
    return this.editedProperty?.files || this.property?.files || []
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    breadcrumbs.push({
      label: this.i18n.$gettext('Grundstücke'),
      to: '/grundstuecke'
    })
    breadcrumbs.push({
      label: this.property?.name || this.i18n.$gettext("Details"),
      to: '/grundstuecke/' + this.property?.id
    })
    return breadcrumbs
  }

  get locations(): Location[] {
    return this.locationsSWR.data || []
  }

  get locationsSWR(): SWR<Location[], number[]> {
    return locationServiceApi.getLocations(false)
  }

  get safeRenderDescription() {
    return this.property?.description ? MarkdownUtil.safeRenderMarkdown(this.property.description) : ''
  }

  get safeRenderNotes() {
    return this.property?.internalNotes ? MarkdownUtil.safeRenderMarkdown(this.property.internalNotes) : ''
  }

  activateEditMode() {
    const scrollpos = this.propertyScroller.scrollTop
    this.editedProperty = this.property ? JSON.parse(JSON.stringify(this.property)) : new Property()
    this.uploadedImage = null
    //Some animation does weird things, so we need to use window.setTimeout
    if(scrollpos < 25) {
      setTimeout(() => {
        if(this.propertyScroller) {
          const scrollpos2 = this.propertyScroller.scrollTop
          const diff = scrollpos - scrollpos2
          this.propertyScroller.scrollTo({top: diff, behavior: "auto"})
        }
      }, 50)
    }
  }

  deactivateEditMode() {
    this.confirm.require({
      message: this.i18n.$gettext('Wollen Sie die Änderungen wirklich verwerfen?'),
      header: this.i18n.$gettext('Sind Sie sicher?'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if (!this.editedProperty?.id) {
          this.goToPropertyList()
        }
        this.editedProperty = null

        if(this.leafLetMapRef) {
          this.leafLetMapRef.discardChanges()
          this.leafLetMapRef.mapObject?.pm?.disableDraw()
        }
      },
      reject: () => {
        //DO nothing
      }
    })
  }

  applyChanges() {
    if (this.leafLetMapRef) {
      const changes: string | null = this.leafLetMapRef.getGeoJSON()
      if (this.editedProperty && changes) {
        this.editedProperty.geoJson = changes
      }
      this.leafLetMapRef.mapObject?.pm?.disableDraw()
    }

    if (!this.editedProperty) return
    this.updateProperty(this.editedProperty)
    this.editedProperty = null
  }

  updateProperty(property: Property, notifyOnSuccess: boolean = true): void {
    propertyServiceApi._updateProperty(property).then((newId: number) => {
      if(notifyOnSuccess) {
        this.toast.success(this.i18n.$gettext('Das Grundstück wurde aktualisiert.'))
      }
      this.closePanoramaDialog()
      this.leafLetMapRef.refreshMap()
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Das Grundstück konnte nicht aktualisiert werden.'), e.message)
    })
  }

  archiveProperty(){
    this.confirm.require({
      message: this.i18n.$gettext('Wollen Sie das Grundstück wirklich archivieren?'),
      header: this.i18n.$gettext('Sind Sie sicher?'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if(!this.property) return
        this.property.isArchived = true
        return propertyServiceApi._updateProperty(this.property).then(() => {
          this.toast.success(this.i18n.$gettext('Das Grundstück wurde archiviert.'))
        }).catch((e: RpcError) => {
          this.toast.error(this.i18n.$gettext('Das Grundstück konnte nicht archiviert werden.'), e.message)
        })
      },
      reject: () => {
        //DO nothing
      }
    })
  }

  unArchiveProperty() {
    this.confirm.require({
      message: this.i18n.$gettext('Wollen Sie das Grundstück aus dem Archiv wiederherstellen?'),
      header: this.i18n.$gettext('Sind Sie sicher?'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        if(!this.property) return
        this.property.isArchived = false
        return propertyServiceApi._updateProperty(this.property).then(() => {
          this.toast.success(this.i18n.$gettext('Das Grundstück wurde aus dem Archiv wiederhergestellt.'))
        }).catch((e: RpcError) => {
          this.toast.error(this.i18n.$gettext('Das Grundstück konnte nicht wiederhergestellt werden.'), e.message)
        })
      },
      reject: () => {
        //DO nothing
      }
    })
  }

  get buildingStates(): any[] {
    return [
      {label: this.i18n.$gettext("Bebaut"), value: 'BEBAUT'},
      {label: this.i18n.$gettext("Unbebaut"), value: 'UNBEBAUT'},
      {label: this.i18n.$gettext("Unbekannt"), value: ''}
    ]
  }

  get hasBuilding(): boolean {
    return this.property?.hasBuilding === true
  }

  get hasNoBuilding(): boolean {
    return this.property?.hasBuilding === false
  }

  get isInDevelopmentPlan(): boolean {
    return this.property?.regulatedInDevelopmentPlan === true
  }

  get buildingPlanIsInPreparation(): boolean {
    return this.property?.buildingPlanIsInPreparation === true
  }

  get isNotInDevelopmentPlan(): boolean {
    return this.property?.regulatedInDevelopmentPlan === false
  }

  goToPropertyList(): void {
    this.$router.push('/grundstuecke/')
  }

  get sizeFormatted(): string {
    return(this.property?.size?.toLocaleString() || '0') + this.i18n.$gettext('qm')
  }

  get propertySWR(): SWR<Property | null, number> | null {
    const propertyId = this.$route.params.id
    if (propertyId) {
      return propertyServiceApi.getPropertyById(Number.parseInt(propertyId))
    }
    return null
  }

  get property(): Property | null  {
    return this.propertySWR?.data || null
  }

  get editMode(): boolean {
    return this.editedProperty != null
  }

  @Watch('editor')
  watchEditor(newEditor: TipTapTextArea, oldEditor: TipTapTextArea) {
    if (this.editor && this.editedProperty?.description && this.editedProperty.description !== '') {
      this.editor.setContent(this.editedProperty.description)
    } else if (this.editor) {
      this.editor.clearContent()
    }
  }

  showField(name: string): boolean {
    return (this.isInternalUser ? true : this.fieldPublicStatus(name))
  }

  fieldPublicStatus(name: string): boolean {
    const property : Property | null = (this.editedProperty ? this.editedProperty : this.property) || null
    if(!property?.publicFields) return false
    return property.publicFields.indexOf(name) > -1
  }
}

