export default class Document {
  id!: string | null;
  roomId!: number | null;
  name!: string | null;
  date!: string | null;

  constructor() {
    this.id = null;
    this.roomId = null;
    this.name = null;
    this.date = null;
  }
}
