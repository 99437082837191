import Project from "@/model/Project"
import Content from "@/model/common/Content"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"
import ProjectTemplate from "@/model/ProjectTemplate"
import i18n from "@/util/i18n"
import ContentUtil from "@/util/ContentUtil"

export class Link {
    to: string | undefined
    hash: string | undefined
    label: string
    icon: string | null = null
    links: Link[] = []
    constructor(to: string | undefined, hash: string | undefined, label: string) {
        this.to = to
        this.hash = hash
        this.label = label
    }
}

export class MenuStructure {
    static menuStructure(panels: Content[], path?: string | undefined): Link[] {
        const links: Link[] = []
        for (let i = 0; i < (panels || []).length; i++) {
            const content = (panels || [])[i]
            const link = this.menuStructureRecursive(content.contentId + '-' + i, content, path)
            if (link) {
                links.push(link)
            }
        }
        return links
    }

    static menuStructureRecursive(id: string, subsection: Content, path?: string | undefined): Link | null {
        if ((!subsection.repeatable || subsection.added) && [ 'section', 'group' ].includes((subsection.contentType || '').toLowerCase())) {
            const link: Link = new Link(path, '#' + id, SdkServiceClient.getLabelForField(subsection?.label))
            if (ContentUtil.hasRequiredField(subsection)) {
                if (ContentUtil.getRequiredIncompleteField(subsection)) {
                    link.icon = 'pi pi-pencil'
                } else {
                    link.icon = 'pi pi-check-circle'
                }
            }
            for (let i = 0; i < (subsection.children || []).length; i++) {
                const content = (subsection.children || [])[i]
                const subLink = this.menuStructureRecursive(id + '-' + content.contentId + '-' + i, content)
                if (subLink) {
                    link.links.push(subLink)
                }
            }
            return link
        } else {
            return null
        }
    }
}
