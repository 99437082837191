
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import Project from "@/model/Project"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import FileUpload from "primevue/fileupload"
import RoomDetails from "@/components/common/RoomDetails.vue"
import {rpcClient} from "@/api/WebsocketClient"
import Panel from "@/components/admin/Panel.vue"
import Card from "primevue/card"
import TwoFactorForm from "@/components/TwoFactorForm.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import OrganizationCard from "@/components/common/OrganizationCard.vue"
import UserPicker from "@/components/controls/UserPicker.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import Dialog from "@/components/common/Dialog.vue"
import ProgressBar from "primevue/progressbar"
import Divider from "primevue/divider"
import MessageList from "@/components/common/MessageList.vue"
import Message from "primevue/message"
import Offer from "@/model/Offer"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import {Container, Draggable} from "vue3-smooth-dnd"
import Skeleton from "primevue/skeleton"
import dayjs from "@/util/dayjs"
import ProjectWithInfo from "@/util/ProjectWithInfo"
import ProjectInfo from "@/components/common/ProjectInfo.vue"
import {offerServiceApi} from "@/api/OfferServiceApi"

@Options({
  name: "ProjectStages",
  components: {
    MessageList, TwoFactorForm, RoomDetails, AnimatedInput, Button, FileUpload, Message, Accordion, AccordionTab,
    Panel, Card, Checkbox, OrganizationCard, UserPicker, Dialog, TipTapTextArea, ProgressBar, Divider, Container,
    Skeleton, Draggable, ProjectInfo
  },
  //@ts-ignore
  props: {
    project: [ ProjectWithInfo, Object ]
  }
})
export default class ProjectStages extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient
  dayjs = dayjs

  project!: ProjectWithInfo

  loading: number[] = []

  get lots(): ProjectWithInfo[] {
    if (this.project?.lotsWithInfo?.length) {
      return this.project.lotsWithInfo
    } else if (this.project) {
      return [ this.project ]
    } else {
      return []
    }
  }

  openedRounds(project: Project) {
    const rounds = []
    for (let i = 1; i < (project.lastOpenedRound || 0) + 1; i++) {
      rounds.push(i)
    }
    return rounds
  }

  get invitedOrganizations(): { offer: Offer, lotOffer: Offer, readOnly: boolean, visible: boolean }[] {
    return []
  }

  openedApplications(lot: ProjectWithInfo, round: number): { offer: Offer, lotOffer: Offer, readOnly: boolean, visible: boolean }[] {
    return (lot.offersByRound[round] || []).filter((o: { offer: Offer, lotOffer: Offer }) => {
      return (o.lotOffer.stage === 1 || o.lotOffer.stage === 2) && !!o.offer.organization
    }).map(o => { return { offer: o.offer, lotOffer: o.lotOffer, readOnly: false, visible: true }})
  }

  acceptedApplications(lot: ProjectWithInfo, round: number): { offer: Offer, lotOffer: Offer, readOnly: boolean, visible: boolean }[] {
    return (lot.offersByRound[round] || []).filter((o: { offer: Offer, lotOffer: Offer }) => {
      return o.lotOffer.stage === 3 && !!o.offer.organization
    }).map(o => { return { offer: o.offer, lotOffer: o.lotOffer, readOnly: false, visible: true }})
  }

  requestedOffers(lot: ProjectWithInfo, round: number): { offer: Offer, lotOffer: Offer, readOnly: boolean, visible: boolean }[] {
    return (lot.offersByRound[round] || []).filter((o: { offer: Offer, lotOffer: Offer }) => {
      return o.lotOffer.stage && o.lotOffer.stage >= 4 && o.lotOffer.stage <= 7 && !!o.offer.organization
    }).map(o => { return { offer: o.offer, lotOffer: o.lotOffer, readOnly: false, visible: true }})
  }

  receivedOffers(lot: ProjectWithInfo, round: number): { offer: Offer, lotOffer: Offer, readOnly: boolean, visible: boolean }[] {
    return (lot.offersByRound[round] || []).filter((o: { offer: Offer, lotOffer: Offer }) => {
      return o.lotOffer.stage && o.lotOffer.stage >= 6 && o.lotOffer.stage <= 7 && !!o.offer.organization
    }).map(o => { return { offer: o.offer, lotOffer: o.lotOffer, readOnly: false, visible: true }})
  }

  get isProjectEditor() {
    const userName = rpcClient.session.user?.email
    return userName && rpcClient.session.user?.roles?.find((r: string) => r === 'SYSTEM_ADMIN') ||
        (rpcClient.isInternalUser && this.project?.team?.find(r => r.userName === userName))
  }

  onCardDrop(event: any, lot: ProjectWithInfo, round: number, stage: number) {
    const added: boolean = !!event.addedIndex|| event.addedIndex === 0
    const payload: any | null | undefined = event.payload
    if (added && payload && this.shouldAcceptDrop(payload || {}, lot, round, stage)) {
      //TODO confirm stage change, decline current preferred bidders
      const newStage: number = this.offerStageFromProjectStage(stage)
      offerServiceApi._updateOfferStage(payload.offer.id, newStage, [ String(payload.lotOffer.projectId) ])
    }
  }

  onDropReady(stage: number, dropResult: any) {

  }

  shouldAcceptDrop(payload: any, lot: ProjectWithInfo, round: number, stage: number): boolean {
    return payload.lotOffer?.stage && this.offerStageFromProjectStage(stage) &&
        this.isProjectEditor && !this.isLoading(payload) &&
        payload.lotOffer.projectId === lot.id && payload.lotOffer.round === round && (
            (stage === 6 && lot.offerPhaseActive && payload.lotOffer.stage < 4) ||
            (stage === 5 && lot.negotiationPhaseActive && payload.lotOffer.stage < 3)
        )
  }

  offerStageFromProjectStage(projectStage: number): number {
    if (projectStage === 5) {
      return 3
    } else if (projectStage === 6) {
      return 4
    } else {
      return 0
    }
  }

  isLoading(payload: any): boolean {
    return Boolean(payload?.lotOffer?.id && this.loading.includes(payload.lotOffer.id))
  }

}
