import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import ProjectTemplate from '@/model/ProjectTemplate';
import Page from '@/model/Page';
import { projectTemplateStore } from '@/store/ProjectTemplateStore';

export default class GeneratedProjectTemplateServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getProjectTemplates(): Promise<ProjectTemplate[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getProjectTemplates', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const projectTemplates: ProjectTemplate[] = data.map(projectTemplate => Object.assign(new ProjectTemplate(), projectTemplate))
                projectTemplateStore.replaceProjectTemplates(projectTemplates)
                return projectTemplates
            } else return Promise.reject()
        })
    }


    _saveProjectTemplate(projectTemplate: ProjectTemplate): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('saveProjectTemplate', rpcParams, false).then((data: any) => {
            const model = Object.assign(new ProjectTemplate(), data)
            projectTemplateStore.addOrReplaceProjectTemplate(model)
            return model.id
        })
    }

    _getProjectTemplate(id: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getProjectTemplate', rpcParams, false).then((data: any) => {
            const model = Object.assign(new ProjectTemplate(), data)
            projectTemplateStore.addOrReplaceProjectTemplate(model)
            return model.id
        })
    }

    _deleteProjectTemplate(id: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteProjectTemplate', rpcParams, false).then(() => {
            projectTemplateStore.removeProjectTemplate(id)
        })
    }

    getProjectTemplates(refresh?: boolean | number): SWR<ProjectTemplate[], string[]> {
        //@ts-ignore
        const result: SWR<ProjectTemplate[], string[]> = reactive(new SWR<ProjectTemplate[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_getProjectTemplates' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getProjectTemplates[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const projectTemplates: ProjectTemplate[] = []
                for (const id of data) {
                    const projectTemplate: ProjectTemplate | undefined = projectTemplateStore.state.projectTemplates.get(id)
                    if (projectTemplate) {
                        projectTemplates.push(projectTemplate)
                    }
                }
                result.data = projectTemplates
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getProjectTemplates().then((data: ProjectTemplate[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(projectTemplate => projectTemplate.id || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let projectTemplates: ProjectTemplate[] = [...projectTemplateStore.state.projectTemplates.values()]
        
        
        
        result.data = projectTemplates
        return result
    }

    saveProjectTemplate(projectTemplate: ProjectTemplate, refresh?: boolean | number): SWR<ProjectTemplate | null, string> {
        //@ts-ignore
        const result: SWR<ProjectTemplate | null, string> = reactive(new SWR<ProjectTemplate | null, string>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_saveProjectTemplate' + JSON.stringify(args)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_saveProjectTemplate[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: string) => {
                result.data = projectTemplateStore.state.projectTemplates.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._saveProjectTemplate(projectTemplate).then((id: string) => {
                call.data = id
                result.data = projectTemplateStore.state.projectTemplates.get(id) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = projectTemplateStore.state.projectTemplates.get(cached.data) || null
        }
        return result
    }


    getProjectTemplate(id: string, refresh?: boolean | number): SWR<ProjectTemplate | null, string> {
        //@ts-ignore
        const result: SWR<ProjectTemplate | null, string> = reactive(new SWR<ProjectTemplate | null, string>())
        const callId: string = '_getProjectTemplate' + JSON.stringify(id)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined) {
            refresh = 3000
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: string) => {
                result.data = projectTemplateStore.state.projectTemplates.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._getProjectTemplate(id).then((id: string) => {
                call.data = id
                result.data = projectTemplateStore.state.projectTemplates.get(id) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        result.data = projectTemplateStore.state.projectTemplates.get(id) || null
        return result
    }

}
