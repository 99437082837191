
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import {rpcClient} from "@/api/WebsocketClient"
import Sidebar from "@/components/controls/Sidebar.vue"
import SidebarLink from "@/components/common/Link.vue"

@Options({
  name: "AdminSidebar",
  components: {
    Sidebar, Button, SidebarLink
  }
})
export default class AdminSidebar extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient

}
