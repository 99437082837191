
import {Vue, Options} from "vue-class-component"
import {Watch} from "vue-property-decorator"
import Dropdown from "@/components/controls/Dropdown.vue"

@Options({
  name: "TriStateDropdown",
  components: {
    Dropdown
  },
  //@ts-ignore
  props: {
    modelValue: Boolean,
    label: String,
    truthyLabel: String,
    falsyLabel: String,
    noneLabel: String,
    binary: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class Checkbox extends Vue {
  binary!: boolean
  disabled!: boolean
  label!: string
  value!: string
  checked!: any
  modelValue!: any
  internalValue: String = "none"
  default: boolean | null = null

  truthyLabel: string = ""
  falsyLabel: string = ""
  noneLabel: string = ""

  get choices() : {key: string, label: string}[] {
    let result = [
      {
        key: "yes",
        label: this.truthyLabel
      },
      {
        key: "no",
        label: this.falsyLabel
      },
    ]

    if(!this.binary) {
      result.push({
        key: "none",
        label: this.noneLabel
      })
    }
    return result
  }

  initialize(){
    switch (this.modelValue) {
      case true:
        this.internalValue = "yes"
        break
      case false:
        this.internalValue = "no"
        break
      default:
        this.internalValue = this.binary ? "no" : "none"
    }
  }

  @Watch('internalValue')
  handleInput (val: any, oldVal: any) {
    let realValue: boolean | null = this.binary ? false : null
    if(this.internalValue === "yes") {
      realValue  = true
    } else if (this.internalValue === "no") {
      realValue = false
    }
    this.$emit('update:modelValue', realValue)
  }

  @Watch('modelValue')
  onValueChanged (val: any, oldVal: any) {
    this.initialize()
  }

  mounted() {
    this.initialize()
    if(this.binary){
      this.internalValue = "no"
    } else {
      this.internalValue = "none"
    }
  }
}
