import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '../assets/ELLY_Logo_animated_loop_1_fast.svg'
import _imports_1 from '../assets/ELLY_Logo_als_Pfad.svg'


const _hoisted_1 = { class: "container-wide" }
const _hoisted_2 = {
  class: "row align-items-center justify-content-around",
  style: {"min-height":"100vh"}
}
const _hoisted_3 = { class: "col-12 col-lg-6" }
const _hoisted_4 = { class: "card with-circle p-sm-4 p-md-6 shadow-row-element" }
const _hoisted_5 = { class: "card-img-circle" }
const _hoisted_6 = {
  key: 0,
  src: _imports_0
}
const _hoisted_7 = {
  key: 1,
  src: _imports_1
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col mt-4" }
const _hoisted_10 = { class: "mb-2" }
const _hoisted_11 = { class: "text-muted" }
const _hoisted_12 = {
  key: 0,
  class: "row"
}
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = {
  key: 1,
  class: "row"
}
const _hoisted_16 = { class: "col" }
const _hoisted_17 = { class: "row mt-5" }
const _hoisted_18 = { class: "col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_AnimatedInput = _resolveComponent("AnimatedInput")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : (_openBlock(), _createElementBlock("img", _hoisted_7))
          ]),
          _createElementVNode("form", {
            class: "card-body",
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("h1", _hoisted_10, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Uniki")
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("h5", _hoisted_11, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Neues Passwort vergeben.")
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            (_ctx.token)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_AnimatedInput, {
                      id: "login",
                      modelValue: _ctx.newPassword,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPassword) = $event)),
                      modelModifiers: { lazy: true, trim: true },
                      name: "newPassword",
                      type: "password",
                      label: _ctx.i18n.$gettext('Neues Passwort'),
                      required: ""
                    }, null, 8, ["modelValue", "label"])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_AnimatedInput, {
                      modelValue: _ctx.newPasswordRepeat,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPasswordRepeat) = $event)),
                      modelModifiers: { lazy: true, trim: true },
                      name: "newPasswordRepeat",
                      type: "password",
                      label: _ctx.i18n.$gettext('Passwort wiederholen'),
                      required: ""
                    }, null, 8, ["modelValue", "label"])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_AnimatedInput, {
                      modelValue: _ctx.emailOrUserName,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.emailOrUserName) = $event)),
                      modelModifiers: { lazy: true, trim: true },
                      name: "emailOrUserName",
                      type: "text",
                      label: _ctx.i18n.$gettext('E-Mail Adresse'),
                      required: ""
                    }, null, 8, ["modelValue", "label"])
                  ])
                ])),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_Button, {
                  id: "signin",
                  type: "submit",
                  variant: "primary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Absenden")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ])
          ], 32)
        ])
      ])
    ])
  ]))
}