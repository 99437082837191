import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Organization from '@/model/Organization';


export default class GeneratedOrganizationStore {

    state = reactive({
        organizations: new Map<number, Organization>(),
        calls: new Map<string, number | number[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.organizations) this.state.organizations.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceOrganization(newOrganization: Organization) {
        if (newOrganization.id) {
            this.state.organizations.set(newOrganization.id, newOrganization)
            
        }
    }

    addOrReplaceOrganizations(newOrganizations: Organization[]) {
        for (const newOrganization of newOrganizations) {
            this.addOrReplaceOrganization(newOrganization)
        }
    }

    replaceOrganizations(organizations: Organization[]) {
        const newOrganizations = new Map<number, Organization>()
        for (const newOrganization of organizations) {
            if (newOrganization.id) {
                newOrganizations.set(newOrganization.id, newOrganization)
                
            }
        }
        this.state.organizations = newOrganizations
    }

    removeOrganization(id: number | undefined | null) {
        if (id !== undefined && id !== null) {
            this.state.organizations.delete(id)
        }
    }

    removeOrganizations(ids: (number | null)[] | undefined | null) {
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                this.removeOrganization(id)
            }
        }
    }
}