
import {Vue, Options} from "vue-class-component"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import DatePicker from "@/components/controls/DatePicker.vue"
import Card from "primevue/card"
import FieldTemplate from "@/components/admin/FieldTemplate.vue"
import Content from "@/model/common/Content"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import {rpcClient} from "@/api/WebsocketClient"
import Button from "primevue/button"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"
import AccordionTab from "primevue/accordiontab"
import Accordion from "primevue/accordion"
import Project from "@/model/Project"
import Organization from "@/model/Organization"
import {organizationServiceApi} from "@/api/OrganizationServiceApi"
import OrganizationCard from "@/components/common/OrganizationCard.vue"
import SWR from "@/api/SWR"
import ContentUtil from "@/util/ContentUtil"
import {propertyServiceApi} from "@/api/PropertyServiceApi"
import Property from "@/model/gis/Property"
import ProgressBar from "primevue/progressbar"
import Dialog from "primevue/dialog"

@Options({
  name: "PanelTemplate",
  components: {
    AnimatedInput, DatePicker, Card, FieldTemplate, Button, Accordion, AccordionTab, OrganizationCard, ProgressBar, Dialog
  },
  //@ts-ignore
  props: {
    panel: [ Content, Object ],
    index: {
      type: [ String, Number ],
      default: -1
    },
    repeatableIndex: Number,
    id: String,
    active: Boolean,
    canRemove: Boolean,
    label: String,
    project: [ Project, Object ]
  },
  emits: [
    'remove-content', 'create-organization', 'value-changed', 'choose-property'
  ]
})
export default class PanelTemplate extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient

  panel!: Content
  project!: Project
  index!: number
  repeatableIndex!: number
  showPropertyChooserDialog: boolean = false

  copyPanel(panel: Content): Content {
    const newContent = Object.assign(new Content(), JSON.parse(JSON.stringify(panel)))
    newContent.added = true
    return newContent
  }

  addDefaultContent(i: number, panel: Content) {
    if (!this.children.find(c => c.added && c.contentId === panel.contentId)) {
      const copy: Content = this.copyPanel(panel)
      this.panel.children?.splice(i, 0, copy)
    }
  }

  get labelTranslated(): string {
    return this.panel ? SdkServiceClient.getLabelForField(this.panel?.label) : ''
  }

  translateLabel(panel: Content): string {
    return SdkServiceClient.getLabelForField(panel?.label)
  }

  get labelConditional() {
    if (this.isLot) {
      let label: string = this.labelTranslated + ' ' + (this.findLotId(this.panel) || this.repeatableIndex || '')
      const title = this.findLotTitle(this.panel)
      if (title) {
        label = label + ': ' + title
      }
      return label
    } else {
      return this.labelTranslated
    }
  }

  findLotId(content: Content): string | null {
    if (content.contentId === 'BT-22-Lot') {
      return content.stringValue || ''
    } else if (content.children) {
      for (const child of content.children) {
        const title = this.findLotId(child)
        if (title !== null) {
          return title
        }
      }
    }
    return null
  }

  findLotTitle(content: Content): string | null {
    if (content.contentId === 'BT-21-Lot') {
      return content.stringValue || ''
    } else if (content.children) {
      for (const child of content.children) {
        const title = this.findLotTitle(child)
        if (title !== null) {
          return title
        }
      }
    }
    return null
  }

  get required(): boolean {
    return this.panel && ContentUtil.hasRequiredField(this.panel)
  }

  get requiredIncomplete(): any {
    return this.required && ContentUtil.getRequiredIncompleteField(this.panel)
  }

  get requiredComplete(): boolean {
    return this.required && !ContentUtil.getRequiredIncompleteField(this.panel)
  }

  get filterChildren(): Content[] {
    return this.applyFilter(this.panel?.children)
  }

  get children(): Content[] {
    const filterChildren = this.filterChildren
    if (this.project?.templateId?.startsWith('EU-') &&
        filterChildren.length === 1 && !filterChildren[0].repeatable &&
        filterChildren[0].contentType?.toLowerCase() === 'group') {
      return this.applyFilter(filterChildren[0].children)
    } else {
      return filterChildren
    }
  }

  get organizations(): SWR<Organization[], number[]> {
    return organizationServiceApi.getOrganizations('CLIENT')
  }

  get clientOrganization(): Organization | null {
    const id = this.project?.organizationId
    return id ? this.organizations.data?.find(o => String(o.id) === String(id)) || null : null
  }

  get isLot(): boolean {
    return [ 'GR-Lot', 'GR-Part' ].includes(this.panel?.contentId || '')
  }

  get lots(): Project[] | null {
    if (this.project?.lots?.length) {
      return this.project.lots
    } else if (this.project?.id) {
      return [ this.project ]
    } else {
      return null
    }
  }

  get lot(): Project | undefined {
    if (this.isLot && this.lots) {
      const lotId: string | null | undefined = ContentUtil.findContent(this.children, 'BT-22-Lot')?.stringValue
      if (lotId) {
        return this.lots.find((lot: Project) => {
          return lotId === ContentUtil.findContent(lot.panels || [], 'BT-22-Lot')?.stringValue
        })
      }
    }
  }

  get properties(): SWR<Property[], number[]> {
    return propertyServiceApi.getProperties()
  }

  get linkedProperties(): Property[] {
    let propertyIds
    if (this.lot) {
      propertyIds = this.lot.propertyIds || []
      this.panel.propertyIds = propertyIds
    } else {
      propertyIds = this.panel.propertyIds || []
    }
    return propertyIds.map((id: number) => propertyServiceApi.getPropertyById(id).data).filter((p: any) => !!p) as Property[]
  }

  addProperty(property: Property) {
    if (!this.panel.propertyIds) {
      this.panel.propertyIds = this.lot?.propertyIds || []
    }
    if (property.id && !this.panel.propertyIds.includes(property.id)) {
      this.panel.propertyIds.push(property.id)
    }
    if (this.lot) {
      this.lot.propertyIds = this.panel.propertyIds
    }
  }

  removeProperty(property: Property) {
    if (!this.panel.propertyIds) {
      this.panel.propertyIds = this.lot?.propertyIds || []
    }
    if (property.id) {
      this.panel.propertyIds = this.panel.propertyIds.filter(id => String(id) !== String(property.id))
    }
    if (this.lot) {
      this.lot.propertyIds = this.panel.propertyIds
    }
  }

  contentIsLot(content: Content): boolean {
    return [ 'GR-Lot', 'GR-Part' ].includes(content.contentId || '')
  }

  applyFilter(contents: Content[] | null | undefined): Content[] {
    return (contents || []).filter((p: Content) => {
      return ![
        'meta-data-group',
        'GR-Change',
        'GR-LotsGroup',
        'GR-Organisations-Section',
        'GR-Procedure-GroupComposition'
      ].includes(p.contentId || '')
    })
  }

  canRemoveChild(content: Content) {
    return content.repeatable && (this.children.filter(p => p.contentId === content.contentId && p.repeatable && p.added)?.length || 0) > ((content.required || this.contentIsLot(content)) ? 1 : 0)
  }

  childRepeatableIndex(content: Content) {
    return this.children.filter(p => p.contentId === content.contentId && (!p.repeatable || p.added)).indexOf(content) + 1
  }

  sizeFormatted(size: number | null): string {
    if (!size) {
      return this.i18n.$gettext("Unbekannt")
    } else {
      return size.toLocaleString() + this.i18n.$gettext('qm')
    }
  }

  fieldVisible(content: Content) {
    return ContentUtil.fieldVisible(content, this.panel?.children || [])
  }

  mounted() {
    void SdkServiceClient.fetchAll(this.i18n.current)
  }

}
