import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3be3d470"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "textarea p-inputtext p-0 d-flex flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "flex-shrink-0 flex-grow-0 d-flex flex-row justify-content-between"
}
const _hoisted_3 = {
  key: 0,
  class: "flex-shrink-1 compose-buttons-format"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_editor_content = _resolveComponent("editor-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.editor)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.showToolBar)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_Button, {
                  icon: "cil-bold",
                  class: _normalizeClass(["p-button-text p-button-secondary flat", { 'is-active': _ctx.editor.isActive('bold') }]),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editor.chain().focus().toggleBold().run()))
                }, null, 8, ["class"]),
                _createVNode(_component_Button, {
                  icon: "cil-italic",
                  class: _normalizeClass(["p-button-text p-button-secondary flat", { 'is-active': _ctx.editor.isActive('italic') }]),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editor.chain().focus().toggleItalic().run()))
                }, null, 8, ["class"]),
                _createVNode(_component_Button, {
                  icon: "cil-strikethrough",
                  class: _normalizeClass(["p-button-text p-button-secondary flat", { 'is-active': _ctx.editor.isActive('strike') }]),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editor.chain().focus().toggleStrike().run()))
                }, null, 8, ["class"]),
                _createVNode(_component_Button, {
                  icon: "cil-list",
                  class: _normalizeClass(["p-button-text p-button-secondary flat", { 'is-active': _ctx.editor.isActive('bulletList') }]),
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editor.chain().focus().toggleBulletList().run()))
                }, null, 8, ["class"]),
                _createVNode(_component_Button, {
                  icon: "cil-list-numbered",
                  class: _normalizeClass(["p-button-text p-button-secondary flat", { 'is-active': _ctx.editor.isActive('orderedList') }]),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editor.chain().focus().toggleOrderedList().run()))
                }, null, 8, ["class"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_editor_content, {
      class: "p-0 flex-shrink-0 flex-grow-1 tiptap-content",
      editor: _ctx.editor,
      style: {"cursor":"text"},
      onClick: _ctx.focus
    }, null, 8, ["editor", "onClick"])
  ]))
}