import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "py-3"
}
const _hoisted_2 = { class: "h5 font-weight-bold mb-3 ml-4" }
const _hoisted_3 = { class: "d-flex flex-column mb-4" }
const _hoisted_4 = { class: "h5 font-weight-bold mb-3 ml-4" }
const _hoisted_5 = { class: "d-flex flex-column mb-4" }
const _hoisted_6 = { class: "h5 font-weight-bold mb-3 ml-4" }
const _hoisted_7 = { class: "d-flex flex-column mb-4" }
const _hoisted_8 = { class: "h5 font-weight-bold mb-3 ml-4" }
const _hoisted_9 = { class: "d-flex flex-column mb-4" }
const _hoisted_10 = { class: "h5 font-weight-bold mb-3 ml-4" }
const _hoisted_11 = { class: "d-flex flex-column mb-4" }
const _hoisted_12 = {
  key: 1,
  class: "py-3"
}
const _hoisted_13 = { class: "h5 font-weight-bold mb-3 ml-4" }
const _hoisted_14 = { class: "d-flex flex-column mb-4" }
const _hoisted_15 = { class: "h5 font-weight-bold mb-3 ml-4" }
const _hoisted_16 = { class: "d-flex flex-column mb-4" }
const _hoisted_17 = {
  key: 0,
  class: "h5 font-weight-bold mb-3 ml-4"
}
const _hoisted_18 = {
  key: 1,
  class: "d-flex flex-column mb-4"
}
const _hoisted_19 = {
  key: 2,
  class: "h5 font-weight-bold mb-3 ml-4"
}
const _hoisted_20 = {
  key: 3,
  class: "d-flex flex-column mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_SidebarLink = _resolveComponent("SidebarLink")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createBlock(_component_Sidebar, {
    class: _normalizeClass({ 'd-none': !_ctx.rpcClient.fullyLoggedIn }),
    visible: true,
    dismissable: false,
    modal: false,
    style: {"overflow-y":"auto"}
  }, {
    default: _withCtx(() => [
      (_ctx.rpcClient.isInternalUser)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Ausschreibungen")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/projekte', hash: '#öffentlich', label: _ctx.i18n.$gettext('Öffentliche') }
              }, null, 8, ["link"]),
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/projekte', hash: '#entwurf', label: _ctx.i18n.$gettext('Entwürfe') }
              }, null, 8, ["link"]),
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/projekte', hash: '#archiv', label: _ctx.i18n.$gettext('Archiv') }
              }, null, 8, ["link"])
            ]),
            _createElementVNode("p", _hoisted_4, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Grundstücke")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/standorte', hash: '#orte', label: _ctx.i18n.$gettext('Standorte') }
              }, null, 8, ["link"]),
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/grundstuecke', hash: '#liste', label: _ctx.i18n.$gettext('Liste') }
              }, null, 8, ["link"]),
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/grundstuecke', hash: '#karte', label: _ctx.i18n.$gettext('Karte') }
              }, null, 8, ["link"])
            ]),
            _createElementVNode("p", _hoisted_6, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Organisationen")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/auftraggeber', label: _ctx.i18n.$gettext('Auftraggeber') }
              }, null, 8, ["link"]),
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/bieter', label: _ctx.i18n.$gettext('Bieter') }
              }, null, 8, ["link"])
            ]),
            _createElementVNode("p", _hoisted_8, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Benutzer")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/benutzer/intern', label: _ctx.i18n.$gettext('Interne Benutzer') }
              }, null, 8, ["link"]),
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/benutzer', label: _ctx.i18n.$gettext('Externe Benutzer') }
              }, null, 8, ["link"])
            ]),
            _createElementVNode("p", _hoisted_10, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("System")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_11, [
              (_ctx.rpcClient.isSystemAdmin)
                ? (_openBlock(), _createBlock(_component_SidebarLink, {
                    key: 0,
                    class: "px-4 py-2",
                    link: { to: '/audit-log', label: _ctx.i18n.$gettext('Audit-Log') }
                  }, null, 8, ["link"]))
                : _createCommentVNode("", true),
              (_ctx.rpcClient.isSystemAdmin)
                ? (_openBlock(), _createBlock(_component_SidebarLink, {
                    key: 1,
                    class: "px-4 py-2",
                    link: { to: '/einstellungen', label: _ctx.i18n.$gettext('Einstellungen') }
                  }, null, 8, ["link"]))
                : _createCommentVNode("", true),
              (_ctx.rpcClient.isSystemAdmin)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(["collapse-menu", { show: ['/einstellungen','/formulare'].includes(_ctx.$route.path)  }])
                  }, [
                    _createVNode(_component_SidebarLink, {
                      class: "px-4 py-2",
                      link: { to: '/formulare', label: _ctx.i18n.$gettext('Formulare') },
                      style: {"padding-left":"3rem !important"}
                    }, null, 8, ["link"])
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.rpcClient.isSystemAdmin)
                ? (_openBlock(), _createBlock(_component_SidebarLink, {
                    key: 3,
                    class: "px-4 py-2",
                    link: { to: '/grundstueckseinstellungen', label: _ctx.i18n.$gettext('Grundstückseinstellungen') }
                  }, null, 8, ["link"]))
                : _createCommentVNode("", true),
              (_ctx.rpcClient.isSystemAdmin)
                ? (_openBlock(), _createBlock(_component_SidebarLink, {
                    key: 4,
                    class: "px-4 py-2",
                    link: { to: '/changelog', label: _ctx.i18n.$gettext('Versionshinweise') }
                  }, null, 8, ["link"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Ausschreibungen")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/projekte', hash: '#öffentlich', label: _ctx.i18n.$gettext('Öffentliche Ausschreibungen') }
              }, null, 8, ["link"]),
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/projekte', hash: '#merkliste', label: _ctx.i18n.$gettext('Merkliste') }
              }, null, 8, ["link"]),
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/projekte', hash: '#einladung', label: _ctx.i18n.$gettext('Einladungen') }
              }, null, 8, ["link"]),
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/projekte', hash: '#teilnahme', label: _ctx.i18n.$gettext('Teilnahmen') }
              }, null, 8, ["link"]),
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/projekte', hash: '#bewerbung', label: _ctx.i18n.$gettext('Teilnahmeanträge') }
              }, null, 8, ["link"]),
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/projekte', hash: '#archiv', label: _ctx.i18n.$gettext('Archiv') }
              }, null, 8, ["link"])
            ]),
            _createElementVNode("p", _hoisted_15, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Grundstücke")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/grundstuecke', hash: '#liste', label: _ctx.i18n.$gettext('Liste') }
              }, null, 8, ["link"]),
              _createVNode(_component_SidebarLink, {
                class: "px-4 py-2",
                link: { to: '/grundstuecke', hash: '#karte', label: _ctx.i18n.$gettext('Karte') }
              }, null, 8, ["link"])
            ]),
            (_ctx.rpcClient.isOrganizationAdmin)
              ? (_openBlock(), _createElementBlock("p", _hoisted_17, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Unternehmenskonto")
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.rpcClient.isOrganizationAdmin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createVNode(_component_SidebarLink, {
                    class: "px-4 py-2",
                    link: { to: '/organisation', label: _ctx.i18n.$gettext('Unternehmensdaten') }
                  }, null, 8, ["link"]),
                  _createVNode(_component_SidebarLink, {
                    class: "px-4 py-2",
                    link: { to: '/benutzer', label: _ctx.i18n.$gettext('Benutzer') }
                  }, null, 8, ["link"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.rpcClient.fullyLoggedIn && !_ctx.rpcClient.isOrganizationAdmin)
              ? (_openBlock(), _createElementBlock("p", _hoisted_19, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Benutzerkonto")
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.rpcClient.fullyLoggedIn && !_ctx.rpcClient.isOrganizationAdmin)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createVNode(_component_SidebarLink, {
                    class: "px-4 py-2",
                    link: { to: '/benutzer/' + _ctx.rpcClient.session.user.email, label: _ctx.i18n.$gettext('Eigene Daten') }
                  }, null, 8, ["link"])
                ]))
              : _createCommentVNode("", true)
          ]))
    ]),
    _: 1
  }, 8, ["class"]))
}