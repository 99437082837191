import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createSlots as _createSlots, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-369d9aa1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-100 w-100 flex-shrink-1 flex-grow-1 d-flex flex-column card bg-white" }
const _hoisted_2 = {
  key: 1,
  class: "w-100 h-100 d-flex flex-column"
}
const _hoisted_3 = { class: "flex-shrink-0 d-flex flex-row justify-content-between align-items-center py-4 ml-4 mr-5" }
const _hoisted_4 = {
  key: 0,
  class: "mb-0 text-primary"
}
const _hoisted_5 = {
  key: 1,
  class: "w-100 pr-4"
}
const _hoisted_6 = { class: "flex-shrink-0" }
const _hoisted_7 = {
  class: "flex-grow-1 px-4",
  ref: "propertyScroller",
  style: {"overflow":"auto"}
}
const _hoisted_8 = { class: "row flex-shrink-0 mb-4" }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = {
  key: 0,
  class: "row mb-4"
}
const _hoisted_11 = { class: "p-image p-component p-image-preview-container" }
const _hoisted_12 = ["src"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 0,
  class: "col-auto d-flex justify-content-center align-items-center"
}
const _hoisted_15 = { class: "d-flex flex-row w-100 align-items-center" }
const _hoisted_16 = { class: "flex-grow-1" }
const _hoisted_17 = { class: "mb-0" }
const _hoisted_18 = {
  key: 0,
  class: "flex-shrink-0"
}
const _hoisted_19 = {
  key: 0,
  class: "row mb-2"
}
const _hoisted_20 = { class: "col-6 col-md-4" }
const _hoisted_21 = { class: "col-6 col-md" }
const _hoisted_22 = {
  key: 0,
  class: "p-float-label d-flex align-items-center"
}
const _hoisted_23 = { key: 1 }
const _hoisted_24 = {
  key: 1,
  class: "row mb-2"
}
const _hoisted_25 = { class: "col-6 col-md-4" }
const _hoisted_26 = { class: "col" }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = {
  key: 2,
  class: "row mb-2"
}
const _hoisted_29 = { class: "col-6 col-md-4" }
const _hoisted_30 = { class: "col" }
const _hoisted_31 = {
  key: 0,
  class: "p-inputgroup"
}
const _hoisted_32 = { class: "p-float-label" }
const _hoisted_33 = { key: 1 }
const _hoisted_34 = {
  key: 0,
  class: "col-2 d-flex flex-row-reverse align-items-center"
}
const _hoisted_35 = {
  key: 3,
  class: "row mb-2"
}
const _hoisted_36 = { class: "col-6 col-md-4" }
const _hoisted_37 = { class: "col" }
const _hoisted_38 = { key: 1 }
const _hoisted_39 = {
  key: 0,
  class: "col-2 d-flex flex-row-reverse align-items-center"
}
const _hoisted_40 = {
  key: 4,
  class: "row mb-2"
}
const _hoisted_41 = { class: "col-6 col-md-4" }
const _hoisted_42 = { class: "col" }
const _hoisted_43 = { key: 1 }
const _hoisted_44 = {
  key: 0,
  class: "col-2 d-flex flex-row-reverse align-items-center"
}
const _hoisted_45 = {
  key: 5,
  class: "row mb-2"
}
const _hoisted_46 = { class: "col-6 col-md-4" }
const _hoisted_47 = { class: "col" }
const _hoisted_48 = {
  key: 0,
  class: "p-field-checkbox"
}
const _hoisted_49 = { for: "hasbuildingcheckbox" }
const _hoisted_50 = { key: 1 }
const _hoisted_51 = {
  key: 0,
  class: "col-2 d-flex flex-row-reverse align-items-center"
}
const _hoisted_52 = {
  key: 6,
  class: "row mb-2"
}
const _hoisted_53 = { class: "col-6 col-md-4" }
const _hoisted_54 = { class: "col" }
const _hoisted_55 = {
  key: 0,
  class: "p-field-checkbox"
}
const _hoisted_56 = { for: "hasbuildingcheckbox" }
const _hoisted_57 = { key: 1 }
const _hoisted_58 = {
  key: 0,
  class: "col-2 d-flex flex-row-reverse align-items-center"
}
const _hoisted_59 = {
  key: 7,
  class: "row mb-2"
}
const _hoisted_60 = { class: "col-6 col-md-4" }
const _hoisted_61 = { class: "col" }
const _hoisted_62 = {
  key: 0,
  class: "p-field-checkbox"
}
const _hoisted_63 = { for: "hasbuildingcheckbox" }
const _hoisted_64 = { key: 1 }
const _hoisted_65 = {
  key: 0,
  class: "col-2 d-flex flex-row-reverse align-items-center"
}
const _hoisted_66 = {
  key: 8,
  class: "row mb-2"
}
const _hoisted_67 = { class: "col-6 col-md-4" }
const _hoisted_68 = { class: "col" }
const _hoisted_69 = {
  key: 0,
  class: "d-flex flex-row w-100 align-items-center"
}
const _hoisted_70 = { class: "flex-shrink-0 pr-1" }
const _hoisted_71 = { class: "flex-grow-1" }
const _hoisted_72 = { key: 1 }
const _hoisted_73 = { key: 2 }
const _hoisted_74 = {
  key: 0,
  class: "col-2 d-flex flex-row-reverse align-items-center"
}
const _hoisted_75 = {
  key: 9,
  class: "row mb-2"
}
const _hoisted_76 = { class: "col-6 col-md-4" }
const _hoisted_77 = { class: "col" }
const _hoisted_78 = { key: 1 }
const _hoisted_79 = {
  key: 0,
  class: "col-2 d-flex flex-row-reverse align-items-center"
}
const _hoisted_80 = {
  key: 10,
  class: "row mb-2"
}
const _hoisted_81 = { class: "col-6 col-md-4" }
const _hoisted_82 = { class: "col" }
const _hoisted_83 = { key: 1 }
const _hoisted_84 = {
  key: 0,
  class: "col-2 d-flex flex-row-reverse align-items-center"
}
const _hoisted_85 = { class: "col-6 col-md-4" }
const _hoisted_86 = {
  key: 0,
  class: "col"
}
const _hoisted_87 = { key: 0 }
const _hoisted_88 = ["href"]
const _hoisted_89 = {
  key: 1,
  class: "col"
}
const _hoisted_90 = {
  key: 2,
  class: "col-auto"
}
const _hoisted_91 = {
  key: 3,
  class: "col-2 d-flex flex-row-reverse align-items-center"
}
const _hoisted_92 = { class: "w-100 d-flex align-items-center" }
const _hoisted_93 = { class: "mb-0 flex-grow-1" }
const _hoisted_94 = {
  key: 0,
  class: "col-2 flex-shrink-0 d-flex flex-row-reverse align-items-center"
}
const _hoisted_95 = ["innerHTML"]
const _hoisted_96 = { class: "mb-0" }
const _hoisted_97 = ["innerHTML"]
const _hoisted_98 = { class: "d-flex flex-row w-100 align-items-center" }
const _hoisted_99 = { class: "flex-grow-1" }
const _hoisted_100 = { class: "mb-0" }
const _hoisted_101 = {
  key: 0,
  class: "flex-shrink-0"
}
const _hoisted_102 = {
  key: 0,
  class: "p-2"
}
const _hoisted_103 = { class: "row" }
const _hoisted_104 = { class: "col-12" }
const _hoisted_105 = { class: "row" }
const _hoisted_106 = ["innerHTML"]
const _hoisted_107 = {
  key: 1,
  class: "p-2"
}
const _hoisted_108 = { class: "row" }
const _hoisted_109 = { class: "col-12" }
const _hoisted_110 = { class: "lead mb-0" }
const _hoisted_111 = { class: "row" }
const _hoisted_112 = { class: "col" }
const _hoisted_113 = { class: "lead mb-0" }
const _hoisted_114 = { class: "lead mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_AnimatedInput = _resolveComponent("AnimatedInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_LeafletMap = _resolveComponent("LeafletMap")!
  const _component_Image = _resolveComponent("Image")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_translate = _resolveComponent("translate")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Autocomplete = _resolveComponent("Autocomplete")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_AnimatedNumberInput = _resolveComponent("AnimatedNumberInput")!
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")!
  const _component_AccordionTab = _resolveComponent("AccordionTab")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_TipTapTextArea = _resolveComponent("TipTapTextArea")!
  const _component_PropertyFolderDisplay = _resolveComponent("PropertyFolderDisplay")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_PhotoSphereViewer = _resolveComponent("PhotoSphereViewer")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.propertySWR?.call?.loading || _ctx.locationsSWR?.call?.loading)
      ? (_openBlock(), _createBlock(_component_ProgressBar, {
          key: 0,
          mode: "indeterminate"
        }))
      : _createCommentVNode("", true),
    (_ctx.property || _ctx.editedProperty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.property && !_ctx.editedProperty)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(_ctx.propertyName), 1))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_AnimatedInput, {
                    modelValue: _ctx.editedProperty.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editedProperty.name) = $event)),
                    class: "w-100",
                    type: "text",
                    label: _ctx.i18n.$gettext('Name')
                  }, null, 8, ["modelValue", "label"])
                ])),
            _createElementVNode("div", _hoisted_6, [
              (!_ctx.editedProperty && _ctx.rpcClient.isInternalUser && !_ctx.property.isArchived)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    class: "p-button-text flat mr-3",
                    label: _ctx.i18n.$gettext('Archivieren'),
                    onClick: _ctx.archiveProperty
                  }, null, 8, ["label", "onClick"]))
                : _createCommentVNode("", true),
              (!_ctx.editedProperty && _ctx.rpcClient.isInternalUser && !_ctx.property.isArchived)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    label: _ctx.i18n.$gettext('Bearbeiten'),
                    onClick: _ctx.activateEditMode
                  }, null, 8, ["label", "onClick"]))
                : _createCommentVNode("", true),
              (_ctx.rpcClient.isInternalUser && _ctx.property.isArchived)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 2,
                    class: "p-button-text flat ml-3",
                    label: _ctx.i18n.$gettext('Wiederherstellen'),
                    onClick: _ctx.unArchiveProperty
                  }, null, 8, ["label", "onClick"]))
                : _createCommentVNode("", true),
              (_ctx.editedProperty)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 3,
                    class: "p-button-text flat mr-3",
                    label: _ctx.i18n.$gettext('Abbrechen'),
                    onClick: _ctx.deactivateEditMode
                  }, null, 8, ["label", "onClick"]))
                : _createCommentVNode("", true),
              (_ctx.editedProperty)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 4,
                    class: "p-button-success",
                    label: _ctx.i18n.$gettext('Übernehmen'),
                    onClick: _ctx.applyChanges
                  }, null, 8, ["label", "onClick"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_LeafletMap, {
                  ref: "leafLetMapRef",
                  class: "card flat",
                  style: {"min-height":"500px","height":"40vh","width":"100%","display":"block"},
                  property: _ctx.editedProperty || _ctx.property,
                  properties: _ctx.properties,
                  locations: _ctx.locations,
                  "only-show-properties": true,
                  editmode: _ctx.editMode,
                  panoramas: _ctx.panoramas,
                  onShowpanorama: _ctx.showPanorama
                }, null, 8, ["property", "properties", "locations", "editmode", "panoramas", "onShowpanorama"])
              ])
            ]),
            (_ctx.images.length > 0 || _ctx.panoramas.length > 0 || !!_ctx.editedProperty)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: image.url,
                      class: "col-auto position-relative"
                    }, [
                      (_ctx.editedProperty)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            class: "p-button-primary p-button-edge p-button-sm",
                            icon: "cil cil-trash",
                            onClick: ($event: any) => (_ctx.deleteImage(image))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Image, {
                        src: image.url,
                        alt: image.originalFileName,
                        height: "80",
                        preview: ""
                      }, null, 8, ["src", "alt"])
                    ]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.panoramas, (panorama) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: panorama.url,
                      class: "col-auto position-relative"
                    }, [
                      (_ctx.editedProperty)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            class: "p-button-primary p-button-edge p-button-sm",
                            icon: "cil cil-trash",
                            onClick: ($event: any) => (_ctx.deletePanorama(panorama))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (_ctx.editedProperty)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 1,
                            class: "p-button-primary p-button-edge p-button-edge-bottom p-button-sm",
                            icon: "cil cil-pen",
                            onClick: ($event: any) => (_ctx.showPanorama(panorama, true))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("img", {
                          src: panorama.url,
                          style: {"max-height":"80px"}
                        }, null, 8, _hoisted_12),
                        _createElementVNode("button", {
                          ref_for: true,
                          ref: "previewButton",
                          class: "p-image-preview-indicator",
                          onClick: ($event: any) => (_ctx.showPanorama(panorama, false))
                        }, [
                          _createVNode(_component_EyeIcon, { class: "p-image-preview-icon" })
                        ], 8, _hoisted_13)
                      ])
                    ]))
                  }), 128)),
                  (_ctx.editedProperty)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createVNode(_component_Button, {
                          icon: "cil-plus",
                          label: _ctx.i18n.$gettext('Bild hochladen'),
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showUploadDialog = true))
                        }, null, 8, ["label"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_Accordion, {
              "active-index": 0,
              class: "mb-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AccordionTab, null, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("h4", _hoisted_17, [
                          _createVNode(_component_translate, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Steckbrief")
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      (_ctx.editedProperty)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                            _createVNode(_component_Button, {
                              icon: "cil-plus",
                              label: _ctx.i18n.$gettext('Zusätzliches Feld'),
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAddAdditionalFieldDialog = true))
                            }, null, 8, ["label"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  default: _withCtx(() => [
                    (_ctx.isInternalUser)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _createElementVNode("div", _hoisted_20, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Veröffentlicht")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(": ")
                          ]),
                          _createElementVNode("div", _hoisted_21, [
                            (_ctx.editedProperty)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_22, [
                                  _createVNode(_component_InputSwitch, {
                                    modelValue: _ctx.editedProperty.isPublic,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editedProperty.isPublic) = $event)),
                                    class: "mr-2"
                                  }, null, 8, ["modelValue"]),
                                  _createTextVNode(),
                                  _createVNode(_component_translate, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Veröffentlicht")
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_23, [
                                  (_ctx.property.isPublic)
                                    ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Ja ")
                                        ]),
                                        _: 1
                                      }))
                                    : (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Nein ")
                                        ]),
                                        _: 1
                                      }))
                                ]))
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showField('locationId'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                          _createElementVNode("div", _hoisted_25, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Standort")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(": ")
                          ]),
                          _createElementVNode("div", _hoisted_26, [
                            (_ctx.editedProperty)
                              ? (_openBlock(), _createBlock(_component_Dropdown, {
                                  key: 0,
                                  modelValue: _ctx.editedProperty.locationId,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editedProperty.locationId) = $event)),
                                  options: _ctx.locations,
                                  class: "w-100",
                                  "option-label": "name",
                                  "option-value": "id",
                                  filter: "",
                                  label: _ctx.i18n.$gettext('Standort')
                                }, null, 8, ["modelValue", "options", "label"]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_ctx.property.location?.name), 1))
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showField('locationId'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                          _createElementVNode("div", _hoisted_29, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Adresse")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(": ")
                          ]),
                          _createElementVNode("div", _hoisted_30, [
                            (_ctx.editedProperty)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                                  _createElementVNode("span", _hoisted_32, [
                                    _createVNode(_component_Autocomplete, {
                                      class: "w-100",
                                      "scroll-height": "50vh",
                                      "force-selection": "",
                                      modelValue: _ctx.autocompleteInput,
                                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.autocompleteInput) = $event)),
                                      suggestions: _ctx.locationSuggestions,
                                      field: "label",
                                      onComplete: _ctx.updateLocationSuggestions,
                                      onItemSelect: _ctx.onLocationComplete
                                    }, null, 8, ["modelValue", "suggestions", "onComplete", "onItemSelect"]),
                                    _createElementVNode("label", null, [
                                      _createVNode(_component_translate, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Nach einer Adresse suchen")
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ])
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_ctx.property.addressDisplayName), 1))
                          ]),
                          (_ctx.isInternalUser && _ctx.property)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                                (_ctx.editedProperty)
                                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                                      key: 0,
                                      class: "ml-1",
                                      modelValue: _ctx.editedProperty.publicFields,
                                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editedProperty.publicFields) = $event)),
                                      value: "addressDisplayName",
                                      style: {"margin-bottom":"0.3rem"}
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true),
                                (_ctx.fieldPublicStatus('addressDisplayName'))
                                  ? (_openBlock(), _createBlock(_component_Tag, {
                                      key: 1,
                                      value: _ctx.i18n.$gettext('Öffentlich')
                                    }, null, 8, ["value"]))
                                  : (_openBlock(), _createBlock(_component_Tag, {
                                      key: 2,
                                      severity: "success",
                                      value: _ctx.i18n.$gettext('Intern')
                                    }, null, 8, ["value"]))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showField('plat'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                          _createElementVNode("div", _hoisted_36, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Flurkennzeichung")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(": ")
                          ]),
                          _createElementVNode("div", _hoisted_37, [
                            (_ctx.editedProperty)
                              ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                                  key: 0,
                                  modelValue: _ctx.editedProperty.plat,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editedProperty.plat) = $event)),
                                  class: "w-100",
                                  label: _ctx.i18n.$gettext('Flurkennzeichnung')
                                }, null, 8, ["modelValue", "label"]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(_ctx.property.plat), 1))
                          ]),
                          (_ctx.isInternalUser && _ctx.property)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                                (_ctx.editedProperty)
                                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                                      key: 0,
                                      class: "ml-1",
                                      modelValue: _ctx.editedProperty.publicFields,
                                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editedProperty.publicFields) = $event)),
                                      value: "plat",
                                      style: {"margin-bottom":"0.3rem"}
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true),
                                (_ctx.fieldPublicStatus('plat'))
                                  ? (_openBlock(), _createBlock(_component_Tag, {
                                      key: 1,
                                      value: _ctx.i18n.$gettext('Öffentlich')
                                    }, null, 8, ["value"]))
                                  : (_openBlock(), _createBlock(_component_Tag, {
                                      key: 2,
                                      severity: "success",
                                      value: _ctx.i18n.$gettext('Intern')
                                    }, null, 8, ["value"]))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showField('size'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                          _createElementVNode("div", _hoisted_41, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Größe")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(": ")
                          ]),
                          _createElementVNode("div", _hoisted_42, [
                            (_ctx.editedProperty)
                              ? (_openBlock(), _createBlock(_component_AnimatedNumberInput, {
                                  key: 0,
                                  modelValue: _ctx.editedProperty.size,
                                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editedProperty.size) = $event)),
                                  class: "w-100",
                                  label: _ctx.i18n.$gettext('Größe im qm')
                                }, null, 8, ["modelValue", "label"]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_43, _toDisplayString(_ctx.sizeFormatted), 1))
                          ]),
                          (_ctx.isInternalUser && _ctx.property)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                                (_ctx.editedProperty)
                                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                                      key: 0,
                                      class: "ml-1",
                                      modelValue: _ctx.editedProperty.publicFields,
                                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editedProperty.publicFields) = $event)),
                                      value: "size",
                                      style: {"margin-bottom":"0.3rem"}
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true),
                                (_ctx.fieldPublicStatus('size'))
                                  ? (_openBlock(), _createBlock(_component_Tag, {
                                      key: 1,
                                      value: _ctx.i18n.$gettext('Öffentlich')
                                    }, null, 8, ["value"]))
                                  : (_openBlock(), _createBlock(_component_Tag, {
                                      key: 2,
                                      severity: "success",
                                      value: _ctx.i18n.$gettext('Intern')
                                    }, null, 8, ["value"]))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showField('hasBuilding'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                          _createElementVNode("div", _hoisted_46, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Bebaut")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(": ")
                          ]),
                          _createElementVNode("div", _hoisted_47, [
                            (_ctx.editedProperty)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                                  _createVNode(_component_TriStateCheckbox, {
                                    modelValue: _ctx.editedProperty.hasBuilding,
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editedProperty.hasBuilding) = $event)),
                                    "input-id": "hasbuildingcheckbox"
                                  }, null, 8, ["modelValue"]),
                                  _createElementVNode("label", _hoisted_49, [
                                    _createVNode(_component_translate, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Bebaut")
                                      ]),
                                      _: 1
                                    })
                                  ])
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_50, [
                                  (_ctx.hasBuilding)
                                    ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Ja ")
                                        ]),
                                        _: 1
                                      }))
                                    : (_ctx.hasNoBuilding)
                                      ? (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Nein ")
                                          ]),
                                          _: 1
                                        }))
                                      : (_openBlock(), _createBlock(_component_translate, { key: 2 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Unbekannt ")
                                          ]),
                                          _: 1
                                        }))
                                ]))
                          ]),
                          (_ctx.isInternalUser && _ctx.property)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                                (_ctx.editedProperty)
                                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                                      key: 0,
                                      class: "ml-1",
                                      modelValue: _ctx.editedProperty.publicFields,
                                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editedProperty.publicFields) = $event)),
                                      value: "hasBuilding",
                                      style: {"margin-bottom":"0.3rem"}
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true),
                                (_ctx.fieldPublicStatus('hasBuilding'))
                                  ? (_openBlock(), _createBlock(_component_Tag, {
                                      key: 1,
                                      value: _ctx.i18n.$gettext('Öffentlich')
                                    }, null, 8, ["value"]))
                                  : (_openBlock(), _createBlock(_component_Tag, {
                                      key: 2,
                                      severity: "success",
                                      value: _ctx.i18n.$gettext('Intern')
                                    }, null, 8, ["value"]))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showField('regulatedInDevelopmentPlan'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                          _createElementVNode("div", _hoisted_53, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("In einem Bebauungsplan")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(": ")
                          ]),
                          _createElementVNode("div", _hoisted_54, [
                            (_ctx.editedProperty)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                                  _createVNode(_component_TriStateCheckbox, {
                                    modelValue: _ctx.editedProperty.regulatedInDevelopmentPlan,
                                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editedProperty.regulatedInDevelopmentPlan) = $event)),
                                    "input-id": "hasbuildingcheckbox"
                                  }, null, 8, ["modelValue"]),
                                  _createElementVNode("label", _hoisted_56, [
                                    _createVNode(_component_translate, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("In einem Bebauungsplan")
                                      ]),
                                      _: 1
                                    })
                                  ])
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_57, [
                                  (_ctx.isInDevelopmentPlan)
                                    ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Ja ")
                                        ]),
                                        _: 1
                                      }))
                                    : (_ctx.isNotInDevelopmentPlan)
                                      ? (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Nein ")
                                          ]),
                                          _: 1
                                        }))
                                      : (_openBlock(), _createBlock(_component_translate, { key: 2 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Unbekannt ")
                                          ]),
                                          _: 1
                                        }))
                                ]))
                          ]),
                          (_ctx.isInternalUser && _ctx.property)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                                (_ctx.editedProperty)
                                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                                      key: 0,
                                      class: "ml-1",
                                      modelValue: _ctx.editedProperty.publicFields,
                                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editedProperty.publicFields) = $event)),
                                      value: "regulatedInDevelopmentPlan",
                                      style: {"margin-bottom":"0.3rem"}
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true),
                                (_ctx.fieldPublicStatus('regulatedInDevelopmentPlan'))
                                  ? (_openBlock(), _createBlock(_component_Tag, {
                                      key: 1,
                                      value: _ctx.i18n.$gettext('Öffentlich')
                                    }, null, 8, ["value"]))
                                  : (_openBlock(), _createBlock(_component_Tag, {
                                      key: 2,
                                      severity: "success",
                                      value: _ctx.i18n.$gettext('Intern')
                                    }, null, 8, ["value"]))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showField('buildingPlanIsInPreparation'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                          _createElementVNode("div", _hoisted_60, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Bebauungsplan in Vorbereitung")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(": ")
                          ]),
                          _createElementVNode("div", _hoisted_61, [
                            (_ctx.editedProperty)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                                  _createVNode(_component_TriStateCheckbox, {
                                    modelValue: _ctx.editedProperty.buildingPlanIsInPreparation,
                                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.editedProperty.buildingPlanIsInPreparation) = $event)),
                                    "input-id": "hasbuildingcheckbox"
                                  }, null, 8, ["modelValue"]),
                                  _createElementVNode("label", _hoisted_63, [
                                    _createVNode(_component_translate, null, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Bebauungsplan in Vorbereitung")
                                      ]),
                                      _: 1
                                    })
                                  ])
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_64, [
                                  (_ctx.buildingPlanIsInPreparation)
                                    ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Ja ")
                                        ]),
                                        _: 1
                                      }))
                                    : (_ctx.buildingPlanIsInPreparation)
                                      ? (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Nein ")
                                          ]),
                                          _: 1
                                        }))
                                      : (_openBlock(), _createBlock(_component_translate, { key: 2 }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" Unbekannt ")
                                          ]),
                                          _: 1
                                        }))
                                ]))
                          ]),
                          (_ctx.isInternalUser && _ctx.property)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                                (_ctx.editedProperty)
                                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                                      key: 0,
                                      class: "ml-1",
                                      modelValue: _ctx.editedProperty.publicFields,
                                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.editedProperty.publicFields) = $event)),
                                      value: "buildingPlanIsInPreparation",
                                      style: {"margin-bottom":"0.3rem"}
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true),
                                (_ctx.fieldPublicStatus('buildingPlanIsInPreparation'))
                                  ? (_openBlock(), _createBlock(_component_Tag, {
                                      key: 1,
                                      value: _ctx.i18n.$gettext('Öffentlich')
                                    }, null, 8, ["value"]))
                                  : (_openBlock(), _createBlock(_component_Tag, {
                                      key: 2,
                                      severity: "success",
                                      value: _ctx.i18n.$gettext('Intern')
                                    }, null, 8, ["value"]))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showField('availability'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
                          _createElementVNode("div", _hoisted_67, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Verfügbar")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(": ")
                          ]),
                          _createElementVNode("div", _hoisted_68, [
                            (_ctx.editedProperty)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
                                  _createElementVNode("div", _hoisted_70, [
                                    _createVNode(_component_Checkbox, {
                                      modelValue: _ctx.editedProperty.availability,
                                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.editedProperty.availability) = $event)),
                                      label: _ctx.i18n.$gettext('Verfügbar'),
                                      binary: true,
                                      name: "availability"
                                    }, null, 8, ["modelValue", "label"])
                                  ]),
                                  _createElementVNode("div", _hoisted_71, [
                                    _createVNode(_component_AnimatedInput, {
                                      modelValue: _ctx.editedProperty.availabilityNotes,
                                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.editedProperty.availabilityNotes) = $event)),
                                      class: "w-100",
                                      label: _ctx.i18n.$gettext('Weitere Angaben')
                                    }, null, 8, ["modelValue", "label"])
                                  ])
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_72, [
                                  (_ctx.property.availability)
                                    ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Ja ")
                                        ]),
                                        _: 1
                                      }))
                                    : (_openBlock(), _createBlock(_component_translate, { key: 1 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" Nein ")
                                        ]),
                                        _: 1
                                      })),
                                  (_ctx.property.availabilityNotes)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_73, ", " + _toDisplayString(_ctx.property.availabilityNotes), 1))
                                    : _createCommentVNode("", true)
                                ]))
                          ]),
                          (_ctx.isInternalUser && _ctx.property)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
                                (_ctx.editedProperty)
                                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                                      key: 0,
                                      class: "ml-1",
                                      modelValue: _ctx.editedProperty.publicFields,
                                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.editedProperty.publicFields) = $event)),
                                      value: "availability",
                                      style: {"margin-bottom":"0.3rem"}
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true),
                                (_ctx.fieldPublicStatus('availability'))
                                  ? (_openBlock(), _createBlock(_component_Tag, {
                                      key: 1,
                                      value: _ctx.i18n.$gettext('Öffentlich')
                                    }, null, 8, ["value"]))
                                  : (_openBlock(), _createBlock(_component_Tag, {
                                      key: 2,
                                      severity: "success",
                                      value: _ctx.i18n.$gettext('Intern')
                                    }, null, 8, ["value"]))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showField('siteDevelopment'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
                          _createElementVNode("div", _hoisted_76, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Erschließung")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(": ")
                          ]),
                          _createElementVNode("div", _hoisted_77, [
                            (_ctx.editedProperty)
                              ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                                  key: 0,
                                  modelValue: _ctx.editedProperty.siteDevelopment,
                                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.editedProperty.siteDevelopment) = $event)),
                                  type: "text",
                                  label: this.i18n.$gettext('Erschließung')
                                }, null, 8, ["modelValue", "label"]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_78, _toDisplayString(_ctx.property.siteDevelopment), 1))
                          ]),
                          (_ctx.isInternalUser && _ctx.property)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
                                (_ctx.editedProperty)
                                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                                      key: 0,
                                      class: "ml-1",
                                      modelValue: _ctx.editedProperty.publicFields,
                                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.editedProperty.publicFields) = $event)),
                                      value: "siteDevelopment",
                                      style: {"margin-bottom":"0.3rem"}
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true),
                                (_ctx.fieldPublicStatus('siteDevelopment'))
                                  ? (_openBlock(), _createBlock(_component_Tag, {
                                      key: 1,
                                      value: _ctx.i18n.$gettext('Öffentlich')
                                    }, null, 8, ["value"]))
                                  : (_openBlock(), _createBlock(_component_Tag, {
                                      key: 2,
                                      severity: "success",
                                      value: _ctx.i18n.$gettext('Intern')
                                    }, null, 8, ["value"]))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showField('allowedUsageTypes'))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_80, [
                          _createElementVNode("div", _hoisted_81, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Erlaubte Nutzung")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(": ")
                          ]),
                          _createElementVNode("div", _hoisted_82, [
                            (_ctx.editedProperty)
                              ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                                  key: 0,
                                  modelValue: _ctx.editedProperty.allowedUsageTypes,
                                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.editedProperty.allowedUsageTypes) = $event)),
                                  type: "text",
                                  label: this.i18n.$gettext('Erlaubte Nutzung')
                                }, null, 8, ["modelValue", "label"]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_83, _toDisplayString(_ctx.property.allowedUsageTypes), 1))
                          ]),
                          (_ctx.isInternalUser && _ctx.property)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_84, [
                                (_ctx.editedProperty)
                                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                                      key: 0,
                                      class: "ml-1",
                                      modelValue: _ctx.editedProperty.publicFields,
                                      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.editedProperty.publicFields) = $event)),
                                      value: "allowedUsageTypes",
                                      style: {"margin-bottom":"0.3rem"}
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true),
                                (_ctx.fieldPublicStatus('allowedUsageTypes'))
                                  ? (_openBlock(), _createBlock(_component_Tag, {
                                      key: 1,
                                      value: _ctx.i18n.$gettext('Öffentlich')
                                    }, null, 8, ["value"]))
                                  : (_openBlock(), _createBlock(_component_Tag, {
                                      key: 2,
                                      severity: "success",
                                      value: _ctx.i18n.$gettext('Intern')
                                    }, null, 8, ["value"]))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.additionalFields, (field, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "row mb-2",
                        key: field.name + '-' + index
                      }, [
                        _createElementVNode("div", _hoisted_85, _toDisplayString(field.name), 1),
                        (!_ctx.editedProperty)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_86, [
                              (!_ctx.additionalFieldIsLink(field))
                                ? (_openBlock(), _createElementBlock("span", _hoisted_87, _toDisplayString(field.value), 1))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    href: field.value,
                                    rel: "noopener noreferrer"
                                  }, _toDisplayString(field.value), 9, _hoisted_88))
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_89, [
                              _createVNode(_component_AnimatedInput, {
                                modelValue: field.value,
                                "onUpdate:modelValue": ($event: any) => ((field.value) = $event),
                                type: "text",
                                label: field.name || ''
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "label"])
                            ])),
                        (_ctx.editedProperty && !field.preventDeletion)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_90, [
                              _createVNode(_component_Button, {
                                icon: "cil-trash",
                                rounded: "",
                                onClick: ($event: any) => (_ctx.deleteAdditionalField(index))
                              }, null, 8, ["onClick"])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.isInternalUser)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_91, [
                              (_ctx.editedProperty)
                                ? (_openBlock(), _createBlock(_component_Checkbox, {
                                    key: 0,
                                    class: "ml-1",
                                    modelValue: field.isShared,
                                    "onUpdate:modelValue": ($event: any) => ((field.isShared) = $event),
                                    binary: true,
                                    style: {"margin-bottom":"0.3rem"}
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                : _createCommentVNode("", true),
                              (field.isShared)
                                ? (_openBlock(), _createBlock(_component_Tag, {
                                    key: 1,
                                    value: _ctx.i18n.$gettext('Öffentlich')
                                  }, null, 8, ["value"]))
                                : (_openBlock(), _createBlock(_component_Tag, {
                                    key: 2,
                                    severity: "success",
                                    value: _ctx.i18n.$gettext('Intern')
                                  }, null, 8, ["value"]))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.showField('description'))
              ? (_openBlock(), _createBlock(_component_Accordion, {
                  key: 1,
                  "active-index": _ctx.hasDescription? 0 : null,
                  class: "mb-4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AccordionTab, {
                      disabled: !_ctx.hasDescription && !_ctx.editMode
                    }, {
                      header: _withCtx(() => [
                        _createElementVNode("div", _hoisted_92, [
                          _createElementVNode("h4", _hoisted_93, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Kurzexposé")
                              ]),
                              _: 1
                            }),
                            _createTextVNode(),
                            (!_ctx.hasDescription && !_ctx.editMode)
                              ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" (Nicht verfügbar) ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          (_ctx.isInternalUser)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_94, [
                                (_ctx.editedProperty)
                                  ? (_openBlock(), _createBlock(_component_Checkbox, {
                                      key: 0,
                                      class: "ml-1",
                                      modelValue: _ctx.editedProperty.publicFields,
                                      "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.editedProperty.publicFields) = $event)),
                                      value: "description",
                                      style: {"margin-bottom":"0.3rem"}
                                    }, null, 8, ["modelValue"]))
                                  : _createCommentVNode("", true),
                                (_ctx.fieldPublicStatus('description'))
                                  ? (_openBlock(), _createBlock(_component_Tag, {
                                      key: 1,
                                      value: _ctx.i18n.$gettext('Öffentlich')
                                    }, null, 8, ["value"]))
                                  : (_openBlock(), _createBlock(_component_Tag, {
                                      key: 2,
                                      severity: "success",
                                      value: _ctx.i18n.$gettext('Intern')
                                    }, null, 8, ["value"]))
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      default: _withCtx(() => [
                        (_ctx.editedProperty)
                          ? (_openBlock(), _createBlock(_component_TipTapTextArea, {
                              key: 0,
                              ref: "editor",
                              modelValue: _ctx.editedProperty.description,
                              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.editedProperty.description) = $event)),
                              class: "w-100 mt-4"
                            }, null, 8, ["modelValue"]))
                          : _createCommentVNode("", true),
                        (!_ctx.editMode && _ctx.safeRenderDescription)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: "custom-editor mt-4 w-100 border p-4",
                              innerHTML: _ctx.safeRenderDescription
                            }, null, 8, _hoisted_95))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }, 8, ["active-index"]))
              : _createCommentVNode("", true),
            (_ctx.isInternalUser)
              ? (_openBlock(), _createBlock(_component_Accordion, {
                  key: 2,
                  "active-index": (_ctx.hasInternalNotes && !_ctx.editMode )? 0 : null,
                  class: "mb-4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AccordionTab, {
                      disabled: !_ctx.hasInternalNotes && !_ctx.editMode
                    }, {
                      header: _withCtx(() => [
                        _createElementVNode("h4", _hoisted_96, [
                          _createVNode(_component_translate, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Interne Notizen")
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      default: _withCtx(() => [
                        (_ctx.editedProperty)
                          ? (_openBlock(), _createBlock(_component_TipTapTextArea, {
                              key: 0,
                              ref: "editor",
                              modelValue: _ctx.editedProperty.internalNotes,
                              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.editedProperty.internalNotes) = $event)),
                              class: "w-100 mt-4",
                              style: {"min-height":"40vh"}
                            }, null, 8, ["modelValue"]))
                          : _createCommentVNode("", true),
                        (!_ctx.editMode && _ctx.safeRenderNotes)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: "custom-editor mt-4 w-100 border p-4",
                              innerHTML: _ctx.safeRenderNotes
                            }, null, 8, _hoisted_97))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }, 8, ["active-index"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Accordion, {
              "active-index": (_ctx.hasLinkedProject && !_ctx.editMode )? 0 : null,
              class: "mb-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AccordionTab, {
                  disabled: !_ctx.hasLinkedProject
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("div", _hoisted_98, [
                      _createElementVNode("div", _hoisted_99, [
                        _createElementVNode("h4", _hoisted_100, [
                          _createVNode(_component_translate, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Verknüpfte Ausschreibung")
                            ]),
                            _: 1
                          }),
                          _createTextVNode(),
                          (!_ctx.hasLinkedProject && !_ctx.editMode)
                            ? (_openBlock(), _createBlock(_component_translate, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" (Nicht verfügbar) ")
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      (_ctx.hasLinkedProject)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_101, [
                            _createVNode(_component_Button, {
                              class: "mr-3",
                              icon: "pi pi-external-link",
                              label: _ctx.i18n.$gettext('Zur Ausschreibung'),
                              onClick: _withModifiers(_ctx.goToLinkedProject, ["stop","prevent"])
                            }, null, 8, ["label", "onClick"])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  default: _withCtx(() => [
                    (_ctx.linkedProject)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_102, [
                          _createElementVNode("div", _hoisted_103, [
                            _createElementVNode("div", _hoisted_104, [
                              _createElementVNode("h5", null, _toDisplayString(_ctx.linkedProject.name), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_105, [
                            _createElementVNode("div", {
                              class: "col-12",
                              innerHTML: _ctx.safeRenderLinkedProjectDescription
                            }, null, 8, _hoisted_106)
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_107, [
                          _createElementVNode("div", _hoisted_108, [
                            _createElementVNode("div", _hoisted_109, [
                              _createVNode(_component_translate, null, {
                                default: _withCtx(() => [
                                  _createTextVNode("Es ist keine Ausschreibung mit diesem Grundstück verknüpft")
                                ]),
                                _: 1
                              })
                            ])
                          ])
                        ]))
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            }, 8, ["active-index"]),
            _createVNode(_component_Card, { class: "flat border p-4 mb-4" }, _createSlots({
              header: _withCtx(() => [
                _createElementVNode("h4", null, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Vorhandene Daten")
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 2
            }, [
              (_ctx.property)
                ? {
                    name: "content",
                    fn: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.propertyFiles, (folder) => {
                        return (_openBlock(), _createBlock(_component_PropertyFolderDisplay, {
                          key: folder.name + folder.created,
                          folder: folder,
                          interactive: !_ctx.editMode,
                          "property-id": _ctx.property.id,
                          onUpdateNeeded: _cache[27] || (_cache[27] = ($event: any) => (_ctx.updateProperty(_ctx.property, false)))
                        }, null, 8, ["folder", "interactive", "property-id"]))
                      }), 128))
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1024),
            _createVNode(_component_Card, { class: "flat border p-4 mb-4" }, {
              header: _withCtx(() => [
                _createElementVNode("h4", null, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Disclaimer / Rechtshinweis")
                    ]),
                    _: 1
                  })
                ])
              ]),
              content: _withCtx(() => [
                _createElementVNode("p", null, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Die Kartendarstellung, Panoramen, Fotos und anderen Bildmaterialien auf dieser Seite dient nur zur Einordnung und Veranschaulichung des Grundstücks. Diese bilden weder den genauen Stand noch genaue Ortsmarkierungen ab. Konsultieren Sie für eine genaue Angabe des Standorts immer entsprechende Pläne der öffentlichen Stelle, z.B. offizielle Katasterpläne. Für den Umfang der Ausschreibung als auch aktuellen Grundstücksinhalt und -bebauung konsultieren Sie bitte immer die öffentlich gemachte Vergabeunterlage. ")
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ], 512),
          _createVNode(_component_Dialog, {
            visible: _ctx.showAddAdditionalFieldDialog,
            "onUpdate:visible": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.showAddAdditionalFieldDialog) = $event)),
            modal: true,
            onHide: _ctx.closeAdditionalFieldDialog
          }, {
            header: _withCtx(() => [
              _createElementVNode("p", _hoisted_110, [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Zusätzliches Feld anlegen")
                  ]),
                  _: 1
                })
              ])
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Button, {
                label: _ctx.i18n.$gettext('Abbrechen'),
                icon: "pi pi-times",
                class: "p-button-text",
                onClick: _ctx.closeAdditionalFieldDialog
              }, null, 8, ["label", "onClick"]),
              _createVNode(_component_Button, {
                label: _ctx.i18n.$gettext('Hinzufügen'),
                icon: "pi pi-check",
                autofocus: "",
                disabled: !_ctx.newAdditionalFieldName,
                onClick: _ctx.addAdditionalField
              }, null, 8, ["label", "disabled", "onClick"])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_111, [
                _createElementVNode("div", _hoisted_112, [
                  _createVNode(_component_AnimatedInput, {
                    modelValue: _ctx.newAdditionalFieldName,
                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.newAdditionalFieldName) = $event)),
                    class: "w-100 mb-2",
                    type: "text",
                    label: _ctx.i18n.$gettext('Feld-Name')
                  }, null, 8, ["modelValue", "label"]),
                  _createElementVNode("p", null, [
                    _createVNode(_component_translate, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Hinweis: Dieses Feld wird nur für dieses Grundstück angelegt. Der Administrator kann in den Einstellungen Felder für alle neu angelegten Grundstücke hinzufügen.")
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["visible", "onHide"]),
          _createVNode(_component_Dialog, {
            visible: _ctx.showUploadDialog,
            "onUpdate:visible": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.showUploadDialog) = $event)),
            modal: true,
            onHide: _cache[31] || (_cache[31] = ($event: any) => (_ctx.showUploadDialog = false))
          }, _createSlots({
            default: _withCtx(() => [
              _createVNode(_component_FileUpload, {
                mode: "basic",
                class: "p-button-text p-button-sm flat w-100",
                name: "files",
                url: _ctx.basePath + _ctx.editedProperty.id,
                multiple: false,
                "max-file-size": 1000000000,
                auto: true,
                "choose-label": _ctx.i18n.$gettext('Panorama'),
                onBeforeSend: _ctx.interceptUploadAndChangePanoramaFilename,
                onUpload: _ctx.onUploadDone,
                onError: _ctx.onUploadFailed
              }, null, 8, ["url", "choose-label", "onBeforeSend", "onUpload", "onError"]),
              _createVNode(_component_FileUpload, {
                mode: "basic",
                class: "p-button-text p-button-sm flat w-100",
                name: "files",
                url: _ctx.basePath + _ctx.editedProperty.id,
                multiple: false,
                "max-file-size": 1000000000,
                auto: true,
                "choose-label": _ctx.i18n.$gettext('Bild'),
                onBeforeSend: _ctx.interceptUploadAndChangeImageFilename,
                onUpload: _ctx.onUploadDone,
                onError: _ctx.onUploadFailed
              }, null, 8, ["url", "choose-label", "onBeforeSend", "onUpload", "onError"])
            ]),
            _: 2
          }, [
            (_ctx.editPanorama)
              ? {
                  name: "header",
                  fn: _withCtx(() => [
                    _createElementVNode("p", _hoisted_113, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Datei hochladen")
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["visible"]),
          _createVNode(_component_Dialog, {
            visible: _ctx.showPanoramaDialog,
            "onUpdate:visible": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.showPanoramaDialog) = $event)),
            modal: true,
            "close-on-escape": false,
            style: {"width":"90vw","height":"90vh"},
            onHide: _ctx.closePanoramaDialog
          }, _createSlots({
            default: _withCtx(() => [
              _createVNode(_component_PhotoSphereViewer, {
                ref: "panoramaViewer",
                class: "card flat",
                edit: _ctx.editPanorama,
                panorama: _ctx.panorama,
                "panorama-list": _ctx.panoramas
              }, null, 8, ["edit", "panorama", "panorama-list"])
            ]),
            _: 2
          }, [
            (_ctx.editPanorama)
              ? {
                  name: "header",
                  fn: _withCtx(() => [
                    _createElementVNode("p", _hoisted_114, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Panorama bearbeiten")
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  key: "0"
                }
              : undefined,
            (_ctx.editPanorama)
              ? {
                  name: "footer",
                  fn: _withCtx(() => [
                    _createVNode(_component_Button, {
                      label: _ctx.i18n.$gettext('Abbrechen'),
                      icon: "pi pi-times",
                      class: "p-button-text",
                      onClick: _ctx.closePanoramaDialog
                    }, null, 8, ["label", "onClick"]),
                    _createVNode(_component_Button, {
                      label: _ctx.i18n.$gettext('Speichern'),
                      icon: "pi pi-check",
                      autofocus: "",
                      onClick: _ctx.savePanorama
                    }, null, 8, ["label", "onClick"])
                  ]),
                  key: "1"
                }
              : undefined
          ]), 1032, ["visible", "onHide"]),
          (_openBlock(), _createBlock(_Teleport, { to: "#breadcrumbs" }, [
            _createVNode(_component_Breadcrumb, {
              home: { icon: 'pi pi-home', to: '/' },
              model: _ctx.breadcrumbs
            }, null, 8, ["model"])
          ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}