export default class AdditionalField {
  name!: string | null;
  value!: string | null;
  preventDeletion!: boolean | null;
  isShared!: boolean | null;

  constructor() {
    this.name = null;
    this.value = null;
    this.preventDeletion = null;
    this.isShared = null;
  }
}
