
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import {rpcClient} from "@/api/WebsocketClient"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Card from "primevue/card"
import Dropdown from "@/components/controls/Dropdown.vue"
import RoomDetails from "@/components/common/RoomDetails.vue"
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import {projectTemplateServiceApi} from "@/api/ProjectTemplateServiceApi"
import ProjectTemplate from "@/model/ProjectTemplate"
import {Watch} from "vue-property-decorator"
import PanelEditor from "@/components/admin/PanelEditor.vue"
import useToast from "@/util/toasts"
import RpcError from "@/api/RpcError"
import {Container, Draggable} from 'vue3-smooth-dnd'
import Panel from "@/model/common/Content"
import {MenuItem} from "primevue/menuitem"
import Breadcrumb from "primevue/breadcrumb"
import Multiselect from "@/components/controls/Multiselect.vue"
import InputSwitch from "primevue/inputswitch"

@Options({
  name: "ProjectTemplateForm",
  components: { Multiselect, InputSwitch,
    PanelEditor, AnimatedInput, Button, Card, Dropdown, RoomDetails, TipTapTextArea, Container, Draggable, Breadcrumb
  },
  //@ts-ignore
  props: {

  }
})
export default class ProjectTemplateForm extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient

  name: string = ''
  description: string = ''
  projectTypes: string[] = []
  active: boolean = true

  panels: any[] = []
  roomTemplates: any[] = []

  panelName: string = ''
  panelOrdinal: string = ''

  get availableProjectTypes(): { code: string, translation: string }[]{
    return [
      {
        code: "KONZESSION_VERHANDLUNG",
        translation: this.i18n.$gettext("Konzessionsrecht - Verhandlungsverfahren")
      },
      {
        code: "KONZESSION_DAUERVERHANDLUNG",
        translation: this.i18n.$gettext("Konzessionsrecht - Dauerverhandlungsverfahren")
      },
      {
        code: "KONZESSION_SEKTORENVERORDNUNG",
        translation: this.i18n.$gettext("Konzessionsrecht - Sektorenverordnung (2014/25/EU)")
      },
      {
        code: "KONZESSION_VERGABEVERORDNUNG",
        translation: this.i18n.$gettext("Konzessionsrecht - Vergabeverodnung (2014/24/EU)")
      },
      {
        code: "KONZESSION_VOBA-EU",
        translation: this.i18n.$gettext("Konzessionsrecht - VOB/A-EU (2014/24/EU)")
      },
      {
        code: "KONZESSION_VERTEIDIGUNG",
        translation: this.i18n.$gettext("Konzessionsrecht - Vergabeverordnung Verteidigung/Sicherheit (2009/81/EC)")
      },
      {
        code: "KONZESSION_VOBA_NATIONAL",
        translation: this.i18n.$gettext("Konzessionsrecht - VOB/A (national)")
      },
      {
        code: "KONZESSION_UVGO_NATIONAL",
        translation: this.i18n.$gettext("Konzessionsrecht - UVgO (national)")
      }
    ]
  }

  get id(): string {
    return this.$route.params?.id || ''
  }

  get disabled(): boolean {
    return !!this.id?.startsWith('EU-')
  }

  //TODO loading
  get projectTemplate(): ProjectTemplate | null {
    if (this.id) {
      const id = this.id.includes('#') ? this.id.substring(0, this.id.lastIndexOf('#')) : this.id
      return projectTemplateServiceApi.getProjectTemplate(id).data
    } else {
      return null
    }
  }

  copyPanel(panel: Panel): Panel {
    return Object.assign(new Panel(), JSON.parse(JSON.stringify(panel)))
  }

  onPanelDrop(panels: any[], dropResult: any) {
    if ((dropResult.removedIndex !== null || dropResult.addedIndex !== null) && dropResult.removedIndex != dropResult.addedIndex) {
      if (dropResult.removedIndex != null) {
        panels.splice(dropResult.removedIndex, 1)
      }
      if (dropResult.addedIndex != null && dropResult.payload) {
        panels.splice(dropResult.addedIndex, 0, dropResult.payload)
      }
    }
  }

  save() {
    const template: ProjectTemplate = Object.assign(this.projectTemplate || new ProjectTemplate(), {
      name: this.name,
      description: this.description,
      projectTypes: this.projectTypes,
      active: this.active,
      panels: this.panels/*,
      roomTemplates: this.roomTemplates*/
    })
    projectTemplateServiceApi._saveProjectTemplate(template).then(() => {
      this.toast.success(this.i18n.$gettext('Das Formular wurde gespeichert.'))
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Das Formular konnte nicht gespeichert werden.'), e.message)
    })
    //TODO
  }

  @Watch('projectTemplate')
  watchProjectTemplate() {
    this.name = this.projectTemplate?.name || ''
    this.description = this.projectTemplate?.description || ''
    this.projectTypes = this.projectTemplate?.projectTypes || []
    this.active = (this.projectTemplate && this.projectTemplate.active !== null ) ? this.projectTemplate.active : true
    this.panels = this.projectTemplate?.panels || []
    this.roomTemplates = this.projectTemplate?.roomTemplates || []
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    breadcrumbs.push({
      label: this.i18n.$gettext('Formulare'),
      to: '/formulare'
    })
    const name = this.name || 'Neues Formular'
    breadcrumbs.push({
      label: name.substring(0, Math.min(20 , name.length)) + (name.length > 20 ? '...' : ''),
      to: this.$route.path
    })
    return breadcrumbs
  }

  mounted() {
    this.watchProjectTemplate()
  }
}
