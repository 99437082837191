import {replaceColonsWithUTF8, replaceUTF8WithColons} from "@/util/emojiFromStringConverter"
import insane from "insane"

const marked = require("marked")

const insaneOptions = {
    "allowedAttributes": {
        "a": ["href", "name", "target", "rel"],
        "iframe": ["allowfullscreen", "frameborder", "src"],
        "img": ["src"],
        "div": ["class", "role", "aria-checked"],
        "span": ["class"],
        "li": ["class", "data-checked", "id"],
        "ul": ["class", "data-type"],
        "ol": ["class", "start"],
        "input": ["type", "class", "disabled", "checked", "id"]
    },
    "allowedClasses": {},
    "allowedSchemes": ["http", "https", "mailto"],
    "allowedTags": [
        "a", "article", "b", "blockquote", "br", "caption", "code", "del", "details", "div", "em",
        "h1", "h2", "h3", "h4", "h5", "h6", "hr", "i", "img", "ins", "kbd", "li", "main", "ol",
        "p", "pre", "section", "span", "strike", "strong", "sub", "summary", "sup", "table",
        "tbody", "td", "th", "thead", "tr", "u", "ul", "input", "label"
    ],
    "filter": null,
    "transformText": null
}

export default class MarkdownUtil {

    //inline = true does not render lists
    static safeRenderMarkdown(text: string | null, inline?: boolean, wrapEmojis?: boolean): string {
        if (!text) return ""
        let unsafeHtml: string
        if (text.trim().startsWith('<') && text.trim().endsWith('>')) {
            unsafeHtml = text
        } else {
            const renderer = new marked.Renderer()
            const linkRenderer = renderer.link
            renderer.link = (href: any, title: any, text: any) => {
                const localLink = href.startsWith(`${location.protocol}//${location.hostname}`) || href.startsWith("/")
                const html = linkRenderer.call(renderer, this.removeMarkdownEscapes(href), this.removeMarkdownEscapes(title), this.removeMarkdownEscapes(text))
                return localLink ? html : html.replace(/^<a /, '<a target="_blank" rel="noreferrer noopener nofollow" ')
            }
            renderer.listitem = (text: any, task: any) => {
                //Make it compatible with TipTap, because we need to be able to insert this list into TipTap for editing
                if (task && text.includes('checked=""')) {
                    return this.getCheckedCheckBox(text)
                } else if (task) {
                    return this.getUncheckedCheckBox(text)
                } else {
                    return '<li>' + text + '</li>'
                }
            }
            renderer.list = (body: any, ordered: any, start: any) => {
                if (!ordered && body.includes('<li class="taskItem"')) {
                    return '<ul data-type="taskList">' + body + '</ul>'
                } else if (ordered) {
                    return '<ol start="' + start + '">' + body + '</ol>'
                } else {
                    return '<ul>' + body + '</ul>'
                }
            }
            marked.setOptions({
                renderer: renderer,
                // https://marked.js.org/using_advanced
                // If true, add <br> on a single line break (copies GitHub behavior on comments,
                // but not on rendered markdown files). Requires gfm be true (default).
                breaks: true,
                // If true, conform to the original markdown.pl as much as possible.
                // Don't fix original markdown bugs or behavior.
                // Turns off and overrides gfm.
                pedantic: false
            })

            text = replaceUTF8WithColons(text)
            if (wrapEmojis) {
                text = replaceColonsWithUTF8(text, true)
            }
            if (/^<span class="emoji-native">[^\/]+<\/span>$/.test((text || '').trim())) {
                text = (text || '').replace('emoji-native', 'emoji-large')
            }
            if (inline) {
                unsafeHtml = marked.parseInline(text || '')
            } else {
                unsafeHtml = marked.parse(text || '')
            }
        }
        return insane(unsafeHtml, insaneOptions)
    }

    static removeMarkdownEscapes(text: string): string {
        return text ? text.replace(/\\([\\`*_{}[\]()#+\-.!])/g, '$1') : text
    }

    static getCheckedCheckBox(text: string) {
        return '<li class="taskItem" data-checked="true"><div class="p-field-checkbox"><div class="p-checkbox p-component mr-2 p-checkbox-checked" contenteditable="false"><div class="p-hidden-accessible"><input type="checkbox" checked="checked"></div><div class="p-checkbox-box p-highlight" role="checkbox" aria-checked="true"><span class="p-checkbox-icon pi pi-check"></span></div></div><div><p>' +
            text.replace(/<input\s+.+\s+type="checkbox">\s/, '') +
            '</p></div></div></li>'
    }

    static getUncheckedCheckBox(text: string) {
        return '<li class="taskItem" data-checked="false"><div class="p-field-checkbox"><div class="p-checkbox p-component mr-2" contenteditable="false"><div class="p-hidden-accessible"><input type="checkbox"></div><div class="p-checkbox-box" role="checkbox" aria-checked="false"><span class="p-checkbox-icon"></span></div></div><div><p>' +
            text.replace(/<input\s+.+\s+type="checkbox">\s/, '') +
            '</p></div></div></li>'
    }
}
