import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71fc032f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "viewer",
  ref: "viewercontainer"
}
const _hoisted_2 = { class: "lead mb-0" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex align-items-center mb-2"
}
const _hoisted_4 = { class: "w-50 flex-1 flex flex-column align-items-center" }
const _hoisted_5 = {
  for: "strokeColorId",
  class: "font-bold block mr-2"
}
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_AnimatedInput = _resolveComponent("AnimatedInput")!
  const _component_TipTapTextArea = _resolveComponent("TipTapTextArea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, null, 512),
    _createVNode(_component_Dialog, {
      visible: _ctx.showEditMarkerDialog,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showEditMarkerDialog) = $event)),
      "close-on-escape": false,
      onHide: _ctx.closeDialog
    }, {
      header: _withCtx(() => [
        _createElementVNode("p", _hoisted_2, [
          _createVNode(_component_translate, null, {
            default: _withCtx(() => [
              _createTextVNode("Markierung bearbeiten")
            ]),
            _: 1
          })
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: _ctx.i18n.$gettext('Abbrechen'),
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _ctx.closeDialog
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.i18n.$gettext('Übernehmen'),
          icon: "pi pi-check",
          autofocus: "",
          onClick: _ctx.updateMarker
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        (_ctx.currentMarkerType == _ctx.markerTypePolygon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.editedMarker.svgStyle.strokeWidth,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editedMarker.svgStyle.strokeWidth) = $event)),
                options: _ctx.strokeWidthOptions,
                class: "mr-3 w-100",
                "option-label": "label",
                "option-value": "value",
                label: _ctx.i18n.$gettext('Linienbreite')
              }, null, 8, ["modelValue", "options", "label"]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("label", _hoisted_5, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Linienfarbe")
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_ColorPicker, {
                  modelValue: _ctx.strokeColor,
                  "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event: any) => ((_ctx.strokeColor) = $event)),
                    _cache[2] || (_cache[2] = ($event: any) => (_ctx.editedMarker.svgStyle.stroke = '#' + _ctx.strokeColor))
                  ],
                  "input-id": "strokeColorId",
                  format: "hex"
                }, null, 8, ["modelValue"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_AnimatedInput, {
            modelValue: _ctx.editedMarker.tooltip.content,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editedMarker.tooltip.content) = $event)),
            class: "mr-3 w-100",
            label: _ctx.i18n.$gettext('Tooltip Text')
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.editedMarker.tooltip.position,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editedMarker.tooltip.position) = $event)),
            options: _ctx.tooltipPositionOptions,
            class: "w-50",
            "option-label": "label",
            "option-value": "value",
            "show-clear": "",
            label: _ctx.i18n.$gettext('Tooltip Position')
          }, null, 8, ["modelValue", "options", "label"])
        ]),
        (_ctx.currentMarkerType == _ctx.markerTypeWaypoint)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 1,
              modelValue: _ctx.editedMarker.data.waypoint,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editedMarker.data.waypoint) = $event)),
              options: _ctx.waypointTargetOptions,
              class: "w-100 mt-3",
              "option-label": "label",
              "option-value": "value",
              "show-clear": "",
              label: _ctx.i18n.$gettext('Ziel')
            }, null, 8, ["modelValue", "options", "label"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("h4", _hoisted_8, [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Beschreibung")
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_TipTapTextArea, {
                ref: "editor",
                modelValue: _ctx.editedMarker.content,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editedMarker.content) = $event)),
                class: "w-100",
                "html-mode": "",
                style: {"min-height":"40vh"}
              }, null, 8, ["modelValue"])
            ]))
      ]),
      _: 1
    }, 8, ["visible", "onHide"])
  ]))
}