export default class PanoramaImage {
  name!: string | null;
  originalFileName!: string | null;
  url!: string | null;
  fileSizeInBytes!: number | null;
  created!: string | null;
  markers!: string | null;

  constructor() {
    this.name = null;
    this.originalFileName = null;
    this.url = null;
    this.fileSizeInBytes = null;
    this.created = null;
    this.markers = null;
  }
}
