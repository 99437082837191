
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import User from "@/model/User"
import Organization from "@/model/Organization"
import Contact from "@/model/Contact"
import {registrationServiceApi} from "@/api/RegistrationServiceApi"
import Card from "primevue/card"
import Checkbox from "@/components/controls/Checkbox.vue"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import useToast from "@/util/toasts"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import OrganizationForm from "@/components/common/OrganizationForm.vue"
import {organizationServiceApi} from "@/api/OrganizationServiceApi"
import RpcError from "@/api/RpcError"
import {MenuItem} from "primevue/menuitem"
import Breadcrumb from "primevue/breadcrumb"
import {ref} from "@vue/reactivity"
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"

@Options({
  name: "OrganizationRegistrationForm",
  components: {
    Button, AnimatedInput, Card, Checkbox, Accordion, AccordionTab, OrganizationForm, Breadcrumb
  },
  //@ts-ignore
  props: {
    organization: [ Organization, Object ]
  }
})
export default class OrganizationRegistrationForm extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient

  step: number = 0
  organization: Organization = Object.assign(new Organization(), { contact: new Contact(), type: 'CONTRACTOR' })
  user: User = Object.assign(new User(), { contact: new Contact() })
  passwordRepeat: string = ''
  userIsContact: boolean = false
  confirm1: boolean = false
  confirm2: boolean = false

  //@ts-ignore
  organizationFormElement: HTMLFormElement = ref<HTMLFormElement>(null)

  triggerFormSubmit() {
    if(this.organizationFormElement) {
      this.organizationFormElement.requestSubmit()
    }
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    if (this.organization?.type === 'CLIENT') {
      breadcrumbs.push({
        label: this.i18n.$gettext('Auftraggeber'),
        to: '/auftraggeber'
      })
      if (this.organization.id) {
        breadcrumbs.push({
          label: this.organization.name || '',
          to: this.$route.path
        })
      } else {
        breadcrumbs.push({
          label: this.i18n.$gettext('Auftraggeber anlegen'),
          to: this.$route.path
        })
      }
    } else {
      breadcrumbs.push({
        label: this.i18n.$gettext('Bieter'),
        to: '/bieter'
      })
      if (this.organization.id) {
        breadcrumbs.push({
          label: this.organization.name || '',
          to: this.$route.path
        })
      } else {
        breadcrumbs.push({
          label: this.i18n.$gettext('Bieter einladen'),
          to: this.$route.path
        })
      }
    }
    return breadcrumbs
  }

  proceed() {
    if (this.organization.id) {
      organizationServiceApi._updateOrganization(this.organization).then((id: number) => {
        this.toast.success(this.i18n.$gettext('Die Unternehmens-Daten wurden geändert.'))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Die Unternehmens-Daten konnten nicht geändert werden.'))
      })
    } else if (this.organization.type === 'CLIENT') {
      organizationServiceApi._createOrganization(this.organization).then((id: number) => {
        this.toast.success(this.i18n.$gettext('Das Unternehmen wurde gespeichert.'))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Die Organisation konnte nicht gespeichert werden.'))
      })
    } else if (this.step < 2) {
      this.step += 1
    } else {
      this.send()
    }
  }

  send() {
    if (rpcClient.isInternalUser || (this.user.password && this.passwordRepeat === this.user.password && this.confirm1 && this.confirm2)) {
      if (this.userIsContact) {
        this.user.contact = null
      }
      registrationServiceApi._registerOrganization(this.organization, this.user).then(() => {
        if (rpcClient.isInternalUser) {
          this.toast.success(this.i18n.$gettext('Das Unternehmen wurde gespeichert.'))
          this.$router.push('/bieter')
        } else { //should always be true
          this.$router.push({ path: '/projekte', query: { message: 'registered' } }).then(() => {
            rpcClient.nextRoute.afterLogin = this.$route
          })
        }
        Object.assign(this.organization, Object.assign(new Organization(), { contact: new Contact() }))
        this.user = Object.assign(new User(), { contact: new Contact() })
        this.passwordRepeat = ''
        //TODO go to details
      }).catch((e: RpcError) => {
        if (e.data?.exceptionTypeName?.includes('ConflictException')) {
          if (e.message?.includes('Organisation')) {
            alert(this.i18n.$gettext('Dieses Unternehmen wurde bereits registriert. Bitten Sie den Verantwortlichen für das Unternehmen, ein Benutzer-Konto für Sie anzulegen.'))
          } else if (e.message?.includes('E-Mail-Adresse')) {
            alert(this.i18n.$gettext('Diese E-Mail-Adresse wurde bereits registriert. Falls Sie Ihr Passwort vergessen haben, klicken Sie auf "Einloggen" und dann auf "Passwort vergessen".'))
          } else {
            this.toast.error(this.i18n.$gettext('Die Registrierung war nicht möglich.'))
          }
        } else if (e.data) {
          this.toast.error(this.i18n.$gettext('Die Registrierung war nicht möglich.'))
        }
      }).finally(() => {

      })
    } else {
      this.toast.error(this.i18n.$gettext('Die Passwörter stimmen nicht überein.'))
    }
  }
}
