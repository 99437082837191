import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import User from '@/model/User';


export default class GeneratedUserStore {

    state = reactive({
        users: new Map<string, User>(),
        calls: new Map<string, string | string[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.users) this.state.users.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceUser(newUser: User) {
        if (newUser.email) {
            this.state.users.set(newUser.email, newUser)
            
        }
    }

    addOrReplaceUsers(newUsers: User[]) {
        for (const newUser of newUsers) {
            this.addOrReplaceUser(newUser)
        }
    }

    replaceUsers(users: User[]) {
        const newUsers = new Map<string, User>()
        for (const newUser of users) {
            if (newUser.email) {
                newUsers.set(newUser.email, newUser)
                
            }
        }
        this.state.users = newUsers
    }

    removeUser(email: string | undefined | null) {
        if (email !== undefined && email !== null) {
            this.state.users.delete(email)
        }
    }

    removeUsers(emails: (string | null)[] | undefined | null) {
        if (emails !== undefined && emails !== null) {
            for (const email of emails) {
                this.removeUser(email)
            }
        }
    }
}