import {createGettext} from "@jshmrtn/vue3-gettext"
import {getNormalizedBrowserLanguage} from "@/util/language"
import translations from "@/locale/generated/translations.json"

const i18n = createGettext({
    availableLanguages: {
        en_US: 'American English',
        de_DE: 'Deutsch'
    },
    silent: true,
    defaultLanguage: getNormalizedBrowserLanguage(),
    translations
})
export default i18n
