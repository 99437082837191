
import {Options, Vue} from "vue-class-component"
import Card from "primevue/card"
import Button from "primevue/button"
import Dialog from "@/components/common/Dialog.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Dropdown from "@/components/controls/Dropdown.vue"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import {Container, Draggable} from 'vue3-smooth-dnd'
import Field from "@/model/common/Content"
import Panel from "@/model/common/Content"
import Choice from "@/model/common/Choice"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"
import Content from "@/model/common/Content"
import ContentUtil from "@/util/ContentUtil"

@Options({
  name: "PanelEditorField",
  components: {
    Card, Button, Dialog, AnimatedInput, Dropdown, Checkbox, Container, Draggable
  },
  //@ts-ignore
  props: {
    field: [ Field, Object ],
    canMoveUp: Boolean,
    canMoveDown: Boolean,
    id: String,
    disabled: Boolean,
    isEuCopy: Boolean,
    index: Number,
    count: Number
  },
  emits: [
      'copy-field', 'remove-field', 'move-up', 'move-down', 'add-field', 'start-edit', 'edit-choice', 'add-field-for-choice'
  ]
})
export default class PanelEditorField extends Vue {

  i18n: Language = useGettext()

  field!: Field

  listEntry: string | null = null
  eFormListEntries: string[] | null = null
  fieldTypeOptions: { name: string, type: string }[] = [
    {
      name: this.i18n.$gettext('Text-Feld (lang)'),
      type: 'TEXTAREA'
    },
    {
      name: this.i18n.$gettext('Text-Feld (kurz)'),
      type: 'TEXTBOX'
    },
    {
      name: this.i18n.$gettext('Einfach-Auswahl'),
      type: 'COMBOBOX'
    },
    {
      name: this.i18n.$gettext('Mehrfach-Auswahl'),
      type: 'MULTICHECKBOX'
    },
    {
      name: this.i18n.$gettext('Ganzzahl-Feld'),
      type: 'NUMBER'
    },
    {
      name: this.i18n.$gettext('Datums-Feld'),
      type: 'DATE'
    },
    {
      name: this.i18n.$gettext('Zeit-Feld'),
      type: 'TIME'
    },
    {
      name: this.i18n.$gettext('Kontrollkästchen (Ja/Nein)'),
      type: 'RADIO'
    }
  ]

  translate(content: Content): string {
    return SdkServiceClient.getLabelForField(content?.label)
  }

  copyChoice(choice: Choice): Choice {
    return Object.assign(new Choice(), JSON.parse(JSON.stringify(choice)))
  }

  copyPanel(panel: Panel): Panel {
    return Object.assign(new Panel(), JSON.parse(JSON.stringify(panel)))
  }

  onFieldDrop(fields: Field[], dropResult: any) {
    if ((dropResult.removedIndex !== null || dropResult.addedIndex !== null) && dropResult.removedIndex != dropResult.addedIndex) {
      if (dropResult.removedIndex != null) {
        fields?.splice(dropResult.removedIndex, 1)
      }
      if (dropResult.addedIndex != null && dropResult.payload) {
        fields.splice(dropResult.addedIndex, 0, dropResult.payload)
      }
    }
  }

  get typeOfField(): string | null {
    return ContentUtil.typeOfField(this.field)
  }

  get listEntries(): string[] {
    if (this.eFormListEntries !== null) {
      return this.eFormListEntries
    } else if (this.field?.idSchemes) {
      return  SdkServiceClient.listValuesForSchemeIds(this.field.idSchemes) || []
    } else {
      return []
    }
  }

  mounted() {
    if (this.field?.schemeId && !this.field.stringValue) {
      this.field.stringValue = SdkServiceClient.createNewValueForSchemeId(this.field.schemeId)
    }
    void SdkServiceClient.fetchAll(this.i18n.current)
    if (!this.field?.idSchemes && this.typeOfField && ['COMBOBOX', 'RADIO'].includes(this.typeOfField)) {
      SdkServiceClient.getListForField(this.field).then((list: { value: string, label: string }[]) => {
        this.eFormListEntries = list.map(entry => entry.value + ' - ' + entry.label)
      }).catch(() => {
        this.eFormListEntries = null
      })
    }
  }
}
