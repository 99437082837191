
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import SWR from "@/api/SWR"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import {rpcClient} from "@/api/WebsocketClient"
import Offer from '@/model/Offer'
import Checkbox from "@/components/controls/Checkbox.vue"
import Card from "primevue/card"
import Dialog from "@/components/common/Dialog.vue"
import i18n from '@/util/i18n'
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import RpcError from "@/api/RpcError"
import Message from "@/model/Message"
import {messageServiceApi} from "@/api/MessageServiceApi"
import MarkdownUtil from "@/util/MarkdownUtil"
import ProgressBar from "primevue/progressbar"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import useToast from "@/util/toasts"

@Options({
  name: "ChatView",
  components: {
    AnimatedInput, Button, Card, Checkbox, Dialog, TipTapTextArea, ProgressBar
  },
  //@ts-ignore
  props: {
    offer: [ Offer, Object ]
  },
  emits: [
    'close'
  ]
})
export default class ChatView extends Vue {

  i18n = i18n
  rpcClient = rpcClient
  toast = useToast()

  offer!: Offer

  showCreateMessageDialog: boolean = false
  messageText: string = ''

  get messages(): SWR<Message[], number[]> | null {
    return this.offer ? messageServiceApi.getMessages(this.offer.projectId, this.offer.id) : null
  }

  resetCreateMessageDialog() {
    this.messageText = ''
    this.showCreateMessageDialog = false
  }

  createMessage() {
    const message: Message = new Message()
    message.messageText = this.messageText
    message.offerId = this.offer.id
    if (this.rpcClient.isInternalUser) {
      message.recipientOrganizationId = this.offer.organizationId
    } else {
      message.senderOrganizationId = rpcClient.session.user?.organizationId || this.offer.organizationId
    }
    message.projectId = this.offer.projectId
    return messageServiceApi._sendMessage(message).then((id: number) => {
      this.resetCreateMessageDialog()
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Die Nachricht konnte nicht gesendet werden.'))
    })
  }

  isInternal(message: Message) {
    return message.recipientOrganizationId === this.offer.organizationId
  }

  safeRender(messageText: string) {
    return MarkdownUtil.safeRenderMarkdown(messageText, true)
  }

  sort(messages: Message[]): Message[] {
    SortAndFilterUtil.sort(messages, [ 'created:asc' ])
    return messages
  }
}
