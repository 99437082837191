
import {Options, Vue} from 'vue-class-component'
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import ProgressBar from "primevue/progressbar"
import Checkbox from "@/components/controls/Checkbox.vue"
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import Settings from "@/model/settings/Settings"
import {settingsServiceApi} from "@/api/SettingsServiceApi"
import Button from "primevue/button"
import RpcError from "@/api/RpcError"
import Dialog from "@/components/common/Dialog.vue"

import Dropdown from "@/components/controls/Dropdown.vue"
import Breadcrumb from "primevue/breadcrumb"
import {MenuItem} from "primevue/menuitem"
import PropertyFileFolder from "@/model/gis/PropertyFileFolder"
import PropertyFolderEditor from "@/components/admin/PropertyFolderEditor.vue"
import Field from "@/model/common/Content"
import {Container, Draggable} from "vue3-smooth-dnd"
import AdditionalField from "@/model/gis/AdditionalField"
import CustomFieldEditorElement from "@/components/admin/CustomFieldEditorElement.vue"
import Card from "primevue/card"
import InputSwitch from "primevue/inputswitch"

@Options({
  name: "PropertySettings",
  components: {
    PropertyFolderEditor, CustomFieldEditorElement, Card, InputSwitch,
    AnimatedInput, ProgressBar, Checkbox, TipTapTextArea, Button, Dialog, Dropdown, Breadcrumb, Container, Draggable
  },
  //@ts-ignore
  props: {}
})
export default class SystemSettings extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient
  settings: Settings | null = null

  folderName: string = ""
  fieldName: string = ""
  fieldValue: string = ""

  uneditableFields: string[] = [
    this.i18n.$gettext("Standort"),
    this.i18n.$gettext("Flurkennzeichung"),
    this.i18n.$gettext("Größe"),
    this.i18n.$gettext("Bebaut"),
    this.i18n.$gettext("In einem Bebauungsplan"),
    this.i18n.$gettext("Bebauungsplan in Vorbereitung"),
    this.i18n.$gettext("Verfügbar"),
    this.i18n.$gettext("Erschließung"),
    this.i18n.$gettext("Erlaubte Nutzung"),
  ]

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    breadcrumbs.push({
      label: this.i18n.$gettext('Einstellungen'),
      to: '/einstellungen'
    })
    return breadcrumbs
  }

  addNewField(){
    const newField: AdditionalField  = new AdditionalField()
    newField.name = this.fieldName
    newField.value = this.fieldValue
    newField.isShared = false
    newField.preventDeletion = true

    this.settings?.additionalFieldsTemplateList.push(newField)
    this.fieldName = ''
    this.fieldValue = ''
  }

  deleteAdditionalField(index: number) {
    if (!this.settings?.additionalFieldsTemplateList || index < 0) return
    this.settings.additionalFieldsTemplateList.splice(index, 1)
  }

  addNewFolder(){
    const newFolder: PropertyFileFolder  = new PropertyFileFolder()
    newFolder.name = this.folderName
    newFolder.created = (new Date()).toISOString()
    newFolder.children = []
    newFolder.contents = []

    this.settings?.propertyFolderTemplateList.push(newFolder)
    this.folderName = ''
  }

  save() {
    settingsServiceApi._setSettings(this.settings).then((settings: Settings) => {
      this.toast.success(this.i18n.$gettext('Die Einstellungen wurden gespeichert.'))
      this.settings = settings
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Die Einstellungen konnten nicht gespeichert werden.'))
    })
  }

  onFieldDrop(fields: Field[], dropResult: any) {
    if ((dropResult.removedIndex !== null || dropResult.addedIndex !== null) && dropResult.removedIndex != dropResult.addedIndex) {
      if (dropResult.removedIndex != null) {
        fields?.splice(dropResult.removedIndex, 1)
      }
      if (dropResult.addedIndex != null && dropResult.payload) {
        fields.splice(dropResult.addedIndex, 0, dropResult.payload)
      }
    }
  }

  mounted() {
    settingsServiceApi._getSettings().then((settings: Settings) => {
      this.settings = settings
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Die Einstellungen konnten nicht geladen werden.'))
    })
  }
}
