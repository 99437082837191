import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f4cc284e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card bg-white mx-auto d-flex flex-column justify-content-center greeting-circle pt-3" }
const _hoisted_2 = {
  class: "text-primary",
  style: {"font-family":"Urbanist,sans-serif","font-weight":"500"}
}
const _hoisted_3 = { class: "align-self-center mb-4 row" }
const _hoisted_4 = { class: "col col-lg-10 offset-lg-1" }
const _hoisted_5 = {
  class: "text-primary mb-0",
  style: {"font-family":"Urbanist,sans-serif","font-weight":"500"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, [
      _renderSlot(_ctx.$slots, "top", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("h3", _hoisted_5, [
      _renderSlot(_ctx.$slots, "bottom", {}, undefined, true)
    ])
  ]))
}