
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import SWR from "@/api/SWR"
import InfiniteList from "@/components/controls/InfiniteList.vue"
import ProgressBar from "primevue/progressbar"
import Dialog from "@/components/common/Dialog.vue"
import useToast from "@/util/toasts"
import Organization from "@/model/Organization"
import {organizationServiceApi} from "@/api/OrganizationServiceApi"
import Dropdown from "@/components/controls/Dropdown.vue"
import RpcError from "@/api/RpcError"
import {twoFactorServiceApi} from "@/api/TwoFactorServiceApi"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Breadcrumb from "primevue/breadcrumb"
import {MenuItem} from "primevue/menuitem"

@Options({
  name: "UserList",
  components: {
    InfiniteList, Button, ProgressBar, Dialog, Dropdown, Breadcrumb
  },
  //@ts-ignore
  props: {
    role: String
  }
})
export default class UserList extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient
  sortAndFilterUtil = SortAndFilterUtil

  role!: string

  organizationId: number | null = null

  get orgIdFromQueryOrIntern(): number | null {
    if (this.organizationId !== null) {
      return this.organizationId
    } else {
      const query = this.$route.query['oid']
      return typeof query === 'string' && query.match(/\d+/) ? Number(query) : null
    }
  }

  get users(): SWR<User[], string[]> {
    return userServiceApi.getUsers([ this.role ])
  }

  get organizations(): SWR<Organization[], number[]> {
    return organizationServiceApi.getOrganizations('CONTRACTOR')
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    if (this.role === 'SYSTEM_USER' && rpcClient.isInternalUser) {
      breadcrumbs.push({
        label: this.i18n.$gettext('Interne Benutzer'),
        to: '/benutzer/intern'
      })
    } else {
      breadcrumbs.push({
        label: rpcClient.isInternalUser ? this.i18n.$gettext('Externe Benutzer') : this.i18n.$gettext('Benutzer'),
        to: '/benutzer'
      })
    }
    return breadcrumbs
  }

  openUser(email: string) {
    this.$router.push('/benutzer/' + encodeURIComponent(email))
  }

  displayName(user: User): string {
    let displayName = ''
    if (user.givenName || user.contact?.givenName) {
      displayName += user.givenName || user.contact?.givenName
    }
    if (user.surName || user.contact?.surName) {
      if (displayName) displayName += ' '
      displayName += user.surName || user.contact?.surName
    }
    return displayName
  }

  displayNameOrEmail(user: User): string {
    const displayName: string = this.displayName(user)
    return (displayName !== '' ? displayName : user.email) || ''
  }

  resetPW(user: User) {
    if (user.email) userServiceApi._sendPasswordResetMail(user.email).then(() => {
      this.toast.error(this.i18n.$gettext('Das Passwort wurde zurückgesetzt.'))
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Das Passwort konnte nicht zurückgesetzt werden.'), e.message)
    })
  }

  reset2FA(user: User) {
    if (user.email) twoFactorServiceApi._changeTwoFactorConfig(null, null, null, user.email).then(() => {
      user.twoFactorEnabled = null
      this.toast.error(this.i18n.$gettext('Die Zwei-Faktor-Authentifizierung wurde zurückgesetzt.'))
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Die Zwei-Faktor-Authentifizierung konnte nicht zurückgesetzt werden.'), e.message)
    })
  }

  setUserState(user: User, state: string) {
    if (user.email) userServiceApi._setUserState(user.email, state).then(() => {
      this.toast.success(this.i18n.$gettext('Das Benutzer-Konto wurde') + ' ' + (state === 'ACTIVE' ? this.i18n.$gettext('aktiviert') : this.i18n.$gettext('deaktiviert')) + '.')
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Das Benutzer-Konto konnte nicht') + ' ' + (state === 'ACTIVE' ? this.i18n.$gettext('aktiviert') : this.i18n.$gettext('deaktiviert')) + 'werden.', e.message)
    })
  }
}
