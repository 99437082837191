
import {Options, Vue} from "vue-class-component"
import Card from "primevue/card"
import Button from "primevue/button"
import Dialog from "@/components/common/Dialog.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Dropdown from "@/components/controls/Dropdown.vue"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import {Container, Draggable} from 'vue3-smooth-dnd'
import PanelEditorField from "@/components/admin/PanelEditorField.vue"
import PropertyFileFolder from "@/model/gis/PropertyFileFolder"
import Field from "@/components/admin/Field.vue"

@Options({
  name: "PropertyFolderEditor",
  components: {
    PanelEditorField, Card, Button, Dialog, AnimatedInput, Dropdown, Checkbox, Container, Draggable
  },
  //@ts-ignore
  props: {
    folder: [ PropertyFileFolder, Object ],
    canMoveUp: Boolean,
    canMoveDown: Boolean,
    id: String,
    disabled: Boolean,
  },
  emits: [
      'copy', 'remove', 'move-up', 'move-down'
  ]
})
export default class PanelEditor extends Vue {

  i18n: Language = useGettext()

  folder!: PropertyFileFolder

  editedFolderName: string = ""
  newFolderName: string = ""


  showNewFolderDialog: boolean = false
  showEditFolderDialog: boolean = false

  openFolderEditDialog() {
    this.resetDialogues()
    this.editedFolderName = this.folder.name || ""
    this.showEditFolderDialog = true
    //TODO
  }

  openNewPanelDialog() {
    this.resetDialogues()
    this.showNewFolderDialog = true
  }

  resetDialogues() {
    this.showEditFolderDialog = false
    this.editedFolderName = ""
    this.showNewFolderDialog = false
    this.newFolderName = ""
    //TODO
  }

  addOrSaveFolder() {
    if (this.folder && this.newFolderName) {
      if (!this.folder.children) {
        this.folder.children = []
      }
      console.log("Creating new Folder")
      const folder = new PropertyFileFolder()
      folder.name = this.newFolderName
      folder.created = (new Date()).toISOString()
      folder.children = []
      folder.contents = []
      this.folder.children.push(folder)

      this.resetDialogues()
    }
  }


  onFieldDrop(fields: Field[], dropResult: any) {
    if ((dropResult.removedIndex !== null || dropResult.addedIndex !== null) && dropResult.removedIndex != dropResult.addedIndex) {
      if (dropResult.removedIndex != null) {
        fields?.splice(dropResult.removedIndex, 1)
      }
      if (dropResult.addedIndex != null && dropResult.payload) {
        fields.splice(dropResult.addedIndex, 0, dropResult.payload)
      }
    }
  }
}
