

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import Divider from "primevue/divider"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import Dropdown from "@/components/controls/Dropdown.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {ref} from "@vue/reactivity"
import L, {FeatureGroup, Layer, PathOptions} from 'leaflet'
import '@geoman-io/leaflet-geoman-free'
import Property from "@/model/gis/Property"
import Location from "@/model/gis/Location"
import {Watch} from "vue-property-decorator"
import Dialog from "@/components/common/Dialog.vue"
import PanoramaImage from "@/model/gis/PanoramaImage"
import {toRaw} from "@vue/reactivity"
import ResizeObserver from "resize-observer-polyfill"

/* Leaflet map */

@Options({
  name: "LeafletMap",
  components: {
    Dialog,
    Button, Divider, TabPanel, TabView, Dropdown
  },
  //@ts-ignore
  props: {
    property: [ Property, Object ],
    properties: [ Property, Array ],
    locations: [ Location, Array ],
    panoramas: [ PanoramaImage, Array ],
    editmode: Boolean,
    isLocationSelector: Boolean,
    onlyShowLocations: Boolean,
    onlyShowProperties: Boolean,
  },
  emits: [
    'showpanorama', 'mapclick', 'shapecreate'
  ]
})
export default class LeafletMap extends Vue {

  i18n: Language = useGettext()

  //@ts-ignore
  mapcontainer: HTMLDivElement = ref<HTMLDivElement>(null)

  zoomLevelSwitchDetails: number = 15
  startPosition: [number, number] = [53.213294, 8.927874] //Kanzlei BPK as Default

  mapObject: L.Map | null = null
  property: Property | null = null
  properties: Property[] = []
  locations: Location[] = []
  panoramas: PanoramaImage[] = []
  editmode: boolean = false
  isLocationSelector: boolean = false
  onlyShowLocations: boolean = false
  onlyShowProperties: boolean = false
  userHasMovedMap: boolean = false

  layerToEdit: Layer | null = null
  showMarkerEditDialog: boolean = false

  selectedPanorama: PanoramaImage|null = null

  //Saves currently rendered objects since you can only remove them by reference
  currentlyRenderedLocations: FeatureGroup | null = null
  currentlyRenderedProperties: FeatureGroup | null = null

  iconPanorama: L.Icon = L.icon({
    iconUrl: '/img/map-marker-photo.png',
    iconSize: [40, 40], // size of the icon
    iconAnchor: [20, 39], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -39] // point from which the popup should open relative to the iconAnchor
  })

  iconDefault: L.Icon = L.icon({
    iconUrl: '/img/map-marker.png',
    iconSize: [40, 40], // size of the icon
    iconAnchor: [20, 39], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -39] // point from which the popup should open relative to the iconAnchor
  })

  iconSearchResult: L.Icon = L.icon({
    iconUrl: '/img/map-marker-grey.png',
    iconSize: [40, 40], // size of the icon
    iconAnchor: [20, 39], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -39] // point from which the popup should open relative to the iconAnchor
  })

  colorAvailable: PathOptions = {
    color: 'blue',
    fillColor: 'blue',
    fillOpacity: 0.4,
  }

  colorUnAvailable: PathOptions = {
    color: 'red',
    fillColor: 'red',
    fillOpacity: 0.3,
  }

  colorNeighbourProperty: PathOptions = {
    color: 'grey',
    fillColor: 'grey',
    fillOpacity: 0.6,
    opacity: 0.6
  }

  get editControls(): any {
    return {
      position: 'topleft',
      drawCircle: false,
      drawCircleMarker: false,
      drawText: false,
      drawPolyline: false,
      drawRectangle: false,
      cutPolygon: false,
      drawMarker: !this.isLocationSelector,
      editControls: !this.isLocationSelector
    }
  }

  mounted() {
    this.$nextTick(() => {
      this.createMap()
    })
  }

  onMapClick(e: L.LeafletMouseEvent): void {
    this.$emit('mapclick', e)
  }

  getGeoJSON(): string | null {
    if (!this.mapObject || !this.property) return null
    //return JSON.stringify(
    //  toRaw(this.mapObject).pm.getGeomanLayers(true).toGeoJSON()
    //)

    const editedGroup: L.FeatureGroup = L.featureGroup()
    toRaw(this.mapObject).pm.getGeomanLayers(true).eachLayer((layer)=>{
      //@ts-ignore
      if (!layer.pm?.options?.pmIgnore) {
        editedGroup.addLayer(layer)
      }
    })
    return JSON.stringify(editedGroup.toGeoJSON())
  }

  discardChanges(): void {
    //TODO reset
    this.refreshMap()
  }

  createMap() {
    let startPostionAdjusted = this.startPosition
    if (this.property && !this.property.geoJson && this.locations) {
      const location = this.locations.find((l: Location) => {
        return l.id === this.property?.locationId
      })
      if (location) {
        startPostionAdjusted = [location.latitude || 0, location.longitude || 0]
      }
    }

    this.mapObject = L.map(this.mapcontainer, {zoomAnimation: true}).setView(startPostionAdjusted, 14)
    toRaw(this.mapObject).removeControl(toRaw(this.mapObject).attributionControl)
    toRaw(this.mapObject).addControl(
      L.control
        .attribution()
        .addAttribution(
          'Kartendaten &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        )
        .setPrefix(false)
    )
    toRaw(this.mapObject).on('click', this.onMapClick)

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(toRaw(this.mapObject))
    L.control.scale().addTo(toRaw(this.mapObject))

    this.refreshMap()
    this.rerenderLocationsIfNecessary()
    this.rerenderPropertiesIfNecessary()

    if (this.property && this.focusProperyGeoJSON) {
      this.mapObject.fitBounds(this.focusProperyGeoJSON.getBounds().pad(0.1), {maxZoom: 22})
    } else if (this.locationLayer?.getBounds()?.isValid()) {
      this.focusMapOnAllLocations()
    }

    toRaw(this.mapObject).on('zoomend', (ev: any) => {
      this.rerenderLocationsIfNecessary()
      this.rerenderPropertiesIfNecessary()
    })

    toRaw(this.mapObject).on('moveend', () => {
      this.userHasMovedMap = true
    })

    //Map setup complete, now check for edit:
    this.applyEditMode()

    //Attach listener if size changes, rerender:
    const resizeObserver = new ResizeObserver((entries) => {
      this.rerender()
    })

    resizeObserver.observe(this.mapcontainer)

    if(this.isLocationSelector) {
      toRaw(this.mapObject).pm.enableDraw("Polygon", {});
    }
  }

  focusMapOnAllLocations(){
    if(this.mapObject && this.locationLayer?.getBounds()?.isValid()) {
      toRaw(this.mapObject).fitBounds(this.locationLayer.getBounds().pad(0.1), {maxZoom: 14})
    }
  }

  applyChanges(): void {
    if (this.layerToEdit) {
      //@ts-ignore
      this.layerToEdit.feature.properties.linkedPano = this.selectedPanorama || ''
      if (this.layerToEdit instanceof L.Marker) {
        this.layerToEdit.setIcon(this.selectedPanorama ? this.iconPanorama : this.iconDefault)
      }
    }
    this.closeEditMarkerDialog()
  }

  get panoramaTargetOptions(): any[] {
    const waypoints: any[] = []
    this.panoramas.forEach((p: PanoramaImage) => {
      waypoints.push({label: p.name || p.originalFileName, value: p.url})
    })
    return waypoints
  }

  closeEditMarkerDialog(): void {
    this.showMarkerEditDialog = false
    this.layerToEdit = null
    this.selectedPanorama = null
  }

  get focusProperyGeoJSON(): L.GeoJSON | null {
    if (!this.property?.geoJson) return null
    return L.geoJson(JSON.parse(this.property.geoJson))
  }

  @Watch('property')
  watchProperty() {
    this.refreshMap()
  }

  @Watch('editmode')
  refreshMap() {
    if (!this.mapObject) return
    toRaw(this.mapObject).on('pm:create', (e) => {
      e.layer.options.pmIgnore = false
      //@ts-ignore
      L.PM.reInitLayer(e.layer)
      this.$emit('shapecreate', e)
    })

    L.Marker.prototype.options.icon = this.iconDefault

    toRaw(this.mapObject).pm.setGlobalOptions({ markerStyle: {
      icon: this.iconDefault
    } })

    toRaw(this.mapObject).pm.getGeomanLayers().forEach((l: L.Layer) => {
      if (this.mapObject) toRaw(this.mapObject).removeLayer(l)
    })
    let layer: L.GeoJSON|null = null //L.GeoJSON is also a feature group!
    if (this.property?.geoJson) {
      const onEachFeature = (feature: any, layer: Layer) => {
        //@ts-ignore
        const isPoint: boolean = layer.feature.geometry.type === "Point"
        if (isPoint) {
          if (this.editmode) {
            layer.bindTooltip("Klicken um Marker mit Panorama zu verknüpfen")
          }
          layer.on({
            click: () => {
              if (!this.mapObject) return
              if (this.editmode) {
                if (!this.anyPmModeEnabled(toRaw(this.mapObject).pm)) {
                  this.layerToEdit = layer
                  this.showMarkerEditDialog = true
                }
              } else {
                //@ts-ignore
                const linkedPanoramaId: string = layer?.feature?.properties?.linkedPano || ''
                if (linkedPanoramaId) {
                  const linkedPanorama: PanoramaImage | undefined = this.panoramas.find((p: PanoramaImage) => p.url == linkedPanoramaId)
                  if (linkedPanorama) {
                    this.$emit('showpanorama', linkedPanorama)
                  }
                }
              }
            },
          })
        }
        //@ts-ignore
        if (layer?.feature?.properties?.linkedPano) {
          //@ts-ignore
          layer.options.icon = this.iconPanorama
        }
      }
      layer = L.geoJson(JSON.parse(this.property.geoJson), {
        pmIgnore: false,
        style: this.getPropStyle(this.property.availability || false),
        onEachFeature: onEachFeature
      })
    }

    if(this.isLocationSelector && this.locations && this.locations.length === 1) {
      const geoJson: string | null = this.locations[0].geoJson
      if(geoJson) {
        layer = L.geoJson(JSON.parse(geoJson), {
          pmIgnore: false,
          style: this.getPropStyle(true)
        })
      }
    }

    if (this.isEditableMap && layer?.getLayers()?.length) {
      toRaw(this.mapObject).addLayer(layer)
      toRaw(this.mapObject).fitBounds(layer.getBounds())
    } else if (this.isEditableMap && this.property?.location?.latitude && this.property.location.longitude) {
      //pan to location
      toRaw(this.mapObject).panTo(new L.LatLng(
        this.property.location?.latitude, this.property.location?.longitude
      ))
    }
  }

  anyPmModeEnabled(pm: L.PM.PMMap): boolean {
    return pm.globalCutModeEnabled() || pm.globalDrawModeEnabled() || pm.globalDragModeEnabled() ||
      pm.globalEditModeEnabled() || pm.globalRotateModeEnabled() || pm.globalRemovalModeEnabled()
  }

  get layerToEditHasPanorama() : boolean {
    return false //TODO
  }

  get isEditableMap() {
    return this.property !== null
  }

  get locationLayer(): L.FeatureGroup {
    const harborIcon = L.icon({
      iconUrl: '/img/map-marker-ship.png',
      iconSize: [40, 40], // size of the icon
      iconAnchor: [20, 39], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -39] // point from which the popup should open relative to the iconAnchor
    })

    const markers: L.Marker[] = []

    if(!this.isLocationSelector) {
      this.locations?.forEach((loc: Location) => {
        if (!loc.latitude || !loc.longitude) return
        const marker: L.Marker = L.marker([loc.latitude, loc.longitude], {icon: harborIcon})
        marker.on("click", () => {
          if (this.mapObject) {
            if(loc.geoJson) {
              const layer: Layer = L.geoJson(JSON.parse(loc.geoJson))
              const bounds : L.LatLngBounds = L.featureGroup([layer]).getBounds()
              toRaw(this.mapObject).fitBounds(bounds.pad(0.1))
            } else {
              toRaw(this.mapObject).setView(marker.getLatLng(), this.zoomLevelSwitchDetails)
            }

          }
        })
        if (loc.name) {
          marker.bindTooltip(loc.name, { permanent: true, direction: "top", offset: [0,-39], interactive: true})
          marker.getTooltip()?.on("click", () => {
            if (this.mapObject) {
              toRaw(this.mapObject).setView(marker.getLatLng(), this.zoomLevelSwitchDetails)
            }
          })
        }
        markers.push(marker)
      })
    }

    return L.featureGroup(markers)
  }

  get propertyLayer(): L.FeatureGroup {
    const parts: L.GeoJSON[] = []

    this.properties?.forEach((property: Property) => {
      const callback: any = (e: any) => {
        if (property.id) {
          this.goToProperty(property.id)
        }
      }
      if (property?.geoJson && this.mapObject &&
          (!this.property || (property.id !== this.property.id && property.locationId === this.property?.locationId))) {
        const options = !!this.property ? {
          pmIgnore: true,
          style: this.colorNeighbourProperty
        } : {
          style: this.getPropStyle(property.availability || false)
        }
        const layer: L.GeoJSON = L.geoJson(JSON.parse(property.geoJson), options)
        layer.getLayers()?.forEach((l: L.Layer) => {
          //@ts-ignore
          if (this.property && l.pm?.options) {
            //@ts-ignore
            l.pm.options.pmIgnore = true
          }
          if (property && property.name) {
            l.bindTooltip(property.name , { permanent: false, direction: "top", offset: [0,0], interactive: true, sticky: true})
          }
          l.on('click', callback)
        })
        parts.push(layer)
      }
    })
    return L.featureGroup(parts)
  }

  getPropStyle(isAvailable: boolean): PathOptions {
    if (isAvailable) return this.colorAvailable
    else return this.colorUnAvailable
  }

  @Watch('editmode')
  applyEditMode(): void {
    if (!this.mapObject) return
    if (this.editmode) {
      toRaw(this.mapObject).pm.addControls(this.editControls)
    } else {
      toRaw(this.mapObject).pm.removeControls()
    }
  }

  @Watch('locationLayer')
  rerenderLocationsIfNecessary(): void {
    //Do we need to do something?
    if (!this.mapObject) return

    //Remove previous layer
    if (this.currentlyRenderedLocations) {
      toRaw(this.mapObject).removeLayer(toRaw(this.currentlyRenderedLocations))
    }

    if (this.onlyShowProperties) return

    if (!this.onlyShowLocations && toRaw(this.mapObject).getZoom() >= this.zoomLevelSwitchDetails) {
      return //Too far zoomed in and not only locations to display
    }

    //Add new layer, save reference
    this.currentlyRenderedLocations = this.locationLayer
    toRaw(this.mapObject).addLayer(toRaw(this.currentlyRenderedLocations))
  }

  @Watch('propertyLayer')
  rerenderPropertiesIfNecessary(): void {
    //Do we need to do something?
    if (!this.mapObject) return

    //Remove previous layer
    if (this.currentlyRenderedProperties) {
      toRaw(this.mapObject).removeLayer(toRaw(this.currentlyRenderedProperties))
    }

    if (this.onlyShowLocations || (!this.property && toRaw(this.mapObject).getZoom() < this.zoomLevelSwitchDetails)) {
      return //Too far zoomed out or only locations to display
    }

    //Add new layer, save reference
    this.currentlyRenderedProperties = this.propertyLayer
    toRaw(this.mapObject).addLayer(toRaw(this.currentlyRenderedProperties))
  }

  goToProperty(propId: number): void {
    this.$router.push('/grundstuecke/' + propId)
  }

  flyToPositon(lat: number, lon: number, zoom: number) {
    if(this.mapObject) {
      L.marker([lat, lon], {icon: this.iconSearchResult}).addTo(toRaw(this.mapObject));
      toRaw(this.mapObject).flyTo([lat, lon], zoom)
    }

  }

  //Map not rendered correctly? Tiles missing? Call this method!
  rerender() {
    if (this.mapObject) {
      toRaw(this.mapObject).invalidateSize()
    }
  }
}

