import Organization from '@/model/Organization';

export default class Offer {
  id!: number | null;
  parentId!: number | null;
  organizationId!: number | null;
  projectId!: number | null;
  stage!: number | null;
  round!: number | null;
  tags!: any | null;
  stageUpdated!: string | null;
  applicationSent!: string | null;
  offerSent!: string | null;
  created!: string | null;
  updated!: string | null;
  deleted!: string | null;
  database!: any | null;
  lotOffers!: Offer[] | null;
  organization!: Organization | null;

  constructor() {
    this.id = null;
    this.parentId = null;
    this.organizationId = null;
    this.projectId = null;
    this.stage = null;
    this.round = null;
    this.tags = null;
    this.stageUpdated = null;
    this.applicationSent = null;
    this.offerSent = null;
    this.created = null;
    this.updated = null;
    this.deleted = null;
    this.database = null;
    this.lotOffers = null;
    this.organization = null;
  }
}
