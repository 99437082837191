export default class LogEntry {
  id!: number | null;
  userName!: string | null;
  projectId!: number | null;
  offerId!: number | null;
  roomId!: number | null;
  type!: string | null;
  created!: string | null;
  data!: any | null;

  constructor() {
    this.id = null;
    this.userName = null;
    this.projectId = null;
    this.offerId = null;
    this.roomId = null;
    this.type = null;
    this.created = null;
    this.data = null;
  }
}
