export default class RoomTemplate {
  id!: number | null;
  name!: string | null;
  description!: string | null;
  stage!: number | null;
  version!: number | null;
  database!: any | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.description = null;
    this.stage = null;
    this.version = null;
    this.database = null;
  }
}
