
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import Project from "@/model/Project"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import SWR from "@/api/SWR"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import FileUpload from "primevue/fileupload"
import RoomDetails from "@/components/common/RoomDetails.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import Card from "primevue/card"
import {rpcClient} from "@/api/WebsocketClient"
import dayjs from "@/util/dayjs"
import Offer from "@/model/Offer"
import {offerServiceApi} from "@/api/OfferServiceApi"
import ProjectDetails from "@/components/common/ProjectDetails.vue"
import {Watch} from "vue-property-decorator"
import {Router, useRouter} from "vue-router"
import i18n from "@/util/i18n"
import DatePicker from "@/components/controls/DatePicker.vue"
import {roomServiceApi} from "@/api/RoomServiceApi"
import Room from "@/model/Room"
import OfferDetails from "@/components/common/OfferDetails.vue"
import RpcError from "@/api/RpcError"
import {projectTagServiceApi} from "@/api/ProjectTagServiceApi"
import Message from 'primevue/message'
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import Dropdown from "primevue/dropdown"
import Sidebar from "@/components/controls/Sidebar.vue"
import ProjectStageCard from "@/components/common/ProjectStageCard.vue"
import MessageList from "@/components/common/MessageList.vue"
import OrganizationPicker from "@/components/controls/OrganizationPicker.vue"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import {organizationServiceApi} from "@/api/OrganizationServiceApi"
import Organization from "@/model/Organization"
import ProgressBar from "primevue/progressbar"
import Multiselect from "@/components/controls/Multiselect.vue"
import Content from "@/model/common/Content"
import useToast from "@/util/toasts"
import AuditLog from "@/components/admin/AuditLog.vue"
import LoginForm from "@/components/LoginForm.vue"
import {useConfirm} from "primevue/useconfirm"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"
import Dialog from "@/components/common/Dialog.vue"
import {Link, MenuStructure} from "@/util/MenuStructure"
import SidebarLink from "@/components/common/Link.vue"
import {MenuItem} from "primevue/menuitem"
import Breadcrumb from "primevue/breadcrumb"
import ContentUtil from "@/util/ContentUtil"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import Tag from "primevue/tag"
import {messageServiceApi} from "@/api/MessageServiceApi"
import {propertyServiceApi} from "@/api/PropertyServiceApi"
import ProjectStages from "@/components/common/ProjectStages.vue"
import {offerStore} from "@/store/OfferStore"
import ProjectWithInfo from "@/util/ProjectWithInfo"
import ProjectDocuments from "@/components/common/ProjectDocuments.vue"
import ProjectRole from "@/model/ProjectRole"
import UserPicker from "@/components/controls/UserPicker.vue"

@Options({
  name: "ProjectView",
  components: {
    UserPicker,
    ProjectStages, OrganizationPicker, MessageList, ProjectStageCard, OfferDetails, ProjectDetails, RoomDetails, Tag,
    AnimatedInput, Dropdown, Sidebar, Button, FileUpload, Card, Dialog, DatePicker, Checkbox, Message, TipTapTextArea,
    ProgressBar, AuditLog, Multiselect, LoginForm, SidebarLink, Breadcrumb, Accordion, AccordionTab, ProjectDocuments
  }
})
export default class ProjectView extends Vue {

  i18n = i18n
  rpcClient = rpcClient
  dayjs = dayjs
  router: Router = useRouter()
  toast = useToast()
  confirm = useConfirm()

  projectId: number | null = null

  showInvitationDialog: boolean = false
  showPublicationDialog: boolean = false
  showApplicationPhaseDialog: boolean = false
  showOpenApplicationsDialog: boolean = false
  showOpenOffersDialog: boolean = false
  showNegotiationPhaseDialog: boolean = false
  showOfferPhaseDialog: boolean = false
  publicationDate: Date | null = null
  applicationDeadline: Date | null = null
  finalDeadline: Date | null = null
  deadlinePeriodDays: number | null = null
  offerDeadline: Date | null = null
  showCreateRoomDialog: boolean = false
  roomName: string = ''
  roomDescription: string = ''
  acceptedOffers: any[][] = []
  invitedEmails: { email: string, organizationName: string }[] = []
  password: string = ''
  selectedLots: string[] = []

  publishToSIMAP: boolean = false
  startApplicationPhaseWhenPublished: boolean = true
  skipApplicationPhase: boolean = false
  startOfferPhase: boolean = false

  TYPE_OPTIONS: { type: string, label: string }[] = [
    {
      type: '0',
      label: this.i18n.$gettext('Ausschreibung')
      /*}, {
        type: '1',
        label: this.i18n.$gettext('Ohne Teilnahmewettbewerb')*/
    }, {
      type: '2',
      label: this.i18n.$gettext('Interessenbekundungsverfahren')
    }, {
      type: '3',
      label: this.i18n.$gettext('Bekanntmachung')
    }, {
      type: '4',
      label: this.i18n.$gettext('Vergebener Auftrag')
    }, {
      type: '5',
      label: this.i18n.$gettext('Dauerverhandlungsverfahren')
    }
  ]

  ROLE_OPTIONS: { role: string, label: string }[] = [
    {
      role: 'ADMIN',
      label: this.i18n.$gettext('Projekt-Admin')
    }, {
      role: 'EDITOR',
      label: this.i18n.$gettext('Sachbearbeiter')
    }
  ]
  showAddTeamMembersDialog: boolean = false
  currentTeamMembers: ProjectRole[] | null | undefined
  newTeamMembers: User[] = []

  displayRole(role: string): string {
    return this.ROLE_OPTIONS.find((r) => r.role == role)?.label || 'Sachbearbeiter'
  }

  canAddMembers(): boolean {
    return !this.loadingAddMembers && Boolean(this.newTeamMembers?.length && this.newTeamMembers.length > 0)
  }
  openAddTeamMembersDialog() {
    this.newTeamMembers = []
    if (this.projectId && this.project) {
      this.showAddTeamMembersDialog = true
    }
  }

  closeAddTeamMembersDialog() {
    this.newTeamMembers = []
    this.showAddTeamMembersDialog = false
  }

  loadingAddMembers: boolean = false
  addNewTemMembers() {
    if (!this.projectId) return
    if (!this.newTeamMembers?.length || this.newTeamMembers.length < 1) return
    this.loadingAddMembers = true
    const userNames: string[] = []
    this.newTeamMembers.forEach((u: User) => {
      if (u && u.email) userNames.push(u.email)
    })
    projectServiceApi._addProjectEditors(this.projectId, userNames).then((id: number) => {
      this.toast.success(this.i18n.$gettext('Sachbearbeiter hinzugefügt.'))
      this.closeAddTeamMembersDialog()
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext('Hinzufügen fehlgeschlagen.'))
    }).finally(() => {
      this.loadingAddMembers = false
    })

  }

  displayName(userEmail: string): string {
    const user: User|null = this.users?.data?.find((u: User) => u.email == userEmail) || null
    if (!user) return userEmail
    return this.displayUser(user)
  }

  displayUser(user: User): string {
    let displayName = ''
    if (user.givenName || user.contact?.givenName) {
      displayName += user.givenName || user.contact?.givenName
    }
    if (user.surName || user.contact?.surName) {
      if (displayName) displayName += ' '
      displayName += user.surName || user.contact?.surName
    }
    return displayName
  }

  get projectTypeName(): string {
    return this.TYPE_OPTIONS.find(o => o.type === String(this.projectInfo?.type || '0'))?.label || ''
  }

  get childRoute1() {
    return this.$route.params?.childRoute1
  }

  get childId1() {
    return this.$route.params?.childId1
  }

  get childRoute2() {
    return this.$route.params?.childRoute2
  }

  get childId2() {
    return this.$route.params?.childId2
  }

  get linkedProperties() { //TODO lots too
    return this.projectInfo?.propertyIds?.map((id: number) => propertyServiceApi.getPropertyById(id).data)?.filter((p: any) => !!p) || []
  }

  get organizationId() {
    if ([ 'teilnehmer', 'bieter', 'bewerber' ].includes(this.childRoute1)) {
      return this.childId1
    } else if ([ 'teilnehmer', 'bieter', 'bewerber' ].includes(this.childRoute2)) {
      return this.childId2
    }
  }

  get project(): SWR<Project | null, number> | null {
    if (this.projectId) {
      const swr:SWR<Project | null, number> | null = projectServiceApi.getProject(this.projectId)
      this.applicationDeadline = swr?.data?.applicationDeadline ? dayjs(swr.data.applicationDeadline).toDate() : null
      this.publicationDate = swr?.data?.published ? dayjs(swr.data.published).toDate() : null
      this.offerDeadline = swr?.data?.offerDeadline ? dayjs(swr.data.offerDeadline).toDate() : null
      this.deadlinePeriodDays = swr?.data?.deadlinePeriodDays || null
      this.finalDeadline = swr?.data?.finalDeadline ? dayjs(swr.data.finalDeadline).toDate() : null
      this.currentTeamMembers = swr?.data?.team
      return swr
    } else {
      return null
    }
  }

  get offers(): SWR<Offer[], number[]> | null {
    if ((this.isProjectEditor || (!rpcClient.isInternalUser && rpcClient.session.user?.organizationId)) && this.projectId) {
      return offerServiceApi.getOffersByProjectId(this.projectId)
    } else {
      return null
    }
  }

  get rooms(): SWR<Room[], number[]> | null {
    if (this.projectId) { //TODO only during "verhandlung" and take rooms from project?
      return roomServiceApi.getRoomsByProjectId(this.projectId)
    } else {
      return null
    }
  }

  get generalRooms(): Room[] {
    return this.rooms?.data?.filter((r: Room) => !r.organizationId && !r.offerId) || []
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    breadcrumbs.push({
      label: this.i18n.$gettext('Ausschreibungen'),
      to: '/projekte'
    })
    const name = this.projectInfo?.name
    if (name) {
      breadcrumbs.push({
        label: name.substring(0, Math.min(20 , name.length)) + (name.length > 20 ? '...' : ''),
        to: '/projekte/' + this.projectInfo?.id
      })
    }
    //TODO hashes etc.?
    return breadcrumbs
  }

  get hasRequiredIncomplete() {
    return !!this.projectInfo?.panels.find((p: Content) => !!ContentUtil.getRequiredIncompleteField(p))
  }

  get unreadMessages(): number {
    return messageServiceApi.getMessages(this.projectId, null).data?.filter(m => !m.read)?.length || 0
  }

  roomsByOfferId(offerId: number | string | null | undefined): Room[] {
    return offerId ? (this.rooms?.data?.filter((r: Room) => !r.organizationId && String(r.offerId) === String(offerId)) || []) : []
  }

  get organizationSpecificRooms(): Room[] {
    if (!this.rpcClient.isInternalUser && rpcClient.session.user?.organizationId) {
      return this.rooms?.data?.filter((r: Room) => r.organizationId === rpcClient.session.user?.organizationId) || []
    } else {
      return []
    }
  }

  get offer(): Offer | null {
    const organizationId: string | number | undefined | null = this.organizationId || rpcClient.session.user?.organizationId
    if (organizationId) {
      return (this.offers?.data || []).find(o => String(o.organizationId) === String(organizationId)) || null
    } else {
      return null
    }
  }

  get room() {
    const roomId = this.childId1
    const offerId = this.childId2
    if (roomId && offerId) {
      return this.roomsByOfferId(offerId).find((r: Room) => String(r.id) === roomId)
    } else if (roomId) {
      return this.rooms?.data?.find(r => String(r.id) === String(roomId))
    }
  }

  get hasLots(): boolean {
    return (this.projectInfo?.lots?.length || 0) > 1
  }

  get lots(): ProjectWithInfo[] {
    if (this.projectInfo?.lotsWithInfo?.length) {
      return this.projectInfo.lotsWithInfo
    } else if (this.projectInfo) {
      return [ this.projectInfo ]
    } else {
      return []
    }
  }

  get lotsWithCanStartApplicationPhase(): ProjectWithInfo[] {
    return this.lots.filter(p => p.canStartApplicationPhase)
  }

  get lotsWithLaterRoundActive(): ProjectWithInfo[] {
    return this.lots.filter(p => p.laterRoundActive)
  }

  get lotsWithFirstRoundActive(): ProjectWithInfo[] {
    return this.lots.filter(p => p.firstRoundActive)
  }

  get lotsWithCanOpenFirstRound(): ProjectWithInfo[] {
    return this.lots.filter(p => p.canOpenFirstRound)
  }

  get lotsWithCanStartNegotiationPhase(): ProjectWithInfo[] {
    return this.lots.filter(p => p.canStartNegotiationPhase)
  }

  get lotsWithNegotiationPhaseActive(): ProjectWithInfo[] {
    return this.lots.filter(p => p.negotiationPhaseActive)
  }

  get lotsWithCanStartOfferPhase(): ProjectWithInfo[] {
    return this.lots.filter(p => p.canStartOfferPhase)
  }

  get lotsWithOfferPhaseActive(): ProjectWithInfo[] {
    return this.lots.filter(p => p.offerPhaseActive)
  }

  get lotsWithCanOpenOffers(): ProjectWithInfo[] {
    return this.lots.filter(p => p.canOpenOffers)
  }

  get lotsWithCanOpenNextRound(): ProjectWithInfo[] {
    return this.lots.filter(p => !p.canOpenFirstRound && (p.nextOpenableRound || 0) > 1)
  }

  get lotsWithOpenDate(): ProjectWithInfo[] {
    return this.lots.filter(p => p.openDateText)
  }

  get lotsWithOpenedBy(): ProjectWithInfo[] {
    return this.lots.filter(p => p.openedByText)
  }

  get lotsWithCanOpenSecondStep(): ProjectWithInfo[] {
    return this.lots.filter(p => p.canOpenSecondStep)
  }

  //TODO
  get lotsWithCanApply(): ProjectWithInfo[] {
    return this.rpcClient.isInternalUser ? [] : this.lots.filter(p => p.canApply)
  }

  get canInvite(): boolean {
    return !!this.lots.find((p: ProjectWithInfo) => {
      if (p.laterRoundActive) {
        return true
      } else if (p.skippedPhases?.includes('3')) {
        return (p.stage || 0) < 6 || p.offerPhaseActive
      } else {
        return (p.stage || 0) < 3 || p.firstRoundActive
      }
    })
  }

  get allLotsSameDates(): boolean {
    if (this.lots.length > 1) {
      let applicationDeadline
      let offerDeadline
      let finalDeadline
      let deadlinePeriodDays
      for (const lot of this.lots) {
        if (!applicationDeadline) {
          applicationDeadline = lot.applicationDeadline
        } else if (applicationDeadline !== lot.applicationDeadline ) {
          return false
        }
        if (!offerDeadline) {
          offerDeadline = lot.offerDeadline
        } else if (offerDeadline !== lot.offerDeadline ) {
          return false
        }
        if (lot.type === 5) {
          if (!finalDeadline) {
            finalDeadline = lot.finalDeadline
          } else if (finalDeadline !== lot.finalDeadline ) {
            return false
          }
          if (!deadlinePeriodDays) {
            deadlinePeriodDays = lot.deadlinePeriodDays
          } else if (deadlinePeriodDays !== lot.deadlinePeriodDays ) {
            return false
          }
        }
      }
    }
    return true
  }

  get isProjectOrSystemAdmin(): boolean {
    const userName = rpcClient.session.user?.email
    return Boolean(userName && rpcClient.session.user?.roles?.find((r: string) => r === 'SYSTEM_ADMIN') ||
        (rpcClient.isInternalUser && this.projectInfo?.team?.find(r => r.userName === userName && r.role === 'ADMIN')))
  }

  get isProjectEditor(): boolean {
    const userName = rpcClient.session.user?.email
    return Boolean(userName && rpcClient.session.user?.roles?.find((r: string) => r === 'SYSTEM_ADMIN') ||
        (rpcClient.isInternalUser && this.projectInfo?.team?.find(r => r.userName === userName)))
  }

  get minPublishingDate(): Date {
    const minDate: Date = this.publishToSIMAP ? new Date(new Date().getTime() + 2*86400000) : new Date()
    this.publicationDate = minDate
    return minDate
  }

  get isOnWatchlist() {
    return Boolean(this.projectInfo?.tags?.includes('merkliste'))
  }

  get users(): SWR<User[], string[]> {
    return userServiceApi.getUsers([ 'ORGANIZATION_USER', 'ORGANIZATION_ADMIN' ])
  }

  get filteredEditorsOptions(): User[] {
    return this.editorsOptions.data?.filter((user: User) => {
      const alreadyMember: boolean = Boolean(this.currentTeamMembers && this.currentTeamMembers.find((p: ProjectRole) => p.userName == user.email))
      const alreadyAdded: boolean =  Boolean(this.newTeamMembers && this.newTeamMembers.find((u: User) => u.email == user.email))
      return !(alreadyMember || alreadyAdded)
    }) || []
  }

  get editorsOptions(): SWR<User[], string[]> {
    return userServiceApi.getUsers([ 'SYSTEM_USER' ])
  }

  get organizations(): SWR<Organization[], number[]> {
    return organizationServiceApi.getOrganizations('CONTRACTOR')
  }

  get inviteToken() {
    return this.$route.query?.inviteToken
  }

  //TODO check and allow moving to later round
  get offersWithCanUploadApplication(): Offer[] {
    return this.lots.filter(l => l.lotOffer && !l.lotOffer.stage && l.lotOffer.round === l.currentApplicationRound && (l.firstRoundActive || l.laterRoundActive)).map(l => l.lotOffer) as Offer[]
  }

  //TODO check
  get offersWithCanUploadOffer(): Offer[] {
    return this.lots.filter(l => l.lotOffer && l.lotOffer.stage && l.lotOffer.stage >= 4 && l.lotOffer.stage <= 5 && l.lotOffer.round === l.lastOpenedRound && l.offerPhaseActive).map(l => l.lotOffer) as Offer[]
  }

  get projectInfo(): ProjectWithInfo | null {
    const project = this.project?.data
    const isPublished = Boolean(project?.stage && project.stage > 1 && project.published && dayjs(project.published).isBefore(dayjs()))
    return project ? this.getProjectWithInfo(project, isPublished) : null
  }

  getProjectWithInfo(project: Project, isPublished: boolean): ProjectWithInfo {
    const now: dayjs.Dayjs = dayjs()
    const applicationDeadline: dayjs.Dayjs | null = project.applicationDeadline ? dayjs(project.applicationDeadline) : null
    const finalDeadline: dayjs.Dayjs | null = project.finalDeadline ? dayjs(project.finalDeadline) : null
    const offerDeadline: dayjs.Dayjs | null = project.offerDeadline ? dayjs(project.offerDeadline) : null
    let nextDeadline: dayjs.Dayjs | null = null
    if (project.type === 5 && project.deadlinePeriodDays && project.currentApplicationRound && project.currentApplicationRound > 1 && applicationDeadline) {
      nextDeadline = applicationDeadline.add((project.currentApplicationRound - 1) * project.deadlinePeriodDays, 'day')
      this.setReloadTimer(nextDeadline)
    } else if (applicationDeadline) {
      this.setReloadTimer(applicationDeadline)
    }
    if (finalDeadline) this.setReloadTimer(finalDeadline)
    if (offerDeadline) this.setReloadTimer(offerDeadline)

    const lots: ProjectWithInfo[] = project.lots ? project.lots.map(p => this.getProjectWithInfo(p, isPublished)) : []
    const firstRoundActive: boolean = (project.stage === 3 || project.type === 2) && Boolean(applicationDeadline?.isAfter(now))
    const laterRoundActive: boolean = !firstRoundActive && project.type === 5 && (project.currentApplicationRound || 1) > 1 && Boolean(finalDeadline?.isAfter(now))
    const canStartApplicationPhase: boolean = isPublished && (project.stage || 0) <= 2
    //const hasApplicationsInCurrentRound: boolean = !!project.numberOfApplicationsByRound && !!project.numberOfApplicationsByRound[project.currentApplicationRound || '1']
    const hasApplicationsInOpenedRound: boolean = !!project.numberOfApplicationsByRound && !!project.lastOpenedRound && !!project.numberOfApplicationsByRound[project.lastOpenedRound || '1']
    const hasOffersInOpenedRound: boolean = !!project.numberOfOffersByRound && !!project.lastOpenedRound && !!project.numberOfOffersByRound[project.lastOpenedRound || '1']
    const canOpenFirstRound: boolean = Boolean(project.numberOfApplicationsByRound && project.numberOfApplicationsByRound['1'] && project.stage === 3 && applicationDeadline?.isBefore(now))
    const canStartNegotiationPhase: boolean = !project.skippedPhases?.includes('5') && Boolean(project.skippedPhases?.includes('3') ? (project.stage || 0) < 5 : (project.stage === 4 && hasApplicationsInOpenedRound))
    const negotiationPhaseActive: boolean = project.stage === 5
    let canStartOfferPhase: boolean
    if (project.skippedPhases?.includes('6')) {
      canStartOfferPhase = false
    } else if (project.skippedPhases?.includes('3') && project.skippedPhases?.includes('5')) {
      canStartOfferPhase = (project.stage || 0) < 6
    } else if (project.skippedPhases?.includes('3')) {
      canStartOfferPhase = (project.stage || 0) === 5
    } else if (project.skippedPhases?.includes('5')) {
      canStartOfferPhase = (project.stage || 0) === 4 && hasApplicationsInOpenedRound
    } else {
      canStartOfferPhase = (project.stage || 0) === 5 && hasApplicationsInOpenedRound
    }
    const offerPhaseActive: boolean = project.stage === 6 && Boolean(offerDeadline?.isAfter(now))
    const canOpenOffers: boolean = project.stage === 6 && !offerPhaseActive && hasOffersInOpenedRound
    const openDate: string | undefined = project?.openDate || undefined
    const openUser: string | undefined = project?.openUser || undefined
    const openDateText: string | null = openDate ? dayjs(openDate).format('LLL') : null
    const openedByText: string | null = (openUser && openDate && dayjs(openDate).isAfter(dayjs(new Date().getTime() - 15*60000))) ? openUser : null
    const openingInProgress = Boolean(openDate && openUser && dayjs(openDate).isAfter(dayjs(new Date().getTime() - 15*60000)))
    const canOpenSecondStep: boolean = openingInProgress && openUser !== rpcClient.session.user?.email
    const skippedPhases = project.skippedPhases || ''
    let lotOffer: Offer | null = null
    if (!this.rpcClient.isInternalUser && this.offer) {
      lotOffer = String(this.offer.projectId) === String(project.id) ? this.offer : this.offer?.lotOffers?.find(lo => String(lo.projectId) === String(project.id)) || null
    }
    let canApply = false
    if (firstRoundActive || laterRoundActive) {
      canApply = !lotOffer || (!lotOffer.stage && (lotOffer.round || 1) < (project.currentApplicationRound || 1))
    } else if (this.inviteToken && skippedPhases.includes('3')) {
      canApply = negotiationPhaseActive || (skippedPhases.includes('5') && offerPhaseActive)
    }
    const canOffer = offerPhaseActive && (lotOffer?.stage || 0) >= 4
    let stageName = ''
    if (project.type === 3) {
      stageName = this.TYPE_OPTIONS.find(o => o.type === '3')?.label || ''
    } else if (project.type === 4) {
      stageName = this.TYPE_OPTIONS.find(o => o.type === '4')?.label || ''
    } else if (!project.stage || !isPublished) {
      stageName = this.i18n.$gettext('Entwurf')
    } else if (project.stage < 3) {
      if (project.published && dayjs(project.published).isAfter(now)) {
        stageName = this.i18n.$gettext('Veröffentlichung am' + ' ' + dayjs(project.published).format('LLL'))
      } else if (project.published) {
        stageName = this.i18n.$gettext('Veröffentlicht')
      }
    } else if (firstRoundActive) {
      stageName = this.i18n.$gettext('Teilnahmewettbewerb (aktiv)')
    } else if (project.stage < 4) {
      stageName = this.i18n.$gettext('Teilnahmewettbewerb (beendet)')
    } else if (project.stage < 5) {
      stageName = this.i18n.$gettext('Teilnahmewettbewerb (geöffnet)')
    } else if (project.stage < 6) {
      stageName = this.i18n.$gettext('Verhandlung')
    } else if (offerPhaseActive) {
      stageName = this.i18n.$gettext('Angebotsphase (aktiv)')
    } else if (project.stage < 7) {
      stageName = this.i18n.$gettext('Angebotsphase (beendet)')
    } else if (project.stage < 8) {
      stageName = this.i18n.$gettext('Angebotsphase (geöffnet)')
    } else {
      stageName = this.i18n.$gettext('Archiviert')
    }

    return new ProjectWithInfo(
        project, lots, stageName,
        canStartApplicationPhase,
        laterRoundActive,
        firstRoundActive,
        this.offersByRound(project),
        canOpenFirstRound,
        canStartNegotiationPhase,
        negotiationPhaseActive,
        canStartOfferPhase,
        offerPhaseActive,
        canOpenOffers,
        openDateText,
        openedByText,
        canOpenSecondStep,
        openingInProgress || !!lots?.find(l => l.openingInProgress), //Not dependent on lot. Only one opening process allowed at a time
        isPublished, //Not dependent on lot.
        canApply,
        canOffer,
        lotOffer
    )
  }

  timers: any = {}
  setReloadTimer(time: dayjs.Dayjs) {
    if (!this.timers[time.toISOString()]) {
      const millis = time.diff(dayjs())
      if (millis > 0) {
        this.timers[time.toISOString()] = setTimeout(() => {
          if (this.projectId) projectServiceApi.getProject(this.projectId)
        }, millis)
      }
    }
  }

  offersByRound(project: Project): { offer: Offer, lotOffer: Offer }[][] {
    if (this.isProjectEditor) {
      const rounds = project.currentApplicationRound || 1
      const offersByRound: { offer: Offer, lotOffer: Offer }[][] = []
      for (let i = 1; i <= rounds; i++) {
        offersByRound[i] = (this.offers?.data || []).map((o: Offer) => {
          const lotOffer = String(o.projectId) === String(project.id) ? o : o.lotOffers?.find(lo => String(lo.projectId) === String(project.id))
          return ((lotOffer?.stage || 0) >= 1 && lotOffer?.round === i) ? { offer: o, lotOffer: lotOffer } : null
        }).filter(o => !!o) as { offer: Offer, lotOffer: Offer }[]
      }
      return offersByRound
    } else {
      return []
    }
  }

  get showChanges(): boolean {
    return !!this.project?.data?.hasChanges
  }

  get filterPanels(): Content[] {
    const exclusionList = [ 'meta-data-group', 'GR-LotsGroup', 'GR-Organisations-Section' ]
    if (!this.showChanges) {
      exclusionList.push('GR-Change')
    }
    return (this.projectInfo?.panels || []).filter((p: Content) => {
      return !p.hidden && (!p.repeatable || p.added) &&
          !exclusionList.includes(p.contentId || '') &&
          (p.required || ContentUtil.hasValuedField(p))
    }).map((p: Content) => {
      if (p.contentId === 'GR-Buyer') {
        return this.findOrganizationForm(p.children || []) || p
      } else {
        return p
      }
    })
  }

  findOrganizationForm(contents: Content[]): Content | null {
    return ContentUtil.findContent(contents, 'GR-ContractingAuthority')
  }

  get menuStructure(): Link[] {
    const panels = [...this.filterPanels]
    return MenuStructure.menuStructure(panels, '/projekte/' + this.projectId)
  }

  roomDisabled(room: Room): boolean {
    return Boolean(!this.projectInfo || this.projectInfo.archived ||
        (room.stage || 0) < (this.projectInfo.stage || 0) ||
        (this.rpcClient.isInternalUser && room.type !== 'CLIENT') ||
        (!this.rpcClient.isInternalUser && (room.type !== 'CONTRACTOR' ||
            ((room.stage || 0) <= 4 && !this.offersWithCanUploadApplication.find(o => String(room.offerId) === String(o.id))) ||
            ((room.stage || 0) >= 6 && (room.stage || 0) <= 7 && !this.offersWithCanUploadOffer.find(o => String(room.offerId) === String(o.id))))))
  }

  finalizeOffer(offer: Offer) { //TODO check documents
    if (offer.id && (!offer.stage || offer.stage === 4 || offer.stage === 5)) {
      let selectedLots = null
      if (offer.parentId && offer.projectId && this.hasLots) {
        selectedLots = [ String(offer.projectId) ]
      }
      const stage = !offer.stage ? 1 : 6
      offerServiceApi._updateOfferStage(offer.parentId || offer.id, stage, selectedLots).then((id: number) => {
        //TODO
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Das Angebot konnte nicht eingereicht werden.'))
      })
    }
  }

  withdrawOffer(offer: Offer) {
    if (offer.id) {
      let selectedLots = null
      if (offer.parentId && offer.projectId && this.hasLots) {
        selectedLots = [ String(offer.projectId) ]
      }
      const stage = (offer.stage || 0) >= 6 ? 5 : 0
      offerServiceApi._updateOfferStage(offer.parentId || offer.id, stage, selectedLots).then((id: number) => {
        //TODO
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Das Angebot konnte nicht zurückgezogen werden.'))
      })
    }
  }

  @Watch('$route.params')
  watchRouteParams(params: any) {
    if (params && params.hasOwnProperty("id")) {
      this.projectId = Number(params['id'])
    } else {
      this.projectId = null
    }
  }

  closeOrganizationDetails() {
    this.$router.push('/projekte/' + this.projectId + '/' + this.childRoute1)
  }

  openInvitationDialog() {
    this.invitedEmails = []
    this.showInvitationDialog = true
  }

  openPublicationDialog() {
    this.publishingStep = 0
    this.publishToSIMAP = false
    this.startOfferPhase = false
    this.startApplicationPhaseWhenPublished = false
    this.skipApplicationPhase = false
    this.showPublicationDialog = true
  }

  openApplicationPhaseDialog() {
    this.acceptedOffers = []
    if (this.projectInfo?.applicationDeadline) {
      this.applicationDeadline = dayjs(this.projectInfo.applicationDeadline).toDate()
    }
    this.selectedLots = this.lotsWithCanStartApplicationPhase.map(p => String(p.id))
    this.showApplicationPhaseDialog = true
  }

  openOpenOffersDialog() {
    if (this.projectId) {
      void projectServiceApi._getProject(this.projectId).then(() => {
        this.$nextTick(() => {
          if (this.projectInfo?.openingInProgress) {
            this.selectedLots = this.lotsWithCanOpenSecondStep.map(p => String(p.id))
          } else {
            this.selectedLots = this.lotsWithCanOpenOffers.map(p => String(p.id))
          }
          this.showOpenOffersDialog = true
        })
      })
    }
  }

  openOpenApplicationsDialog() {
    if (this.projectId) {
      void projectServiceApi._getProject(this.projectId).then(() => {
        this.$nextTick(() => {
          if (this.projectInfo?.openingInProgress) {
            this.selectedLots = this.lotsWithCanOpenSecondStep.map(p => String(p.id))
          } else {
            //TODO distinguish first or later round
            this.selectedLots = this.lotsWithCanOpenFirstRound.map(p => String(p.id))
          }
          this.showOpenApplicationsDialog = true
        })
      })
    }
  }

  openNegotiationPhaseDialog() {
    this.acceptedOffers = []
    this.selectedLots = this.lotsWithCanStartNegotiationPhase.map(p => String(p.id))
    this.showNegotiationPhaseDialog = true
  }

  openOfferPhaseDialog() {
    this.acceptedOffers = []
    if (this.projectInfo?.offerDeadline) {
      this.offerDeadline = dayjs(this.projectInfo.offerDeadline).toDate()
    }
    this.selectedLots = this.lotsWithCanStartOfferPhase.map(p => String(p.id))
    this.showOfferPhaseDialog = true
  }

  inviteUsers() {
    const invites: any = {}
    if (this.invitedEmails) {
      for (let invite of this.invitedEmails) {
        invites[invite.email] = invite.organizationName
      }
    }
    if (this.projectId) {
      void projectServiceApi._inviteUsers(this.projectId, invites).then(() => {
        this.invitedEmails = []
        this.showInvitationDialog = false
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Die Einladungen konnten nicht gesendet werden.'))
      })
    }
  }

  async updateStage(stage: number) {
    if (this.project?.data) {
      const project = JSON.parse(JSON.stringify(this.project.data))
      if ([ 3, 4 ].includes(this.projectInfo?.type || 0)) {
        project.skippedPhases = '3,4,5,6,7'
      } else if (this.startOfferPhase) {
        project.skippedPhases = '3,4,5'
      } else if (this.skipApplicationPhase || this.projectInfo?.type === 2) {
        project.skippedPhases = '3,4'
      }
      if (this.publishToSIMAP && stage > 0 && !project.stage && project.id) {
        await projectServiceApi._publishToSIMAP(project.id)
      }
      project.stage = stage

      if (this.hasLots) {
        for (let id of this.selectedLots) {
          const lot: Project | undefined = (project.lots || []).find((l: Project) => String(l.id) === String(id))
          if (lot) {
            lot.stage = stage
          }
        }
      }
      if (stage <= 3) {
        if (this.publicationDate) {
          project.published = this.publicationDate?.toISOString()
        }
        if (this.applicationDeadline) {
          project.applicationDeadline = this.applicationDeadline?.toISOString()
        }
        if (this.finalDeadline) {
          project.finalDeadline = this.finalDeadline?.toISOString()
        }
        if (this.deadlinePeriodDays) {
          project.deadlinePeriodDays = this.deadlinePeriodDays
        }
      }
      if (stage === 5) { //TODO handle errors
        const offersToAccept: any = {}
        const offersToDecline: any = {}

        for (const lot of this.lots) {
          const offers: { offer: Offer, lotOffer: Offer }[] = lot.offersByRound[lot.lastOpenedRound || 1].filter(o => o.lotOffer.stage === 1 && !!o.offer.organization)
          for (const o of offers) {
            if (o.lotOffer?.id && !!this.acceptedOffers[lot.id || 0]?.find(id => String(id) === String(o.lotOffer.id))) {
              const lotIds: string[] = offersToAccept[String(o.offer.id)] || []
              if (!lotIds.includes(String(lot.id))) {
                lotIds.push(String(lot.id))
              }
              offersToAccept[String(o.offer.id)] = lotIds
            } else {
              const lotIds: string[] = offersToDecline[String(o.offer.id)] || []
              if (!lotIds.includes(String(lot.id))) {
                lotIds.push(String(lot.id))
              }
              offersToDecline[String(o.offer.id)] = lotIds
            }
          }
        }

        for (const id in offersToDecline) {
          await offerServiceApi._updateOfferStage(Number(id), 2, offersToDecline[id])
        }
        for (const id in offersToAccept) {
          await offerServiceApi._updateOfferStage(Number(id), 3, offersToAccept[id])
        }
      }
      if (stage <= 6 && this.offerDeadline) {
        project.offerDeadline = this.offerDeadline?.toISOString()
      }
      if (stage === 6) { //TODO handle errors
        const offersToAccept: any = {}
        for (const lot of this.lots) {
          const offers: { offer: Offer, lotOffer: Offer }[] = lot.offersByRound[lot.lastOpenedRound || 1].filter(o => o.lotOffer.stage === 3 && !!o.offer.organization)
          for (const o of offers) {
            if (o.lotOffer?.id && !!this.acceptedOffers[lot.id || 0]?.find(id => String(id) === String(o.lotOffer.id))) {
              const lotIds: string[] = offersToAccept[String(o.offer.id)] || []
              if (!lotIds.includes(String(lot.id))) {
                lotIds.push(String(lot.id))
              }
              offersToAccept[String(o.offer.id)] = lotIds
            }
          }
        }

        for (const id in offersToAccept) {
          await offerServiceApi._updateOfferStage(Number(id), 4, offersToAccept[id])
        }
      }
      await projectServiceApi._updateProjectStage(project, this.hasLots ? this.selectedLots : null, this.password).then((id: number) => {
        this.showApplicationPhaseDialog = false
        this.showPublicationDialog = false
        this.showOfferPhaseDialog = false
        this.showNegotiationPhaseDialog = false
        if (stage === 4) {
          //this.$router.push('/projekte/' + this.projectId + '/bewerber')
        }
        if (stage === 7) {
          //this.$router.push('/projekte/' + this.projectId + '/bieter')
        }
        if (this.publishToSIMAP) {
          this.toast.error(this.i18n.$gettext('Die Ausschreibung wurde veröffentlicht.'))
        }
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Die Ausschreibungs-Stufe konnte nicht geändert werden.'))
      }).finally(() => {
        if (this.projectId) {
          offerServiceApi.getOffersByProjectId(this.projectId, true) //refresh offers, they may have been updated
        }
      })
    }
  }

  addToWatchlist() {
    const organizationId = rpcClient.session.user?.organizationId
    if (organizationId && this.projectId) {
      projectTagServiceApi._addProjectTag(this.projectId, 'merkliste').then(() => {
        //TODO
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Die Ausschreibung konnte nicht auf die Merkliste gesetzt werden.'))
      })
    } else if (!organizationId) {
      rpcClient.nextRoute.afterLogin = this.$route
      this.$router.replace({ query: { message: 'nr_wat' } })
    }
  }

  openApplications() {
    this.updateStage(4) //TODO
    this.password = ''
    this.showOpenApplicationsDialog = false
  }

  openOffers() {
    this.updateStage(7) //TODO
    this.password = ''
    this.showOpenOffersDialog = false
  }

  cancelProject() {
    const project = this.projectInfo
    if (project) {
      if (project.isPublished) {
        this.confirm.require({
          message: this.i18n.$gettext('Achtung: Die Ausschreibung wurde bereits veröffentlicht. Es muss eine Bekanntmachung erstellt werden.'),
          header: this.i18n.$gettext('Verfahren abbrechen'),
          icon: 'cil-warning',
          accept: () => {
            this.$nextTick(() => {
              this.confirmCancelProject(this.project?.data)
            })
          },
          reject: () => {
            //callback to execute when user rejects the action
          }
        })
      } else {
        this.confirmCancelProject(this.project?.data)
      }
    }
  }

  confirmCancelProject(project: Project | null | undefined) {
    if (project) {
      const newProject = Object.assign(new Project(), project)
      this.confirm.require({
        message: this.i18n.$gettext('Sind Sie sicher, dass Sie das Verfahren abbrechen und archivieren möchten?'),
        header: this.i18n.$gettext('Verfahren abbrechen'),
        icon: 'cil-warning',
        accept: () => {
          newProject.stage = 8
          newProject.archived = new Date().toISOString()
          projectServiceApi._updateProjectStage(newProject, null, null).then(() => {
            //TODO
          }).catch((e: RpcError) => {
            this.toast.error(this.i18n.$gettext('Die Ausschreibung konnte nicht archiviert werden.'))
          })
        },
        reject: () => {
          //callback to execute when user rejects the action
        }
      })
    }
  }

  resetCreateRoomDialog() {
    this.roomName = ''
    this.roomDescription = ''
    this.showCreateRoomDialog = false
  }

  createRoom(stage: number) {
    const type = rpcClient.isInternalUser ? 'CLIENT' : 'CONTRACTOR'
    const room = Object.assign(new Room(), {
      name: this.roomName,
      description: this.roomDescription,
      projectId: this.projectId,
      stage: stage,
      type: type
    })
    if (!rpcClient.isInternalUser && this.offer?.id) {
      room.offerId = this.offer.id
    }
    if (rpcClient.isInternalUser || room.offerId) {
      return roomServiceApi._createRoom(room).then((id: number) => {
        this.resetCreateRoomDialog()
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Der Datenraum konnte nicht angelegt werden.'))
      })
    } else {
      //TODO should not happen
    }
  }

  createOffer() {
    const organizationId = rpcClient.session.user?.organizationId
    const projectId = this.projectId
    if (organizationId && projectId) {
      offerServiceApi._createOffer(projectId, this.inviteToken || null).then((id: number) => {
        const offer = offerStore.state.offers.get(id)
        if ((offer?.stage || 0) >= 4) {
          this.$router.push('/projekte/' + projectId + '/angebot')
        } else if (offer?.stage === 3) {
          this.$router.push('/projekte/' + projectId + '/verhandlung')
        } else {
          this.$router.push('/projekte/' + projectId + '/bewerbung')
        }
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Teilnahme an der Ausschreibung nicht möglich') + ': ' + e.message)
      }).finally(() => {
        if (this.projectId) roomServiceApi.getRoomsByProjectId(this.projectId, true)
      })
    } else if (!organizationId) {
      rpcClient.nextRoute.afterLogin = this.$route
      this.$router.replace({ query: { message: 'nr_app' } })
    }
  }

  downloadPDF(): Promise<void> {
    if (this.projectId) {
      return projectServiceApi._createPDFAndGetDownloadLink(this.projectId).then((link: string) => {
        window.open(link, '_blank')
      })
    } else {
      return Promise.reject()
    }
  }

  translate(content: Content): string {
    return SdkServiceClient.getLabelForField(content?.label)
  }

  mounted() {
    this.watchRouteParams(this.$route?.params)
  }
}
