
import {Vue, Options} from "vue-class-component"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import DatePicker from "@/components/controls/DatePicker.vue"
import Card from "primevue/card"
import Checkbox from "@/components/controls/Checkbox.vue"
import PanelValue from "@/model/common/Content"
import Button from "primevue/button"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import {rpcClient} from "@/api/WebsocketClient"
import Field from "@/components/admin/Field.vue"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"
import Content from "@/model/common/Content"
import ContentUtil from "@/util/ContentUtil"
import OrganizationCard from "@/components/common/OrganizationCard.vue"
import SWR from "@/api/SWR"
import Organization from "@/model/Organization"
import {organizationServiceApi} from "@/api/OrganizationServiceApi"
import ProjectWithInfo from "@/util/ProjectWithInfo"
import {propertyServiceApi} from "@/api/PropertyServiceApi"
import Project from "@/model/Project"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import dayjs from "@/util/dayjs"
import ProjectInfo from "@/components/common/ProjectInfo.vue"

@Options({
  name: "Panel",
  components: {
    AnimatedInput, DatePicker, Card, Checkbox, Button, Field, OrganizationCard, Accordion, AccordionTab, ProjectInfo
  },
  //@ts-ignore
  props: {
    panel: [ PanelValue, Object ],
    project: [ ProjectWithInfo, Object ],
    id: String
  },
  emits: [ 'save' ]
})
export default class Panel extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient
  dayjs = dayjs

  panel!: PanelValue
  project!: ProjectWithInfo

  translateLabel(content: Content | undefined): string {
    return content ? SdkServiceClient.getLabelForField(content.label) : ''
  }

  get organizations(): SWR<Organization[], number[]> {
    return organizationServiceApi.getOrganizations('CLIENT')
  }

  get clientOrganization(): Organization | null {
    const field = (this.panel?.children || []).find((c: Content) => c.contentId === 'OPT-300-Procedure-Buyer')
    if (field?.stringValue) {
      return this.organizations.data?.find((o: Organization) => String(o.id) === field.stringValue) || null
    }
    return null
  }

  get isLot(): boolean {
    return [ 'GR-Lot', 'GR-Part' ].includes(this.panel?.contentId || '')
  }

  get lots(): ProjectWithInfo[] {
    if (this.project?.lotsWithInfo?.length) {
      return this.project.lotsWithInfo
    } else if (this.project) {
      return [ this.project ]
    } else {
      return []
    }
  }

  get lot(): ProjectWithInfo | undefined {
    if (this.isLot && this.project && this.panel?.children) {
      const lotId: string | null | undefined = ContentUtil.findContent(this.panel.children, 'BT-22-Lot')?.stringValue
      if (lotId) {
        return this.lots.find((lot: Project) => {
          return lotId === ContentUtil.findContent(lot.panels || [], 'BT-22-Lot')?.stringValue
        })
      }
    }
  }

  get linkedProperties() { //TODO lots too
    if (this.isLot) {
      return this.lot?.propertyIds?.map((id: number) => propertyServiceApi.getPropertyById(id).data)?.filter((p: any) => !!p) || []
    } else {
      return []
    }
  }

  get labelConditional() {
    if (this.lots.length > 1 && this.isLot) {
      let label: string = this.translateLabel(this.panel) + ' ' + (this.findLotId(this.panel) || '')
      const title = this.findLotTitle(this.panel)
      if (title) {
        label = label + ': ' + title
      }
      return label
    } else {
      return this.translateLabel(this.panel)
    }
  }

  findLotId(content: Content): string | null {
    if (content.contentId === 'BT-22-Lot') {
      return content.stringValue || ''
    } else if (content.children) {
      for (const child of content.children) {
        const title = this.findLotId(child)
        if (title !== null) {
          return title
        }
      }
    }
    return null
  }

  findLotTitle(content: Content): string | null {
    if (content.contentId === 'BT-21-Lot') {
      return content.stringValue || ''
    } else if (content.children) {
      for (const child of content.children) {
        const title = this.findLotTitle(child)
        if (title !== null) {
          return title
        }
      }
    }
    return null
  }

  sizeFormatted(size: number | null): string {
    if (!size) {
      return this.i18n.$gettext("Unbekannt")
    } else {
      return size.toLocaleString() + this.i18n.$gettext('qm')
    }
  }

  fieldVisible(content: Content) {
    return !content.hidden && ContentUtil.fieldVisible(content, this.panel?.children || [])
  }

  hasValue(content: Content) {
    return !content.hidden && (!content.repeatable || content.added) && (content.required || ContentUtil.hasValuedField(content))
  }

  mounted() {
    void SdkServiceClient.fetchAll(this.i18n.current)
  }
}
