import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Project from '@/model/Project';
import { organizationStore } from '@/store/OrganizationStore';

export default class GeneratedProjectStore {

    state = reactive({
        projects: new Map<number, Project>(),
        calls: new Map<string, number | number[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.projects) this.state.projects.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceProject(newProject: Project) {
        if (newProject.id) {
            this.state.projects.set(newProject.id, newProject)
            if (newProject.organization) {
                organizationStore.addOrReplaceOrganization(newProject.organization)
            }

        }
    }

    addOrReplaceProjects(newProjects: Project[]) {
        for (const newProject of newProjects) {
            this.addOrReplaceProject(newProject)
        }
    }

    replaceProjects(projects: Project[]) {
        const newProjects = new Map<number, Project>()
        for (const newProject of projects) {
            if (newProject.id) {
                newProjects.set(newProject.id, newProject)
                if (newProject.organization) {
                organizationStore.addOrReplaceOrganization(newProject.organization)
            }

            }
        }
        this.state.projects = newProjects
    }

    removeProject(id: number | undefined | null) {
        if (id !== undefined && id !== null) {
            this.state.projects.delete(id)
        }
    }

    removeProjects(ids: (number | null)[] | undefined | null) {
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                this.removeProject(id)
            }
        }
    }
}