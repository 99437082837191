export default class Location {
  id!: number | null;
  name!: string | null;
  latitude!: number | null;
  longitude!: number | null;
  isArchived!: boolean | null;
  geoJson!: string | null;
  addressDisplayName!: string | null;
  addressOsmType!: string | null;
  addessOsmId!: string | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.latitude = null;
    this.longitude = null;
    this.isArchived = null;
    this.geoJson = null;
    this.addressDisplayName = null;
    this.addressOsmType = null;
    this.addessOsmId = null;
  }
}
