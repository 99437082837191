
import {Options, Vue} from "vue-class-component"
import {rpcClient} from "@/api/WebsocketClient"
import i18n from '@/util/i18n'
import useToast from "@/util/toasts"
import {Link as MenuLink} from "@/util/MenuStructure"
import {RouteLocationRaw} from "vue-router"

@Options({
  name: "Link",
  components: {

  },
  //@ts-ignore
  props: {
    link: [ MenuLink, Object ],
    showIcon: Boolean,
    activeWithoutHash: Boolean
  },
  emits: [
    'close'
  ]
})
export default class Link extends Vue {

  i18n = i18n
  rpcClient = rpcClient
  toast = useToast()

  link!: MenuLink
  activeWithoutHash!: boolean

  get target(): RouteLocationRaw {
    if (this.link.to && this.link.hash) {
      return { path: this.link.to, hash: this.link.hash }
    } else if (this.link.to) {
      return { path: this.link.to }
    } else if (this.link.hash) {
      return { hash: this.link.hash }
    } else {
      return this.$route
    }
  }

  get active(): boolean {
    if (this.link.hash && this.link.to) {
      return this.$route.path === this.link.to && (this.$route.hash === this.link.hash || (this.activeWithoutHash && !this.$route.hash))
    } else if (this.link.hash) {
      return this.$route.hash === this.link.hash
    } else if (this.link.to) {
      return this.$route.path === this.link.to
    } else {
      return false
    }
  }

  get linkIconClass(): string {
    if (this.link.icon === 'pi pi-pencil') {
      return ' text-primary'
    } else if (this.link.icon === 'pi pi-check-circle') {
      return ' font-weight-bold text-success'
    }
    return ''
  }
}
