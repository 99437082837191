
import {Options, Vue} from 'vue-class-component'
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {rpcClient} from "@/api/WebsocketClient"
import useToast from "@/util/toasts"
import Button from "primevue/button"
import {loginServiceApi} from "@/api/LoginServiceApi"
import LoginResult from "@/model/LoginResult"
import User from "@/model/User"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Message from "primevue/message"
import Dialog from "@/components/common/Dialog.vue"
import ProgressSpinner from "primevue/progressspinner"
import breakpointUtil from "@/util/BreakpointUtil"

@Options({
  name: "LoginForm",
  components: {
    AnimatedInput, Button, Message, Dialog, ProgressSpinner
  },
  //@ts-ignore
  props: {
    next: String,
    visible: Boolean
  },
  emits: [
      'login'
  ]
})
export default class LoginForm extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  rpcClient = rpcClient
  breakpointUtil = breakpointUtil

  next!: string
  visible!: boolean

  username = ""
  password = ""
  loading = false
  autofillOverwrite = false //Overrides validation if chrome autofill does input something directly after page load. See: https://bugs.chromium.org/p/chromium/issues/detail?id=669724

  get show() {
    if (this.$route.path === '/login') {
      this.$router.push('/')
      return true
    } if (this.visible) {
      return true
    } else {
      const show = Boolean(!this.rpcClient.fullyLoggedIn && rpcClient.nextRoute.afterLogin)
      if (show) {
        return true
      } else if (rpcClient.nextRoute.afterLogin) {
        this.$router.push(rpcClient.nextRoute.afterLogin)
      }
      return false
    }
  }

  set show(show: boolean) {
    if (!show && !this.visible) {
      rpcClient.nextRoute.afterLogin = null
    }
  }

  login() {
    this.loading = true
    if (rpcClient.useWebsocket) {
      loginServiceApi._login(this.username.toLowerCase(), this.password).then((data: LoginResult) => {
        if (data.user && data.token) {
          rpcClient.setLoginData(data.user, data.token)
          this.$emit('login')
          if (rpcClient.nextRoute.afterLogin) {
            this.$router.push(rpcClient.nextRoute.afterLogin)
          }
        } else {
          this.toast.error(this.i18n.$gettext('Login fehlgeschlagen.'))
        }
      }).catch(e => {
        this.toast.error(this.i18n.$gettext('Login fehlgeschlagen.'))
      }).finally(() => {
        this.loading = false
      })
    } else {
      rpcClient.login(this.username.toLowerCase(), this.password).then((user: User) => {
        this.$emit('login')
        if (rpcClient.nextRoute.afterLogin) {
          this.$router.push(rpcClient.nextRoute.afterLogin)
        }
        return user
      }).catch(e => {
        this.toast.error(this.i18n.$gettext('Login fehlgeschlagen.'))
      }).finally(() => {
        this.loading = false
      })
    }
  }

  isInternalUser(user: User) {
    return !!user?.roles?.find((r: string) => r.startsWith('SYSTEM_'))
  }

  handleAutofill(args: boolean) {
    this.autofillOverwrite = args
  }
}
