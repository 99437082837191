import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b05510b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-float-label" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueDropdown = _resolveComponent("PrimeVueDropdown")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_PrimeVueDropdown, {
      ref: "myinput",
      modelValue: _ctx.internalValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
      class: "w-100",
      placeholder: _ctx.placeholder,
      "option-label": _ctx.optionLabel,
      "option-value": _ctx.optionValue,
      options: _ctx.options,
      disabled: _ctx.disabled,
      filter: _ctx.filter,
      "show-clear": _ctx.showClear,
      required: _ctx.required,
      "empty-message": _ctx.emptyMessage,
      virtualScrollerOptions: _ctx.virtualScrollerOptions,
      "scroll-height": _ctx.scrollHeight,
      onShow: _ctx.handleFocus,
      onHide: _ctx.handleBlur,
      onChange: _ctx.handleChange
    }, null, 8, ["modelValue", "placeholder", "option-label", "option-value", "options", "disabled", "filter", "show-clear", "required", "empty-message", "virtualScrollerOptions", "scroll-height", "onShow", "onHide", "onChange"]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label + (_ctx.required ? ' *' : '')), 1))
      : _createCommentVNode("", true)
  ]))
}