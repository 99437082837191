import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "container-wide w-100 flex-shrink-1 flex-grow-1 d-flex flex-column",
  style: {"min-height":"0"}
}
const _hoisted_2 = {
  key: 1,
  class: "flex-grow-1 flex-shrink-1",
  style: {"overflow":"auto"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_UserRegistrationForm = _resolveComponent("UserRegistrationForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.u || _ctx.userSWR.call?.loading)
      ? (_openBlock(), _createBlock(_component_ProgressBar, {
          key: 0,
          mode: "indeterminate"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_UserRegistrationForm, { user: _ctx.u }, null, 8, ["user"])
        ]))
  ]))
}