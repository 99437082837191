

import {Options, Vue} from "vue-class-component"
import {Watch} from "vue-property-decorator"
import { ref } from "@vue/reactivity"
import Calendar from "primevue/calendar"
import FlatPickr from "vue-flatpickr-component"

@Options({
  name: "DatePicker",
  //@ts-ignore
  props: {
    label: String,
    required: Boolean,
    placeholder: String,
    modelValue: [ Date, String ],
    inline: Boolean,
    autocomplete: String,
    error: String,
    type: String,
    disabled: Boolean,
    name: String,
    showTime: {
      type: Boolean,
      default: false
    },
    small: Boolean,
    noBackground: Boolean,
    showButtonBar: Boolean,
    minDate: Date,
    maxDate: Date,
    useStrings: {
      type: Boolean,
      default: false
    },
    timeOnly: {
      type: Boolean,
      default: false
    },
  },
  components: {
    FlatPickr
  }
})
export default class DatePicker extends Vue {

  label!: string;
  placeholder!: string;
  modelValue!: Date | string;
  inline!: boolean;
  autocomplete!: string;
  error!: string;
  type!: string;
  disabled!: boolean;
  name!: string;
  small!: boolean;
  noBackground!: boolean;
  showTime!: boolean
  monthNavigator!: boolean
  minDate!: Date
  maxDate!: Date
  useStrings: boolean = false
  timeOnly: boolean = false

  focused = false;
  internalValue: Date | null = typeof this.modelValue === "string" ? new Date(this.modelValue) : (this.modelValue || null);

  //@ts-ignore
  myinput: Calendar = ref<Calendar | null>(null);

  convertValueToDate(e: Date[]){
    if(e.length > 0) {
      if(this.useStrings){
        this.$emit('update:modelValue', e[0].toISOString())
      } else {
        this.$emit('update:modelValue', e[0])
      }

    }
  }

  get startPickerOptions() {
    return {
      enableTime: this.showTime || this.timeOnly,
      time_24hr: true,
      allowInput: true,
      minDate: this.minDate,
      maxDate: this.maxDate,
      noCalendar: this.timeOnly,
    }
  }

  get animationClass (): string {
    return this.internalValue !== null ? 'p-filled': ''
  }

  get inlineClass (): string {
    return (this.inline ? ' animated-input-inline' : '')
  }

  get sizeClass(): string {
    return (this.small ? ' animated-input-sm' : '')
  }

  get errorClass(): string {
    if (this.error) {
      if (this.focused) {
        return ' border-danger'
      } else {
        return ' border-danger danger'
      }
    } else {
      return ''
    }
  }

  @Watch('modelValue')
  handleReactiveData (val: Date | string, oldVal: Date | string) {
    if (typeof val === "string") {
      if(this.internalValue?.toISOString() !== val) {
        this.internalValue = new Date(val)
      }
    } else {
      if (this.internalValue?.toISOString() !== val?.toISOString()) {
        this.internalValue = val
      }
    }
  }

  handleFocus () {
    this.focused = true
    this.$emit('focus')
  }

  handleBlur () {
    this.focused = false
    this.$emit('blur')
  }
}
