
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import Organization from "@/model/Organization"
import {organizationServiceApi} from "@/api/OrganizationServiceApi"
import SWR from "@/api/SWR"
import InfiniteList from "@/components/controls/InfiniteList.vue"
import InputText from "primevue/inputtext"
import ProgressBar from "primevue/progressbar"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import {MenuItem} from "primevue/menuitem"
import Breadcrumb from "primevue/breadcrumb"

@Options({
  name: "OrganizationList",
  components: {
    Button, InfiniteList, InputText, ProgressBar, Breadcrumb
  },
  //@ts-ignore
  props: {
    type: String
  }
})
export default class OrganizationList extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient
  sortAndFilterUtil = SortAndFilterUtil

  type!: string

  get organizations(): SWR<Organization[], number[]> {
    return organizationServiceApi.getOrganizations(this.type)
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    if (this.type === 'CLIENT') {
      breadcrumbs.push({
        label: this.i18n.$gettext('Auftraggeber'),
        to: '/auftraggeber'
      })
    } else {
      breadcrumbs.push({
        label: this.i18n.$gettext('Bieter'),
        to: '/bieter'
      })
    }
    return breadcrumbs
  }

  openOrganization(o: Organization) {
    if (o.id) this.$router.push(this.$route.path + '/' + encodeURIComponent(o.id))
  }
}
