import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import LogEntry from '@/model/LogEntry';
import Page from '@/model/Page';
import { logEntryStore } from '@/store/LogEntryStore';

export default class GeneratedAuditLogServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _createPDFAndGetDownloadLink(arg0: any[] | null): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createPDFAndGetDownloadLink', rpcParams, false) as Promise<string>
    }

    _getLogEntries(projectId: number | null, type: string[] | null): Promise<LogEntry[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getLogEntries', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const logEntrys: LogEntry[] = data.map(logEntry => Object.assign(new LogEntry(), logEntry))
                logEntryStore.addOrReplaceLogEntrys(logEntrys)
                return logEntrys
            } else return Promise.reject()
        })
    }


    getLogEntries(projectId: number | null, type: string[] | null, refresh?: boolean | number): SWR<LogEntry[], number[]> {
        //@ts-ignore
        const result: SWR<LogEntry[], number[]> = reactive(new SWR<LogEntry[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_getLogEntries' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getLogEntries[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const logEntrys: LogEntry[] = []
                for (const id of data) {
                    const logEntry: LogEntry | undefined = logEntryStore.state.logEntrys.get(id)
                    if (logEntry) {
                        logEntrys.push(logEntry)
                    }
                }
                result.data = logEntrys
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getLogEntries(projectId, type).then((data: LogEntry[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(logEntry => logEntry.id || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let logEntrys: LogEntry[] = [...logEntryStore.state.logEntrys.values()]
        if (projectId !== undefined && projectId !== null) logEntrys = SortAndFilterUtil.filter(logEntrys, { projectId: projectId })
if (type !== undefined && type !== null) logEntrys = SortAndFilterUtil.filter(logEntrys, { type: type })
        
        
        result.data = logEntrys
        return result
    }

    getLogEntrys(pageIndex?: number, pageSize?: number): LogEntry[] {
        let logEntrys: LogEntry[] = [...logEntryStore.state.logEntrys.values()]
        
        
        if (pageSize !== null && pageSize !== undefined) {
            logEntrys = logEntrys.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return logEntrys
    }

    getLogEntry(id: number): LogEntry | undefined {
        return logEntryStore.state.logEntrys.get(id)
    }
}
