import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Property from '@/model/gis/Property';
import { locationStore } from '@/store/LocationStore';

export default class GeneratedPropertyStore {

    state = reactive({
        propertys: new Map<number, Property>(),
        calls: new Map<string, number | number[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.propertys) this.state.propertys.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceProperty(newProperty: Property) {
        if (newProperty.id) {
            this.state.propertys.set(newProperty.id, newProperty)
            if (newProperty.location) {
                locationStore.addOrReplaceLocation(newProperty.location)
            }

        }
    }

    addOrReplacePropertys(newPropertys: Property[]) {
        for (const newProperty of newPropertys) {
            this.addOrReplaceProperty(newProperty)
        }
    }

    replacePropertys(propertys: Property[]) {
        const newPropertys = new Map<number, Property>()
        for (const newProperty of propertys) {
            if (newProperty.id) {
                newPropertys.set(newProperty.id, newProperty)
                if (newProperty.location) {
                locationStore.addOrReplaceLocation(newProperty.location)
            }

            }
        }
        this.state.propertys = newPropertys
    }

    removeProperty(id: number | undefined | null) {
        if (id !== undefined && id !== null) {
            this.state.propertys.delete(id)
        }
    }

    removePropertys(ids: (number | null)[] | undefined | null) {
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                this.removeProperty(id)
            }
        }
    }
}