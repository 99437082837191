
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import {twoFactorServiceApi} from "@/api/TwoFactorServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import ProgressSpinner from "primevue/progressspinner"
import {useConfirm} from "primevue/useconfirm"
import {settingsServiceApi} from "@/api/SettingsServiceApi"
import Settings from "@/model/settings/Settings"

@Options({
  name: "EnableTwoFactorForm",
  components: {
    AnimatedInput, Button, ProgressSpinner
  }
})
export default class EnableTwoFactorForm extends Vue {


  i18n: Language = useGettext()
  router: Router = useRouter()
  confirm = useConfirm()
  rpcClient = rpcClient

  canSkip = false
  qrCode: string | null = null
  code: string = ''
  password: string = ''

  enable() {
    twoFactorServiceApi._changeTwoFactorConfig(this.password, this.code, true, null).then(() => {
      if (rpcClient.session.user) {
        rpcClient.session.user.twoFactorEnabled = true
      }
      twoFactorServiceApi._authenticate(this.code).then(() => {
        this.rpcClient.session.twoFactorConfirmed = true
      })
    })
  }

  disable() {
    this.confirm.require({
      message: this.i18n.$gettext('Die Zwei-Faktor-Authentifizierung erhöht die Sicherheit Ihres Kontos. Sind Sie sicher, dass Sie die Zwei-Faktor-Authentifizierung nicht aktivieren möchten?'),
      header: this.i18n.$gettext('Überspringen'),
      icon: 'cil-warning',
      accept: () => {
        twoFactorServiceApi._changeTwoFactorConfig(this.password, '', false, null).then(() => {
          if (rpcClient.session.user) {
            rpcClient.session.user.twoFactorEnabled = false
          }
        })
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  mounted() {
    twoFactorServiceApi._getTwoFactorQRCode().then(code => this.qrCode = code)
    settingsServiceApi._getSettings().then((settings: Settings) => {
      this.canSkip = !settings.twoFactorAuthenticationRequired
    })
  }
}
