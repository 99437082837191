import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8f1c3fde"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative"} }
const _hoisted_2 = { class: "row my-5" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "mb-2" }
const _hoisted_5 = { class: "text-muted mb-0" }
const _hoisted_6 = { class: "row mb-5" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "p-4" }
const _hoisted_9 = { class: "mb-0" }
const _hoisted_10 = { class: "p-4" }
const _hoisted_11 = { class: "mb-0" }
const _hoisted_12 = { class: "p-4" }
const _hoisted_13 = { class: "mb-0" }
const _hoisted_14 = { class: "p-4" }
const _hoisted_15 = { class: "mb-0" }
const _hoisted_16 = { class: "p-4" }
const _hoisted_17 = { class: "mb-0" }
const _hoisted_18 = { class: "row mb-6" }
const _hoisted_19 = { class: "col-12 mb-4 mb-md-0 col-md-6" }
const _hoisted_20 = { class: "col-12 col-md-6" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = {
  key: 0,
  style: {"position":"absolute","width":"150px","height":"150px","top":"calc(50% - 75px)","left":"calc(50% - 75px)"}
}
const _hoisted_23 = { class: "d-flex flex-row flex-wrap justify-content-between align-items-center" }
const _hoisted_24 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_Message = _resolveComponent("Message")!
  const _component_AnimatedInput = _resolveComponent("AnimatedInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Dialog, {
      visible: _ctx.show,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.show) = $event)),
      class: "container",
      onSubmit: _withModifiers(_ctx.login, ["prevent"]),
      modal: "",
      closable: !_ctx.visible
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_23, [
          (!_ctx.breakpointUtil.isOnMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createVNode(_component_Button, {
                  class: "p-button-text flat",
                  label: _ctx.i18n.$gettext('Registrieren'),
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push('/registrierung')))
                }, null, 8, ["label"]),
                _createVNode(_component_Button, {
                  class: "p-button-text flat",
                  label: _ctx.i18n.$gettext('Passwort vergessen'),
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push('/passwort-reset')))
                }, null, 8, ["label"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_Button, {
            id: "signin",
            type: "submit",
            disabled: _ctx.loading,
            class: "p-button-success"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Einloggen")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["animate-opacity", { 'opacity-20': _ctx.loading }])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h1", _hoisted_4, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Willkommen!")
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("h4", _hoisted_5, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Bitte einloggen.")
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (!_ctx.rpcClient.session.user?.activated && _ctx.$route.query?.message === 'nr_mes')
                  ? (_openBlock(), _createBlock(_component_Message, {
                      key: 0,
                      closable: false,
                      class: "mt-0"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("p", _hoisted_9, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Um eine Nachricht an die Vergabestelle zu senden, benötigen Sie ein Benutzerkonto. Bitte loggen Sie sich ein, oder klicken Sie auf \"Registrieren\", um ein Konto zu erstellen.")
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.rpcClient.session.user?.activated && _ctx.$route.query?.message === 'nr_wat')
                  ? (_openBlock(), _createBlock(_component_Message, {
                      key: 1,
                      closable: false,
                      class: "mt-0"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("p", _hoisted_11, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Um eine Ausschreibung auf Ihre Merkliste zu setzen, benötigen Sie ein Benutzerkonto. Bitte loggen Sie sich ein, oder klicken Sie auf \"Registrieren\", um ein Konto zu erstellen.")
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.rpcClient.session.user?.activated && _ctx.$route.query?.message === 'nr_app')
                  ? (_openBlock(), _createBlock(_component_Message, {
                      key: 2,
                      closable: false,
                      class: "mt-0"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("p", _hoisted_13, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Um sich auf eine Ausschreibung zu bewerben, benötigen Sie ein Benutzerkonto. Bitte loggen Sie sich ein, oder klicken Sie auf \"Registrieren\", um ein Konto zu erstellen.")
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (!_ctx.rpcClient.session.user?.activated && _ctx.$route.query?.message === 'registered')
                  ? (_openBlock(), _createBlock(_component_Message, {
                      key: 3,
                      closable: false,
                      class: "mt-0"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("p", _hoisted_15, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Zur Aktivierung Ihres Benutzer-Kontos ist die Bestätigung Ihrer E-Mail Adresse notwendig. Bitte klicken Sie dazu auf den Link, den Sie per E-Mail erhalten haben.")
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.rpcClient.session.user?.activated)
                  ? (_openBlock(), _createBlock(_component_Message, {
                      key: 4,
                      closable: false
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("p", _hoisted_17, [
                            _createVNode(_component_translate, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Vielen Dank für die Bestätigung Ihrer E-Mail Adresse! Ihr Konto ist nun aktiv und Sie können sich hier einloggen.")
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_AnimatedInput, {
                  id: "login",
                  name: "username",
                  type: "text",
                  autocomplete: "true",
                  label: _ctx.i18n.$gettext('E-Mail Adresse'),
                  modelValue: _ctx.username,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                  modelModifiers: { lazy: true, trim: true },
                  onAutofill: _ctx.handleAutofill,
                  required: ""
                }, null, 8, ["label", "modelValue", "onAutofill"])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_AnimatedInput, {
                  name: "password",
                  type: "password",
                  autocomplete: "true",
                  label: _ctx.i18n.$gettext('Passwort'),
                  modelValue: _ctx.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                  modelModifiers: { lazy: true, trim: true },
                  onAutofill: _ctx.handleAutofill,
                  required: ""
                }, null, 8, ["label", "modelValue", "onAutofill"])
              ])
            ]),
            (_ctx.breakpointUtil.isOnMobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createVNode(_component_Button, {
                    class: "p-button-text flat",
                    label: _ctx.i18n.$gettext('Registrieren'),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push('/registrierung')))
                  }, null, 8, ["label"]),
                  _createVNode(_component_Button, {
                    class: "p-button-text flat",
                    label: _ctx.i18n.$gettext('Passwort vergessen'),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/passwort-reset')))
                  }, null, 8, ["label"])
                ]))
              : _createCommentVNode("", true)
          ], 2),
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createVNode(_component_ProgressSpinner)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible", "onSubmit", "closable"])
  ]))
}