import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Offer from '@/model/Offer';
import Page from '@/model/Page';
import { offerStore } from '@/store/OfferStore';

export default class GeneratedOfferServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getOffersByProjectId(projectId: number): Promise<Offer[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getOffersByProjectId', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const offers: Offer[] = data.map(offer => Object.assign(new Offer(), offer))
                offerStore.addOrReplaceOffers(offers)
                return offers
            } else return Promise.reject()
        })
    }


    _updateOfferStage(id: number, stage: number, lotIds: string[] | null): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateOfferStage', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Offer(), data)
            offerStore.removeOffer(id)
            offerStore.addOrReplaceOffer(model)
            return model.id
        })
    }

    _createOffer(projectId: number, inviteToken: string): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createOffer', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Offer(), data)
            offerStore.addOrReplaceOffer(model)
            return model.id
        })
    }

    _getOffersByOrganizationId(organizationId: number): Promise<Offer[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getOffersByOrganizationId', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                const offers: Offer[] = data.map(offer => Object.assign(new Offer(), offer))
                offerStore.addOrReplaceOffers(offers)
                return offers
            } else return Promise.reject()
        })
    }


    getOffersByProjectId(projectId: number, refresh?: boolean | number): SWR<Offer[], number[]> {
        //@ts-ignore
        const result: SWR<Offer[], number[]> = reactive(new SWR<Offer[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_getOffersByProjectId' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getOffersByProjectId[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const offers: Offer[] = []
                for (const id of data) {
                    const offer: Offer | undefined = offerStore.state.offers.get(id)
                    if (offer) {
                        offers.push(offer)
                    }
                }
                result.data = offers
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getOffersByProjectId(projectId).then((data: Offer[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(offer => offer.id || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let offers: Offer[] = [...offerStore.state.offers.values()]
        if (projectId !== undefined && projectId !== null) offers = SortAndFilterUtil.filter(offers, { projectId: projectId })
        
        
        result.data = offers
        return result
    }

    createOffer(projectId: number, inviteToken: string, refresh?: boolean | number): SWR<Offer | null, number> {
        //@ts-ignore
        const result: SWR<Offer | null, number> = reactive(new SWR<Offer | null, number>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_createOffer' + JSON.stringify(args)
        const cached: Call<number> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_createOffer[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: number) => {
                result.data = offerStore.state.offers.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number>()) as Call<number>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._createOffer(projectId, inviteToken).then((id: number) => {
                call.data = id
                result.data = offerStore.state.offers.get(id) || null
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = offerStore.state.offers.get(cached.data) || null
        }
        return result
    }


    getOffersByOrganizationId(organizationId: number, refresh?: boolean | number): SWR<Offer[], number[]> {
        //@ts-ignore
        const result: SWR<Offer[], number[]> = reactive(new SWR<Offer[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_getOffersByOrganizationId' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getOffersByOrganizationId[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const offers: Offer[] = []
                for (const id of data) {
                    const offer: Offer | undefined = offerStore.state.offers.get(id)
                    if (offer) {
                        offers.push(offer)
                    }
                }
                result.data = offers
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getOffersByOrganizationId(organizationId).then((data: Offer[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(offer => offer.id || '')
                return call.data
            }).catch(e => {
                this.cache.delete(callId)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let offers: Offer[] = [...offerStore.state.offers.values()]
        if (organizationId !== undefined && organizationId !== null) offers = SortAndFilterUtil.filter(offers, { organizationId: organizationId })
        
        
        result.data = offers
        return result
    }

    getOffers(pageIndex?: number, pageSize?: number): Offer[] {
        let offers: Offer[] = [...offerStore.state.offers.values()]
        
        
        if (pageSize !== null && pageSize !== undefined) {
            offers = offers.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return offers
    }

    getOffer(id: number): Offer | undefined {
        return offerStore.state.offers.get(id)
    }
}
