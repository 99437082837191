import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Location from '@/model/gis/Location';


export default class GeneratedLocationStore {

    state = reactive({
        locations: new Map<number, Location>(),
        calls: new Map<string, number | number[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.locations) this.state.locations.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceLocation(newLocation: Location) {
        if (newLocation.id) {
            this.state.locations.set(newLocation.id, newLocation)
            
        }
    }

    addOrReplaceLocations(newLocations: Location[]) {
        for (const newLocation of newLocations) {
            this.addOrReplaceLocation(newLocation)
        }
    }

    replaceLocations(locations: Location[]) {
        const newLocations = new Map<number, Location>()
        for (const newLocation of locations) {
            if (newLocation.id) {
                newLocations.set(newLocation.id, newLocation)
                
            }
        }
        this.state.locations = newLocations
    }

    removeLocation(id: number | undefined | null) {
        if (id !== undefined && id !== null) {
            this.state.locations.delete(id)
        }
    }

    removeLocations(ids: (number | null)[] | undefined | null) {
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                this.removeLocation(id)
            }
        }
    }
}