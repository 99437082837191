
import {Options, Vue} from "vue-class-component"
import Organization from "@/model/Organization"
import Card from "primevue/card"
import Checkbox from "@/components/controls/Checkbox.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import {rpcClient} from "@/api/WebsocketClient"
import Content from "@/model/common/Content"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"

@Options({
  name: "OrganizationCard",
  components: {
    Card, Checkbox
  },
  //@ts-ignore
  props: {
    organization: [ Organization, Object ],
    header: String
  }
})
export default class OrganizationCard extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient

  organization!: Organization

  translate(content: Content): string {
    return SdkServiceClient.getLabelForField(content?.label)
  }
}
