import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-row justify-content-end mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_AnimatedInput = _resolveComponent("AnimatedInput")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_ctx.user)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        class: "w-100 container-wide flex-shrink-1 flex-grow-1 card bg-white p-5",
        onSubmit: _cache[19] || (_cache[19] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent","stop"])),
        style: {"min-height":"0","overflow":"auto"}
      }, [
        (_openBlock(), _createBlock(_Teleport, {
          to: "#topmenu-buttons",
          class: "card bg-white p-5 mb-4"
        }, [
          (_ctx.user.authenticationBackend !== 'ldap')
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                type: "submit",
                class: "p-button-success",
                onClick: [
                  _cache[0] || (_cache[0] = () => {}),
                  _withModifiers(_ctx.save, ["prevent","stop"])
                ],
                disabled: !(_ctx.user.roles || []).length || (!_ctx.isIntern && !_ctx.user.organizationId)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Speichern")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]))
            : _createCommentVNode("", true)
        ])),
        (_openBlock(), _createBlock(_Teleport, { to: "#breadcrumbs" }, [
          _createVNode(_component_Breadcrumb, {
            home: { icon: 'pi pi-home', to: '/' },
            model: _ctx.breadcrumbs
          }, null, 8, ["model"])
        ])),
        _createElementVNode("div", _hoisted_1, [
          (_ctx.user.authenticationBackend !== 'ldap')
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: "p-button-text border-primary bg-white",
                onClick: _ctx.openChangePasswordDialog
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Passwort ändern")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_Multiselect, {
          modelValue: _ctx.user.roles,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.roles) = $event)),
          disabled: _ctx.user.authenticationBackend === 'ldap',
          options: Object.keys(_ctx.roleChoices).map(k => { return { id: k, name: _ctx.roleChoices[k] } }),
          filter: "",
          optionLabel: "name",
          optionValue: "id",
          label: _ctx.i18n.$gettext('Berechtigungen'),
          class: "w-100 mb-4",
          required: ""
        }, null, 8, ["modelValue", "disabled", "options", "label"]),
        (!_ctx.isIntern && _ctx.rpcClient.isInternalUser)
          ? (_openBlock(), _createBlock(_component_Dropdown, {
              key: 0,
              disabled: !!_ctx.user.authenticationBackend,
              class: "flex-grow-1 mb-4",
              modelValue: _ctx.user.organizationId,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.organizationId) = $event)),
              loading: _ctx.organizations.call?.loading,
              options: (_ctx.organizations.data || []),
              "option-label": "name",
              "option-value": "id",
              label: _ctx.i18n.$gettext('Unternehmen'),
              filter: "",
              required: ""
            }, null, 8, ["disabled", "modelValue", "loading", "options", "label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_AnimatedInput, {
          class: "mb-3",
          disabled: _ctx.user.authenticationBackend === 'ldap',
          type: "text",
          label: _ctx.i18n.$gettext('Vorname'),
          modelValue: _ctx.user.givenName,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.givenName) = $event)),
          modelModifiers: { lazy: true, trim: true },
          required: ""
        }, null, 8, ["disabled", "label", "modelValue"]),
        _createVNode(_component_AnimatedInput, {
          class: "mb-3",
          disabled: _ctx.user.authenticationBackend === 'ldap',
          type: "text",
          label: _ctx.i18n.$gettext('Nachname'),
          modelValue: _ctx.user.surName,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.surName) = $event)),
          modelModifiers: { lazy: true, trim: true },
          required: ""
        }, null, 8, ["disabled", "label", "modelValue"]),
        _createVNode(_component_AnimatedInput, {
          class: "mb-3",
          disabled: _ctx.user.authenticationBackend === 'ldap',
          type: "email",
          label: _ctx.i18n.$gettext('E-Mail'),
          modelValue: _ctx.user.email,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.email) = $event)),
          modelModifiers: { lazy: true, trim: true },
          required: ""
        }, null, 8, ["disabled", "label", "modelValue"]),
        (_ctx.user.contact)
          ? (_openBlock(), _createBlock(_component_AnimatedInput, {
              key: 1,
              class: "mb-3",
              type: "text",
              label: _ctx.i18n.$gettext('Telefon'),
              modelValue: _ctx.user.contact.phone,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.user.contact.phone) = $event)),
              modelModifiers: { lazy: true, trim: true }
            }, null, 8, ["label", "modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.user.contact)
          ? (_openBlock(), _createBlock(_component_AnimatedInput, {
              key: 2,
              class: "mb-3",
              type: "text",
              label: _ctx.i18n.$gettext('Fax'),
              modelValue: _ctx.user.contact.facsimile,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.user.contact.facsimile) = $event)),
              modelModifiers: { lazy: true, trim: true }
            }, null, 8, ["label", "modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.user.contact)
          ? (_openBlock(), _createBlock(_component_AnimatedInput, {
              key: 3,
              class: "mb-3",
              type: "text",
              label: _ctx.i18n.$gettext('Abteilung'),
              modelValue: _ctx.user.contact.department,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.user.contact.department) = $event)),
              modelModifiers: { lazy: true, trim: true },
              "auto-resize": ""
            }, null, 8, ["label", "modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.user.contact)
          ? (_openBlock(), _createBlock(_component_AnimatedInput, {
              key: 4,
              class: "mb-3",
              type: "text",
              label: _ctx.i18n.$gettext('Straße / Hausnummer'),
              modelValue: _ctx.user.contact.street,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.user.contact.street) = $event)),
              modelModifiers: { lazy: true, trim: true },
              "auto-resize": ""
            }, null, 8, ["label", "modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.user.contact)
          ? (_openBlock(), _createBlock(_component_AnimatedInput, {
              key: 5,
              class: "mb-3",
              type: "text",
              label: _ctx.i18n.$gettext('PLZ'),
              modelValue: _ctx.user.contact.zip,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.user.contact.zip) = $event)),
              modelModifiers: { lazy: true, trim: true }
            }, null, 8, ["label", "modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.user.contact)
          ? (_openBlock(), _createBlock(_component_AnimatedInput, {
              key: 6,
              class: "mb-3",
              type: "text",
              label: _ctx.i18n.$gettext('Ort'),
              modelValue: _ctx.user.contact.city,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.user.contact.city) = $event)),
              modelModifiers: { lazy: true, trim: true }
            }, null, 8, ["label", "modelValue"]))
          : _createCommentVNode("", true),
        (_ctx.user.contact)
          ? (_openBlock(), _createBlock(_component_AnimatedInput, {
              key: 7,
              class: "mb-3",
              type: "text",
              label: _ctx.i18n.$gettext('Land'),
              modelValue: _ctx.user.contact.country,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.user.contact.country) = $event)),
              modelModifiers: { lazy: true, trim: true }
            }, null, 8, ["label", "modelValue"]))
          : _createCommentVNode("", true),
        (!_ctx.rpcClient.isInternalUser && !_ctx.user.authenticationBackend)
          ? (_openBlock(), _createBlock(_component_AnimatedInput, {
              key: 8,
              class: "mb-3",
              type: "password",
              label: _ctx.i18n.$gettext('Passwort'),
              modelValue: _ctx.user.password,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.user.password) = $event)),
              modelModifiers: { lazy: true, trim: true },
              required: ""
            }, null, 8, ["label", "modelValue"]))
          : _createCommentVNode("", true),
        (!_ctx.rpcClient.isInternalUser && !_ctx.user.authenticationBackend)
          ? (_openBlock(), _createBlock(_component_AnimatedInput, {
              key: 9,
              class: "mb-3",
              type: "password",
              label: _ctx.i18n.$gettext('Passwort wiederholen'),
              modelValue: _ctx.passwordRepeat,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.passwordRepeat) = $event)),
              modelModifiers: { lazy: true, trim: true },
              required: ""
            }, null, 8, ["label", "modelValue"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Dialog, {
          header: _ctx.i18n.$gettext('Passwort ändern'),
          visible: _ctx.showChangePasswordDialog,
          "onUpdate:visible": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.showChangePasswordDialog) = $event)),
          modal: "",
          dismissableMask: "",
          onHide: _ctx.resetChangePasswordDialog
        }, {
          footer: _withCtx(() => [
            _createVNode(_component_Button, {
              label: _ctx.i18n.$gettext('Abbrechen'),
              icon: "pi pi-times",
              class: "p-button-text",
              onClick: _ctx.resetChangePasswordDialog
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              label: _ctx.i18n.$gettext('Speichern'),
              icon: "pi pi-check",
              autofocus: "",
              disabled: !_ctx.oldPassword || !_ctx.newPassword || _ctx.newPassword !== _ctx.passwordRepeat,
              onClick: _ctx.changePassword
            }, null, 8, ["label", "disabled", "onClick"])
          ]),
          default: _withCtx(() => [
            (!_ctx.rpcClient.isInternalUser)
              ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                  key: 0,
                  class: "mb-3",
                  type: "password",
                  label: _ctx.i18n.$gettext('Altes Passwort'),
                  modelValue: _ctx.oldPassword,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.oldPassword) = $event)),
                  modelModifiers: { lazy: true, trim: true },
                  required: ""
                }, null, 8, ["label", "modelValue"]))
              : _createCommentVNode("", true),
            (!_ctx.rpcClient.isInternalUser)
              ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                  key: 1,
                  class: "mb-3",
                  type: "password",
                  label: _ctx.i18n.$gettext('Neues Passwort'),
                  modelValue: _ctx.newPassword,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.newPassword) = $event)),
                  modelModifiers: { lazy: true, trim: true },
                  required: ""
                }, null, 8, ["label", "modelValue"]))
              : _createCommentVNode("", true),
            (!_ctx.rpcClient.isInternalUser)
              ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                  key: 2,
                  class: "mb-3",
                  type: "password",
                  label: _ctx.i18n.$gettext('Neues Passwort wiederholen'),
                  modelValue: _ctx.passwordRepeat,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.passwordRepeat) = $event)),
                  modelModifiers: { lazy: true, trim: true },
                  required: ""
                }, null, 8, ["label", "modelValue"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header", "visible", "onHide"])
      ], 32))
    : _createCommentVNode("", true)
}