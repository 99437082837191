import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "container-wide w-100 flex-shrink-1 flex-grow-1 d-flex flex-column",
  style: {"min-height":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_OrganizationRegistrationForm = _resolveComponent("OrganizationRegistrationForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.o || _ctx.organizationSWR.call?.loading)
      ? (_openBlock(), _createBlock(_component_ProgressBar, {
          key: 0,
          mode: "indeterminate"
        }))
      : (_openBlock(), _createBlock(_component_OrganizationRegistrationForm, {
          key: 1,
          organization: _ctx.o
        }, null, 8, ["organization"]))
  ]))
}