

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import Divider from "primevue/divider"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import InputText from "primevue/inputtext"
import RadioButton from 'primevue/radiobutton'
import Slider from "primevue/slider"
import Dropdown from "@/components/controls/Dropdown.vue"
import LeafletMap from "@/components/common/LeafletMap.vue"
import InfiniteList from "@/components/controls/InfiniteList.vue"
import Breadcrumb from "primevue/breadcrumb"
import ProgressBar from "primevue/progressbar"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import AccordionTab from "primevue/accordiontab"
import Accordion from "primevue/accordion"
import TriStateDropdown from "@/components/controls/TriStateDropdown.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast from "@/util/toasts"
import Property from "@/model/gis/Property"
import {Watch} from "vue-property-decorator"
import {rpcClient} from "@/api/WebsocketClient"
import Settings from "@/model/settings/Settings"
import {settingsServiceApi} from "@/api/SettingsServiceApi"
import RpcError from "@/api/RpcError"
import {setElSeg} from "@fullcalendar/core"

@Options({
  name: "PropertyFilter",
  components: {
    TriStateDropdown, AnimatedInput, Button, Divider, TabPanel, TabView, Dropdown, LeafletMap, InfiniteList, Checkbox,
    Breadcrumb, InputText, Slider, RadioButton, ProgressBar, Accordion, AccordionTab
  },
  //@ts-ignore
  props: {
    properties: Array
  },
  emits: [
      'filter-changed'
  ]
})
export default class PropertyFilter extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  properties!: Property[]

  usageFilter: string = ""
  platFilter: string = ""
  nameFilter: string = ""
  sizeFilter: number[] = [0,0]
  buildingFilter: boolean | null = null
  availabilityFilter: boolean | null = null
  developmentPlanFilter: boolean | null = null
  archivedFilter: boolean = false

  showFilters: boolean = true

  get sizeSliderLabel(): string {
    const min: number = this.sizeFilter[0]
    const max: number = this.sizeFilter[1]
    const minString: string = this.convertSizeToString(min)
    const maxString: string = this.convertSizeToString(max)

    return this.i18n.$gettext('Fläche') + ': ' +
        this.i18n.$gettext('von') + ' ' + minString + ' ' +
        this.i18n.$gettext('bis') + ' ' + maxString
  }

  get maxSize() {
    let max: number = 0
    for (const property of (this.properties || [])) {
      if (property.size && property.size > max) {
        max = property.size
      }
    }
    if (this.sizeFilter[1] === 0 && this.properties?.length) {
      this.sizeFilter = [0, max]
    }
    return max
  }

  get propertiesFiltered(): Property[] {
    return (this.properties || []).filter((p: Property) => {
      let result: boolean = true
      if (this.usageFilter?.length) {
        result = result && !!p.allowedUsageTypes?.toLowerCase()?.includes(this.usageFilter.toLowerCase())
      }
      if (this.platFilter?.length) {
        result = result && !!p.plat?.toLowerCase()?.includes(this.platFilter.toLowerCase())
      }
      if (this.nameFilter?.length) {
        result = result && !!p.name?.toLowerCase()?.includes(this.nameFilter.toLowerCase())
      }
      if (this.sizeFilter[0] > 0) {
        result = result && (p.size || 0) >= this.sizeFilter[0]
      }
      if (this.sizeFilter[1] > 0) {
        result = result && (p.size || 0) <= this.sizeFilter[1]
      }
      if (this.buildingFilter !== null) {
        result = result && p.hasBuilding === this.buildingFilter
      }
      if (this.availabilityFilter !== null) {
        result = result && p.availability === this.availabilityFilter
      }
      if (this.developmentPlanFilter !== null) {
        result = result && p.regulatedInDevelopmentPlan === this.developmentPlanFilter
      }

      result = result && (Boolean(p.isArchived) === Boolean(this.archivedFilter))

      return result
    })
  }

  @Watch('properties')
  watchProperties() {
    if (this.properties) {
      this.$emit('filter-changed', this.propertiesFiltered)
    }
  }

  convertSizeToString(size: number): string {
    if (size < 10000) return this.sizeFormatted(size)
    const converted: number = size/10000
    return converted.toLocaleString() + this.i18n.$gettext('ha')
  }

  sizeFormatted(size: number | null): string {
    if(!size) {
      return this.i18n.$gettext("Unbekannt")
    } else {
      return size.toLocaleString() + this.i18n.$gettext('qm')
    }
  }

  get isInternalUser(): boolean{
    return rpcClient.isInternalUser
  }

  mounted() {
    if(this.isInternalUser) {
      this.showFilters = true
    } else {

      settingsServiceApi._getSettings().then((settings: Settings) => {
        if(this.isInternalUser) { //it may have changed since the first check
          this.showFilters = true
          return
        }
        if(settings.showFilterViewForExternalUsers !== null) {
          this.showFilters = settings.showFilterViewForExternalUsers
        } else {
          this.showFilters = this.isInternalUser
        }
      }).catch((e: RpcError) => {
        this.showFilters = this.isInternalUser
        //Do nothing, it may silently fail.
      })
    }
  }
}
