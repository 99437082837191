import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col-12 col-md-5" }
const _hoisted_4 = { class: "mb-0 font-weight-bold" }
const _hoisted_5 = { class: "col-12 col-md-7" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = {
  key: 1,
  class: "row"
}
const _hoisted_8 = { class: "col-12 col-md-5" }
const _hoisted_9 = { class: "mb-0 font-weight-bold" }
const _hoisted_10 = { class: "col-12 col-md-7" }
const _hoisted_11 = { class: "mb-0" }
const _hoisted_12 = {
  key: 2,
  class: "row"
}
const _hoisted_13 = { class: "col-12 col-md-5" }
const _hoisted_14 = { class: "mb-0 font-weight-bold" }
const _hoisted_15 = { class: "col-12 col-md-7" }
const _hoisted_16 = { class: "mb-0" }
const _hoisted_17 = {
  key: 3,
  class: "row"
}
const _hoisted_18 = { class: "col-12 col-md-5" }
const _hoisted_19 = { class: "mb-0 font-weight-bold" }
const _hoisted_20 = { class: "col-12 col-md-7" }
const _hoisted_21 = { class: "mb-0" }
const _hoisted_22 = {
  key: 4,
  class: "row"
}
const _hoisted_23 = { class: "col-12 col-md-5" }
const _hoisted_24 = { class: "mb-0 font-weight-bold" }
const _hoisted_25 = { class: "col-12 col-md-7" }
const _hoisted_26 = { class: "mb-0" }
const _hoisted_27 = {
  key: 5,
  class: "row"
}
const _hoisted_28 = { class: "col-12 col-md-5" }
const _hoisted_29 = { class: "mb-0 font-weight-bold" }
const _hoisted_30 = { class: "col-12 col-md-7" }
const _hoisted_31 = { class: "mb-0" }
const _hoisted_32 = {
  key: 6,
  class: "row"
}
const _hoisted_33 = { class: "col-12 col-md-5" }
const _hoisted_34 = { class: "mb-0 font-weight-bold" }
const _hoisted_35 = { class: "col-12 col-md-7" }
const _hoisted_36 = { class: "mb-0" }
const _hoisted_37 = { key: 7 }
const _hoisted_38 = {
  key: 0,
  class: "row"
}
const _hoisted_39 = { class: "col-12 col-md-5" }
const _hoisted_40 = { class: "mb-0 font-weight-bold" }
const _hoisted_41 = { class: "col-12 col-md-7" }
const _hoisted_42 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_43 = {
  key: 1,
  class: "mb-0"
}
const _hoisted_44 = {
  key: 1,
  class: "row"
}
const _hoisted_45 = { class: "col-12 col-md-5" }
const _hoisted_46 = { class: "mb-0 font-weight-bold" }
const _hoisted_47 = { class: "col-12 col-md-7" }
const _hoisted_48 = { class: "mb-0" }
const _hoisted_49 = {
  key: 2,
  class: "row"
}
const _hoisted_50 = { class: "col-12 col-md-5" }
const _hoisted_51 = { class: "mb-0 font-weight-bold" }
const _hoisted_52 = { class: "col-12 col-md-7" }
const _hoisted_53 = { class: "mb-0" }
const _hoisted_54 = {
  key: 3,
  class: "row"
}
const _hoisted_55 = { class: "col-12 col-md-5" }
const _hoisted_56 = { class: "mb-0 font-weight-bold" }
const _hoisted_57 = { class: "col-12 col-md-7" }
const _hoisted_58 = { class: "mb-0" }
const _hoisted_59 = {
  key: 4,
  class: "row"
}
const _hoisted_60 = { class: "col-12 col-md-5" }
const _hoisted_61 = { class: "mb-0 font-weight-bold" }
const _hoisted_62 = { class: "col-12 col-md-7" }
const _hoisted_63 = { class: "mb-0" }
const _hoisted_64 = {
  key: 5,
  class: "row"
}
const _hoisted_65 = { class: "col-12 col-md-5" }
const _hoisted_66 = { class: "mb-0 font-weight-bold" }
const _hoisted_67 = { class: "col-12 col-md-7" }
const _hoisted_68 = { class: "mb-0" }
const _hoisted_69 = {
  key: 6,
  class: "row"
}
const _hoisted_70 = { class: "col-12 col-md-5" }
const _hoisted_71 = { class: "mb-0 font-weight-bold" }
const _hoisted_72 = { class: "col-12 col-md-7" }
const _hoisted_73 = { class: "mb-0" }
const _hoisted_74 = {
  key: 7,
  class: "row"
}
const _hoisted_75 = { class: "col-12 col-md-5" }
const _hoisted_76 = { class: "mb-0 font-weight-bold" }
const _hoisted_77 = { class: "col-12 col-md-7" }
const _hoisted_78 = { class: "mb-0" }
const _hoisted_79 = {
  key: 8,
  class: "row"
}
const _hoisted_80 = { class: "col-12 col-md-5" }
const _hoisted_81 = { class: "mb-0 font-weight-bold" }
const _hoisted_82 = { class: "col-12 col-md-7" }
const _hoisted_83 = { class: "mb-0" }
const _hoisted_84 = {
  key: 9,
  class: "row"
}
const _hoisted_85 = { class: "col-12 col-md-5" }
const _hoisted_86 = { class: "mb-0 font-weight-bold" }
const _hoisted_87 = { class: "col-12 col-md-7" }
const _hoisted_88 = { class: "mb-0" }
const _hoisted_89 = {
  key: 10,
  class: "row"
}
const _hoisted_90 = { class: "col-12 col-md-5" }
const _hoisted_91 = { class: "mb-0 font-weight-bold" }
const _hoisted_92 = { class: "col-12 col-md-7" }
const _hoisted_93 = { class: "mb-0" }
const _hoisted_94 = {
  key: 11,
  class: "row"
}
const _hoisted_95 = { class: "col-12 col-md-5" }
const _hoisted_96 = { class: "mb-0 font-weight-bold" }
const _hoisted_97 = { class: "col-12 col-md-7" }
const _hoisted_98 = { class: "mb-0" }
const _hoisted_99 = {
  key: 0,
  class: "row mb-3"
}
const _hoisted_100 = { class: "col-12 col-md-5" }
const _hoisted_101 = { class: "mb-0 font-weight-bold" }
const _hoisted_102 = { class: "col-12 col-md-7" }
const _hoisted_103 = { class: "mb-0" }
const _hoisted_104 = {
  key: 1,
  class: "row mb-3"
}
const _hoisted_105 = { class: "col-12 col-md-5" }
const _hoisted_106 = { class: "mb-0 font-weight-bold" }
const _hoisted_107 = { class: "col-12 col-md-7" }
const _hoisted_108 = { class: "mb-0" }
const _hoisted_109 = { key: 2 }
const _hoisted_110 = { class: "col-12 col-md-5" }
const _hoisted_111 = { class: "mb-0 font-weight-bold" }
const _hoisted_112 = { class: "col-12 col-md-7" }
const _hoisted_113 = { class: "mb-0" }
const _hoisted_114 = {
  key: 3,
  class: "row mb-3"
}
const _hoisted_115 = { class: "col-12 col-md-5" }
const _hoisted_116 = { class: "mb-0 font-weight-bold" }
const _hoisted_117 = { class: "col-12 col-md-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "flat border" }, {
    header: _withCtx(() => [
      (_ctx.header)
        ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_ctx.header), 1))
        : _createCommentVNode("", true)
    ]),
    content: _withCtx(() => [
      (_ctx.organization.name)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Name")
                  ]),
                  _: 1
                }),
                _createTextVNode(": ")
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.organization.name), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.organization.identificationNumber)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Nationale Identifikationsnummer")
                  ]),
                  _: 1
                }),
                _createTextVNode(": ")
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.organization.identificationNumber), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.organization.nutsCode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("p", _hoisted_14, [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => [
                    _createTextVNode("NUTS Code")
                  ]),
                  _: 1
                }),
                _createTextVNode(": ")
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.organization.nutsCode), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.organization.registrationNumber)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("p", _hoisted_19, [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Handelsregister-Nr.")
                  ]),
                  _: 1
                }),
                _createTextVNode(": ")
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.organization.registrationNumber), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.organization.registrationCourt)
        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("p", _hoisted_24, [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Zuständiges Amtsgericht (Ort)")
                  ]),
                  _: 1
                }),
                _createTextVNode(": ")
              ])
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.organization.registrationCourt), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.organization.vat)
        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("p", _hoisted_29, [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Umsatzsteuer-ID")
                  ]),
                  _: 1
                }),
                _createTextVNode(": ")
              ])
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.organization.vat), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.organization.additionalInformation)
        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("p", _hoisted_34, [
                _createVNode(_component_translate, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Sonstiges")
                  ]),
                  _: 1
                }),
                _createTextVNode(": ")
              ])
            ]),
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.organization.additionalInformation), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.organization.contact)
        ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
            (_ctx.organization.contact.street || _ctx.organization.contact.street2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                  _createElementVNode("div", _hoisted_39, [
                    _createElementVNode("p", _hoisted_40, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Postanschrift")
                        ]),
                        _: 1
                      }),
                      _createTextVNode(": ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_41, [
                    (_ctx.organization.contact.street)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_42, _toDisplayString(_ctx.organization.contact.street), 1))
                      : _createCommentVNode("", true),
                    (_ctx.organization.contact.street2)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_43, _toDisplayString(_ctx.organization.contact.street2), 1))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.organization.contact.postOfficeBox)
              ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("p", _hoisted_46, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Postfach")
                        ]),
                        _: 1
                      }),
                      _createTextVNode(": ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_47, [
                    _createElementVNode("p", _hoisted_48, _toDisplayString(_ctx.organization.contact.postOfficeBox), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.organization.contact.zip)
              ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                  _createElementVNode("div", _hoisted_50, [
                    _createElementVNode("p", _hoisted_51, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Postleitzahl")
                        ]),
                        _: 1
                      }),
                      _createTextVNode(": ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_52, [
                    _createElementVNode("p", _hoisted_53, _toDisplayString(_ctx.organization.contact.zip), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.organization.contact.city)
              ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                  _createElementVNode("div", _hoisted_55, [
                    _createElementVNode("p", _hoisted_56, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Ort")
                        ]),
                        _: 1
                      }),
                      _createTextVNode(": ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_57, [
                    _createElementVNode("p", _hoisted_58, _toDisplayString(_ctx.organization.contact.city), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.organization.contact.country)
              ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                  _createElementVNode("div", _hoisted_60, [
                    _createElementVNode("p", _hoisted_61, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Land")
                        ]),
                        _: 1
                      }),
                      _createTextVNode(": ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_62, [
                    _createElementVNode("p", _hoisted_63, _toDisplayString(_ctx.organization.contact.country), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.organization.contact.department)
              ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
                  _createElementVNode("div", _hoisted_65, [
                    _createElementVNode("p", _hoisted_66, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Kontaktstelle")
                        ]),
                        _: 1
                      }),
                      _createTextVNode(": ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_67, [
                    _createElementVNode("p", _hoisted_68, _toDisplayString(_ctx.organization.contact.department), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.organization.contact.surName || _ctx.organization.contact.givenName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
                  _createElementVNode("div", _hoisted_70, [
                    _createElementVNode("p", _hoisted_71, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Ansprechpartner")
                        ]),
                        _: 1
                      }),
                      _createTextVNode(": ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_72, [
                    _createElementVNode("p", _hoisted_73, _toDisplayString(((_ctx.organization.contact.givenName || '') + ' ' + (_ctx.organization.contact.surName || '')).trim()), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.organization.contact.phone)
              ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
                  _createElementVNode("div", _hoisted_75, [
                    _createElementVNode("p", _hoisted_76, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Telefon")
                        ]),
                        _: 1
                      }),
                      _createTextVNode(": ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_77, [
                    _createElementVNode("p", _hoisted_78, _toDisplayString(_ctx.organization.contact.phone), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.organization.contact.mobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
                  _createElementVNode("div", _hoisted_80, [
                    _createElementVNode("p", _hoisted_81, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Mobiltelefon")
                        ]),
                        _: 1
                      }),
                      _createTextVNode(": ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_82, [
                    _createElementVNode("p", _hoisted_83, _toDisplayString(_ctx.organization.contact.mobile), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.organization.contact.facsimile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_84, [
                  _createElementVNode("div", _hoisted_85, [
                    _createElementVNode("p", _hoisted_86, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Fax")
                        ]),
                        _: 1
                      }),
                      _createTextVNode(": ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_87, [
                    _createElementVNode("p", _hoisted_88, _toDisplayString(_ctx.organization.contact.facsimile), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.organization.contact.email)
              ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
                  _createElementVNode("div", _hoisted_90, [
                    _createElementVNode("p", _hoisted_91, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("E-Mail")
                        ]),
                        _: 1
                      }),
                      _createTextVNode(": ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_92, [
                    _createElementVNode("p", _hoisted_93, _toDisplayString(_ctx.organization.contact.email), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.organization.contact.url)
              ? (_openBlock(), _createElementBlock("div", _hoisted_94, [
                  _createElementVNode("div", _hoisted_95, [
                    _createElementVNode("p", _hoisted_96, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Internet-Adresse (URL)")
                        ]),
                        _: 1
                      }),
                      _createTextVNode(": ")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_97, [
                    _createElementVNode("p", _hoisted_98, _toDisplayString(_ctx.organization.contact.url), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.organization.contact.additionalFields || []), (field) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "mb-3",
                key: field.label + field.contentId
              }, [
                (field.stringValue)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_99, [
                      _createElementVNode("div", _hoisted_100, [
                        _createElementVNode("p", _hoisted_101, _toDisplayString(_ctx.translate(field)) + ": ", 1)
                      ]),
                      _createElementVNode("div", _hoisted_102, [
                        _createElementVNode("p", _hoisted_103, _toDisplayString(field.stringValue), 1)
                      ])
                    ]))
                  : (field.longValue)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_104, [
                        _createElementVNode("div", _hoisted_105, [
                          _createElementVNode("p", _hoisted_106, _toDisplayString(_ctx.translate(field)) + ": ", 1)
                        ]),
                        _createElementVNode("div", _hoisted_107, [
                          _createElementVNode("p", _hoisted_108, _toDisplayString(field.longValue), 1)
                        ])
                      ]))
                    : (field.booleanValue)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_109, [
                          _createElementVNode("div", _hoisted_110, [
                            _createElementVNode("p", _hoisted_111, _toDisplayString(_ctx.translate(field)) + ": ", 1)
                          ]),
                          _createElementVNode("div", _hoisted_112, [
                            _createElementVNode("p", _hoisted_113, _toDisplayString(String(field.booleanValue || 'false') === 'true' ? _ctx.i18n.$gettext('Ja') : _ctx.i18n.$gettext('Nein')), 1)
                          ])
                        ]))
                      : (field.listValue)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_114, [
                            _createElementVNode("div", _hoisted_115, [
                              _createElementVNode("p", _hoisted_116, _toDisplayString(_ctx.translate(field)) + ": ", 1)
                            ]),
                            _createElementVNode("div", _hoisted_117, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.listValue, (item) => {
                                return (_openBlock(), _createBlock(_component_Checkbox, {
                                  checked: "",
                                  binary: "",
                                  disabled: "",
                                  label: item,
                                  key: item
                                }, null, 8, ["label"]))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}