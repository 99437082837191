import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-field-checkbox" }
const _hoisted_2 = {
  key: 0,
  for: "checkbox"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimeVueCheckbox = _resolveComponent("PrimeVueCheckbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PrimeVueCheckbox, {
      id: "checkbox",
      modelValue: _ctx.internalValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalValue) = $event)),
      value: _ctx.value,
      binary: _ctx.binary,
      disabled: _ctx.disabled
    }, null, 8, ["modelValue", "value", "binary", "disabled"]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true)
  ]))
}