import {toastServiceInstance} from "@/main"
import i18n from "@/util/i18n"

export class ToastAPI {

    //toastImplementation = toastImpl()
    defaultTimeout: number = 5000

    private toast(severity: "error" | "success" | "info" | "warn" | undefined, body: string = "", header: string, options: any = {}){
        const opts = {
            severity,
            summary: header,
            detail: body,
            life: this.defaultTimeout
        }

        Object.assign(opts, options)
        toastServiceInstance.add(opts)
    }

    success(body: string, header: string = "", options: any = {}){
        if(header === "") header = i18n.$gettext("Erfolg")
        this.toast('success', body, header, options)
    }

    info(body: string, header: string = "", options: any = {}){
        if(header === "") header = i18n.$gettext("Information")
        this.toast('info', body, header, options)
    }

    warn(body: string, header: string = "", options: any = {}){
        if(header === "") header = i18n.$gettext("Warnung")
        this.toast('warn', body, header, options)
    }

    error(body: string, header: string = "", options: any = {}){
        if(header === "") header = i18n.$gettext("Fehler")
        this.toast('error', body, header, options)
    }
}

export default function useToast(): ToastAPI {
    return new ToastAPI()
}
