import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createVNode as _createVNode, Teleport as _Teleport, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "w-100 flex-shrink-1 flex-grow-1 d-flex flex-column card bg-white p-5",
  style: {"overflow":"auto"}
}
const _hoisted_2 = { class: "card bg-white p-5 mb-4" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "card bg-white p-5 mb-4" }
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = { class: "card bg-white p-5 mb-4" }
const _hoisted_7 = { class: "mb-4" }
const _hoisted_8 = {
  key: 0,
  class: "card bg-white p-5 mb-4"
}
const _hoisted_9 = { class: "mb-4" }
const _hoisted_10 = { class: "d-flex flex-row align-items-center justify-content-between my-3" }
const _hoisted_11 = { class: "lead mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_translate = _resolveComponent("translate")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_AnimatedInput = _resolveComponent("AnimatedInput")!
  const _component_TipTapTextArea = _resolveComponent("TipTapTextArea")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!

  return (!_ctx.settings)
    ? (_openBlock(), _createBlock(_component_ProgressBar, {
        key: 0,
        mode: "indeterminate"
      }))
    : (_openBlock(), _createElementBlock("form", {
        key: 1,
        class: "container-wide flex-shrink-1 flex-grow-1 d-flex flex-column",
        style: {"min-height":"0"},
        onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent","stop"]))
      }, [
        (_openBlock(), _createBlock(_Teleport, { to: "#topmenu-buttons" }, [
          _createVNode(_component_Button, {
            type: "submit",
            class: "p-button-success mr-2",
            label: _ctx.i18n.$gettext('Speichern'),
            onClick: _withModifiers(_ctx.save, ["prevent","stop"])
          }, null, 8, ["label", "onClick"])
        ])),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h5", _hoisted_3, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Standardformulare")
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.settings.defaultTemplateSettings.konzVV,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settings.defaultTemplateSettings.konzVV) = $event)),
              class: "flex-grow-1 mb-2",
              loading: _ctx.projectTemplates.call?.loading,
              options: _ctx.allTemplates,
              "option-label": "label",
              "option-value": "value",
              label: _ctx.i18n.$gettext('Vergabe/Konzession/Verhandlung'),
              "empty-message": _ctx.i18n.$gettext('Keine Formulare vorhanden. Bitte erstellen Sie zunächst ein Formular.'),
              "scroll-height": "400px",
              filter: "",
              required: ""
            }, null, 8, ["modelValue", "loading", "options", "label", "empty-message"]),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.settings.defaultTemplateSettings.konzVD,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settings.defaultTemplateSettings.konzVD) = $event)),
              class: "flex-grow-1",
              loading: _ctx.projectTemplates.call?.loading,
              options: _ctx.allTemplates,
              "option-label": "label",
              "option-value": "value",
              label: _ctx.i18n.$gettext('Vergabe/Konzession/Dauerverhandlung'),
              "empty-message": _ctx.i18n.$gettext('Keine Formulare vorhanden. Bitte erstellen Sie zunächst ein Formular.'),
              "scroll-height": "400px",
              filter: "",
              required: ""
            }, null, 8, ["modelValue", "loading", "options", "label", "empty-message"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h5", _hoisted_5, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Sicherheit")
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_Checkbox, {
              modelValue: _ctx.settings.twoFactorAuthenticationRequired,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.settings.twoFactorAuthenticationRequired) = $event)),
              label: _ctx.i18n.$gettext('Zwei-Faktor-Authentifizierung erforderlich'),
              binary: ""
            }, null, 8, ["modelValue", "label"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h5", _hoisted_7, [
              _createVNode(_component_translate, null, {
                default: _withCtx(() => [
                  _createTextVNode("Seafile")
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_AnimatedInput, {
              modelValue: _ctx.settings.filesUrl,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.settings.filesUrl) = $event)),
              modelModifiers: { lazy: true, trim: true },
              class: "mb-3",
              type: "text",
              label: _ctx.i18n.$gettext('URL des Seafile-Servers')
            }, null, 8, ["modelValue", "label"]),
            _createVNode(_component_AnimatedInput, {
              modelValue: _ctx.settings.filesRepo,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.settings.filesRepo) = $event)),
              modelModifiers: { lazy: true, trim: true },
              type: "text",
              label: _ctx.i18n.$gettext('Bibliotheks-Id für Ausschreibungs-Archivierung')
            }, null, 8, ["modelValue", "label"])
          ]),
          (_ctx.settings.mailNotifications)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("h5", _hoisted_9, [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("E-Mail Benachrichtigungen")
                    ]),
                    _: 1
                  })
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.settings.mailNotifications), (key) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_Checkbox, {
                      modelValue: _ctx.settings.mailNotifications[key].enabled,
                      "onUpdate:modelValue": ($event: any) => ((_ctx.settings.mailNotifications[key].enabled) = $event),
                      class: "mb-3 flex-grow-1",
                      label: _ctx.labels[key]?.label,
                      disabled: _ctx.labels[key]?.required,
                      binary: ""
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "disabled"]),
                    _createVNode(_component_Button, {
                      icon: "pi pi-pencil",
                      label: _ctx.i18n.$gettext('Vorlage bearbeiten'),
                      onClick: ($event: any) => {_ctx.showEmailEditorDialog = true; _ctx.emailKey = key}
                    }, null, 8, ["label", "onClick"])
                  ]))
                }), 256)),
                _createVNode(_component_Dialog, {
                  visible: _ctx.showEmailEditorDialog,
                  "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showEmailEditorDialog) = $event)),
                  modal: "",
                  style: {"min-height":"90vh","max-width":"100vw","width":"600px"},
                  onHide: _ctx.hideEmailEditorDialog
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("p", _hoisted_11, [
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => [
                          _createTextVNode("E-Mail Vorlage bearbeiten")
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  footer: _withCtx(() => [
                    _createVNode(_component_Button, {
                      label: _ctx.i18n.$gettext('Abbrechen'),
                      icon: "pi pi-times",
                      class: "p-button-text",
                      onClick: _ctx.hideEmailEditorDialog
                    }, null, 8, ["label", "onClick"]),
                    _createVNode(_component_Button, {
                      label: _ctx.i18n.$gettext('Speichern'),
                      icon: "pi pi-check",
                      autofocus: "",
                      onClick: _ctx.save
                    }, null, 8, ["label", "onClick"])
                  ]),
                  default: _withCtx(() => [
                    (_ctx.settings.mailNotifications[_ctx.emailKey])
                      ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                          key: 0,
                          modelValue: _ctx.settings.mailNotifications[_ctx.emailKey].subject,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.settings.mailNotifications[_ctx.emailKey].subject) = $event)),
                          modelModifiers: { lazy: true, trim: true },
                          class: "mb-3",
                          type: "text",
                          label: _ctx.i18n.$gettext('Betreff')
                        }, null, 8, ["modelValue", "label"]))
                      : _createCommentVNode("", true),
                    (_ctx.settings.mailNotifications[_ctx.emailKey])
                      ? (_openBlock(), _createBlock(_component_TipTapTextArea, {
                          key: 1,
                          ref: "editor",
                          modelValue: _ctx.settings.mailNotifications[_ctx.emailKey].text,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.settings.mailNotifications[_ctx.emailKey].text) = $event)),
                          modelModifiers: { lazy: true, trim: true },
                          class: "mb-3 flex-grow-1",
                          type: "text",
                          placeholder: _ctx.i18n.$gettext('Text'),
                          "html-mode": ""
                        }, null, 8, ["modelValue", "placeholder"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["visible", "onHide"])
              ]))
            : _createCommentVNode("", true)
        ]),
        (_openBlock(), _createBlock(_Teleport, { to: "#breadcrumbs" }, [
          _createVNode(_component_Breadcrumb, {
            home: { icon: 'pi pi-home', to: '/' },
            model: _ctx.breadcrumbs
          }, null, 8, ["model"])
        ]))
      ], 32))
}