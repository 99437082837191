
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import ProjectTemplate from "@/model/ProjectTemplate"
import {projectTemplateServiceApi} from "@/api/ProjectTemplateServiceApi"
import SWR from "@/api/SWR"
import InfiniteList from "@/components/controls/InfiniteList.vue"
import ProgressBar from "primevue/progressbar"
import dayjs from "@/util/dayjs"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import RpcError from "@/api/RpcError"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"
import {MenuItem} from "primevue/menuitem"
import Breadcrumb from "primevue/breadcrumb"
import useToast from "@/util/toasts"
import {useConfirm} from "primevue/useconfirm"
import Project from "@/model/Project"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Tag from "primevue/tag"

@Options({
  name: "ProjectTemplateList",
  components: {
    Button, InfiniteList, ProgressBar, Breadcrumb, AnimatedInput, Tag
  }
})
export default class ProjectTemplateList extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  confirm = useConfirm()
  rpcClient = rpcClient

  searchTerm: string = ''
  sortBy: string[] = [ 'id:asc' ]

  get allTemplates(): { value: string, label: string, active: boolean }[] {
    const all: { value: string, label: string, active: boolean}[] = []
    const internalTemplates: ProjectTemplate[] = this.projectTemplates.data || []
    all.push(...internalTemplates.filter((t: ProjectTemplate) => {
      return t.id && t.name
    }).map((t: ProjectTemplate) => {
      return {
        value: String(t.id || '0'),
        label: String(t.id || '0') + ' - ' + SdkServiceClient.getLabelForNoticeType(t.name || ''),
        active: t.active !== null ? t.active : true }
    }))
    return all
  }

  get projectTemplates(): SWR<ProjectTemplate[], string[]> {
    return projectTemplateServiceApi.getProjectTemplates()
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    breadcrumbs.push({
      label: this.i18n.$gettext('Formulare'),
      to: '/formulare'
    })
    return breadcrumbs
  }

  matchesSearchTerm(template: { value: string, label: string }): boolean {
    const term = this.searchTerm?.toLowerCase()
    if (term && term.trim() !== '') {
      return Boolean(template && template.label?.toLowerCase()?.includes(term))
    } else {
      return true
    }
  }

  goToTemplate(id: number) {
    this.$router.push('/formulare/anlage/' + encodeURIComponent(id) + '#top')
  }

  duplicateTemplate(id: { value: string, label: string }) {
    const template: ProjectTemplate | null = projectTemplateServiceApi.getProjectTemplate(id.value).data
    if (template) {
      const duplicate = Object.assign(new ProjectTemplate(), template)
      if (template.id?.startsWith('EU-')) {
        duplicate.id = template.id + '-copy'
        duplicate.name = id.label
        duplicate.active = template.active !== null ? template.active : true
        duplicate.projectTypes = template.projectTypes || []
      } else {
        duplicate.id = null
      }
      projectTemplateServiceApi._saveProjectTemplate(duplicate).then(() => {
        this.toast.success(this.i18n.$gettext('Das Formular wurde dupliziert.'))
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Das Formular konnte nicht dupliziert werden.'), e.message)
      })
    } else {
      //TODO
    }
  }

  confirmDelete(id: string) {
    this.confirm.require({
      message: this.i18n.$gettext('Möchten Sie dieses Formular wirklich löschen?'),
      header: this.i18n.$gettext('Formular löschen'),
      icon: 'cil-warning',
      accept: () => {
        this.deleteTemplate(id)
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  deleteTemplate(id: string) {
    projectTemplateServiceApi._deleteProjectTemplate(id).then(() => {
      //TODO
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Das Formular konnte nicht gelöscht werden.'), e.message)
    })
  }

  dateString(date: string | null | undefined) {
    return date ? dayjs(date).format('LLL') : ''
  }

  setSort(by: string) {
    let direction: string
    if (this.sortBy.length > 0 && this.sortBy[0].startsWith(by + ':')) {
      direction = this.sortBy[0].endsWith('desc') ? 'asc' : 'desc'
    } else {
      direction = by === 'id' ? 'asc' : 'desc'
    }
    this.sortBy[0] = by + ':' + direction
  }

  sort(projectTemplates: { value: string, label: string }[]) {
    SortAndFilterUtil.sort(projectTemplates, this.sortBy)
    return projectTemplates
  }

  mounted() {
    void SdkServiceClient.fetchAll(this.i18n.current)
  }
}
