

import {Options, Vue} from "vue-class-component"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Watch} from "vue-property-decorator"
import AutoComplete from "primevue/autocomplete"
import Button from "primevue/button"
import User from "@/model/User"
import Organization from "@/model/Organization"

@Options({
  name: 'OrganizationPicker',
  //@ts-ignore
  props: {
    users: Array,
    organizations: Array,
    modelValue: Array,
    placeholder: String,
    disabled: Boolean,
    label: String
  },
  components: {
    AutoComplete, Button
  },
  emits : [
    'update:modelValue'
  ]
})
export default class OrganizationPicker extends Vue {

  i18n: Language = useGettext()

  users!: User[] | null
  organizations!: Organization[] | null
  modelValue!: { email: string, organizationName: string | null }[]
  placeholder!: string
  disabled!: boolean
  label!: string

  newUser: string | { email: string, organizationName: string | null } = ''
  filter: string = ''
  selectableUsers: { email: string, organizationName: string }[] = []
  selectedUsers: { email: string, organizationName: string | null }[] = []

  updateSelection() {
    let email: string
    if (this.newUser.hasOwnProperty('email')) {
      email = (this.newUser as { email: string, organizationName: string | null }).email
    } else {
      email = this.newUser as string
    }
    if (email && !this.selectedUsers.find(s => s.email === email)) {
      const organizationName = this.selectableUsers.find(s => s.email === email)?.organizationName || null
      this.filterUsers({ query: '' })
      this.selectedUsers.push({ email: email, organizationName: organizationName !== email ? organizationName : null })
      this.newUser = ''
    }
    this.$emit('update:modelValue', this.selectedUsers)
  }

  filterUsers(input: { query: string }) {
    this.filter = (input.query || '').toLowerCase()
    this.watchUsers((this.users || []))
  }

  @Watch('modelValue')
  watchSelection(modelValue: { email: string, organizationName: string | null }[], /* oldSelection: string[] */) {
    this.selectedUsers = modelValue
  }

  @Watch('users')
  watchUsers(newUsers: User[], /* oldUsers: any[] */) {
    const selectable: { email: string, organizationName: string }[] = []
    if (this.filter?.match(/.+@.+\..+/)) {
      selectable.push({ email: this.filter, organizationName: this.filter })
    }
    for (let user of newUsers) {
      const organization: Organization | undefined = user.organizationId ? this.organizations?.find(o => o.id === user.organizationId) : undefined
      if (organization?.name && user.email && (!this.filter ||
          (organization.name.toLowerCase().includes(this.filter)) ||
          (user.surName && user.surName.toLowerCase().includes(this.filter)) ||
          (user.givenName && user.givenName.toLowerCase().includes(this.filter)) ||
          (user.email && user.email.toLowerCase().includes(this.filter)))) {
        selectable.push({ email: user.email, organizationName: organization.name })
      }
    }
    this.selectableUsers = selectable
  }

  mounted() {
    this.watchSelection(this.modelValue || [])
    this.watchUsers((this.users || []))
  }

}
