
import {Options, Vue} from 'vue-class-component'
import Button from "primevue/button"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import SWR from "@/api/SWR"
import InfiniteList from "@/components/controls/InfiniteList.vue"
import ProgressBar from "primevue/progressbar"
import dayjs from "@/util/dayjs"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import ProjectForm from "@/components/admin/ProjectForm.vue"
import Dropdown from "@/components/controls/Dropdown.vue"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Message from "@/model/Message"
import {messageServiceApi} from "@/api/MessageServiceApi"
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import Dialog from "@/components/common/Dialog.vue"
import RpcError from "@/api/RpcError"
import MarkdownUtil from "@/util/MarkdownUtil"
import RoomDetails from "@/components/common/RoomDetails.vue"
import Offer from "@/model/Offer"
import useToast from "@/util/toasts"
import Multiselect from "@/components/controls/Multiselect.vue"
import Organization from "@/model/Organization"
import {organizationServiceApi} from "@/api/OrganizationServiceApi"
import Tag from "primevue/tag"
import {ref} from "@vue/reactivity"
import {Watch} from "vue-property-decorator"

@Options({
  name: "MessageList",
  components: {
    RoomDetails, Button, InfiniteList, ProgressBar, ProjectForm, Dropdown, AnimatedInput, TipTapTextArea, Dialog,
    Multiselect, Tag
  },
  //@ts-ignore
  props: {
    projectId: Number,
    offer: [ Offer, Object ]
  }
})
export default class MessageList extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient
  toast = useToast()

  projectId!: number
  offer!: Offer

  //@ts-ignore
  editor: TipTapTextArea = ref<TipTapTextArea>(null)

  isReply: boolean = false
  replyAttachment: string = ""
  selectedRecipients: number[] = [0]
  previouslySelectedRecipients: number[] = [0]
  showCreateMessageDialog: boolean = false
  recipientOrganizationId: number | null = null
  replyToMessageId: number | null = null
  messageSubject: string = ''
  messageText: string = ''
  messageRoomId: number | null = null
  showMessage: boolean = false
  message: Message | null = null

  sortBy: string[] = [ 'created:desc' ]
  searchTerm: string = ''

  get messages(): SWR<Message[], number[]> {
    return messageServiceApi.getMessages(this.projectId || this.offer?.projectId, null) //TODO optionally filter by offer id?
  }

  get organizations(): SWR<Organization[], number[]> | null {
    return rpcClient.isInternalUser ? organizationServiceApi.getOrganizations('CONTRACTOR') : null
  }

  get recipientsLabelText() {
    let text = this.i18n.$gettext('Empfänger')
    if (!this.selectedRecipients?.length) {
      text += ': ' + this.i18n.$gettext('Öffentlich')
    } else if (this.selectedRecipients?.includes(0)) {
      text += ': ' + this.i18n.$gettext('Alle Teilnehmer')
    }
    return text
  }

  markRead(message: Message) {
    if (message?.id) {
      void messageServiceApi._addTag(message.id, 'gelesen')
    }
  }

  processRecipientsSelection() {
    if (JSON.stringify(this.previouslySelectedRecipients) === JSON.stringify(this.selectedRecipients)) {
      return
    }
    if (this.selectedRecipients?.includes(0) && !this.previouslySelectedRecipients?.includes(0)) {
      this.selectedRecipients = [0]
    } else if (this.selectedRecipients?.find(r => r !== 0)) {
      this.selectedRecipients = this.selectedRecipients.filter(r => r !== 0)
    }
    this.previouslySelectedRecipients = [...this.selectedRecipients]
  }

  resetCreateMessageDialog() {
    this.selectedRecipients = []
    this.recipientOrganizationId = null
    this.messageSubject = ''
    this.messageText = ''
    this.showCreateMessageDialog = false
    this.isReply = false
  }

  matchesSearchTerm(message: Message): boolean {
    const term = this.searchTerm?.toLowerCase()
    if (term && term.trim() !== '') {
      return Boolean(message &&
          message.recipientNames?.find(n => n?.toLowerCase()?.includes(term)) ||
          message.senderName?.toLowerCase()?.includes(term) ||
          message.messageSubject?.toLowerCase()?.includes(term) ||
          message.messageText?.toLowerCase()?.includes(term))
    } else {
      return true
    }
  }

  setSort(by: string) {
    let direction: string
    if (this.sortBy.length > 0 && this.sortBy[0].startsWith(by + ':')) {
      direction = this.sortBy[0].endsWith('desc') ? 'asc' : 'desc'
    } else {
      direction = by === 'lastModified' ? 'desc' : 'asc'
    }
    this.sortBy[0] = by + ':' + direction
  }

  sort(messages: Message[]) {
    SortAndFilterUtil.sort(messages, this.sortBy)
    return messages
  }

  dateString(date: string | null | undefined) {
    return date ? dayjs(date).format('LL') : ''
  }

  dateTimeString(date: string | null | undefined) {
    return date ? dayjs(date).format('LLL') : ''
  }

  safeRender(messageText: string) {
    return MarkdownUtil.safeRenderMarkdown(messageText, true)
  }

  openMessage(message: Message) {
    this.showMessage = true
    this.message = message
    this.markRead(message)
  }

  closeMessage() {
    this.showMessage = false
    this.message = null
  }

  createCite(message: Message): string {
    if (!message.messageText) return ''
    let sender: string = message.senderName ? ' schrieb ' + message.senderName : ' geschrieben'
    let cite: string = '\n\nAm ' + dayjs(message.created).format('LLLL') + sender + ':'
    const text: string = message.messageText
    let start = 0, end = 0
    const nl = '\n'
    while ((end = text.indexOf(nl, start)) !== -1) {
      cite += '\n>' + text.substring(start, end)
      start = end + 1
    }
    if (start < text.length) {
      cite += '\n>' + text.substring(start)
    }
    return cite
  }

  reply(message: Message) {
    this.showMessage = false

    this.isReply = true
    this.recipientOrganizationId = message.senderOrganizationId
    this.showCreateMessageDialog = true
    this.messageSubject = 'Re: ' + message.messageSubject

    this.replyAttachment = this.createCite(message)
    this.replyToMessageId = message.id
  }

  @Watch('editor')
  updateReplyCitation() {
    if (this.reply && this.replyAttachment && this.editor) {
      this.editor.appendContent(this.replyAttachment)
      this.replyAttachment = ''
    }
  }


  openCreateMessageDialog() {
    if (rpcClient.fullyLoggedIn) {
      this.showCreateMessageDialog = true
    } else {
      rpcClient.nextRoute.afterLogin = this.$route
      this.$router.replace({ query: { message: 'nr_mes' } })
    }
  }

  createMessage() {
    const message: Message = new Message()
    message.replyToMessageId = this.replyToMessageId
    message.messageSubject = this.messageSubject
    message.messageText = this.messageText
    message.roomId = this.messageRoomId
    if (this.rpcClient.isInternalUser) {
      message.recipientOrganizationId = this.recipientOrganizationId
      message.recipientOrganizationIds = (this.selectedRecipients || []).map(id => String(id))
    } else {
      message.senderOrganizationId = rpcClient.session.user?.organizationId || this.offer?.organizationId || null
    }
    message.projectId = this.projectId
    return messageServiceApi._sendMessage(message).then((id: number) => {
      this.resetCreateMessageDialog()
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Die Nachricht konnte nicht gesendet werden.'))
    })
  }
}
