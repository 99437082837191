
import {Options, Vue} from 'vue-class-component'
import globalState from "@/util/GlobalState"
import breakpointUtil from "@/util/BreakpointUtil"
import Button from "primevue/button"

@Options({
  name: "Sidebar",
  components: {
    Button
  },
  //@ts-ignore
  props: {
    classes: [ String, Object, Array ]
  }
})
export default class Sidebar extends Vue {

  globalState = globalState
  breakpointUtil = breakpointUtil

}
