import Mandatory from '@/model/eform/Mandatory';
import Assert from '@/model/eform/Assert';
import Pattern from '@/model/eform/Pattern';

export default class Content {
  contentId!: string | null;
  contentType!: string | null;
  label!: string | null;
  ordinal!: string | null;
  version!: number | null;
  description!: string | null;
  repeatable!: boolean | null;
  displayType!: string | null;
  required!: boolean | null;
  alwaysShow!: boolean | null;
  hidden!: boolean | null;
  choices!: any | null;
  longValue!: number | null;
  stringValue!: string | null;
  dateValue!: string | null;
  dateTimeValue!: string | null;
  listValue!: string[] | null;
  booleanValue!: boolean | null;
  unitValue!: string | null;
  fields!: Content[] | null;
  children!: Content[] | null;
  added!: boolean | null;
  propertyIds!: number[] | null;
  readOnly!: boolean | null;
  schemeId!: string | null;
  schemeName!: string | null;
  idSchemes!: string[] | null;
  mandatory!: Mandatory | null;
  myAssert!: Assert | null;
  pattern!: Pattern | null;
  database!: any | null;

  constructor() {
    this.contentId = null;
    this.contentType = null;
    this.label = null;
    this.ordinal = null;
    this.version = null;
    this.description = null;
    this.repeatable = null;
    this.displayType = null;
    this.required = null;
    this.alwaysShow = null;
    this.hidden = null;
    this.choices = null;
    this.longValue = null;
    this.stringValue = null;
    this.dateValue = null;
    this.dateTimeValue = null;
    this.listValue = null;
    this.booleanValue = null;
    this.unitValue = null;
    this.fields = null;
    this.children = null;
    this.added = null;
    this.propertyIds = null;
    this.readOnly = null;
    this.schemeId = null;
    this.schemeName = null;
    this.idSchemes = null;
    this.mandatory = null;
    this.myAssert = null;
    this.pattern = null;
    this.database = null;
  }
}
