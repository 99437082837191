import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  id: "top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Panel = _resolveComponent("Panel")!

  return (!_ctx.project)
    ? (_openBlock(), _createBlock(_component_ProgressBar, {
        key: 0,
        mode: "indeterminate"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.panels, (panel, i) => {
          return (_openBlock(), _createBlock(_component_Panel, {
            panel: panel,
            key: 'panels-' + panel.id,
            id: panel.contentId + '-' + i,
            project: _ctx.project,
            class: "p-5 mb-4"
          }, null, 8, ["panel", "id", "project"]))
        }), 128))
      ]))
}