import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "flex-grow-1",
  style: {"min-width":"0"}
}
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-row fle-wrap"
}
const _hoisted_3 = { key: 6 }
const _hoisted_4 = { key: 7 }
const _hoisted_5 = { key: 8 }
const _hoisted_6 = {
  key: 1,
  class: "pl-2"
}
const _hoisted_7 = { class: "pi pi-question-circle text-primary" }
const _hoisted_8 = { class: "w-100 d-flex justify-content-end mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_AnimatedNumberInput = _resolveComponent("AnimatedNumberInput")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_AnimatedInput = _resolveComponent("AnimatedInput")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_FieldTemplate = _resolveComponent("FieldTemplate", true)!
  const _component_Button = _resolveComponent("Button")!
  const _component_OrganizationForm = _resolveComponent("OrganizationForm")!
  const _component_translate = _resolveComponent("translate")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_ctx.loadingList)
    ? (_openBlock(), _createBlock(_component_ProgressBar, {
        key: 0,
        mode: "indeterminate",
        class: "d-flex align-items-center"
      }))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["align-items-center", { 'd-none': _ctx.isHidden, 'd-flex': !_ctx.isHidden }])
      }, [
        (_ctx.field.ordinal)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["flex-shrink-0", { 'align-self-start': _ctx.typeOfField === 'MULTICHECKBOX' }])
            }, [
              _createElementVNode("h6", {
                class: "mb-0 mx-1",
                style: _normalizeStyle({ marginTop: _ctx.typeOfField === 'MULTICHECKBOX' ? '0.1rem' : '0' })
              }, _toDisplayString(_ctx.field.ordinal) + " ", 5)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          (_ctx.typeOfField === 'NUMBER')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_AnimatedNumberInput, {
                  class: "flex-grow-1",
                  modelValue: _ctx.field.longValue,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((_ctx.field.longValue) = $event)),
                    _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('value-changed', _ctx.field)))
                  ],
                  label: _ctx.labelTranslated,
                  disabled: _ctx.field.readOnly,
                  required: _ctx.field.required && (!_ctx.field.repeatable || _ctx.field.added),
                  type: "number"
                }, null, 8, ["modelValue", "label", "disabled", "required"]),
                (_ctx.unitChoices?.length)
                  ? (_openBlock(), _createBlock(_component_Dropdown, {
                      key: 0,
                      class: "flex-shrink-0 ml-3",
                      style: {"min-width":"260px"},
                      modelValue: _ctx.field.unitValue,
                      "onUpdate:modelValue": [
                        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.field.unitValue) = $event)),
                        _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('value-changed', _ctx.field)))
                      ],
                      options: _ctx.unitChoices,
                      optionLabel: "label",
                      optionValue: "value",
                      label: _ctx.unitLabel,
                      disabled: _ctx.field.readOnly
                    }, null, 8, ["modelValue", "options", "label", "disabled"]))
                  : _createCommentVNode("", true)
              ]))
            : (_ctx.typeOfField === 'DATE')
              ? (_openBlock(), _createBlock(_component_DatePicker, {
                  key: 1,
                  modelValue: _ctx.field.dateValue,
                  "onUpdate:modelValue": [
                    _cache[4] || (_cache[4] = ($event: any) => ((_ctx.field.dateValue) = $event)),
                    _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('value-changed', _ctx.field)))
                  ],
                  label: _ctx.labelTranslated,
                  disabled: _ctx.field.readOnly,
                  required: _ctx.field.required && (!_ctx.field.repeatable || _ctx.field.added),
                  "use-strings": ""
                }, null, 8, ["modelValue", "label", "disabled", "required"]))
              : (_ctx.typeOfField === 'TIME')
                ? (_openBlock(), _createBlock(_component_DatePicker, {
                    key: 2,
                    modelValue: _ctx.field.dateTimeValue,
                    "onUpdate:modelValue": [
                      _cache[6] || (_cache[6] = ($event: any) => ((_ctx.field.dateTimeValue) = $event)),
                      _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('value-changed', _ctx.field)))
                    ],
                    "time-only": "",
                    disabled: _ctx.field.readOnly,
                    label: _ctx.labelTranslated,
                    required: _ctx.field.required && (!_ctx.field.repeatable || _ctx.field.added),
                    "use-strings": ""
                  }, null, 8, ["modelValue", "disabled", "label", "required"]))
                : (_ctx.typeOfField === 'DATETIME')
                  ? (_openBlock(), _createBlock(_component_DatePicker, {
                      key: 3,
                      modelValue: _ctx.field.dateTimeValue,
                      "onUpdate:modelValue": [
                        _cache[8] || (_cache[8] = ($event: any) => ((_ctx.field.dateTimeValue) = $event)),
                        _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('value-changed', _ctx.field)))
                      ],
                      disabled: _ctx.field.readOnly,
                      label: _ctx.labelTranslated,
                      required: _ctx.field.required && (!_ctx.field.repeatable || _ctx.field.added),
                      "show-time": "",
                      "use-strings": ""
                    }, null, 8, ["modelValue", "disabled", "label", "required"]))
                  : (_ctx.typeOfField === 'TEXTBOX' || _ctx.typeOfField === 'TEXT')
                    ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                        key: 4,
                        modelValue: _ctx.field.stringValue,
                        "onUpdate:modelValue": [
                          _cache[10] || (_cache[10] = ($event: any) => ((_ctx.field.stringValue) = $event)),
                          _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('value-changed', _ctx.field)))
                        ],
                        label: _ctx.labelTranslated,
                        disabled: _ctx.field.readOnly,
                        required: _ctx.field.required && (!_ctx.field.repeatable || _ctx.field.added),
                        "auto-resize": ""
                      }, null, 8, ["modelValue", "label", "disabled", "required"]))
                    : (_ctx.typeOfField === 'TEXTAREA')
                      ? (_openBlock(), _createBlock(_component_AnimatedInput, {
                          key: 5,
                          modelValue: _ctx.field.stringValue,
                          "onUpdate:modelValue": [
                            _cache[12] || (_cache[12] = ($event: any) => ((_ctx.field.stringValue) = $event)),
                            _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('value-changed', _ctx.field)))
                          ],
                          label: _ctx.labelTranslated,
                          disabled: _ctx.field.readOnly,
                          required: _ctx.field.required && (!_ctx.field.repeatable || _ctx.field.added),
                          "auto-resize": ""
                        }, null, 8, ["modelValue", "label", "disabled", "required"]))
                      : (_ctx.typeOfField === 'MULTICHECKBOX')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createElementVNode("p", null, _toDisplayString(_ctx.labelTranslated), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.field.choices || []), (choice) => {
                              return (_openBlock(), _createElementBlock("div", null, [
                                _createVNode(_component_Checkbox, {
                                  modelValue: _ctx.field.listValue,
                                  "onUpdate:modelValue": [
                                    _cache[14] || (_cache[14] = ($event: any) => ((_ctx.field.listValue) = $event)),
                                    _cache[15] || (_cache[15] = ($event: any) => (_ctx.$emit('value-changed', _ctx.field)))
                                  ],
                                  name: _ctx.field.label,
                                  disabled: _ctx.field.readOnly,
                                  value: choice.name,
                                  label: choice.name
                                }, null, 8, ["modelValue", "name", "disabled", "value", "label"]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children(choice), (subfield) => {
                                  return (_openBlock(), _createBlock(_component_FieldTemplate, {
                                    field: subfield,
                                    class: "mt-2",
                                    onValueChanged: _cache[16] || (_cache[16] = f => _ctx.$emit('value-changed', f))
                                  }, null, 8, ["field"]))
                                }), 256))
                              ]))
                            }), 256))
                          ]))
                        : (_ctx.typeOfField === 'RADIO' && (_ctx.listEntries || []).length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.labelTranslated), 1),
                              _createVNode(_component_Dropdown, {
                                modelValue: _ctx.field.stringValue,
                                "onUpdate:modelValue": [
                                  _cache[17] || (_cache[17] = ($event: any) => ((_ctx.field.stringValue) = $event)),
                                  _cache[18] || (_cache[18] = ($event: any) => (_ctx.$emit('value-changed', _ctx.field)))
                                ],
                                options: _ctx.listEntries,
                                disabled: _ctx.field.readOnly,
                                optionLabel: "label",
                                optionValue: "value",
                                label: '',
                                required: _ctx.field.required && (!_ctx.field.repeatable || _ctx.field.added),
                                "show-clear": !_ctx.field.required
                              }, null, 8, ["modelValue", "options", "disabled", "required", "show-clear"]),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children(_ctx.field.stringValue), (subfield) => {
                                return (_openBlock(), _createBlock(_component_FieldTemplate, {
                                  field: subfield,
                                  class: "mt-2",
                                  onValueChanged: _cache[19] || (_cache[19] = f => _ctx.$emit('value-changed', f))
                                }, null, 8, ["field"]))
                              }), 256))
                            ]))
                          : (_ctx.typeOfField === 'CHECKBOX' || _ctx.typeOfField === 'RADIO')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createVNode(_component_Checkbox, {
                                  modelValue: _ctx.field.booleanValue,
                                  "onUpdate:modelValue": [
                                    _cache[20] || (_cache[20] = ($event: any) => ((_ctx.field.booleanValue) = $event)),
                                    _cache[21] || (_cache[21] = ($event: any) => (_ctx.$emit('value-changed', _ctx.field)))
                                  ],
                                  name: _ctx.field.label,
                                  label: _ctx.labelTranslated,
                                  disabled: _ctx.field.readOnly,
                                  binary: ""
                                }, null, 8, ["modelValue", "name", "label", "disabled"]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children(_ctx.field.booleanValue), (subfield) => {
                                  return (_openBlock(), _createBlock(_component_FieldTemplate, {
                                    field: subfield,
                                    class: "mt-2",
                                    onValueChanged: _cache[22] || (_cache[22] = f => _ctx.$emit('value-changed', f))
                                  }, null, 8, ["field"]))
                                }), 256))
                              ]))
                            : (_ctx.typeOfField === 'COMBOBOX')
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 9,
                                  class: _normalizeClass({ 'd-flex flex-row flex-wrap': _ctx.isOrganizationSelect })
                                }, [
                                  _createVNode(_component_Dropdown, {
                                    modelValue: _ctx.field.stringValue,
                                    "onUpdate:modelValue": [
                                      _cache[23] || (_cache[23] = ($event: any) => ((_ctx.field.stringValue) = $event)),
                                      _cache[24] || (_cache[24] = ($event: any) => (_ctx.$emit('value-changed', _ctx.field)))
                                    ],
                                    options: _ctx.listEntries,
                                    optionLabel: "label",
                                    optionValue: "value",
                                    label: _ctx.labelTranslated + (_ctx.isOrganizationSelect ? ' ' + _ctx.i18n.$gettext('(Organisation auswählen)') : ''),
                                    class: _normalizeClass({ 'flex-grow-1': _ctx.isOrganizationSelect }),
                                    disabled: _ctx.field.readOnly,
                                    required: _ctx.field.required && (!_ctx.field.repeatable || _ctx.field.added),
                                    "show-clear": !_ctx.field.required,
                                    filter: ""
                                  }, null, 8, ["modelValue", "options", "label", "class", "disabled", "required", "show-clear"]),
                                  (_ctx.isOrganizationSelect)
                                    ? (_openBlock(), _createBlock(_component_Button, {
                                        key: 0,
                                        class: "ml-3",
                                        icon: "pi pi-plus",
                                        label: _ctx.i18n.$gettext('Organisation anlegen'),
                                        onClick: _withModifiers(_ctx.openOrganizationForm, ["prevent","stop"])
                                      }, null, 8, ["label", "onClick"]))
                                    : _createCommentVNode("", true),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children(_ctx.field.stringValue), (subfield) => {
                                    return (_openBlock(), _createBlock(_component_FieldTemplate, {
                                      field: subfield,
                                      class: "mt-2",
                                      onValueChanged: _cache[25] || (_cache[25] = f => _ctx.$emit('value-changed', f))
                                    }, null, 8, ["field"]))
                                  }), 256))
                                ], 2))
                              : _createCommentVNode("", true)
        ]),
        (_ctx.hasHint)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
              _withDirectives(_createElementVNode("i", _hoisted_7, null, 512), [
                [
                  _directive_tooltip,
                  _ctx.hintTranslated,
                  void 0,
                  { left: true }
                ]
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.canRemove)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 2,
              icon: "pi pi-trash",
              class: "p-button-text border-primary flex-shrink-0 ml-3",
              label: _ctx.i18n.$gettext('Löschen'),
              onClick: _cache[26] || (_cache[26] = _withModifiers(($event: any) => (_ctx.$emit('remove-content')), ["stop","prevent"]))
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Dialog, {
          visible: _ctx.showOrganizationForm,
          "onUpdate:visible": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.showOrganizationForm) = $event)),
          header: _ctx.i18n.$gettext('Organisation anlegen'),
          onSubmit: _cache[29] || (_cache[29] = _withModifiers(($event: any) => {_ctx.$emit('create-organization', _ctx.field.contentId, _ctx.organization); _ctx.showOrganizationForm = false}, ["prevent"])),
          onHide: _cache[30] || (_cache[30] = ($event: any) => (_ctx.showOrganizationForm = false)),
          modal: ""
        }, {
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_Button, {
                onClick: _cache[27] || (_cache[27] = ($event: any) => (_ctx.organization = null)),
                class: "p-button-text border-primary bg-white mr-3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Abbrechen")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_Button, {
                type: "submit",
                class: "p-button-success"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Speichern")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_OrganizationForm, {
              project: _ctx.project,
              organization: _ctx.organization
            }, null, 8, ["project", "organization"])
          ]),
          _: 1
        }, 8, ["visible", "header"])
      ], 2))
}