
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import Project from "@/model/Project"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import {rpcClient} from "@/api/WebsocketClient"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import Organization from "@/model/Organization"
import DatePicker from "@/components/controls/DatePicker.vue"
import Room from "@/model/Room"
import Card from "primevue/card"
import PanelTemplate from "@/components/admin/PanelTemplate.vue"
import RoomTemplate from "@/model/RoomTemplate"
import FileUpload from "primevue/fileupload"
import SWR from "@/api/SWR"
import {organizationServiceApi} from "@/api/OrganizationServiceApi"
import Dropdown from "@/components/controls/Dropdown.vue"
import UserPicker from "@/components/controls/UserPicker.vue"
import {userServiceApi} from "@/api/UserServiceApi"
import User from "@/model/User"
import OrganizationForm from "@/components/common/OrganizationForm.vue"
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import RoomDetails from "@/components/common/RoomDetails.vue"
import ProjectTemplate from "@/model/ProjectTemplate"
import ProgressBar from "primevue/progressbar"
import {projectTemplateServiceApi} from "@/api/ProjectTemplateServiceApi"
import useToast from "@/util/toasts"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import RpcError from "@/api/RpcError"
import ProjectRole from "@/model/ProjectRole"
import { ref } from "@vue/reactivity"
import Panel from "@/model/common/Content"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"
import {useConfirm} from "primevue/useconfirm"
import Sidebar from "@/components/controls/Sidebar.vue"
import {Link, MenuStructure} from "@/util/MenuStructure"
import SidebarLink from "@/components/common/Link.vue"
import {MenuItem} from "primevue/menuitem"
import Breadcrumb from "primevue/breadcrumb"
import Checkbox from "@/components/controls/Checkbox.vue"
import Content from "@/model/common/Content"
import ContentUtil from "@/util/ContentUtil"
import {Watch} from "vue-property-decorator"
import globalState from "@/util/GlobalState"
import Property from "@/model/gis/Property"
import Dialog from "@/components/common/Dialog.vue"
import {propertyServiceApi} from "@/api/PropertyServiceApi"
import Field from "@/components/admin/Field.vue"
import dayjs from "@/util/dayjs"

@Options({
  name: "ProjectForm",
  components: {
    TipTapTextArea, OrganizationForm, UserPicker, AnimatedInput, Button, DatePicker, Card, Sidebar, SidebarLink, Checkbox,
    PanelTemplate, FileUpload, Dropdown, RoomDetails, ProgressBar, Accordion, AccordionTab, Breadcrumb, Dialog
  }
})
export default class ProjectForm extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient
  confirm = useConfirm()

  //@ts-ignore
  editor: TipTapTextArea = ref<TipTapTextArea>(null)

  step: number = 0
  project: Project = Object.assign(new Project(), {
    panels: [],
    roomTemplates: []
  })
  loading: boolean = false
  templateId: string | null = null
  template: SWR<ProjectTemplate | null, string> | null = null
  roomTemplateName: string = ''
  informationRoomName: string = ''
  teamMembers: User[] = rpcClient.session.user ? [ rpcClient.session.user ] : []
  teamRoles: { user: User, role: string }[] = rpcClient.session.user ? [ {
    user: rpcClient.session.user,
    role: 'ADMIN'
  } ] : []
  projectType: string = '0'
  lotToAddPropertyTo: Content | null = null

  ROLE_OPTIONS: { role: string, label: string }[] = [
    {
      role: 'ADMIN',
      label: this.i18n.$gettext('Projekt-Admin')
    }, {
      role: 'EDITOR',
      label: this.i18n.$gettext('Sachbearbeiter')
    }
  ]

  TYPE_OPTIONS: { type: string, label: string }[] = [
    {
      type: '0',
      label: this.i18n.$gettext('Verhandlungsverfahren')
    /*}, {
      type: '1',
      label: this.i18n.$gettext('Ohne Teilnahmewettbewerb')*/
    }, {
      type: '2',
      label: this.i18n.$gettext('Interessenbekundungsverfahren')
    }, {
      type: '3',
      label: this.i18n.$gettext('Bekanntmachung')
    }, {
      type: '4',
      label: this.i18n.$gettext('Vergebener Auftrag')
    }, {
      type: '5',
      label: this.i18n.$gettext('Dauerverhandlungsverfahren')
    }
  ]

  get name(): string {
    return ContentUtil.findContent(this.filterPanels, 'BT-21-Procedure')?.stringValue || ''
  }

  get allTemplates(): { value: string, label: string }[] {
    const all: { value: string, label: string }[] = []
    const internalTemplates: ProjectTemplate[] = this.projectTemplates.data || []
    all.push(...internalTemplates.filter((t: ProjectTemplate) => {
      return t.id && t.name
    }).map((t: ProjectTemplate) => {
      return { value: String(t.id || '0'), label: String(t.id || '0') + ' - ' + SdkServiceClient.getLabelForNoticeType(t.name || '') }
    }))
    return all.filter(o => !o.value?.startsWith('EU-') || o.label?.toLowerCase()?.includes('konzession'))
  }

  get projectTemplates(): SWR<ProjectTemplate[], string[]> {
    return projectTemplateServiceApi.getProjectTemplates()
  }

  get route(): string {
    const id: string = this.$route.params?.id as string
    let params = ''
    if (id) {
      params += '/' + id
    }
    const path = this.$route.path
    let route = params !== '' ? path.substring(0, this.$route.path.lastIndexOf(params)) : path
    if (!route.endsWith('/')) {
      route += '/'
    }
    return route
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    breadcrumbs.push({
      label: this.i18n.$gettext('Ausschreibungen'),
      to: '/projekte'
    })
    breadcrumbs.push({
      label: this.i18n.$gettext('Ausschreibung erstellen'),
      to: this.$route.path
    })
    return breadcrumbs
  }

  get users(): SWR<User[], string[]> {
    return userServiceApi.getUsers([ 'SYSTEM_USER' ])
  }

  get hasLots(): boolean {
    return this.filterPanels.filter(p => this.isLot(p) && (!p.repeatable || p.added)).length > 1
  }

  isLot(content: Content): boolean {
    return [ 'GR-Lot', 'GR-Part' ].includes(content.contentId || '')
  }

  get showChanges(): boolean {
    return !!this.project?.hasChanges
  }

  canRemove(content: Content) {
    return content.repeatable && (this.filterPanels.filter(p => p.contentId === content.contentId && p.repeatable && p.added).length > ((content.required || this.isLot(content)) ? 1 : 0))
  }

  repeatableIndex(content: Content) {
    return this.filterPanels.filter(p => p.contentId === content.contentId && (!p.repeatable || p.added)).indexOf(content) + 1
  }

  @Watch('project.organizationId')
  watchOrganization(oldId: number, newId: number) {
    if (oldId !== newId && this.project.organizationId) {
      const field = this.findField('OPT-300-Procedure-Buyer')
      if (field) {
        field.stringValue = String(this.project.organizationId)
      }
    }
  }

  createOrganization(contentId: string | null, organization: Organization) {
    if (organization) {
      organizationServiceApi._createOrganization(organization).then((id: number) => {
        if (contentId === 'OPT-300-Procedure-Buyer') {
          this.project.organizationId = id
        }
        if (contentId) {
          const field = ContentUtil.findField(this.project.panels, contentId)
          if (field) field.stringValue = String(id)
        }
      }).catch((e: RpcError) => {
        this.toast.error(this.i18n.$gettext('Die Organisation konnte nicht gespeichert werden.'))
      })
    }
  }

  fieldValueChanged(field: Content) {
    if (field.contentId === 'OPT-300-Procedure-Buyer') {
      this.project.organizationId = field.stringValue?.match(/\d+/) ? Number(field.stringValue) : field.longValue
    }
  }

  findField(contentId: string) {
    return ContentUtil.findField((this.project?.panels || []), contentId)
  }

  get filterPanels(): Content[] {
    const exclusionList = [ 'meta-data-group', 'GR-LotsGroup', 'GR-Organisations-Section' ]
    if (!this.showChanges) {
      exclusionList.push('GR-Change')
    }
    return (this.project?.panels || []).filter((p: Content) => {
      return !exclusionList.includes(p.contentId || '')
    }).map((p: Content) => {
      if (p.contentId === 'GR-Buyer') {
        return this.findOrganizationForm(p.children || []) || p
      } else {
        return p
      }
    })
  }

  findOrganizationForm(contents: Content[]): Content | null {
    return ContentUtil.findContent(contents, 'GR-ContractingAuthority')
  }

  get menuStructure(): Link[] {
    return MenuStructure.menuStructure(this.filterPanels)
  }

  translateLabel(panel: Content): string {
    return SdkServiceClient.getLabelForField(panel?.label)
  }

  updateTeamMembers(users: User[]) {
    for (let i = 0; i < this.teamRoles.length; i++) {
      const member: { user: User, role: string } = this.teamRoles[i]
      const user = users.find(u => u.email === member.user.email)
      if (!user) {
        this.teamRoles.splice(i, 1)
      }
    }
    for (let user of users) {
      const member = this.teamRoles.find(m => m.user.email === user.email)
      if (!member && user.email) {
        this.teamRoles.push({ user: user, role: 'EDITOR' })
      }
    }
  }

  displayName(user: User): string {
    let displayName = ''
    if (user.givenName || user.contact?.givenName) {
      displayName += user.givenName || user.contact?.givenName
    }
    if (user.surName || user.contact?.surName) {
      if (displayName) displayName += ' '
      displayName += user.surName || user.contact?.surName
    }
    return displayName
  }

  addInformationRoom() {
    if (!this.project.informationRooms) {
      this.project.informationRooms = []
    }
    this.project.informationRooms.push(Object.assign(new Room(), { name: this.informationRoomName, type: 'CLIENT' }))
    this.informationRoomName = ''
  }

  addRoomTemplate() {
    if (!this.project.roomTemplates) {
      this.project.roomTemplates = []
    }
    this.project.roomTemplates.push(Object.assign(new RoomTemplate(), { name: this.roomTemplateName }))
    this.roomTemplateName = ''
  }

  url: string = ''
  send() {
    this.project.name = this.name || this.project.name
    this.project.type = Number(this.projectType)
    this.project.team = this.teamRoles.map(m => {
      return Object.assign(new ProjectRole(), { userName: m.user.email, role: m.role })
    })
    let promise: Promise<number>
    if (this.project.id) {
      promise = projectServiceApi._updateProject(this.project, null, null)
    } else {
      promise = projectServiceApi._createProject(this.project)
    }
    promise.then((id: number) => {
      this.toast.success(this.i18n.$gettext('Das Verfahren wurde gespeichert.'))
      globalState.dataSaved = true
      this.$router.push('/projekte/' + id)
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Das Verfahren konnte nicht gespeichert werden.'), e.message)
    })
  }

  chooseTemplate() {
    if (!this.templateId) return
    this.loading = true
    projectTemplateServiceApi.getProjectTemplate(this.templateId).call?.promise?.then((templateId: string) => {
      this.template = projectTemplateServiceApi.getProjectTemplate(templateId)
      if (this.template?.data) {
        const name = this.project.name || ''
        const description = this.project.description || ''
        Object.assign(this.project, JSON.parse(JSON.stringify(this.template.data)))
        this.project.id = null
        this.project.templateId = this.templateId
        this.project.name = name
        this.project.description = description
        this.addDefaultRooms()
        this.setValuesFromQuery()
        this.step = 1
      } else {
        //TODO
      }
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Das Projektvorlage konnte nicht geladen werden.'), e.message)
    }).finally(() => {
      this.loading = false
    })
    /*const template = this.templateId ? this.templates.data?.find(t => String(t.id) === this.templateId) : null
    if (template) {
      const name = this.project.name || ''
      const description = this.project.description || ''
      Object.assign(this.project, JSON.parse(JSON.stringify(template)))
      this.project.id = null
      this.project.templateId = this.templateId
      this.project.name = name
      this.project.description = description
      this.addDefaultRooms()
      this.setValuesFromQuery()
      this.step = 1
    } else {
      //TODO
    }*/
  }

  addDefaultRooms() {
    this.project.informationRooms = this.project.informationRooms || []
    this.project.roomTemplates = this.project.roomTemplates || []
    if (!this.project.roomTemplates.find(t => t.name === this.i18n.$gettext('Ausschreibungs-Dokumente'))) {
      this.project.informationRooms.push(
          Object.assign(new Room(), {
            name: this.i18n.$gettext('Ausschreibungs-Dokumente'),
            description: '',//this.i18n.$gettext(''),
            type: 'CLIENT'
          })
      )
    }
    if (!this.project.roomTemplates.find(t => t.name === this.i18n.$gettext('Teilnahmeantrag'))) {
      this.project.roomTemplates.push(
          Object.assign(new RoomTemplate(), {
            name: this.i18n.$gettext('Teilnahmeantrag'),
            description: this.i18n.$gettext('Laden Sie hier Ihren Antrag zur Teilnahme an der Ausschreibung hoch.'),
            stage: 4
          })
      )
    }
    if (!this.project.roomTemplates.find(t => t.name === this.i18n.$gettext('Angebot'))) {
      this.project.roomTemplates.push(
          Object.assign(new RoomTemplate(), {
            name: this.i18n.$gettext('Angebot'),
            description: this.i18n.$gettext('Laden Sie hier Ihr Angebot hoch.'),
            stage: 7
          })
      )
    }
  }

  setValuesFromQuery() {
    if (this.project?.panels && this.$route.query) {
      for (const key in this.$route.query) {
        if (key === 'type') {
          this.projectType = this.$route.query[key]
        } else {
          ContentUtil.findFieldAndSetValue(key, this.$route.query[key], this.project.panels)
        }
      }
    }
  }

  mounted() {
    void SdkServiceClient.fetchAll(this.i18n.current)
    this.step = 0
    this.projectType = '0'
    if (this.route.includes('anlage') && this.$route.params?.id) {
      this.template = projectTemplateServiceApi.getProjectTemplate(String(this.$route.params?.id))
      if (this.template?.data) {
        this.project = Object.assign(new Project(), {
          panels: [],
          roomTemplates: []
        })
        const name = this.project.name || ''
        const description = this.project.description || ''
        Object.assign(this.project, JSON.parse(JSON.stringify(this.template.data)))
        this.project.id = null
        this.project.templateId = this.template.data?.id
        this.project.name = name
        this.project.description = description
        this.addDefaultRooms()
        this.setValuesFromQuery()
        this.step = 1
      } else if (this.template?.call?.promise) {
        this.loading = true
        this.template.call.promise.finally(() => {
          if (this.template?.data) {
            this.project = Object.assign(new Project(), {
              panels: [],
              roomTemplates: []
            })
            const name = this.project.name || ''
            const description = this.project.description || ''
            Object.assign(this.project, JSON.parse(JSON.stringify(this.template.data)))
            this.project.id = null
            this.project.templateId = this.template.data?.id
            this.project.name = name
            this.project.description = description
            this.addDefaultRooms()
            this.setValuesFromQuery()
            this.step = 1
            this.loading = false
          }
        })
      }
    } else if (this.$route.params?.id) {
      let swr: SWR<Project | null, number> = projectServiceApi.getProject(Number(this.$route.params?.id))
      if (swr.data) {
        this.setProject(swr.data)
      } else if (swr.call?.promise) {
        this.loading = true
        swr.call.promise.finally(() => {
          if (swr.data) {
            this.setProject(swr.data)
          }
          this.loading = false
        })
      }
    }
  }

  setProject(project: Project) {
    if (this.users.data?.length) {
      this.project = Object.assign(new Project(), {
        panels: [],
        roomTemplates: []
      })
      Object.assign(this.project, JSON.parse(JSON.stringify(project)))
      this.projectType = String(project.type)
      this.teamMembers = []
      this.teamRoles = []
      if (project.stage && project.stage >= 2 && project.stage < 7) {
        this.project.hasChanges = true
        //TODO: Do we need to mark changes as mandatory from now on?
      }
      (this.project.team || []).forEach((role: ProjectRole) => {
        const user = (this.users.data || []).find(u => u.email === role.userName) //TODO ensure users are loaded
        if (user) {
          this.teamMembers.push(user)
          this.teamRoles.push({ user: user, role: role.role || '' })
        }
      })
      this.step = 1
      this.setEditorContent(this.project.description)
    } else {
      setTimeout(() => {
        this.setProject(project)
      }, 10)
    }
  }

  setEditorContent(content: string | null) {
    if (this.editor) {
      this.editor.setContent(content || '')
    } else {
      setTimeout(() => {
        this.setEditorContent(content)
      }, 10)
    }
  }

  copyPanel(panel: Panel): Panel {
    const newPanel = Object.assign(new Panel(), JSON.parse(JSON.stringify(panel)))
    newPanel.added = true
    return newPanel
  }

  addDefaultPanel(i: number, panel: Panel) {
    if (!this.project?.panels?.find((p: Panel) => p.added && p.contentId === panel.contentId)) {
      const copy: Panel = this.copyPanel(panel)
      this.project?.panels?.splice(i, 0, copy)
    }
  }
}
