
import {Options, Vue} from 'vue-class-component'
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import ProgressBar from "primevue/progressbar"
import Checkbox from "@/components/controls/Checkbox.vue"
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import Settings from "@/model/settings/Settings"
import {settingsServiceApi} from "@/api/SettingsServiceApi"
import Button from "primevue/button"
import RpcError from "@/api/RpcError"
import Dialog from "@/components/common/Dialog.vue"
import ProjectTemplate from "@/model/ProjectTemplate"
import SdkServiceClient from "@/util/eforms/SdkServiceClient"
import SWR from "@/api/SWR"
import {projectTemplateServiceApi} from "@/api/ProjectTemplateServiceApi"
import Dropdown from "@/components/controls/Dropdown.vue"
import Breadcrumb from "primevue/breadcrumb"
import {MenuItem} from "primevue/menuitem"

@Options({
  name: "SystemSettings",
  components: {
    AnimatedInput, ProgressBar, Checkbox, TipTapTextArea, Button, Dialog, Dropdown, Breadcrumb
  },
  //@ts-ignore
  props: {

  }
})
export default class SystemSettings extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient

  emailKey: string = ''
  showEmailEditorDialog: boolean = false

  settings: Settings | null = null
  labels: any = {
    emailConfirmation: {
      required: true,
      label: this.i18n.$gettext('Bestätigung der E-Mail-Adresse nach Registrierung'),
      requiredParameters: []
    },
    inviteBySystemAdminIntern: {
      required: true,
      label: this.i18n.$gettext('Einladung interner Benutzer (Konto angelegt durch System-Admin)'),
      requiredParameters: []
    },
    inviteBySystemAdminExtern: {
      required: true,
      label: this.i18n.$gettext('Einladung externer Benutzer (Konto angelegt durch System-Admin)'),
      requiredParameters: []
    },
    inviteByOrganizationAdmin: {
      required: true,
      label: this.i18n.$gettext('Einladung (Konto angelegt durch Unternehmens-Admin)'),
      requiredParameters: []
    },
    passwordReset: {
      required: true,
      label: this.i18n.$gettext('Passwort zurücksetzen'),
      requiredParameters: []
    },
    inviteToApply: {
      required: true,
      label: this.i18n.$gettext('Einladung zu einer Ausschreibung'),
      requiredParameters: []
    },
    applicationPhaseStarted: {
      required: false,
      label: this.i18n.$gettext('Teilnahmewettbewerb gestartet (Information an Interessenten)'),
      requiredParameters: []
    },
    offerPhaseStarted: {
      required: false,
      label: this.i18n.$gettext('Angebotsphase gestartet (Information an Eingeladene Bewerber / Interessenten)'),
      requiredParameters: []
    },
    tenderCanceled: {
      required: false,
      label: this.i18n.$gettext('Ausschreibung abgebrochen (Information an Interessenten / Bewerber / Bieter)'),
      requiredParameters: []
    },
    fileUploadedByClient: {
      required: false,
      label: this.i18n.$gettext('Dokument hochgeladen von Vergabestelle'),
      requiredParameters: []
    },
    fileUploadedByContractor: {
      required: false,
      label: this.i18n.$gettext('Dokument hochgeladen von Bewerber / Bieter'),
      requiredParameters: []
    },
    messageSentByClient: {
      required: false,
      label: this.i18n.$gettext('Nachricht gesendet von Vergabestelle'),
      requiredParameters: []
    },
    messageSentByContractor: {
      required: false,
      label: this.i18n.$gettext('Nachricht gesendet von Interessent / Bewerber / Bieter'),
      requiredParameters: []
    },
    applicationStarted: {
      required: false,
      label: this.i18n.$gettext('Für Teilnahme registriert (Information an Interessenten)'),
      requiredParameters: []
    },
    applicationSubmitted: {
      required: false,
      label: this.i18n.$gettext('Teilnahmeantrag eingereicht (Information an Bewerber)'),
      requiredParameters: []
    },
    applicationDenied: {
      required: false,
      label: this.i18n.$gettext('Teilnahmeantrag abgelehnt (Information an Bewerber)'),
      requiredParameters: []
    },
    applicationAccepted: {
      required: false,
      label: this.i18n.$gettext('Teilnahmeantrag angenommen (Information an Bewerber)'),
      requiredParameters: []
    },
    offerExpected: {
      required: false,
      label: this.i18n.$gettext('Angebot erwartet (Information an Bewerber)'),
      requiredParameters: []
    },
    offerSubmitted: {
      required: false,
      label: this.i18n.$gettext('Angebot eingereicht (Information an Bieter)'),
      requiredParameters: []
    },
    offerDenied: {
      required: false,
      label: this.i18n.$gettext('Angebot abgelehnt (Information an Bieter)'),
      requiredParameters: []
    },
    offerAccepted: {
      required: false,
      label: this.i18n.$gettext('Angebot angenommen (Information an Bieter)'),
      requiredParameters: []
    },
    applicationDeadlineChanged: {
      required: false,
      label: this.i18n.$gettext('Teilnahmefrist geändert (Information an Interessenten)'),
      requiredParameters: []
    },
    offerDeadlineChanged: {
      required: false,
      label: this.i18n.$gettext('Angebotsfrist geändert (Information an Bieter / Interessenten)'),
      requiredParameters: []
    },
    openSealedInformationAttempted: {
      required: false,
      label: this.i18n.$gettext('Tresoröffnung wurde versucht (E-Mail an interne Benutzer)'),
      requiredParameters: []
    }
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    breadcrumbs.push({
      label: this.i18n.$gettext('Einstellungen'),
      to: '/einstellungen'
    })
    return breadcrumbs
  }

  get allTemplates(): { value: string, label: string }[] {
    const all: { value: string, label: string }[] = []
    const internalTemplates: ProjectTemplate[] = this.projectTemplates.data || []
    all.push(...internalTemplates.filter((t: ProjectTemplate) => {
      return t.id && t.name
    }).map((t: ProjectTemplate) => {
      return { value: String(t.id || '0'), label: String(t.id || '0') + ' - ' + SdkServiceClient.getLabelForNoticeType(t.name || '') }
    }))
    return all.filter(o => !o.value?.startsWith('EU-') || o.label?.toLowerCase()?.includes('konzession'))
  }

  get projectTemplates(): SWR<ProjectTemplate[], string[]> {
    return projectTemplateServiceApi.getProjectTemplates()
  }

  hideEmailEditorDialog() {
    this.showEmailEditorDialog = false
    this.$nextTick(() => {
      this.emailKey = ''
    })
  }

  save() {
    settingsServiceApi._setSettings(this.settings).then((settings: Settings) => {
      this.toast.success(this.i18n.$gettext('Die Einstellungen wurden gespeichert.'))
      this.hideEmailEditorDialog()
      this.settings = settings
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Die Einstellungen konnten nicht gespeichert werden.'))
    })
  }

  mounted() {
    settingsServiceApi._getSettings().then((settings: Settings) => {
      this.settings = settings
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Die Einstellungen konnten nicht geladen werden.'))
    })
  }
}
