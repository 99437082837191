export default class Room {
  id!: number | null;
  name!: string | null;
  description!: string | null;
  stage!: number | null;
  type!: string | null;
  projectId!: number | null;
  organizationId!: number | null;
  offerId!: number | null;
  messageId!: number | null;
  version!: number | null;
  updated!: string | null;
  created!: string | null;
  deleted!: string | null;
  database!: any | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.description = null;
    this.stage = null;
    this.type = null;
    this.projectId = null;
    this.organizationId = null;
    this.offerId = null;
    this.messageId = null;
    this.version = null;
    this.updated = null;
    this.created = null;
    this.deleted = null;
    this.database = null;
  }
}
