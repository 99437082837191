import alphaSort, {StringComparator} from 'alpha-sort'

const sortFunc: StringComparator = alphaSort({natural: true, caseInsensitive: true})

export default class SortAndFilterUtil {

    static compare(aVal: any | null | undefined, bVal: any| null | undefined): number {
        if (aVal === bVal || (!aVal && !bVal)) {
            return 0
        } else if (aVal === null || aVal === undefined) {
            return -1
        } else if (bVal === null || bVal === undefined) {
            return 1
        } else if (typeof aVal === "string" && typeof bVal === "string") {
            return sortFunc(aVal, bVal)
        } else {
            return aVal > bVal ? 1 : -1
        }
    }

    /* Sorts the array in place */
    static sort(items: any[], sorts?: string[] | string | null): any[] {
        if(sorts === null) return items
        if (sorts && !Array.isArray(sorts)) {
            sorts = [ sorts ]
        }
        if (sorts) {
            for (const sort of sorts) {
                const sortDef = sort.split(':')
                const props: string[] = sortDef[0].split('.')
                const direction: number = (sortDef.length < 2 || sortDef[1] == 'asc') ? 1 : -1
                items.sort((a: any, b: any) => {
                    const aVal: any | null | undefined = this.getNestedProp(a, props)
                    const bVal: any | null | undefined = this.getNestedProp(b, props)
                    return direction * this.compare(aVal, bVal)
                })
            }
        }
        return items
    }

    static getNestedProp(o: any, props: string[]): any | null | undefined {
        let current: any | null | undefined = o
        for (let i = 0; i < props.length; i++) {
            if (current !== Object(current)) {
                return undefined //current is primitive, can't have properties
            } else {
                current = current[props[i]]
            }
        }
        return current
    }

    /* Returns a new filtered array */
    static containsNone(items: any[], filter: any): any[] {
        return items.filter(item => {
            for (const key in filter) {
                if (filter[key] === null) { //Null means don't filter
                    return true
                } else if (filter.hasOwnProperty(key) && (Array.isArray(Object(item)[key]) || typeof Object(item)[key] === 'string')) {
                    if (Array.isArray(filter[key]) && filter[key].find((a: any) => Object(item)[key].includes(a))) {
                        return false
                    } else if (!Array.isArray(filter[key]) && Object(item)[key].includes(filter[key])) {
                        return false
                    }
                } else if (filter.hasOwnProperty(key)) {
                    if (Array.isArray(filter[key]) && filter[key].includes(Object(item)[key])) {
                        return false
                    } else if (!Array.isArray(filter[key]) && filter[key] === Object(item)[key]) {
                        return false
                    }
                }
            }
            return true
        })
    }

    /* Returns a new filtered array */
    static containsOne(items: any[], filter: any): any[] {
        return items.filter(item => {
            for (const key in filter) {
                if (filter[key] === null) { //Null means don't filter
                    return true
                } else if (filter.hasOwnProperty(key) && (Array.isArray(Object(item)[key]) || typeof Object(item)[key] === 'string')) {
                    if (Array.isArray(filter[key]) && filter[key].find((a: any) => Object(item)[key].includes(a))) {
                        return true
                    } else if (!Array.isArray(filter[key]) && Object(item)[key].includes(filter[key])) {
                        return true
                    }
                } else if (filter.hasOwnProperty(key)) {
                    if (Array.isArray(filter[key]) && filter[key].includes(Object(item)[key])) {
                        return true
                    } else if (!Array.isArray(filter[key]) && filter[key] === Object(item)[key]) {
                        return true
                    }
                }
            }
            return false
        })
    }

    /* Returns a new filtered array */
    static containsAll(items: any[], filter: any): any[] {
        return items.filter(item => {
            for (const key in filter) {
                if (filter[key] === null) { //Null means don't filter
                    return true
                } else if (filter.hasOwnProperty(key) && (Array.isArray(Object(item)[key]) || typeof Object(item)[key] === 'string')) {
                    if (Array.isArray(filter[key]) && filter[key].filter((a: any) => Object(item)[key].includes(a)).length !== filter[key].length) {
                        return false
                    } else if (!Array.isArray(filter[key]) && !Object(item)[key].includes(filter[key])) {
                        return false
                    }
                } else if (filter.hasOwnProperty(key)) {
                    if (Array.isArray(filter[key]) && filter[key].filter((a: any) => Object(item)[key] === a).length !== filter[key].length) {
                        return false
                    } else if (filter[key] !== Object(item)[key]) {
                        return false
                    }
                }
            }
            return true
        })
    }

    /* Returns a new filtered array */
    static filter(items: any[], filter: any): any[] {
        return items.filter(item => {
            for (const key in filter) {
                if (filter.hasOwnProperty(key) && filter[key] !== null) { //Null means don't filter
                    if (Array.isArray(filter[key]) && Array.isArray(Object(item)[key]) && filter[key].length &&
                        !!filter[key].find((f: any) => { return Object(item)[key].indexOf(f) < 0 })) {
                        return false
                    } else if (Array.isArray(filter[key]) && !Array.isArray(Object(item)[key]) && filter[key].length && filter[key].indexOf(Object(item)[key]) < 0) {
                        return false
                    } else if (!Array.isArray(filter[key]) && !Array.isArray(Object(item)[key]) && Object(item)[key] !== filter[key]) {
                        return false
                    } else if (!Array.isArray(filter[key]) && Array.isArray(Object(item)[key]) && Object(item)[key].indexOf(filter[key]) < 0) {
                        return false
                    }
                }
            }
            return true
        })
    }

    static arrayEquals(a: any[] | null | undefined, b: any[] | null | undefined): boolean {
        if (a === b) return true
        if (a === null || b === null || a === undefined || b === undefined) return false
        if (a.length !== b.length) return false

        const aSorted = [...a].sort()
        const bSorted = [...b].sort()

        for (let i = 0; i < aSorted.length; ++i) {
            if (aSorted[i] !== bSorted[i]) return false
        }

        return true
    }

    static filterByIds(items: any[], idProperty: string | null, idFilter: any[] | null): any[] {
        if (idFilter && idProperty) {
            return items.filter(i => idFilter.includes(i[idProperty]))
        } else {
            return items
        }
    }
}
