export default class Contact {
  id!: number | null;
  surName!: string | null;
  givenName!: string | null;
  department!: string | null;
  street!: string | null;
  street2!: string | null;
  postOfficeBox!: string | null;
  city!: string | null;
  zip!: string | null;
  country!: string | null;
  phone!: string | null;
  mobile!: string | null;
  facsimile!: string | null;
  email!: string | null;
  url!: string | null;
  projectUrl!: string | null;
  additionalFields!: any | null;
  organizationId!: number | null;
  userEmail!: string | null;

  constructor() {
    this.id = null;
    this.surName = null;
    this.givenName = null;
    this.department = null;
    this.street = null;
    this.street2 = null;
    this.postOfficeBox = null;
    this.city = null;
    this.zip = null;
    this.country = null;
    this.phone = null;
    this.mobile = null;
    this.facsimile = null;
    this.email = null;
    this.url = null;
    this.projectUrl = null;
    this.additionalFields = null;
    this.organizationId = null;
    this.userEmail = null;
  }
}
