
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import SWR from "@/api/SWR"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import {roomServiceApi} from "@/api/RoomServiceApi"
import Room from "@/model/Room"
import FileUpload from "primevue/fileupload"
import RoomDetails from "@/components/common/RoomDetails.vue"
import {rpcClient} from "@/api/WebsocketClient"
import Panel from "@/components/admin/Panel.vue"
import Card from "primevue/card"
import TwoFactorForm from "@/components/TwoFactorForm.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import OrganizationCard from "@/components/common/OrganizationCard.vue"
import UserPicker from "@/components/controls/UserPicker.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Router, useRouter} from "vue-router"
import useToast from "@/util/toasts"
import RpcError from "@/api/RpcError"
import TipTapTextArea from "@/components/controls/TipTapTextArea.vue"
import Dialog from "@/components/common/Dialog.vue"
import ProgressBar from "primevue/progressbar"
import Divider from "primevue/divider"
import MessageList from "@/components/common/MessageList.vue"
import Message from "primevue/message"
import Offer from "@/model/Offer"
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import ProjectWithInfo from "@/util/ProjectWithInfo"

@Options({
  name: "ProjectDocuments",
  components: {
    MessageList, TwoFactorForm, RoomDetails, AnimatedInput, Button, FileUpload, Message, Accordion, AccordionTab,
    Panel, Card, Checkbox, OrganizationCard, UserPicker, Dialog, TipTapTextArea, ProgressBar, Divider
  },
  //@ts-ignore
  props: {
    project: [ ProjectWithInfo, Object ],
    linkedProperties: {
      type: Array,
      default: []
    }
  }
})
export default class ProjectDocuments extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  toast = useToast()
  rpcClient = rpcClient

  project!: ProjectWithInfo

  showCreateRoomDialog: boolean = false
  roomName: string = ''
  roomDescription: string = ''

  get projectId(): number | null {
    return this.project?.id || null
  }

  get rooms(): SWR<Room[], number[]> | null {
    if (this.projectId) {
      return roomServiceApi.getRoomsByProjectId(this.projectId)
    } else {
      return null
    }
  }

  get hasLots(): boolean {
    return (this.project?.lots?.length || 0) > 1
  }

  get lots(): ProjectWithInfo[] {
    if (this.project?.lotsWithInfo?.length) {
      return this.project.lotsWithInfo
    } else if (this.project) {
      return [ this.project ]
    } else {
      return []
    }
  }

  //TODO check
  get offersWithCanUploadApplication(): Offer[] {
    return this.lots.filter(l => l.lotOffer && !l.lotOffer.stage && l.lotOffer.round === l.currentApplicationRound && (l.firstRoundActive || l.laterRoundActive)).map(l => l.lotOffer) as Offer[]
  }

  //TODO check
  get offersWithCanUploadOffer(): Offer[] {
    return this.lots.filter(l => l.lotOffer && l.lotOffer.stage && l.lotOffer.stage >= 4 && l.lotOffer.stage <= 5 && l.lotOffer.round === l.lastOpenedRound && l.offerPhaseActive).map(l => l.lotOffer) as Offer[]
  }

  roomDisabled(room: Room): boolean {
    return Boolean(!this.project || this.project.archived ||
        (room.stage || 0) < (this.project.stage || 0) ||
        (this.rpcClient.isInternalUser && room.type !== 'CLIENT') ||
        (!this.rpcClient.isInternalUser && (room.type !== 'CONTRACTOR' ||
            ((room.stage || 0) <= 4 && !this.offersWithCanUploadApplication.find(o => String(room.offerId) === String(o.id))) ||
            ((room.stage || 0) >= 6 && (room.stage || 0) <= 7 && !this.offersWithCanUploadOffer.find(o => String(room.offerId) === String(o.id))))))
  }

  resetCreateRoomDialog() {
    this.roomName = ''
    this.roomDescription = ''
    this.showCreateRoomDialog = false
  }

  createRoom() {
    return roomServiceApi._createRoom(Object.assign(new Room(), {
      name: this.roomName,
      description: this.roomDescription,
      projectId: this.projectId,
      type: 'CLIENT'
    })).then((id: number) => {
      this.resetCreateRoomDialog()
    }).catch((e: RpcError) => {
      this.toast.error(this.i18n.$gettext('Der Datenraum konnte nicht angelegt werden.'))
    })
  }
}
