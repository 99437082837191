

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import Divider from "primevue/divider"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import Property from "@/model/gis/Property"
import InputText from "primevue/inputtext"
import RadioButton from 'primevue/radiobutton'
import Slider from "primevue/slider"
import Location from "@/model/gis/Location"
import Dropdown from "@/components/controls/Dropdown.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import LeafletMap from "@/components/common/LeafletMap.vue"
import InfiniteList from "@/components/controls/InfiniteList.vue"
import Breadcrumb from "primevue/breadcrumb"
import { MenuItem } from 'primevue/menuitem'
import SWR from "@/api/SWR"
import ProgressBar from "primevue/progressbar"
import {propertyServiceApi} from "@/api/PropertyServiceApi"
import {locationServiceApi} from "@/api/LocationServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import AnimatedInput from "@/components/controls/AnimatedInput.vue"
import SubmittableDialog from "@/components/common/Dialog.vue"
import Checkbox from "@/components/controls/Checkbox.vue"
import useToast from "@/util/toasts"
import {ref} from "@vue/reactivity"
import AccordionTab from "primevue/accordiontab"
import Accordion from "primevue/accordion"
import TriStateDropdown from "@/components/controls/TriStateDropdown.vue"
import PropertyFilter from "@/components/properties/PropertyFilter.vue"

/* Leaflet map */

@Options({
  name: "PropertyList",
  components: {
    PropertyFilter, TriStateDropdown, AnimatedInput, Button, Divider, TabPanel, TabView, Dropdown, LeafletMap,
    InfiniteList, Checkbox, Breadcrumb, InputText, Slider, RadioButton, ProgressBar, SubmittableDialog,
    Accordion, AccordionTab
  }
})
export default class PropertyList extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  rpcClient = rpcClient

  showNewPropertyDialog: boolean = false
  newPropertyName: string = ""
  newPropertyLocation: number | null = null
  propertiesFiltered: Property[] | null = null

  //@ts-ignore
  tabView: TabView = ref<TabView>(null)

  //@ts-ignore
  leafLetMapRef: LeafletMap = ref<LeafletMap | null>(null)
  didCenterMap: boolean = false

  get properties(): SWR<Property[], number[]> {
    return propertyServiceApi.getProperties()
  }

  get propertiesClusteredByLocation(): {location: Location | null, properties: Property[] }[] {
    if (!this.locations.data || !this.properties.data) return []

    let result: {location: Location | null, properties: Property[] }[] = []

    this.locations.data.forEach((location: Location) => {
      result.push({location: location, properties: []})
    })


    let propertyCache: Property[] = [...(this.propertiesFiltered || this.properties.data || [])]
    let unknownGroup: {location: Location | null, properties: Property[] } = { location: null, properties: [] }

    while (propertyCache.length > 0) {
      let prop: Property | undefined = propertyCache.pop()
      if (!prop) break

      let foundGroup = result.find((val : {location: Location | null, properties: Property[] }) => {
        if (!prop?.locationId || !val.location?.id) return false
        return val.location.id === prop.locationId
      })

      if (foundGroup) {
        foundGroup.properties.push(prop)
      } else {
        unknownGroup.properties.push(prop)
      }
    }

    if (unknownGroup.properties.length > 0) {
      result.push(unknownGroup)
    }
    return result
  }

  get locations(): SWR<Location[], number[]> {
    const swr: SWR<Location[], number[]> = locationServiceApi.getLocations(false)
    swr.call?.promise?.then(() => {
      this.$nextTick(() => {
        if(this.leafLetMapRef && !this.didCenterMap) {
          this.leafLetMapRef.focusMapOnAllLocations()
          this.didCenterMap = true
        }
      })
    })
    return swr
  }

  get breadcrumbs(): MenuItem[] {
    const breadcrumbs: MenuItem[] = []
    breadcrumbs.push({
      label: this.i18n.$gettext('Grundstücke'),
      to: '/grundstuecke'
    })
    return breadcrumbs
  }

  goToProperty(propId: number): void {
    this.$router.push('/grundstuecke/' + propId)
  }

  sizeFormatted(size: number | null): string {
    if (!size) {
      return this.i18n.$gettext("Unbekannt")
    } else {
      return size.toLocaleString() + this.i18n.$gettext('qm')
    }
  }

  get hasCreateRights(): boolean {
    //TODO chack user role
    return true
  }

  resetNewModal(): void {
    this.showNewPropertyDialog = false
    this.newPropertyName = ""
    this.newPropertyLocation = null
  }

  createProperty(): void {
    if (!this.newPropertyLocation) return
    void propertyServiceApi._createProperty(this.newPropertyName, this.newPropertyLocation).then((id: number) =>{
      this.$router.push('/grundstuecke/' + id)
    })
  }
}

