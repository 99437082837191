import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Room from '@/model/Room';


export default class GeneratedRoomStore {

    state = reactive({
        rooms: new Map<number, Room>(),
        calls: new Map<string, number | number[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.rooms) this.state.rooms.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceRoom(newRoom: Room) {
        if (newRoom.id) {
            this.state.rooms.set(newRoom.id, newRoom)
            
        }
    }

    addOrReplaceRooms(newRooms: Room[]) {
        for (const newRoom of newRooms) {
            this.addOrReplaceRoom(newRoom)
        }
    }

    replaceRooms(rooms: Room[]) {
        const newRooms = new Map<number, Room>()
        for (const newRoom of rooms) {
            if (newRoom.id) {
                newRooms.set(newRoom.id, newRoom)
                
            }
        }
        this.state.rooms = newRooms
    }

    removeRoom(id: number | undefined | null) {
        if (id !== undefined && id !== null) {
            this.state.rooms.delete(id)
        }
    }

    removeRooms(ids: (number | null)[] | undefined | null) {
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                this.removeRoom(id)
            }
        }
    }
}